var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Tla,Ula,Vla,u4,Wla,z4,A4,B4,C4,Xla,Yla,Zla,$la,ama,bma,cma,dma,ema,M4,N4,O4,P4,fma,gma,L4,K4,hma;$CLJS.s4=function(a,b){$CLJS.ab(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
Tla=function(a){return function(b){var c=$CLJS.Re(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.rl(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=
f;k.h=e;k.g=d;return k}()}};Ula=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.y(b.slice(3),0,null):null;return $CLJS.Sa($CLJS.z($CLJS.OC(c,d)))?c:$CLJS.P.N($CLJS.oZ,c,d,e,b)};$CLJS.t4=function(a,b){return $CLJS.tY(a)&&$CLJS.F.g($CLJS.B(a),b)};
Vla=function(a,b){return $CLJS.B($CLJS.Ol(function(c){if($CLJS.n($CLJS.n(c)?b:c)){var d=$CLJS.yC.h(c);c=$CLJS.zC.h(c);d=$CLJS.n($CLJS.gh(d,b))?$CLJS.RC(b,d,c):null}else d=null;return d},a))};u4=function(a){a=$CLJS.V2(a);var b=$CLJS.SC(a);b||($CLJS.n(a)?(b=$CLJS.n(a)?!$CLJS.Gd($CLJS.q($CLJS.a3),$CLJS.V2(a).toLowerCase()):null,b=$CLJS.Sa(b)):b=null);return $CLJS.n(b)?a:Vla($CLJS.Zb($CLJS.q($CLJS.X2)),a)};
$CLJS.v4=function(a,b,c,d){if($CLJS.z(c)){d=$CLJS.Dk.g($CLJS.mY,d);var e=$CLJS.Wf.j($CLJS.Ug,$CLJS.Ze.h(function(f){return $CLJS.n3.v(a,b,f,c)}),d);return $CLJS.Dk.g(function(f){return $CLJS.S.j(f,$CLJS.E0,$CLJS.Gd(e,f))},c)}return null};$CLJS.w4=function(a){a=$CLJS.A3.h(a);return $CLJS.n(a)?new $CLJS.h(null,2,[$CLJS.lv,$CLJS.lJ,$CLJS.ki,a],null):null};$CLJS.x4=function(a){return $CLJS.F.g($CLJS.qE(a),$CLJS.jN)};
Wla=function(a,b){return $CLJS.n($CLJS.$G.h(a))?$CLJS.Ek.j(a,$CLJS.$G,function(c){return $CLJS.vd(c)?$CLJS.Dk.g(function(d){return $CLJS.y4.g?$CLJS.y4.g(d,b):$CLJS.y4.call(null,d,b)},c):c}):a};
z4=function(a){if($CLJS.n(a)){var b=$CLJS.bf;a:try{if($CLJS.yd(a)&&4===$CLJS.E(a))try{if($CLJS.bd(a,0)instanceof $CLJS.M)try{var c=$CLJS.bd(a,2);if($CLJS.yd(c)&&3===$CLJS.E(c))try{var d=$CLJS.bd(c,0);if($CLJS.fe(d,$CLJS.qI))try{var e=$CLJS.bd(a,3);if($CLJS.yd(e)&&3===$CLJS.E(e))try{var f=$CLJS.bd(e,0);if($CLJS.fe(f,$CLJS.qI)){$CLJS.bd(e,1);$CLJS.bd(e,2);$CLJS.bd(c,1);$CLJS.bd(c,2);$CLJS.bd(a,0);$CLJS.bd(a,1);var k=new $CLJS.Q(null,1,5,$CLJS.R,[!0],null)}else throw $CLJS.oX;}catch(m){if(m instanceof
Error){var l=m;if(l===$CLJS.oX)throw $CLJS.oX;throw l;}throw m;}else throw $CLJS.oX;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.oX)throw $CLJS.oX;throw l;}throw m;}else throw $CLJS.oX;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.oX)throw $CLJS.oX;throw l;}throw m;}else throw $CLJS.oX;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.oX)throw $CLJS.oX;throw l;}throw m;}else throw $CLJS.oX;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.oX)throw $CLJS.oX;throw l;}throw m;}else throw $CLJS.oX;
}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.oX){k=new $CLJS.Q(null,1,5,$CLJS.R,[!1],null);break a}throw l;}throw m;}a=$CLJS.B($CLJS.z(b($CLJS.Ta,k)))}else a=null;return a};A4=function(a){if($CLJS.n(z4(a))){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);$CLJS.H(a,3,null);return b}return null};B4=function(a){return $CLJS.n(z4(a))?($CLJS.H(a,0,null),$CLJS.H(a,1,null),$CLJS.H(a,2,null),$CLJS.H(a,3,null)):null};
C4=function(a,b){var c=$CLJS.y4;if($CLJS.Sa(z4(a)))return a;var d=$CLJS.H(a,0,null),e=$CLJS.H(a,1,null),f=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[d,e,f,$CLJS.P.j(c,a,b)],null)};
Xla=function(a,b,c){return $CLJS.sd($CLJS.rN.h(a))?a:$CLJS.n(b)?Ula(a,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.rN],null),function(d){return function l(f,k){try{if($CLJS.yd(k)&&3===$CLJS.E(k))try{var m=$CLJS.bd(k,0);if($CLJS.fe(m,$CLJS.qI))try{var t=$CLJS.bd(k,1);if(null!=t?t.C&256||$CLJS.r===t.kf||(t.C?0:$CLJS.Wa($CLJS.rb,t)):$CLJS.Wa($CLJS.rb,t))try{var u=$CLJS.J.j(t,$CLJS.qR,$CLJS.zX);if($CLJS.F.g(u,b))return $CLJS.bd(k,2),$CLJS.y4.g?$CLJS.y4.g(k,c):$CLJS.y4.call(null,k,c);throw $CLJS.oX;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.oX)throw $CLJS.oX;throw v;}throw x;}else throw $CLJS.oX;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.oX)throw $CLJS.oX;throw v;}throw x;}else throw $CLJS.oX;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.oX)throw $CLJS.oX;throw v;}throw x;}else throw $CLJS.oX;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.oX)return $CLJS.RY(l,f,k);throw v;}throw x;}}($CLJS.tf,d)}):$CLJS.Ek.j(a,$CLJS.rN,function(d){return $CLJS.Dk.g(function(e){return C4(e,$CLJS.G([c]))},d)})};
$CLJS.y4=function(a,b){var c=$CLJS.qE(a);switch(c instanceof $CLJS.M?c.T:null){case "field":return $CLJS.PG(a,$CLJS.NG,$CLJS.G([$CLJS.qR,b]));case "metadata/column":return $CLJS.NG(a,$CLJS.R0,b);case "mbql/join":return c=$CLJS.K2(a),Xla(Wla($CLJS.NG(a,$CLJS.dH,b),b),c,b);default:return b=$CLJS.XC($CLJS.SA),$CLJS.n($CLJS.WC("metabase.lib.join",b))&&$CLJS.VC("metabase.lib.join",b,$CLJS.dy.l($CLJS.G(["with-join-value should not be called with",$CLJS.Eh.l($CLJS.G([a]))])),null),a}};
$CLJS.D4=function(a,b,c){var d=$CLJS.S.l,e=$CLJS.NC($CLJS.o0,$CLJS.T)(c);a=$CLJS.L2($CLJS.dH.h(a),$CLJS.NC($CLJS.o0,$CLJS.T)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.S,c,$CLJS.o0,e,$CLJS.G([$CLJS.C0,b]))};Yla=function(a,b){return $CLJS.Sa(b)?a:$CLJS.Dk.g(function(c){var d=B4(c);d=$CLJS.n(d)?$CLJS.n($CLJS.K2(d))?null:C4(c,$CLJS.G([b])):null;return $CLJS.n(d)?d:c},a)};$CLJS.E4=function(a,b){b=Yla($CLJS.Dk.g($CLJS.nY,b),$CLJS.K2(a));return $CLJS.NG(a,$CLJS.rN,$CLJS.De(b))};
$CLJS.F4=function(a,b){b=b instanceof $CLJS.M?b:$CLJS.F.g(b,$CLJS.tf)?$CLJS.tC:$CLJS.De($CLJS.Wf.j($CLJS.tf,$CLJS.Bk.g($CLJS.Ze.h($CLJS.mY),function(){var c=$CLJS.K2(a);return $CLJS.n(c)?$CLJS.Ze.h(function(d){return $CLJS.y4(d,c)}):$CLJS.Rd}()),b));return $CLJS.NG(a,$CLJS.$G,b)};
Zla=function(a,b){b=$CLJS.Ol(function(d){return $CLJS.n3.g(d,a)},b);var c=$CLJS.CJ($CLJS.NC($CLJS.u1,$CLJS.v1),b);if($CLJS.n(c))return c;b=$CLJS.B(b);if($CLJS.n(b))return b;b=$CLJS.CJ($CLJS.u1,a);if($CLJS.n(b))return b;b=$CLJS.CJ($CLJS.v1,a);return $CLJS.n(b)?b:$CLJS.B(a)};$la=function(a){return"string"===typeof a?(0,$CLJS.ma)($CLJS.RC(a,/ id$/i,"")):null};
ama=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.Bh(function(){return u4(b)});var d=new $CLJS.Bh(function(){return u4(a)});return $CLJS.F.g(a,b)||$CLJS.F.g(a,$CLJS.q(c))||$CLJS.F.g($CLJS.q(d),b)||$CLJS.F.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
bma=function(a,b,c){var d=$CLJS.M1.g($CLJS.F.g($CLJS.lv.h(b),$CLJS.dY)?b:a,b),e=$CLJS.n(c)?$la($CLJS.M1.g(a,c)):null,f=ama(d,e);return function(){var k=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Sa($CLJS.fh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(k)?k:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
cma=function(a,b,c,d,e){return function m(k,l){try{if($CLJS.t4(l,$CLJS.qI)&&$CLJS.Dd($CLJS.n3.v(a,b,l,e)))return $CLJS.y4(l,d);throw $CLJS.oX;}catch(u){if(u instanceof Error){var t=u;if(t===$CLJS.oX)return $CLJS.RY(m,k,l);throw t;}throw u;}}($CLJS.tf,c)};
dma=function(a,b,c,d,e,f){c=cma(a,b,c,d,f);return function t(l,m){try{if($CLJS.yd(m)&&4===$CLJS.E(m))try{var u=$CLJS.bd(m,2);if($CLJS.t4(u,$CLJS.qI))try{var v=$CLJS.bd(m,3);if($CLJS.t4(v,$CLJS.qI)){var x=$CLJS.bd(m,3),A=$CLJS.bd(m,2),D=$CLJS.bd(m,0),I=$CLJS.bd(m,1),K=$CLJS.K2(A),U=$CLJS.K2(x);if($CLJS.Sa($CLJS.n(K)?K:U))return $CLJS.n($CLJS.n3.v(a,b,x,e))?new $CLJS.Q(null,4,5,$CLJS.R,[D,I,$CLJS.y4(A,d),x],null):new $CLJS.Q(null,4,5,$CLJS.R,[D,I,A,$CLJS.y4(x,d)],null);if($CLJS.F.g(K,d)&&$CLJS.F.g(U,
d)){var Z=$CLJS.PG(A,$CLJS.tk,$CLJS.G([$CLJS.qR])),da=$CLJS.PG(x,$CLJS.tk,$CLJS.G([$CLJS.qR])),xa=null==$CLJS.n3.v(a,b,Z,e);var Ha=xa?$CLJS.n3.v(a,b,da,e):xa;return $CLJS.n(Ha)?new $CLJS.Q(null,4,5,$CLJS.R,[D,I,A,da],null):new $CLJS.Q(null,4,5,$CLJS.R,[D,I,Z,x],null)}return m}throw $CLJS.oX;}catch(Qb){if(Qb instanceof Error){var mb=Qb;if(mb===$CLJS.oX)throw $CLJS.oX;throw mb;}throw Qb;}else throw $CLJS.oX;}catch(Qb){if(Qb instanceof Error){mb=Qb;if(mb===$CLJS.oX)throw $CLJS.oX;throw mb;}throw Qb;
}else throw $CLJS.oX;}catch(Qb){if(Qb instanceof Error){mb=Qb;if(mb===$CLJS.oX)return $CLJS.RY(t,l,m);throw mb;}throw Qb;}}($CLJS.tf,c)};ema=function(a,b){var c=$CLJS.EY();$CLJS.s4(c,b);return c(a)};
$CLJS.G4=function(a,b,c){if($CLJS.Gd(c,$CLJS.dH))return c;var d=$CLJS.BY(a,b),e=$CLJS.u2.j(a,b,d),f=$CLJS.z($CLJS.bf($CLJS.Ta,function v(t,u){try{if($CLJS.yd(u)&&1<=$CLJS.E(u))try{var x=$CLJS.Ik.j(u,0,1);if($CLJS.yd(x)&&1===$CLJS.E(x))try{var A=$CLJS.bd(x,0);if($CLJS.fe(A,$CLJS.qI))return new $CLJS.Q(null,1,5,$CLJS.R,[u],null);throw $CLJS.oX;}catch(I){if(I instanceof Error){var D=I;if(D===$CLJS.oX)throw $CLJS.oX;throw D;}throw I;}else throw $CLJS.oX;}catch(I){if(I instanceof Error){D=I;if(D===$CLJS.oX)throw $CLJS.oX;
throw D;}throw I;}else throw $CLJS.oX;}catch(I){if(I instanceof Error){D=I;if(D===$CLJS.oX)return $CLJS.xX(v,t,u);throw D;}throw I;}}($CLJS.tf,$CLJS.rN.h(c))));f=Zla(e,f);var k=ema(bma(a,c,f),$CLJS.Ol($CLJS.dH,$CLJS.AP.h(d))),l=$CLJS.s2.h($CLJS.F2.g(a,$CLJS.oN.h(c)));return $CLJS.y4($CLJS.Ek.j(c,$CLJS.rN,function(t){return $CLJS.Dk.g(function(u){return dma(a,b,u,k,e,l)},t)}),k)};$CLJS.H4=function(a){return $CLJS.rN.h(a)};
$CLJS.I4=function(a){return $CLJS.Tk.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.lv,$CLJS.sN,$CLJS.LG,a],null),$CLJS.F.g(a,$CLJS.jH)?new $CLJS.h(null,1,[$CLJS.Wh,!0],null):null]))};$CLJS.J4=function(a,b){b=$CLJS.B($CLJS.oN.h(b));return $CLJS.n($CLJS.KX.h(b))?$CLJS.K1(a,$CLJS.KX.h(b)):$CLJS.n($CLJS.bR.h(b))?$CLJS.I1(a,$CLJS.bR.h(b)):null};
M4=function(a){a=$CLJS.BJ(function(d){return $CLJS.v1(d)?K4:$CLJS.u1(d)?L4:$CLJS.dv},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,K4);var c=$CLJS.J.g(b,L4);b=$CLJS.J.g(b,$CLJS.dv);return $CLJS.Ye.l(a,c,$CLJS.G([b]))};N4=function(a,b,c,d){return $CLJS.Sa(c)?d:$CLJS.Dk.g(function(e){return $CLJS.n($CLJS.E0.h(e))?$CLJS.y3(e,$CLJS.w4(c)):e},$CLJS.v4(a,b,d,new $CLJS.Q(null,1,5,$CLJS.R,[c],null)))};O4=function(a,b,c){return $CLJS.CJ($CLJS.v1,$CLJS.u2.j(a,b,c))};
P4=function(a,b){var c=$CLJS.Oi.h(a);return $CLJS.n(c)?$CLJS.CJ(function(d){d=$CLJS.O(d);var e=$CLJS.J.g(d,$CLJS.D0);return $CLJS.u1(d)&&$CLJS.F.g(e,c)},b):null};fma={};gma=new $CLJS.M("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);L4=new $CLJS.M(null,"fk","fk",398442651);K4=new $CLJS.M(null,"pk","pk",-771936732);hma=new $CLJS.w("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);$CLJS.j2.m(null,$CLJS.jN,function(a,b,c){b=$CLJS.O(c);b=$CLJS.J.g(b,$CLJS.oN);b=$CLJS.H(b,0,null);c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.bR);c=$CLJS.J.g(c,$CLJS.KX);a=$CLJS.n(b)?$CLJS.xG.h($CLJS.I1(a,b)):null;if($CLJS.n(a))return a;a=$CLJS.n(c)?$CLJS.P2(c):null;return $CLJS.n(a)?a:$CLJS.SG("Native Query")});$CLJS.q2.m(null,$CLJS.jN,function(a,b,c){a=$CLJS.M1.j(a,b,c);c=$CLJS.dH.h(c);return new $CLJS.h(null,2,[$CLJS.T,$CLJS.n(c)?c:a,$CLJS.xG,a],null)});
$CLJS.p2.m(null,$CLJS.jN,function(){throw $CLJS.ai("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.j2.m(null,$CLJS.sN,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.LG);a=a instanceof $CLJS.M?a.T:null;switch(a){case "left-join":return $CLJS.SG("Left outer join");case "right-join":return $CLJS.SG("Right outer join");case "inner-join":return $CLJS.SG("Inner join");case "full-join":return $CLJS.SG("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.q2.m(null,$CLJS.sN,function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.LG);c=$CLJS.J.g(d,$CLJS.Wh);a=new $CLJS.h(null,2,[$CLJS.g2,$CLJS.kD(e),$CLJS.xG,$CLJS.M1.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.S.j(a,$CLJS.Wh,!0):a});
$CLJS.r2.m(null,$CLJS.jN,function(a,b,c,d){var e=$CLJS.O(c),f=$CLJS.J.g(e,$CLJS.dH),k=$CLJS.J.j(e,$CLJS.$G,$CLJS.tC),l=$CLJS.J.g(e,$CLJS.oN);c=$CLJS.O(d);var m=$CLJS.J.g(c,$CLJS.d2);if($CLJS.F.g(k,$CLJS.tC))return null;var t="undefined"!==typeof $CLJS.aO&&"undefined"!==typeof fma&&"undefined"!==typeof $CLJS.ima&&"undefined"!==typeof $CLJS.Q4?new $CLJS.Fc(function(){return $CLJS.Q4},$CLJS.od(hma,new $CLJS.h(null,1,[$CLJS.Qj,!0],null)),null):null,u=function(){var v=$CLJS.S.j(a,$CLJS.oN,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.F.g(k,$CLJS.MA)?$CLJS.s2.v(u,-1,$CLJS.qd(l),c):function(){return function A(x){return new $CLJS.le(null,function(){for(;;){var D=$CLJS.z(x);if(D){if($CLJS.zd(D)){var I=$CLJS.jc(D),K=$CLJS.E(I),U=$CLJS.oe(K);a:for(var Z=0;;)if(Z<K){var da=$CLJS.kd(I,Z);da=$CLJS.PG(da,$CLJS.tk,$CLJS.G([$CLJS.qR]));da=$CLJS.L1.j(u,-1,da);U.add(da);Z+=1}else{I=!0;break a}return I?$CLJS.re($CLJS.te(U),A($CLJS.lc(D))):$CLJS.re($CLJS.te(U),null)}U=$CLJS.B(D);U=$CLJS.PG(U,$CLJS.tk,
$CLJS.G([$CLJS.qR]));return $CLJS.ce($CLJS.L1.j(u,-1,U),A($CLJS.Kc(D)))}return null}},null,null)}(k)}();return $CLJS.Dk.g(function(v){v=$CLJS.S.j(v,$CLJS.P0,f);v=$CLJS.y4($CLJS.S.l(v,$CLJS.xG,$CLJS.M1.j(a,b,v),$CLJS.G([$CLJS.PM,$CLJS.z0])),f);return $CLJS.D4(e,m,v)},c)});$CLJS.t2.m(null,$CLJS.jN,function(a,b,c,d){return $CLJS.s2.v(a,b,$CLJS.S.j(c,$CLJS.$G,$CLJS.MA),d)});
var R4,jma=$CLJS.Oe($CLJS.N),kma=$CLJS.Oe($CLJS.N),lma=$CLJS.Oe($CLJS.N),mma=$CLJS.Oe($CLJS.N),nma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Yi,$CLJS.sE],null),$CLJS.Yi,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));R4=new $CLJS.Xh($CLJS.xh.g("metabase.lib.join","join-clause-method"),$CLJS.qE,nma,jma,kma,lma,mma);R4.m(null,$CLJS.jN,function(a){return a});R4.m(null,$CLJS.dY,function(a){return $CLJS.lY(new $CLJS.h(null,2,[$CLJS.lv,$CLJS.jN,$CLJS.oN,$CLJS.oN.h($CLJS.zY(a))],null))});
R4.m(null,$CLJS.RX,function(a){return $CLJS.lY(new $CLJS.h(null,2,[$CLJS.lv,$CLJS.jN,$CLJS.oN,new $CLJS.Q(null,1,5,$CLJS.R,[a],null)],null))});R4.m(null,$CLJS.gN,function(a){return $CLJS.lY(new $CLJS.h(null,2,[$CLJS.lv,$CLJS.jN,$CLJS.oN,new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.h(null,2,[$CLJS.KX,$CLJS.Oi.h(a),$CLJS.lv,$CLJS.RX],null)],null)],null))});
R4.m(null,$CLJS.UM,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.R0);a=$CLJS.J.g(b,gma);b=R4.h(new $CLJS.h(null,3,[$CLJS.lv,$CLJS.RX,$CLJS.FD,new $CLJS.h(null,1,[$CLJS.HG,$CLJS.p.h($CLJS.RG())],null),$CLJS.bR,$CLJS.Oi.h(b)],null));c=$CLJS.n(c)?$CLJS.y4(b,c):b;return $CLJS.n(a)?$CLJS.F4.g?$CLJS.F4.g(c,a):$CLJS.F4.call(null,c,a):c});
$CLJS.S4=function(){function a(d,e){return $CLJS.E4(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.u3(R4.h(d),$CLJS.$G,$CLJS.MA)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.oma=function(){function a(d,e,f){var k=$CLJS.S4.h(f);f=$CLJS.sd($CLJS.H4.h?$CLJS.H4.h(k):$CLJS.H4.call(null,k))?function(){var m=$CLJS.J4.g?$CLJS.J4.g(d,k):$CLJS.J4.call(null,d,k);return $CLJS.T4.j?$CLJS.T4.j(d,e,m):$CLJS.T4.call(null,d,e,m)}():null;f=$CLJS.n(f)?$CLJS.E4(k,new $CLJS.Q(null,1,5,$CLJS.R,[f],null)):k;var l=$CLJS.G4(d,e,f);return $CLJS.IY.l(d,e,$CLJS.Ek,$CLJS.G([$CLJS.AP,function(m){return $CLJS.$d.g($CLJS.uf(m),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}
var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.U4=function(){function a(d,e){return $CLJS.De($CLJS.J.g($CLJS.BY(d,e),$CLJS.AP))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.pma=function(){function a(d){d=$CLJS.B2($CLJS.H1(d));d=$CLJS.uC.h(d);return $CLJS.Wf.j($CLJS.tf,$CLJS.Bk.g($CLJS.af($CLJS.Me($CLJS.Gd,d)),$CLJS.Ze.h($CLJS.I4)),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.jH,$CLJS.ZG,$CLJS.nH,$CLJS.qH],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.qma=function(){function a(d,e,f,k){var l=$CLJS.x4(f)?$CLJS.K2(f):null,m=$CLJS.Wf.j($CLJS.Ug,$CLJS.Bk.g($CLJS.Ze.h($CLJS.K2),Tla(function(t){return $CLJS.zk.g(t,l)})),$CLJS.U4.g(d,e));f=$CLJS.n(k)?k:$CLJS.x4(f)?A4($CLJS.B($CLJS.H4(f))):null;return M4(N4(d,e,f,$CLJS.Ck.g(function(t){t=$CLJS.K2(t);return $CLJS.n(t)?$CLJS.Gd(m,t):null},$CLJS.u2.v(d,e,$CLJS.BY(d,e),new $CLJS.h(null,1,[$CLJS.Z1,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();
$CLJS.rma=function(){function a(d,e,f,k,l){k=$CLJS.x4(f)?$CLJS.J4(d,f):f;var m=$CLJS.x4(f)?$CLJS.K2(f):null;f=$CLJS.n(l)?l:$CLJS.x4(f)?B4($CLJS.B($CLJS.H4(f))):null;f=$CLJS.n(f)?$CLJS.Sa(m)?$CLJS.PG(f,$CLJS.tk,$CLJS.G([$CLJS.qR])):f:null;return M4(N4(d,e,f,$CLJS.Ze.g(function(t){t=$CLJS.S.j(t,$CLJS.PM,$CLJS.z0);return $CLJS.n(m)?$CLJS.y4(t,m):t},$CLJS.u2.v(d,e,k,new $CLJS.h(null,1,[$CLJS.Z1,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();$CLJS.sma=function(){function a(c,d,e){return b.v?b.v(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.s3}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.v=function(){return $CLJS.s3};return b}();
$CLJS.T4=function(){function a(d,e,f){function k(t,u){return $CLJS.r4.l($CLJS.q3.h($CLJS.Es),t,$CLJS.G([u]))}var l=function(){var t=O4(d,e,f);if($CLJS.n(t)){if($CLJS.n(t)){var u=$CLJS.u2.j(d,e,$CLJS.BY(d,e));u=P4(t,u)}else u=null;return $CLJS.n(u)?k(u,t):null}return null}();if($CLJS.n(l))return l;l=O4(d,e,$CLJS.BY(d,e));if($CLJS.n(l)){var m=P4(l,$CLJS.u2.j(d,e,f));return $CLJS.n(m)?k(l,m):null}return null}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.tma=function(){function a(e,f,k,l){l=$CLJS.n(l)?l:$CLJS.x4(k)?A4($CLJS.B($CLJS.H4(k))):null;$CLJS.n(l)?(l=$CLJS.P1.j(e,f,l),l=$CLJS.OC(l,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.HY,$CLJS.xG],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.AY(e,f)){l=$CLJS.U4.g(e,f);var m=$CLJS.sd(l);k=m?m:$CLJS.x4(k)?$CLJS.F.g($CLJS.dH.h(k),$CLJS.dH.h($CLJS.B(l))):null;k=$CLJS.n(k)?$CLJS.D1(e):k}else k=l;$CLJS.n(k)?(k=$CLJS.D1(e),k=$CLJS.I1(e,k),e=$CLJS.M1.j(e,f,k)):e=null;return $CLJS.n(e)?e:$CLJS.SG("Previous results")}
function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();
$CLJS.uma=function(){function a(d,e,f,k){f=$CLJS.nY.h(f);$CLJS.H(f,0,null);$CLJS.H(f,1,null);var l=$CLJS.H(f,2,null),m=$CLJS.H(f,3,null);k=k instanceof $CLJS.M?k:$CLJS.ki.h(k);e=$CLJS.AY(d,e);l=$CLJS.D3.j(d,e,l);d=$CLJS.D3.j(d,e,m);m=null==k||$CLJS.Gd($CLJS.Yg($CLJS.Ze.g($CLJS.ki,l)),k);d=null==k||$CLJS.Gd($CLJS.Yg($CLJS.Ze.g($CLJS.ki,d)),k);f=m?$CLJS.Ek.v(f,2,$CLJS.y3,k):f;return d?$CLJS.Ek.v(f,3,$CLJS.y3,k):f}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.m2.m(null,$CLJS.AP,function(a,b){var c=$CLJS.De($CLJS.U4.g(a,b));c=null==c?null:$CLJS.Ze.g(function(d){return $CLJS.M1.j(a,b,d)},c);return null==c?null:$CLJS.Du(" + ",c)});