var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var x_,pja,y_,qja,rja,sja,z_,uja,vja,wja,xja,C_,D_,E_,F_,H_,zja,I_,J_,Aja,Bja,Cja,B_,tja,K_,L_,yja,N_;x_=function(a,b){var c=$CLJS.z(b);for(a=$CLJS.z($CLJS.Te(a,b));;)if(a)c=$CLJS.C(c),a=$CLJS.C(a);else return c};
pja=function(){return function(){function a(k,l,m){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj.j?$CLJS.cj.j(k,l,m):$CLJS.cj.call(null,k,l,m),$CLJS.Zk.j?$CLJS.Zk.j(k,l,m):$CLJS.Zk.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj.g?$CLJS.cj.g(k,l):$CLJS.cj.call(null,k,l),$CLJS.Zk.g?$CLJS.Zk.g(k,l):$CLJS.Zk.call(null,k,l)],null)}function c(k){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj.h?$CLJS.cj.h(k):$CLJS.cj.call(null,k),$CLJS.Zk.h?$CLJS.Zk.h(k):$CLJS.Zk.call(null,
k)],null)}function d(){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj.o?$CLJS.cj.o():$CLJS.cj.call(null),$CLJS.Zk.o?$CLJS.Zk.o():$CLJS.Zk.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.y(A,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.P.N($CLJS.cj,m,t,u,v),$CLJS.P.N($CLJS.Zk,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.B(m);m=$CLJS.C(m);var u=$CLJS.B(m);m=$CLJS.C(m);var v=$CLJS.B(m);m=$CLJS.Kc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.y(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};y_=function(a){return $CLJS.LN($CLJS.hY)(a,$CLJS.tf,$CLJS.tf)};qja=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return null==e?d:$CLJS.S.j(d,f,e)},null==b?a:$CLJS.S.j(a,$CLJS.fI,b),$CLJS.bu(2,2,c))};rja=function(a,b){var c=$CLJS.Rl(a,$CLJS.DS);if($CLJS.n(c)){var d=$CLJS.S.j;c=$CLJS.zb(c);b=$CLJS.Cu.g?$CLJS.Cu.g(c,b):$CLJS.Cu.call(null,c,b);a=d.call($CLJS.S,a,$CLJS.DS,b)}return a};
sja=function(a){return $CLJS.Ye.g($CLJS.IX(a),$CLJS.JX(a))};z_=function(a,b,c){var d=new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.Gt,null,$CLJS.el,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.Nv(2,c):$CLJS.Nv(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?x_(2,c):x_(1,c);var f=$CLJS.H(b,0,null);return $CLJS.z(e)?$CLJS.oZ.j(a,e,function(k){var l=$CLJS.S.j(k,f,null);return $CLJS.yd(k)?$CLJS.Wf.j($CLJS.tf,$CLJS.Ck.h($CLJS.ll),l):$CLJS.wd(k)?$CLJS.UC($CLJS.Ta,l):l}):$CLJS.tk.g(a,f)};
uja=function(a){return $CLJS.ab(function(b,c){var d=$CLJS.H(c,0,null);$CLJS.H(c,1,null);return z_(b,tja,d)},a,sja(a))};
vja=function(a){for(var b=0;;){var c=$CLJS.bd($CLJS.oN.h(a),b);a:{var d=c;for(var e=$CLJS.tf;;){var f=$CLJS.B($CLJS.Ze.g(pja(),$CLJS.bf($CLJS.Bk.j(A_,$CLJS.B,$CLJS.Zk),$CLJS.Kt.h(y_(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);var l=z_(d,f,k),m=$CLJS.XC($CLJS.UA);if($CLJS.n($CLJS.WC("metabase.lib.convert",m))){var t=$CLJS.ND.l($CLJS.G([$CLJS.PB,$CLJS.Eh.l($CLJS.G([k]))])),u=$CLJS.ND,v=u.l,x=$CLJS.Eh,A=x.l;var D=f;D=$CLJS.n(D)?D:$CLJS.QN(y_(d));$CLJS.VC("metabase.lib.convert",
m,$CLJS.qY("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.G([t,v.call(u,$CLJS.G([$CLJS.PB,A.call(x,$CLJS.G([D]))])),$CLJS.ND.l($CLJS.G([$CLJS.EB,$CLJS.rY($CLJS.B($CLJS.sv(d,l)))]))])),null)}if($CLJS.F.g(l,d))break a;d=l;e=$CLJS.$d.g(e,new $CLJS.Q(null,2,5,$CLJS.R,[f,k],null))}else break a}}d=uja(d);if($CLJS.F.g(c,d)){if($CLJS.F.g(b,$CLJS.E($CLJS.oN.h(a))-1))return a;b+=1}else a=$CLJS.Ek.N(a,$CLJS.oN,$CLJS.S,b,d)}};
wja=function(a){var b=$CLJS.EY();return $CLJS.Dk.g(function(c){return $CLJS.F.g($CLJS.dH.h(c),"__join")?$CLJS.Ek.j(c,$CLJS.dH,b):c},a)};xja=function(a){return"string"===typeof $CLJS.bR.h(a)?$CLJS.tk.g($CLJS.S.j(a,$CLJS.KX,$CLJS.CY($CLJS.bR.h(a))),$CLJS.bR):a};C_=function(a,b){var c=$CLJS.J.j(a,b,B_);if($CLJS.F.g(c,B_))throw $CLJS.ai(["Unable to find ",$CLJS.Eh.l($CLJS.G([b]))," in map."].join(""),new $CLJS.h(null,2,[yja,a,$CLJS.Dy,b],null));return c};
D_=function(){return $CLJS.Ck.h(function(a){var b=$CLJS.H(a,0,null);$CLJS.H(a,1,null);(a=$CLJS.ke(b))?(b=$CLJS.ge(b),b=$CLJS.n(b)?$CLJS.F.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};E_=function(a){return $CLJS.Wf.j($CLJS.N,D_(),a)};F_=function(a){return $CLJS.De($CLJS.Wf.j($CLJS.N,$CLJS.Bk.g(D_(),$CLJS.Ck.h(function(b){var c=$CLJS.H(b,0,null);$CLJS.H(b,1,null);return $CLJS.F.g(c,$CLJS.xi)})),a))};
H_=function(a){var b=$CLJS.z(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);var d=$CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),$CLJS.Ze.h($CLJS.G_),c);a=$CLJS.F.g(a,$CLJS.ZH)&&$CLJS.C(c)?$CLJS.$d.g($CLJS.rd(d),new $CLJS.h(null,1,[$CLJS.Wh,$CLJS.qd(d)],null)):d;b=$CLJS.De(F_(b));return $CLJS.n(b)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qO,a,b],null):a};zja=function(a){return $CLJS.Wf.j($CLJS.tf,$CLJS.Bk.g($CLJS.Ze.h(function(b){return $CLJS.PC(b,$CLJS.SD)}),$CLJS.Ze.h($CLJS.G_)),$CLJS.yY.h(a))};
I_=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.oN);a=$CLJS.B($CLJS.ab(function(b,c){var d=$CLJS.H(b,0,null),e=$CLJS.H(b,1,null);b=$CLJS.R;var f=$CLJS.G_.h(c);d=$CLJS.n(d)?$CLJS.S.j(f,$CLJS.FS,d):f;d=$CLJS.n(e)?$CLJS.S.j(d,$CLJS.OQ,zja(e)):d;return new $CLJS.Q(null,2,5,b,[d,$CLJS.jX.h(c)],null)},null,a));return $CLJS.n($CLJS.gS.h(a))?$CLJS.wX(a,new $CLJS.h(null,1,[$CLJS.gS,$CLJS.EO],null)):a};
J_=function(a,b,c){var d=$CLJS.F.g($CLJS.E($CLJS.J.g(a,b)),1)?$CLJS.MY(a,b,$CLJS.Bk.g($CLJS.G_,$CLJS.B)):a;a=1<$CLJS.E($CLJS.J.g(a,b))?$CLJS.MY(d,b,function(e){return $CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.kt],null),$CLJS.Ze.h($CLJS.G_),e)}):d;return $CLJS.wX(a,$CLJS.Ce([b,c]))};Aja=function(a){var b=$CLJS.KX.h(a);return $CLJS.n(b)?$CLJS.S.j($CLJS.tk.g(a,$CLJS.KX),$CLJS.bR,["card__",$CLJS.p.h(b)].join("")):a};
Bja=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);Cja=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);B_=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);tja=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);K_=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
L_=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.M_=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);yja=new $CLJS.M(null,"m","m",1632677161);N_=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var A_,Dja,Eja,Fja,Gja,Hja,Ija,Jja,Kja,Lja,Mja;$CLJS.O_=$CLJS.N;$CLJS.P_=$CLJS.N;A_=new $CLJS.Tg(null,new $CLJS.h(null,7,[$CLJS.eY,null,$CLJS.$G,null,$CLJS.AP,null,$CLJS.WR,null,$CLJS.eI,null,$CLJS.LT,null,$CLJS.fI,null],null),null);Dja=$CLJS.Oe($CLJS.N);Eja=$CLJS.Oe($CLJS.N);Fja=$CLJS.Oe($CLJS.N);Gja=$CLJS.Oe($CLJS.N);Hja=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Yi,$CLJS.sE],null),$CLJS.Yi,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));
$CLJS.Q_=new $CLJS.Xh($CLJS.xh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.qE,Hja,Dja,Eja,Fja,Gja);$CLJS.Q_.m(null,$CLJS.Wh,function(a){if($CLJS.yd(a)&&$CLJS.B(a)instanceof $CLJS.M){var b=$CLJS.qd(a);var c=$CLJS.wd(b);a=c?$CLJS.rd(a):a;var d=$CLJS.z(a);a=$CLJS.B(d);d=$CLJS.C(d);b=$CLJS.lY($CLJS.Wf.j(new $CLJS.Q(null,2,5,$CLJS.R,[a,c?b:$CLJS.N],null),$CLJS.Ze.h($CLJS.Q_),d))}else b=a;return b});$CLJS.Q_.m(null,$CLJS.dY,function(a){return a});
$CLJS.Q_.m(null,$CLJS.RX,function(a){var b=$CLJS.Q_.h($CLJS.fI.h(a)),c=$CLJS.De($CLJS.Dk.g(function(t){var u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);return $CLJS.vY($CLJS.Q_.h(t),u)},$CLJS.WR.h(a))),d=$CLJS.Wf.j($CLJS.N,$CLJS.Sl(function(t,u){$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.HG);return new $CLJS.Q(null,2,5,$CLJS.R,[t,u],null)}),b),e=$CLJS.Wf.j($CLJS.N,$CLJS.Sl(function(t,u){$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.HG);return new $CLJS.Q(null,
2,5,$CLJS.R,[u,t],null)}),b),f=$CLJS.P_,k=$CLJS.O_;$CLJS.P_=d;$CLJS.O_=e;try{var l=qja(xja(a),b,$CLJS.G([$CLJS.WR,c])),m=$CLJS.ab(function(t,u){return $CLJS.Sa($CLJS.J.g(t,u))?t:$CLJS.Ek.j(t,u,$CLJS.Q_)},l,$CLJS.uk.l(A_,$CLJS.fI,$CLJS.G([$CLJS.WR])));return $CLJS.n($CLJS.AP.h(m))?$CLJS.Ek.j(m,$CLJS.AP,wja):m}finally{$CLJS.O_=k,$CLJS.P_=f}});$CLJS.Q_.m(null,$CLJS.YX,function(a){return rja(a,function(b){return $CLJS.MY(b,$CLJS.fO,$CLJS.Q_)})});
$CLJS.Q_.m(null,$CLJS.jN,function(a){a=$CLJS.Ek.j($CLJS.Ek.j(a,$CLJS.rN,$CLJS.Q_),$CLJS.oN,$CLJS.Q_);var b=$CLJS.n($CLJS.$G.h(a))?$CLJS.Ek.j(a,$CLJS.$G,function(c){return $CLJS.xl(c)?$CLJS.Dk.g($CLJS.Q_,c):$CLJS.rh.h(c)}):a;return $CLJS.Sa($CLJS.dH.h(a))?$CLJS.S.j(b,$CLJS.dH,"__join"):b});$CLJS.Q_.m(null,$CLJS.yD,function(a){return $CLJS.Dk.g($CLJS.Q_,a)});
$CLJS.Q_.m(null,$CLJS.AD,function(a){return $CLJS.n($CLJS.cj.h(a))?vja($CLJS.S.j($CLJS.Ek.j($CLJS.zY(a),$CLJS.oN,function(b){return $CLJS.Dk.g($CLJS.Q_,b)}),$CLJS.M_,!0)):$CLJS.Cu(a,$CLJS.Q_)});$CLJS.Q_.m(null,$CLJS.qI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=$CLJS.wd(b)?new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null):new $CLJS.Q(null,2,5,$CLJS.R,[b,a],null);b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return $CLJS.lY(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qI,a,b],null))});
$CLJS.Q_.m(null,$CLJS.tj,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=$CLJS.wX(a,new $CLJS.h(null,3,[$CLJS.rF,$CLJS.ED,$CLJS.AQ,$CLJS.Kj,$CLJS.TT,$CLJS.DG],null));var c=$CLJS.S.j;var d=$CLJS.xi.h(a);$CLJS.n(d)||(d=$CLJS.ED.h(a),d=$CLJS.n(d)?d:$CLJS.VG(b));a=c.call($CLJS.S,a,$CLJS.xi,d);return $CLJS.lY(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tj,a,b],null))});
$CLJS.Q_.m(null,$CLJS.ZH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.Wh.h(c);b=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ZH,$CLJS.tk.g(c,$CLJS.Wh),$CLJS.Dk.g($CLJS.Q_,b)],null);b=$CLJS.lY(b);return null!=a?$CLJS.$d.g(b,$CLJS.Q_.h(a)):b});$CLJS.Q_.m(null,$CLJS.BD,function(a){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.lY(new $CLJS.Q(null,3,5,$CLJS.R,[b,a,c],null))});
$CLJS.Q_.m(null,$CLJS.fI,function(a){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.lY(new $CLJS.Q(null,3,5,$CLJS.R,[b,a,C_($CLJS.P_,c)],null))});$CLJS.Q_.m(null,$CLJS.qO,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);b=$CLJS.Q_.h(b);var c=$CLJS.z(b);b=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);d=$CLJS.C(d);return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.Tk.l($CLJS.G([c,a]))],null),d)});Ija=$CLJS.Oe($CLJS.N);Jja=$CLJS.Oe($CLJS.N);
Kja=$CLJS.Oe($CLJS.N);Lja=$CLJS.Oe($CLJS.N);Mja=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Yi,$CLJS.sE],null),$CLJS.Yi,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));$CLJS.G_=new $CLJS.Xh($CLJS.xh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.qE,Mja,Ija,Jja,Kja,Lja);
$CLJS.G_.m(null,$CLJS.Wh,function(a){if($CLJS.yd(a)&&$CLJS.B(a)instanceof $CLJS.M){a=$CLJS.z(a);var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);if($CLJS.wd(a)){c=$CLJS.Wf.g;b=new $CLJS.Q(null,1,5,$CLJS.R,[b],null);var e=$CLJS.Ye.g;d=$CLJS.Ze.g($CLJS.G_,d);a=F_(a);a=c.call($CLJS.Wf,b,e.call($CLJS.Ye,d,$CLJS.n(a)?new $CLJS.Q(null,1,5,$CLJS.R,[a],null):null))}else a=$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[b],null),$CLJS.Ze.g($CLJS.G_,$CLJS.ce(a,d)))}else a=$CLJS.wd(a)?$CLJS.Cu(E_(a),$CLJS.G_):
a;return a});for(var R_=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[N_,L_],null)),S_=null,T_=0,U_=0;;)if(U_<T_){var Nja=S_.X(null,U_);$CLJS.zH(Nja,K_);U_+=1}else{var V_=$CLJS.z(R_);if(V_){var W_=V_;if($CLJS.zd(W_)){var X_=$CLJS.jc(W_),Oja=$CLJS.lc(W_),Pja=X_,Qja=$CLJS.E(X_);R_=Oja;S_=Pja;T_=Qja}else{var Rja=$CLJS.B(W_);$CLJS.zH(Rja,K_);R_=$CLJS.C(W_);S_=null;T_=0}U_=0}else break}
for(var Y_=$CLJS.z(new $CLJS.Q(null,12,5,$CLJS.R,[$CLJS.gA,$CLJS.kI,$CLJS.zI,$CLJS.VH,$CLJS.Xj,$CLJS.EI,$CLJS.Jn,$CLJS.bI,$CLJS.xI,$CLJS.GI,$CLJS.DI,$CLJS.LI],null)),Z_=null,$_=0,a0=0;;)if(a0<$_){var Sja=Z_.X(null,a0);$CLJS.zH(Sja,N_);a0+=1}else{var b0=$CLJS.z(Y_);if(b0){var c0=b0;if($CLJS.zd(c0)){var d0=$CLJS.jc(c0),Tja=$CLJS.lc(c0),Uja=d0,Vja=$CLJS.E(d0);Y_=Tja;Z_=Uja;$_=Vja}else{var Wja=$CLJS.B(c0);$CLJS.zH(Wja,N_);Y_=$CLJS.C(c0);Z_=null;$_=0}a0=0}else break}
for(var e0=$CLJS.z($CLJS.pf([$CLJS.Hs,$CLJS.iu,$CLJS.Is,$CLJS.MI,$CLJS.ZH,$CLJS.tI,$CLJS.QH,$CLJS.OI,$CLJS.JH,$CLJS.dI,$CLJS.MH,$CLJS.oI,$CLJS.vI,$CLJS.NH,$CLJS.ZK,$CLJS.aL,$CLJS.VA,$CLJS.VK,$CLJS.cL,$CLJS.PK,$CLJS.gL,$CLJS.UK,$CLJS.NK,$CLJS.fL,$CLJS.nL,$CLJS.jL,$CLJS.TK,$CLJS.qL,$CLJS.iL,$CLJS.oL,$CLJS.PH,$CLJS.KH,$CLJS.PI,$CLJS.fK,$CLJS.sI,$CLJS.eA,$CLJS.HI,$CLJS.JI,$CLJS.QI,$CLJS.Gu,$CLJS.Ou],!0)),f0=null,g0=0,h0=0;;)if(h0<g0){var Xja=f0.X(null,h0);$CLJS.zH(Xja,L_);h0+=1}else{var i0=$CLJS.z(e0);
if(i0){var j0=i0;if($CLJS.zd(j0)){var k0=$CLJS.jc(j0),Yja=$CLJS.lc(j0),Zja=k0,$ja=$CLJS.E(k0);e0=Yja;f0=Zja;g0=$ja}else{var aka=$CLJS.B(j0);$CLJS.zH(aka,L_);e0=$CLJS.C(j0);f0=null;g0=0}h0=0}else break}$CLJS.G_.m(null,K_,function(a){return H_(a)});$CLJS.G_.m(null,$CLJS.AD,function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.Bk.g(D_(),$CLJS.Ze.h(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[c,$CLJS.G_.h(b)],null)})),a)});
$CLJS.G_.m(null,$CLJS.fI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);if($CLJS.wd(b))try{var d=F_(b),e=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fI,C_($CLJS.O_,c)],null);return $CLJS.n(d)?$CLJS.$d.g(e,d):e}catch(f){throw b=f,c=$CLJS.pX(b),c=$CLJS.ha.g?$CLJS.ha.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.ha.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.$h(c,new $CLJS.h(null,1,[$CLJS.X,a],null),b);}else return a});
$CLJS.G_.m(null,$CLJS.yD,function(a){return $CLJS.Dk.g($CLJS.G_,a)});$CLJS.G_.m(null,$CLJS.qI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=null==b||$CLJS.wd(b)?new $CLJS.Q(null,2,5,$CLJS.R,[b,a],null):new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null);b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qI,$CLJS.G_.h(a),F_(b)],null)});
$CLJS.G_.m(null,$CLJS.tj,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);b=F_($CLJS.wX(b,new $CLJS.h(null,3,[$CLJS.ED,$CLJS.rF,$CLJS.Kj,$CLJS.AQ,$CLJS.DG,$CLJS.TT],null)));return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tj,a,b],null)});
$CLJS.G_.m(null,$CLJS.jN,function(a){var b=E_(a);a=0==$CLJS.dH.h(a).lastIndexOf("__join",0)?$CLJS.tk.g(b,$CLJS.dH):b;return $CLJS.Tk.l($CLJS.G([$CLJS.Cu($CLJS.tk.l(a,$CLJS.oN,$CLJS.G([$CLJS.rN])),$CLJS.G_),J_($CLJS.Ql(a,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.rN],null)),$CLJS.rN,$CLJS.NT),I_(a)]))});
$CLJS.G_.m(null,$CLJS.RX,function(a){var b=$CLJS.fI.h(a),c=$CLJS.Wf.j($CLJS.N,$CLJS.Sl(function(f,k){$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.HG);return new $CLJS.Q(null,2,5,$CLJS.R,[f,k],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.Sl(function(f,k){$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.HG);return new $CLJS.Q(null,2,5,$CLJS.R,[k,f],null)}),b);var d=$CLJS.P_,e=$CLJS.O_;$CLJS.P_=c;$CLJS.O_=b;try{return $CLJS.ab(function(f,k){return $CLJS.MY(f,
k,$CLJS.G_)},J_($CLJS.MY($CLJS.MY(Aja(E_(a)),$CLJS.fI,function(f){return $CLJS.Dk.g(H_,f)}),$CLJS.WR,function(f){return $CLJS.Wf.g($CLJS.N,function(){return function m(l){return new $CLJS.le(null,function(){for(;;){var t=$CLJS.z(l);if(t){if($CLJS.zd(t)){var u=$CLJS.jc(t),v=$CLJS.E(u),x=$CLJS.oe(v);a:for(var A=0;;)if(A<v){var D=$CLJS.kd(u,A),I=$CLJS.G_.h(D);D=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uY(D),$CLJS.F.g($CLJS.tj,$CLJS.B(I))?$CLJS.hd(I):I],null);x.add(D);A+=1}else{u=!0;break a}return u?$CLJS.re($CLJS.te(x),
m($CLJS.lc(t))):$CLJS.re($CLJS.te(x),null)}x=$CLJS.B(t);u=$CLJS.G_.h(x);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uY(x),$CLJS.F.g($CLJS.tj,$CLJS.B(u))?$CLJS.hd(u):u],null),m($CLJS.Kc(t)))}return null}},null,null)}(f)}())}),$CLJS.eY,$CLJS.UH),$CLJS.uk.l(A_,$CLJS.fI,$CLJS.G([$CLJS.eY,$CLJS.WR])))}finally{$CLJS.O_=e,$CLJS.P_=d}});$CLJS.G_.m(null,$CLJS.YX,function(a){return $CLJS.Cu(E_(a),$CLJS.G_)});
$CLJS.G_.m(null,$CLJS.dY,function(a){try{var b=E_(a),c=$CLJS.rP.h(b),d=I_(b),e=$CLJS.F.g($CLJS.lv.h($CLJS.id($CLJS.oN.h(a))),$CLJS.YX)?$CLJS.gS:$CLJS.EO;return $CLJS.Tk.l($CLJS.G([$CLJS.Cu($CLJS.tk.l(b,$CLJS.oN,$CLJS.G([$CLJS.rP,$CLJS.M_])),$CLJS.G_),function(){var k=$CLJS.Ce([$CLJS.cj,e,e,d]);return $CLJS.z(c)?$CLJS.S.j(k,$CLJS.rP,c):k}()]))}catch(k){var f=k;throw $CLJS.$h(function(){var l=$CLJS.pX(f);return $CLJS.ha.g?$CLJS.ha.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.ha.call(null,
"Error converting MLv2 query to legacy query: %s",l)}(),new $CLJS.h(null,1,[$CLJS.EO,a],null),f);}});
$CLJS.l0=function(){function a(d,e,f){f=$CLJS.w_(null,$CLJS.bC(f,$CLJS.G([$CLJS.ri,!0])));var k=$CLJS.BY(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.fI);k=$CLJS.Wf.j($CLJS.N,$CLJS.Sl(function(v,x){$CLJS.H(x,0,null);x=$CLJS.H(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.HG);return new $CLJS.Q(null,2,5,$CLJS.R,[v,x],null)}),l);l=$CLJS.Wf.j($CLJS.N,$CLJS.Sl(function(v,x){$CLJS.H(x,0,null);x=$CLJS.H(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.HG);return new $CLJS.Q(null,2,5,$CLJS.R,[x,v],null)}),l);var m=
$CLJS.P_,t=$CLJS.O_;$CLJS.P_=k;$CLJS.O_=l;try{try{return $CLJS.Q_.h(f)}catch(v){var u=v;throw $CLJS.$h(function(){var x=$CLJS.pX(u);return $CLJS.ha.g?$CLJS.ha.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.ha.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.EO,d,$CLJS.FY,e,Cja,f,Bja,$CLJS.P_],null),u);}}finally{$CLJS.O_=t,$CLJS.P_=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();