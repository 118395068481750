var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var i8,j8,k8,cpa,dpa,epa,p8,q8,s8,t8,fpa,v8,w8,gpa,hpa;$CLJS.h8=function(a){return $CLJS.i6.h(a)};i8=function(a){return $CLJS.S.j(a,$CLJS.lv,$CLJS.A5)};j8=function(a,b){return $CLJS.F.g($CLJS.wG.h(a),$CLJS.Ql(b,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LG,$CLJS.vG,$CLJS.KG],null)))};k8=function(a){var b=new $CLJS.h(null,3,[$CLJS.HG,$CLJS.p.h($CLJS.RG()),$CLJS.ED,$CLJS.ED.h(a),$CLJS.xi,$CLJS.NC($CLJS.xi,$CLJS.ED)(a)],null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BD,b,$CLJS.T.h(a)],null)};
cpa=function(a){return $CLJS.x2(a,new $CLJS.h(null,1,[$CLJS.LG,$CLJS.rh],null))};
dpa=function(a,b){var c=$CLJS.CJ(function(d){return $CLJS.F.g($CLJS.C0.h(d),a)},b);if($CLJS.n(c))return c;c=$CLJS.CJ(function(d){return $CLJS.F.g($CLJS.T.h(d),a)},b);if($CLJS.n(c))return c;c=$CLJS.XC($CLJS.UA);$CLJS.n($CLJS.WC("metabase.lib.field",c))&&(b=$CLJS.sG("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.G([$CLJS.Eh.l($CLJS.G([a])),$CLJS.Eh.l($CLJS.G([$CLJS.Dk.g($CLJS.C0,b)]))])),b instanceof Error?$CLJS.VC("metabase.lib.field",c,$CLJS.dy(),b):$CLJS.VC("metabase.lib.field",
c,$CLJS.dy.l($CLJS.G([b])),null));return null};
epa=function(a,b,c){if($CLJS.n(l8))return null;var d=l8;l8=!0;try{var e=$CLJS.e3(a,b),f=$CLJS.n(e)?$CLJS.BY(a,e):$CLJS.BY(a,b),k=function(){var m=$CLJS.m8.h(f);if($CLJS.n(m))return m;m=$CLJS.OC(f,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jX,$CLJS.yY],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.KX.h(f);if($CLJS.n(u))return u;u=$CLJS.bR.h(f);if($CLJS.n(u))return u;u=$CLJS.WR.h(f);return $CLJS.n(u)?u:$CLJS.$G.h(f)}())?$CLJS.u2.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.XC($CLJS.UA);if($CLJS.n($CLJS.WC("metabase.lib.field",
m))){var t=$CLJS.sG("Cannot resolve column {0}: stage has no metadata",$CLJS.G([$CLJS.Eh.l($CLJS.G([c]))]));return t instanceof Error?$CLJS.VC("metabase.lib.field",m,$CLJS.dy(),t):$CLJS.VC("metabase.lib.field",m,$CLJS.dy.l($CLJS.G([t])),null)}return null}(),l=function(){var m=$CLJS.z(k);return m?dpa(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.S.j($CLJS.S.j($CLJS.y4($CLJS.tk.l(l,$CLJS.Oi,$CLJS.G([$CLJS.$M,$CLJS.n8,$CLJS.o8])),null),$CLJS.T,function(){var m=$CLJS.C0.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.PM,$CLJS.p0):l:null}finally{l8=d}};
p8=function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.qR);var f=$CLJS.H(c,2,null);c=$CLJS.Tk.l($CLJS.G([function(){var k=$CLJS.ED.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.ED,k],null):null}(),function(){var k=$CLJS.NC($CLJS.xi,$CLJS.ED)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.xi,k],null):null}(),function(){var k=$CLJS.rT.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.n8,k],null):null}(),function(){var k=$CLJS.wI.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.o8,k],null):null}(),$CLJS.Ed(f)?function(){var k=$CLJS.i3(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.lv,$CLJS.NM,$CLJS.T,f],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.lv,$CLJS.NM,$CLJS.T,f],null):function(){var k=epa(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.lv,$CLJS.NM,$CLJS.T,f],null)}()]));return $CLJS.n(d)?$CLJS.y4(c,d):c};q8=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.o8);return $CLJS.n($CLJS.n(b)?$CLJS.Gd($CLJS.zJ,b):b)?$CLJS.mj:$CLJS.NC($CLJS.xi,$CLJS.ED)(a)};
$CLJS.r8=function(a,b,c,d){$CLJS.H(d,0,null);var e=$CLJS.H(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.HG),l=$CLJS.J.g(f,$CLJS.ED),m=$CLJS.J.g(f,$CLJS.rT),t=$CLJS.J.g(f,$CLJS.xi);e=$CLJS.J.g(f,$CLJS.qR);var u=$CLJS.J.g(f,$CLJS.BP),v=$CLJS.J.g(f,$CLJS.wI),x=$CLJS.Tk.l;k=new $CLJS.h(null,2,[$CLJS.lv,$CLJS.NM,$CLJS.h2,k],null);f=$CLJS.xG.h(f);a=$CLJS.n(f)?f:$CLJS.M1.j(a,b,d);c=x.call($CLJS.Tk,$CLJS.G([k,c,new $CLJS.h(null,1,[$CLJS.xG,a],null)]));c=$CLJS.n(t)?$CLJS.S.j(c,$CLJS.xi,t):c;l=$CLJS.n(l)?$CLJS.S.j(c,
$CLJS.ED,l):c;v=$CLJS.n(v)?$CLJS.S.j(l,$CLJS.o8,v):l;m=$CLJS.n(m)?$CLJS.S.j(v,$CLJS.n8,m):v;u=$CLJS.n(u)?$CLJS.S.j(m,$CLJS.ZM,u):m;return $CLJS.n(e)?$CLJS.y4(u,e):u};s8=function(a,b,c){return $CLJS.n($CLJS.Je(function(d){return $CLJS.F.g($CLJS.ki.h(d),c)},a))?$CLJS.Dk.g(function(d){var e=$CLJS.tk.g(d,b);return $CLJS.F.g($CLJS.ki.h(d),c)?$CLJS.S.j(e,b,!0):e},a):a};
t8=function(a){var b=$CLJS.n($CLJS.R2.h(a))?null:function(){var d=$CLJS.PM.h(a),e=new $CLJS.Tg(null,new $CLJS.h(null,3,[$CLJS.G0,null,$CLJS.M0,null,$CLJS.p0,null],null),null);return e.h?e.h(d):e.call(null,d)}(),c=$CLJS.Tk.l($CLJS.G([new $CLJS.h(null,3,[$CLJS.HG,$CLJS.p.h($CLJS.RG()),$CLJS.ED,$CLJS.ED.h(a),$CLJS.xi,q8(a)],null),function(){var d=$CLJS.K2(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.qR,d],null):null}(),function(){var d=$CLJS.o8.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.wI,d],
null):null}(),function(){var d=$CLJS.n8.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.rT,d],null):null}(),function(){var d=$CLJS.ZM.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.BP,d],null):null}()]));b=($CLJS.n(b)?$CLJS.NC($CLJS.C0,$CLJS.T):$CLJS.NC($CLJS.Oi,$CLJS.T))(a);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qI,c,b],null)};
fpa=function(a,b){return $CLJS.bf(function(c){return $CLJS.F.g($CLJS.PM.h(c),$CLJS.w0)},$CLJS.u2.v(a,b,$CLJS.BY(a,b),new $CLJS.h(null,3,[$CLJS.b2,!1,$CLJS.i2,!0,$CLJS.Z1,!1],null)))};$CLJS.o8=new $CLJS.M("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);$CLJS.u8=new $CLJS.M(null,"stage","stage",1843544772);v8=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.m8=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);
w8=new $CLJS.M("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);gpa=new $CLJS.M(null,"earliest","earliest",-1928154382);$CLJS.n8=new $CLJS.M("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);hpa=new $CLJS.M(null,"latest","latest",24323665);var kpa;$CLJS.y2.m(null,$CLJS.qI,function(a){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rh.h(b),$CLJS.x2(c,new $CLJS.h(null,2,[$CLJS.wI,$CLJS.rh,$CLJS.rT,cpa],null)),a],null)});var l8=!1,x8=function x8(a,b){var d=$CLJS.D2(a,v8.h(b));a=$CLJS.n(v8.h(d))?x8.g?x8.g(a,d):x8.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.T);return $CLJS.Ek.j(b,$CLJS.T,function(f){return[$CLJS.p.h(e),".",$CLJS.p.h(f)].join("")})};
$CLJS.n2.m(null,$CLJS.NM,function(a,b,c){return q8(c)});$CLJS.n2.m(null,$CLJS.qI,function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.wI);$CLJS.H(c,2,null);c=p8(a,b,c);c=$CLJS.n(d)?$CLJS.S.j(c,$CLJS.o8,d):c;return $CLJS.o2.j(a,b,c)});$CLJS.p2.m(null,$CLJS.NM,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.T);return $CLJS.S.j(a,$CLJS.T,b)});$CLJS.p2.m(null,$CLJS.qI,function(a,b,c){var d=p8(a,b,c);b=$CLJS.r8(a,b,d,c);return $CLJS.n(v8.h(b))?x8(a,b):b});
$CLJS.j2.m(null,$CLJS.NM,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.xG),f=$CLJS.J.g(c,$CLJS.T),k=$CLJS.J.g(c,$CLJS.ki),l=$CLJS.J.g(c,$CLJS.n8),m=$CLJS.J.g(c,$CLJS.P0),t=$CLJS.J.g(c,$CLJS.ZM),u=$CLJS.J.g(c,$CLJS.$M);e=$CLJS.n(e)?e:"string"===typeof f?$CLJS.I2.g($CLJS.JD,f):$CLJS.p.h(f);$CLJS.F.g(d,$CLJS.N1)&&-1==e.indexOf(" → ")?($CLJS.n(t)?(t=$CLJS.D2(a,t),$CLJS.n(t)?a=(0,$CLJS.ma)($CLJS.RC($CLJS.xG.h($CLJS.P1.j(a,b,t)),$CLJS.JY,"")):(u=$CLJS.J2(a,u),a=$CLJS.M1.v(a,b,u,d))):a=null,m=$CLJS.n(a)?
a:$CLJS.n(m)?m:$CLJS.K2(c)):m=null;m=$CLJS.n(m)?[$CLJS.p.h(m)," → ",$CLJS.p.h(e)].join(""):e;return $CLJS.n(k)?(c=$CLJS.nD($CLJS.RC($CLJS.Zg(k),"-"," ")),$CLJS.ha.j?$CLJS.ha.j("%s: %s",m,c):$CLJS.ha.call(null,"%s: %s",m,c)):$CLJS.n(l)?(c=$CLJS.f6(l,c),$CLJS.ha.j?$CLJS.ha.j("%s: %s",m,c):$CLJS.ha.call(null,"%s: %s",m,c)):m});
$CLJS.j2.m(null,$CLJS.qI,function(a,b,c,d){$CLJS.H(c,0,null);var e=$CLJS.H(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.rT);var k=$CLJS.J.g(f,$CLJS.qR),l=$CLJS.J.g(f,$CLJS.wI);f=$CLJS.J.g(f,$CLJS.BP);$CLJS.H(c,2,null);c=p8(a,b,c);c=$CLJS.n(k)?$CLJS.S.j(c,$CLJS.P0,k):c;l=$CLJS.n(l)?$CLJS.S.j(c,$CLJS.ki,l):c;e=$CLJS.n(e)?$CLJS.S.j(l,$CLJS.n8,e):l;e=$CLJS.n(f)?$CLJS.S.j(e,$CLJS.ZM,f):e;return $CLJS.n(e)?$CLJS.M1.v(a,b,e,d):$CLJS.SG("[Unknown Field]")});
$CLJS.k2.m(null,$CLJS.NM,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.T)});$CLJS.k2.m(null,$CLJS.qI,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=p8(a,b,c);return $CLJS.n(c)?$CLJS.l2.j(a,b,c):"unknown_field"});
$CLJS.q2.m(null,$CLJS.NM,function(a,b,c){return $CLJS.Tk.l($CLJS.G([function(){var d=$CLJS.Uh($CLJS.q2,$CLJS.Wh);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.F.g($CLJS.PM.h(c),$CLJS.G0)?function(){var d=$CLJS.O0.h(c);return $CLJS.n(d)?(d=$CLJS.K1(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.HY,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.xG,$CLJS.T.h(d)],null)],null):null):null}():null]))});$CLJS.A3.m(null,$CLJS.qI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);$CLJS.H(a,2,null);return $CLJS.wI.h(b)});
$CLJS.A3.m(null,$CLJS.NM,function(a){return $CLJS.o8.h(a)});$CLJS.x3.m(null,$CLJS.qI,function(a,b){$CLJS.H(a,0,null);var c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);if($CLJS.n(b)){var d=$CLJS.Gd($CLJS.zJ,b),e=$CLJS.CE(w8,$CLJS.xi,$CLJS.ED)(c);c=$CLJS.S.l(c,$CLJS.wI,b,$CLJS.G([$CLJS.xi,d?$CLJS.mj:e,w8,e]));return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qI,c,a],null)}b=w8.h(c);c=$CLJS.n(b)?$CLJS.tk.g($CLJS.S.j(c,$CLJS.xi,b),w8):c;c=$CLJS.tk.g(c,$CLJS.wI);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qI,c,a],null)});
$CLJS.x3.m(null,$CLJS.NM,function(a,b){return $CLJS.n(b)?$CLJS.S.j(a,$CLJS.o8,b):$CLJS.tk.g(a,$CLJS.o8)});$CLJS.C3.m(null,$CLJS.qI,function(a,b,c){return $CLJS.D3.j(a,b,p8(a,b,c))});
$CLJS.C3.m(null,$CLJS.NM,function(a,b,c){if($CLJS.zk.g($CLJS.PM.h(c),$CLJS.w0)){a=$CLJS.NC($CLJS.xi,$CLJS.ED)(c);b=null==c?null:$CLJS.PP.h(c);if(null==b)var d=null;else try{var e=$CLJS.DF.h($CLJS.cj.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,gpa),l=$CLJS.J.g(f,hpa),m=$CLJS.bpa.l($CLJS.G([$CLJS.g8.h(k),$CLJS.g8.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.ys.g?$CLJS.ys.g(1,m):$CLJS.ys.call(null,1,m))?$CLJS.kv:$CLJS.n($CLJS.ys.g?$CLJS.ys.g(31,m):$CLJS.ys.call(null,31,m))?$CLJS.nv:$CLJS.n($CLJS.ys.g?
$CLJS.ys.g(365,m):$CLJS.ys.call(null,365,m))?$CLJS.ej:$CLJS.nj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.EE(a,$CLJS.DF)?$CLJS.sla:$CLJS.EE(a,$CLJS.eF)?$CLJS.rla:$CLJS.EE(a,$CLJS.cG)?$CLJS.qla:$CLJS.tf;d=$CLJS.n(d)?s8(e,$CLJS.Wh,d):e;return $CLJS.n($CLJS.o8.h(c))?s8(d,$CLJS.U1,$CLJS.o8.h(c)):d}return $CLJS.tf});
$CLJS.i6.m(null,$CLJS.qI,function(a){var b=null==a?null:$CLJS.OG(a);b=null==b?null:$CLJS.rT.h(b);return null==b?null:$CLJS.S.l(b,$CLJS.lv,$CLJS.h6,$CLJS.G([$CLJS.g6,function(c,d){return p8(c,d,a)}]))});$CLJS.i6.m(null,$CLJS.NM,function(a){var b=null==a?null:$CLJS.n8.h(a);return null==b?null:$CLJS.S.l(b,$CLJS.lv,$CLJS.h6,$CLJS.G([$CLJS.g6,$CLJS.Le(a)]))});$CLJS.c6.m(null,$CLJS.qI,function(a,b){return $CLJS.PG(a,$CLJS.NG,$CLJS.G([$CLJS.rT,b]))});
$CLJS.c6.m(null,$CLJS.NM,function(a,b){return $CLJS.NG(a,$CLJS.n8,b)});$CLJS.j6.m(null,$CLJS.qI,function(a,b,c){return $CLJS.k6.j(a,b,p8(a,b,c))});
$CLJS.j6.m(null,$CLJS.NM,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.xi);var d=$CLJS.J.g(b,$CLJS.PP),e=$CLJS.J.g(b,$CLJS.Kj);if($CLJS.zk.g($CLJS.PM.h(b),$CLJS.w0)){var f=function(){var m=null==a?null:$CLJS.B2($CLJS.H1(a));m=null==m?null:$CLJS.uC.h(m);return null==m?null:$CLJS.Gd(m,$CLJS.rT)}(),k=$CLJS.OC(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,$CLJS.Ej],null)),l=$CLJS.h8(b);return function u(t){return new $CLJS.le(null,function(){for(;;){var v=$CLJS.z(t);if(v){if($CLJS.zd(v)){var x=$CLJS.jc(v),
A=$CLJS.E(x),D=$CLJS.oe(A);return function(){for(var K=0;;)if(K<A){var U=$CLJS.kd(x,K),Z=D,da=U;U=j8(U,l)?$CLJS.S.j(da,$CLJS.U1,!0):da;Z.add(U);K+=1}else return!0}()?$CLJS.re($CLJS.te(D),u($CLJS.lc(v))):$CLJS.re($CLJS.te(D),null)}var I=$CLJS.B(v);return $CLJS.ce(function(){var K=I;return j8(I,l)?$CLJS.S.j(K,$CLJS.U1,!0):K}(),u($CLJS.Kc(v)))}return null}},null,null)}($CLJS.Sa(function(){if($CLJS.n(f)){var t=$CLJS.Jn.h(k);return $CLJS.n(t)?$CLJS.Xj.h(k):t}return f}())?null:$CLJS.EE(e,$CLJS.oj)?$CLJS.Dk.g(i8,
new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.e6(),new $CLJS.h(null,2,[$CLJS.xG,$CLJS.SG("Bin every 0.1 degrees"),$CLJS.wG,new $CLJS.h(null,2,[$CLJS.LG,$CLJS.KG,$CLJS.KG,.1],null)],null),new $CLJS.h(null,2,[$CLJS.xG,$CLJS.SG("Bin every 1 degree"),$CLJS.wG,new $CLJS.h(null,2,[$CLJS.LG,$CLJS.KG,$CLJS.KG,1],null)],null),new $CLJS.h(null,2,[$CLJS.xG,$CLJS.SG("Bin every 10 degrees"),$CLJS.wG,new $CLJS.h(null,2,[$CLJS.LG,$CLJS.KG,$CLJS.KG,10],null)],null),new $CLJS.h(null,2,[$CLJS.xG,$CLJS.SG("Bin every 20 degrees"),
$CLJS.wG,new $CLJS.h(null,2,[$CLJS.LG,$CLJS.KG,$CLJS.KG,20],null)],null)],null)):$CLJS.EE(c,$CLJS.Ej)&&!$CLJS.EE(e,$CLJS.fi)?$CLJS.Dk.g(i8,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.e6(),new $CLJS.h(null,2,[$CLJS.xG,$CLJS.SG("10 bins"),$CLJS.wG,new $CLJS.h(null,2,[$CLJS.LG,$CLJS.vG,$CLJS.vG,10],null)],null),new $CLJS.h(null,2,[$CLJS.xG,$CLJS.SG("50 bins"),$CLJS.wG,new $CLJS.h(null,2,[$CLJS.LG,$CLJS.vG,$CLJS.vG,50],null)],null),new $CLJS.h(null,2,[$CLJS.xG,$CLJS.SG("100 bins"),$CLJS.wG,new $CLJS.h(null,2,
[$CLJS.LG,$CLJS.vG,$CLJS.vG,100],null)],null)],null)):null)}return $CLJS.tf});$CLJS.kY.m(null,$CLJS.qI,function(a){return a});
$CLJS.kY.m(null,$CLJS.NM,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.PM);switch(b instanceof $CLJS.M?b.T:null){case "source/aggregations":return b=new $CLJS.h(null,2,[$CLJS.HG,$CLJS.p.h($CLJS.RG()),$CLJS.xi,$CLJS.NC($CLJS.xi,$CLJS.ED)(a)],null),a=$CLJS.h2.h(a),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fI,b,a],null);case "source/expressions":return k8(a);case "source/breakouts":return $CLJS.Gd(a,$CLJS.hH)?k8(a):t8(a);default:return t8(a)}});
$CLJS.ipa=function(){function a(e,f,k){k=$CLJS.De($CLJS.Dk.g($CLJS.mY,k));var l=fpa(e,f),m=$CLJS.Wf.j($CLJS.Ug,$CLJS.Il(function(t){return $CLJS.n3.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.tf);m=$CLJS.Ck.g(m,l);k=$CLJS.n(k)?$CLJS.Wf.j(k,$CLJS.Ze.h($CLJS.mY),m):null;return $CLJS.IY.l(e,f,$CLJS.NG,$CLJS.G([$CLJS.$G,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.y8=function(){function a(d,e){return $CLJS.$G.h($CLJS.BY(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.jpa=function(){function a(d,e){var f=$CLJS.u2.v(d,e,$CLJS.BY(d,e),new $CLJS.h(null,3,[$CLJS.b2,!1,$CLJS.i2,!1,$CLJS.Z1,!1],null)),k=$CLJS.y8.g(d,e);return $CLJS.sd(k)?$CLJS.Dk.g(function(l){return $CLJS.S.j(l,$CLJS.E0,!0)},f):$CLJS.v4(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
kpa=function(){function a(d,e,f){var k=$CLJS.BY(d,e),l=$CLJS.F.g($CLJS.qE(f),$CLJS.fI)?$CLJS.s2:$CLJS.u2;k=l.j?l.j(d,e,k):l.call(null,d,e,k);return $CLJS.n3.v(d,e,f,k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.lpa=function(){function a(d,e,f){f=$CLJS.l0.j(d,e,f);return kpa.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();