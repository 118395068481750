var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.breakout.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';var Dna=function(a){return function(b){var c=$CLJS.Re(-1);return function(){function d(l,m){var t=c.Cd(null,c.Qb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=
e;k.g=d;return k}()}},Ena=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.ED);return $CLJS.Je(function(c){return $CLJS.EE(b,c)},$CLJS.tH)},k7=new $CLJS.M("metabase.lib.order-by","order-by-clause","metabase.lib.order-by/order-by-clause",774979647);$CLJS.zH($CLJS.nI,k7);$CLJS.zH($CLJS.gI,k7);
$CLJS.m2.m(null,$CLJS.LT,function(a,b){var c=$CLJS.De($CLJS.LT.h($CLJS.BY(a,b)));return $CLJS.n(c)?$CLJS.sG("Sorted by {0}",$CLJS.G([$CLJS.w3($CLJS.SG("and"),function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.jc(k),m=$CLJS.E(l),t=$CLJS.oe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.M1.v(a,b,v,$CLJS.N1);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),null)}t=$CLJS.B(k);
return $CLJS.ce($CLJS.M1.v(a,b,t,$CLJS.N1),f($CLJS.Kc(k)))}return null}},null,null)}(c)}())])):null});$CLJS.j2.m(null,k7,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);a=$CLJS.M1.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "asc":return $CLJS.sG("{0} ascending",$CLJS.G([a]));case "desc":return $CLJS.sG("{0} descending",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});
$CLJS.q2.m(null,k7,function(a,b,c){var d=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);return $CLJS.S.j($CLJS.P1.j(a,b,c),$CLJS.Y1,d)});var l7,Fna=$CLJS.Oe($CLJS.N),Gna=$CLJS.Oe($CLJS.N),Hna=$CLJS.Oe($CLJS.N),Ina=$CLJS.Oe($CLJS.N),Jna=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Yi,$CLJS.sE],null),$CLJS.Yi,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));l7=new $CLJS.Xh($CLJS.xh.g("metabase.lib.order-by","order-by-clause-method"),$CLJS.qE,Jna,Fna,Gna,Hna,Ina);l7.m(null,k7,function(a){return $CLJS.lY(a)});
l7.m(null,$CLJS.Wh,function(a){if(null==a)throw $CLJS.ai($CLJS.SG("Can''t order by nil"),$CLJS.N);return $CLJS.lY(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nI,$CLJS.mY(a)],null))});
$CLJS.Kna=function(){function a(d,e){d=l7.h(d);e=$CLJS.n(e)?e:$CLJS.nI;return $CLJS.S.j($CLJS.uf(d),0,e)}function b(d){return c.g?c.g(d,$CLJS.nI):c.call(null,d,$CLJS.nI)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.m7=function(){function a(e,f,k,l){f=$CLJS.n(f)?f:-1;var m=function(){var t=l7.h(k);return $CLJS.n(l)?$CLJS.S.j($CLJS.uf(t),0,l):t}();return $CLJS.IY.l(e,f,$CLJS.Ek,$CLJS.G([$CLJS.LT,function(t){return $CLJS.$d.g($CLJS.uf(t),m)}]))}function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.v?d.v(e,-1,f,null):d.call(null,e,-1,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,
e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();$CLJS.n7=function(){function a(d,e){return $CLJS.De($CLJS.J.g($CLJS.BY(d,e),$CLJS.LT))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.o7=function(){function a(d,e){var f=$CLJS.De($CLJS.l6(d,e)),k=$CLJS.De($CLJS.y5.g(d,e));f=$CLJS.n($CLJS.n(f)?f:k)?$CLJS.Ye.g(f,k):function(){var t=$CLJS.BY(d,e);return $CLJS.u2.v(d,e,t,new $CLJS.h(null,1,[$CLJS.f2,!1],null))}();var l=$CLJS.bf(Ena,f);f=$CLJS.Ze.g(function(t){$CLJS.H(t,0,null);$CLJS.H(t,1,null);return $CLJS.H(t,2,null)},$CLJS.n7.g(d,e));if($CLJS.sd(l))return null;if($CLJS.sd(f))return $CLJS.uf(l);var m=$CLJS.Wf.j($CLJS.N,$CLJS.Bk.g($CLJS.Ze.h($CLJS.mY),Dna(function(t,u){u=$CLJS.n3.v(d,
e,u,l);return $CLJS.n(u)?new $CLJS.Q(null,2,5,$CLJS.R,[u,t],null):null})),f);return $CLJS.Dk.g(function(t){var u=m.h?m.h(t):m.call(null,t);return $CLJS.n(u)?$CLJS.S.j(t,$CLJS.W1,u):t},l)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.p7=new $CLJS.h(null,2,[$CLJS.nI,$CLJS.gI,$CLJS.gI,$CLJS.nI],null);