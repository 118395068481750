var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';$CLJS.RL=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.SL=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.TL=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var UL=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.dt],null)),VL=null,WL=0,XL=0;;)if(XL<WL){var sea=VL.X(null,XL);$CLJS.IH(sea,$CLJS.G([$CLJS.iu,$CLJS.Aj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ps,new $CLJS.h(null,1,[$CLJS.Jn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eH],null)],null)],null)],null)]));XL+=1}else{var YL=$CLJS.z(UL);if(YL){var ZL=YL;if($CLJS.zd(ZL)){var $L=$CLJS.jc(ZL),tea=$CLJS.lc(ZL),
uea=$L,vea=$CLJS.E($L);UL=tea;VL=uea;WL=vea}else{var wea=$CLJS.B(ZL);$CLJS.IH(wea,$CLJS.G([$CLJS.iu,$CLJS.Aj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ps,new $CLJS.h(null,1,[$CLJS.Jn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eH],null)],null)],null)],null)]));UL=$CLJS.C(ZL);VL=null;WL=0}XL=0}else break}$CLJS.GH($CLJS.ft,$CLJS.G([$CLJS.iu,$CLJS.Aj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eH],null)]));
for(var aM=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.II],null)),bM=null,cM=0,dM=0;;)if(dM<cM){var xea=bM.X(null,dM);$CLJS.IH(xea,$CLJS.G([$CLJS.iu,$CLJS.Aj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ps,new $CLJS.h(null,1,[$CLJS.Jn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.kH],null)],null)],null)],null)]));dM+=1}else{var eM=$CLJS.z(aM);if(eM){var fM=eM;if($CLJS.zd(fM)){var gM=$CLJS.jc(fM),yea=$CLJS.lc(fM),
zea=gM,Aea=$CLJS.E(gM);aM=yea;bM=zea;cM=Aea}else{var Bea=$CLJS.B(fM);$CLJS.IH(Bea,$CLJS.G([$CLJS.iu,$CLJS.Aj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ps,new $CLJS.h(null,1,[$CLJS.Jn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.kH],null)],null)],null)],null)]));aM=$CLJS.C(fM);bM=null;cM=0}dM=0}else break}
for(var hM=$CLJS.z(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Bs,$CLJS.Ds,$CLJS.xs,$CLJS.zs],null)),iM=null,jM=0,kM=0;;)if(kM<jM){var Cea=iM.X(null,kM);$CLJS.GH(Cea,$CLJS.G([$CLJS.iu,$CLJS.Aj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mH],null)]));kM+=1}else{var lM=$CLJS.z(hM);if(lM){var mM=lM;if($CLJS.zd(mM)){var nM=$CLJS.jc(mM),Dea=$CLJS.lc(mM),Eea=nM,Fea=$CLJS.E(nM);hM=Dea;iM=Eea;jM=Fea}else{var Gea=$CLJS.B(mM);$CLJS.GH(Gea,$CLJS.G([$CLJS.iu,$CLJS.Aj,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mH],null)]));hM=$CLJS.C(mM);iM=null;jM=0}kM=0}else break}$CLJS.GH($CLJS.SH,$CLJS.G([$CLJS.iu,$CLJS.Aj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mH],null)]));
$CLJS.GH($CLJS.YH,$CLJS.G([$CLJS.iu,$CLJS.Aj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mH],null)]));
for(var oM=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RH,$CLJS.cI],null)),pM=null,qM=0,rM=0;;)if(rM<qM){var Hea=pM.X(null,rM);$CLJS.GH(Hea,$CLJS.G([$CLJS.iu,$CLJS.Aj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.fH],null)]));rM+=1}else{var sM=$CLJS.z(oM);if(sM){var tM=sM;if($CLJS.zd(tM)){var uM=$CLJS.jc(tM),Iea=$CLJS.lc(tM),Jea=uM,Kea=$CLJS.E(uM);oM=Iea;pM=Jea;qM=Kea}else{var Lea=$CLJS.B(tM);$CLJS.GH(Lea,$CLJS.G([$CLJS.iu,$CLJS.Aj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.fH],null)]));oM=$CLJS.C(tM);
pM=null;qM=0}rM=0}else break}
for(var vM=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.TH,$CLJS.$H],null)),wM=null,xM=0,yM=0;;)if(yM<xM){var Mea=wM.X(null,yM);$CLJS.GH(Mea,$CLJS.G([$CLJS.iu,$CLJS.Aj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.oH],null)]));yM+=1}else{var zM=$CLJS.z(vM);if(zM){var AM=zM;if($CLJS.zd(AM)){var BM=$CLJS.jc(AM),Nea=$CLJS.lc(AM),Oea=BM,Pea=$CLJS.E(BM);vM=Nea;wM=Oea;xM=Pea}else{var Qea=$CLJS.B(AM);$CLJS.GH(Qea,$CLJS.G([$CLJS.iu,$CLJS.Aj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.oH],null)]));vM=$CLJS.C(AM);
wM=null;xM=0}yM=0}else break}
for(var CM=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yI,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),$CLJS.ut],null)],null),DM=$CLJS.z(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.hI,$CLJS.BI,$CLJS.pI,$CLJS.iI],null)),EM=null,FM=0,GM=0;;)if(GM<FM){var HM=EM.X(null,GM);$CLJS.RI.v(HM,$CLJS.iu,$CLJS.Aj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,HM],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,$CLJS.GG,CM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,
$CLJS.pH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pH],null)],null));GM+=1}else{var IM=$CLJS.z(DM);if(IM){var JM=IM;if($CLJS.zd(JM)){var KM=$CLJS.jc(JM),Rea=$CLJS.lc(JM),Sea=KM,Tea=$CLJS.E(KM);DM=Rea;EM=Sea;FM=Tea}else{var LM=$CLJS.B(JM);$CLJS.RI.v(LM,$CLJS.iu,$CLJS.Aj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,LM],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,$CLJS.GG,CM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pH],null),new $CLJS.Q(null,2,5,$CLJS.R,
[$CLJS.X,$CLJS.pH],null)],null));DM=$CLJS.C(JM);EM=null;FM=0}GM=0}else break}
$CLJS.RI.v($CLJS.CI,$CLJS.iu,$CLJS.Aj,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.CI],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,$CLJS.GG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FI,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),$CLJS.ut],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cH],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ak,$CLJS.tx,$CLJS.kA,$CLJS.XH],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,$CLJS.sH],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.kJ],null)],null));$CLJS.RI.v($CLJS.OH,$CLJS.iu,$CLJS.Aj,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.OH],null),$CLJS.GG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,$CLJS.uG,$CLJS.BG],null)],null));
$CLJS.Y($CLJS.RL,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lv,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.SL],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jI,new $CLJS.Q(null,17,5,$CLJS.R,[$CLJS.ak,$CLJS.Es,$CLJS.II,$CLJS.YH,$CLJS.SH,$CLJS.Bs,$CLJS.xs,$CLJS.Ds,$CLJS.zs,$CLJS.RH,$CLJS.cI,$CLJS.TH,$CLJS.$H,$CLJS.pI,$CLJS.iI,$CLJS.hI,$CLJS.BI],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.TL,$CLJS.Mi],null)],null));