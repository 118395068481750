var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var m0,bka,cka,dka,eka,fka,s0,gka,hka,ika,v0,jka,kka,lka,A0,B0,mka,nka,oka,K0,pka,qka,rka,N0,ska,tka,uka,vka;m0=function(){};$CLJS.n0=function(a){return null!=a?$CLJS.r===a.fi?!0:a.Vc?!1:$CLJS.Wa(m0,a):$CLJS.Wa(m0,a)};bka=new $CLJS.w(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.o0=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
cka=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);dka=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.p0=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.q0=new $CLJS.M(null,"database-id","database-id",1883826326);eka=new $CLJS.w("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.r0=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);fka=new $CLJS.M(null,"human-readable-values","human-readable-values",-624842907);s0=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);gka=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);hka=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
ika=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.t0=new $CLJS.M("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.u0=new $CLJS.M(null,"dataset","dataset",1159262238);v0=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);jka=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.w0=new $CLJS.M("source","expressions","source/expressions",-458367840);
$CLJS.x0=new $CLJS.M(null,"definition","definition",-1198729982);kka=new $CLJS.M(null,"state","state",-1988618099);$CLJS.y0=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);lka=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.z0=new $CLJS.M("source","joins","source/joins",1225821486);A0=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);
B0=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.C0=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.D0=new $CLJS.M(null,"fk-target-field-id","fk-target-field-id",2019750287);mka=new $CLJS.M("lib","external-remap","lib/external-remap",543358316);$CLJS.E0=new $CLJS.M(null,"selected?","selected?",-742502788);
nka=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);oka=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.F0=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.G0=new $CLJS.M("source","card","source/card",-139977973);$CLJS.H0=new $CLJS.M(null,"active","active",1895962068);$CLJS.I0=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);
$CLJS.J0=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);K0=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);pka=new $CLJS.M(null,"details","details",1956795411);$CLJS.L0=new $CLJS.M("source","fields","source/fields",-649667981);qka=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.M0=new $CLJS.M("source","native","source/native",-1444604147);
rka=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);N0=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.O0=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);ska=new $CLJS.M("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);tka=new $CLJS.w(null,"metadata-provider?","metadata-provider?",1669871524,null);
uka=new $CLJS.M("lib","internal-remap","lib/internal-remap",-220033801);vka=new $CLJS.M("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.P0=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.Q0=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.Y(A0,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.ak,$CLJS.G0,$CLJS.M0,$CLJS.p0,$CLJS.r0,$CLJS.L0,$CLJS.hN,$CLJS.bN,$CLJS.z0,$CLJS.w0,$CLJS.y0],null));$CLJS.Y(K0,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lv,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,vka],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Oi,$CLJS.VM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.BG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.WM,$CLJS.fN],null)],null));
$CLJS.Y(s0,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lv,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,ska],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Oi,$CLJS.VM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.BG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Xt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pj,$CLJS.vt],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[fka,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pj,$CLJS.vt],null)],null)],null));
$CLJS.Y($CLJS.t0,new $CLJS.Q(null,18,5,$CLJS.R,[$CLJS.Wj,new $CLJS.h(null,1,[$CLJS.su,"Valid column metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lv,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.NM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.Oj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ED,$CLJS.JG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Oi,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),$CLJS.fN],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xG,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.ct,$CLJS.Oj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.JG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ZM,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.fN],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.D0,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.fN],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.P0,
new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.BG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PM,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,A0],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.O0,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.XM],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.o0,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.BG],
null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.C0,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Oj,new $CLJS.h(null,2,[$CLJS.Jn,1,$CLJS.Xj,60],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.E0,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),$CLJS.ut],null),new $CLJS.Q(null,3,5,$CLJS.R,[mka,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,K0],null)],null)],
null),new $CLJS.Q(null,3,5,$CLJS.R,[uka,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,s0],null)],null)],null)],null));
$CLJS.Y(N0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.F0,$CLJS.BG],null),new $CLJS.Q(null,2,5,$CLJS.R,[jka,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[ika,$CLJS.BG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ED,$CLJS.JG],null)],null)],null)],null)],null)],null));
$CLJS.Y(v0,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.H0,$CLJS.ut],null),new $CLJS.Q(null,2,5,$CLJS.R,[kka,$CLJS.BG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.F0,$CLJS.BG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.x0,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,N0],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MM,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,
$CLJS.BG],null)],null)],null));
$CLJS.Y(qka,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.Wj,new $CLJS.h(null,1,[$CLJS.su,"Valid Card metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lv,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.gN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Oi,$CLJS.XM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.BG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.q0,$CLJS.YM],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.I0,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),$CLJS.Wj],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.J0,
new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pj,$CLJS.Wj],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.u0,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),$CLJS.ut],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$M,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.SM],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[dka,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,v0],null)],null)],null)],null));
$CLJS.Y(nka,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Wj,new $CLJS.h(null,1,[$CLJS.su,"Valid Segment metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lv,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.RM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Oi,$CLJS.QM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.BG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$M,$CLJS.SM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.x0,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.Wj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.Pu,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.BG],null)],null)],null));
$CLJS.Y(oka,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Wj,new $CLJS.h(null,1,[$CLJS.su,"Valid Metric metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lv,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.TM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Oi,$CLJS.OM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.BG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$M,$CLJS.SM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.x0,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.Wj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.Pu,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.BG],null)],null)],null));
$CLJS.Y(rka,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Wj,new $CLJS.h(null,1,[$CLJS.su,"Valid Table metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lv,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.UM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Oi,$CLJS.SM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.BG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xG,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.BG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bl,new $CLJS.h(null,
1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.BG],null)],null)],null));
$CLJS.Y(cka,new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.Wj,new $CLJS.h(null,1,[$CLJS.su,"Valid Database metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lv,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.dN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Oi,$CLJS.YM],null),new $CLJS.Q(null,3,5,$CLJS.R,[hka,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.Wj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[pka,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),$CLJS.Wj],null),new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.Q0,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),$CLJS.Mi],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uC,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pi,$CLJS.Mi],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[lka,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),$CLJS.ut],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aN,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.Wj],null)],null)],null));
$CLJS.Y(B0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.at,new $CLJS.h(null,1,[$CLJS.su,"Valid MetadataProvider"],null),new $CLJS.Fc(function(){return $CLJS.n0},eka,$CLJS.Ag([$CLJS.Li,$CLJS.T,$CLJS.dk,$CLJS.ni,$CLJS.Ki,$CLJS.dj,$CLJS.kk,$CLJS.mi,$CLJS.V,$CLJS.ck],[bka,tka,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.W(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Kq],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.n0)?$CLJS.n0.H:null]))],null));
$CLJS.Y(gka,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,B0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.h(null,1,[$CLJS.su,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,B0],null)],null)],null)],null));