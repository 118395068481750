var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var d3,Oka,g3,h3,Pka,Qka,Rka,Ska,Uka,Vka,Wka,Tka,k3,l3;d3=function(a,b){var c=$CLJS.Fd,d=c3;for(a=$CLJS.z(a);;)if(null!=a){d=$CLJS.J.j(d,$CLJS.B(a),c);if(c===d)return b;a=$CLJS.C(a)}else return d};$CLJS.e3=function(a,b){a=$CLJS.AY(a,b);return 0<a?a-1:null};
$CLJS.f3=function(a,b,c,d){a=$CLJS.zY(a);b=$CLJS.n(b)?b:-1;var e=$CLJS.BY(a,b);e=!($CLJS.z($CLJS.fI.h(e))||$CLJS.z($CLJS.eI.h(e)));c=$CLJS.IY.l(a,b,$CLJS.Ek,$CLJS.G([c,function(f){return $CLJS.$d.g($CLJS.uf(f),$CLJS.nY.h(d))}]));return e?$CLJS.Ek.N((0,$CLJS.IY)(c,b,function(f){return $CLJS.MY($CLJS.tk.l(f,$CLJS.LT,$CLJS.G([$CLJS.$G])),$CLJS.AP,function(k){return $CLJS.Dk.g(function(l){return $CLJS.tk.g(l,$CLJS.$G)},k)})}),$CLJS.oN,$CLJS.Bk.g(function(f){return $CLJS.Wf.g($CLJS.tf,f)},$CLJS.Ik),0,
$CLJS.AY(a,b)+1):c};Oka=function(a,b){b=$CLJS.K1(a,b);return $CLJS.n(b)?$CLJS.Q2(a,b):null};g3=function(a){return $CLJS.Wf.j($CLJS.Ug,$CLJS.Ck.h(function(b){return $CLJS.ke(b)&&$CLJS.zk.g(b,$CLJS.lv)}),$CLJS.Pg(a))};h3=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.Sl(function(b,c){$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.HG);return new $CLJS.Q(null,2,5,$CLJS.R,[c,b],null)}),$CLJS.fI.h(a))};
$CLJS.i3=function(a,b,c){var d=$CLJS.Tk.l;b=$CLJS.Sa($CLJS.e3(a,b))?function(){var f=$CLJS.KX.h($CLJS.B($CLJS.oN.h(a)));return $CLJS.n(f)?(f=Oka(a,f),$CLJS.n(f)?$CLJS.CJ(function(k){return $CLJS.F.g($CLJS.Oi.h(k),c)},f):null):null}():null;try{var e=$CLJS.D2(a,c)}catch(f){e=null}return d.call($CLJS.Tk,$CLJS.G([b,e]))};
Pka=function(a,b,c){var d=$CLJS.De($CLJS.bf(function(e){return $CLJS.F.g($CLJS.C0.h(e),a)&&$CLJS.F.g($CLJS.NC($CLJS.R0,$CLJS.P0)(e),b)},c));return $CLJS.n(d)?d:$CLJS.bf(function(e){return $CLJS.F.g($CLJS.T.h(e),a)&&$CLJS.F.g($CLJS.NC($CLJS.R0,$CLJS.P0)(e),b)},c)};
Qka=function(a,b,c,d){var e=$CLJS.De($CLJS.bf(function(f){var k=$CLJS.F.g($CLJS.Oi.h(f),a);if(k){if(k=$CLJS.Sa(b)){k=$CLJS.PM.h(f);var l=new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.G0,null,$CLJS.L0,null],null),null);k=l.h?l.h(k):l.call(null,k)}return $CLJS.n(k)?k:$CLJS.F.g($CLJS.NC($CLJS.R0,$CLJS.P0)(f),b)}return k},c));if($CLJS.n(e))return e;c=$CLJS.n(d)?$CLJS.De($CLJS.bf(function(f){return $CLJS.F.g($CLJS.Oi.h(f),a)},c)):null;return $CLJS.n(c)?c:$CLJS.tf};
Rka=function(a,b){var c=$CLJS.De($CLJS.Ck.g($CLJS.ZM,b));if($CLJS.n(c)){if($CLJS.Sa($CLJS.C(c)))return $CLJS.B(c);throw $CLJS.ai("Ambiguous match! Implement more logic in disambiguate-matches.",new $CLJS.h(null,2,[$CLJS.X,a,$CLJS.yY,b],null));}return null};
Ska=function(a,b){b=$CLJS.De($CLJS.Ck.g(function(c){var d=$CLJS.NC($CLJS.R0,$CLJS.P0)(c);$CLJS.n(d)?(c=$CLJS.PM.h(c),d=new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.G0,null],null),null),c=d.h?d.h(c):d.call(null,c),c=$CLJS.Sa(c)):c=d;return c},b));return $CLJS.n(b)?$CLJS.Sa($CLJS.C(b))?$CLJS.B(b):Rka(a,b):null};
Uka=function(a,b){var c=$CLJS.OG(a);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.qR);if($CLJS.n(d)){b=$CLJS.De($CLJS.bf(function(e){return $CLJS.F.g($CLJS.NC($CLJS.R0,$CLJS.P0)(e),d)},b));if($CLJS.n(b)){if($CLJS.Sa($CLJS.C(b)))return $CLJS.B(b);throw $CLJS.ai("Multiple plausible matches with the same :join-alias - more disambiguation needed",new $CLJS.h(null,2,[$CLJS.X,a,Tka,b],null));}return null}return Ska(a,b)};Vka=new $CLJS.M("metabase.lib.equality","no-right","metabase.lib.equality/no-right",2015744769);
Wka=new $CLJS.M("metabase.lib.equality","no-left","metabase.lib.equality/no-left",-1403666439);$CLJS.j3=new $CLJS.M(null,"generous?","generous?",1247836118);$CLJS.Xka=new $CLJS.M(null,"matching-refs","matching-refs",324693611);Tka=new $CLJS.M(null,"matches","matches",635497998);k3=new $CLJS.M(null,"left","left",-399115937);l3=new $CLJS.M("metabase.lib.equality","different-dispatch-values","metabase.lib.equality/different-dispatch-values",-2107733395);var m3=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Yi,$CLJS.sE],null),$CLJS.Yi,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.equality","\x3d"),function(f,k){f=$CLJS.qE(f);k=$CLJS.qE(k);return $CLJS.zk.g(f,k)?l3:f},e,a,b,c,d)}();m3.m(null,l3,function(){return!1});
m3.m(null,$CLJS.AD,function(a,b){var c=g3(a),d=g3(b);return $CLJS.F.g(c,d)&&$CLJS.Ie(function(e){return m3.g($CLJS.J.g(a,e),$CLJS.J.g(b,e))},c)});m3.m(null,$CLJS.yD,function(a,b){var c=$CLJS.F.g($CLJS.E(a),$CLJS.E(b));if(c)for(c=$CLJS.z(a),$CLJS.B(c),$CLJS.C(c),c=$CLJS.z(b),$CLJS.B(c),$CLJS.C(c),c=a,a=b;;){c=$CLJS.z(c);b=$CLJS.B(c);var d=$CLJS.C(c);c=b;b=d;a=$CLJS.z(a);d=$CLJS.B(a);a=$CLJS.C(a);c=m3.g(c,d);if($CLJS.n(c)){if(c=$CLJS.sd(b))return c;c=b}else return c}else return c});var c3=null;
m3.m(null,$CLJS.RX,function(a,b){var c=c3;c3=new $CLJS.h(null,2,[k3,h3(a),$CLJS.Gz,h3(b)],null);try{var d=$CLJS.Uh(m3,$CLJS.AD);return d.g?d.g(a,b):d.call(null,a,b)}finally{c3=c}});
m3.m(null,$CLJS.fI,function(a,b){var c=$CLJS.H(a,0,null),d=$CLJS.H(a,1,null),e=$CLJS.H(a,2,null),f=$CLJS.H(b,0,null),k=$CLJS.H(b,1,null),l=$CLJS.H(b,2,null);return(a=$CLJS.F.l(3,$CLJS.E(a),$CLJS.G([$CLJS.E(b)])))?(c=$CLJS.F.g(c,f))?(d=m3.g(d,k),$CLJS.n(d)?$CLJS.n(c3)?$CLJS.F.g(d3(new $CLJS.Q(null,2,5,$CLJS.R,[k3,e],null),Wka),d3(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gz,l],null),Vka)):$CLJS.F.g(e,l):d):c:a});
m3.m(null,$CLJS.Wh,function(a,b){if($CLJS.wd(a)){var c=$CLJS.Uh(m3,$CLJS.AD);return c.g?c.g(a,b):c.call(null,a,b)}return $CLJS.vd(a)?(c=$CLJS.Uh(m3,$CLJS.yD),c.g?c.g(a,b):c.call(null,a,b)):$CLJS.F.g(a,b)});
$CLJS.n3=function(){function a(f,k,l,m,t){$CLJS.H(l,0,null);$CLJS.H(l,1,null);var u=$CLJS.H(l,2,null),v=e.j?e.j(l,m,t):e.call(null,l,m,t);return $CLJS.n(v)?v:$CLJS.n($CLJS.n(f)?"number"===typeof u:f)&&(m=$CLJS.De($CLJS.Ck.g($CLJS.Oi,m)),$CLJS.n(m)&&(k=$CLJS.i3(f,k,u),$CLJS.n(k)))?(f=$CLJS.S.j,u=$CLJS.C0.h(k),k=$CLJS.n(u)?u:$CLJS.T.h(k),l=f.call($CLJS.S,l,2,k),e.j?e.j(l,m,t):e.call(null,l,m,t)):null}function b(f,k,l,m){return e.N?e.N(f,k,l,m,$CLJS.N):e.call(null,f,k,l,m,$CLJS.N)}function c(f,k,l){var m=
$CLJS.H(f,0,null),t=$CLJS.H(f,1,null),u=$CLJS.H(f,2,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.j3);switch(m instanceof $CLJS.M?m.T:null){case "aggregation":return $CLJS.CJ(function(v){return $CLJS.F.g($CLJS.PM.h(v),$CLJS.hN)&&$CLJS.F.g($CLJS.h2.h(v),u)},k);case "expression":case "field":switch(k="string"===typeof u?Pka(u,$CLJS.qR.h(t),k):Qka(u,$CLJS.qR.h(t),k,l),$CLJS.E(k)){case 0:return null;case 1:return $CLJS.B(k);default:return Uka(f,k)}default:throw $CLJS.ai("Unknown type of ref",new $CLJS.h(null,
1,[$CLJS.X,f],null));}}function d(f,k){return e.j?e.j(f,k,$CLJS.N):e.call(null,f,k,$CLJS.N)}var e=null;e=function(f,k,l,m,t){switch(arguments.length){case 2:return d.call(this,f,k);case 3:return c.call(this,f,k,l);case 4:return b.call(this,f,k,l,m);case 5:return a.call(this,f,k,l,m,t)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.j=c;e.v=b;e.N=a;return e}();
$CLJS.Yka=function(){function a(d,e,f,k){return $CLJS.n3.v(d,e,$CLJS.l0.j(d,e,f),k)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();