var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';$CLJS.t3=function(a,b){return $CLJS.ec($CLJS.ab(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.dc(c,d):c},$CLJS.cc($CLJS.tf),b))};$CLJS.u3=function(a,b,c){return null==c||$CLJS.Gd(a,b)?a:$CLJS.S.j(a,b,c)};$CLJS.v3=function(a,b){return $CLJS.Wf.j(new $CLJS.Q(null,2,5,$CLJS.R,[a,new $CLJS.h(null,1,[$CLJS.HG,$CLJS.p.h($CLJS.RG())],null)],null),$CLJS.Ze.h($CLJS.nY),b)};
$CLJS.w3=function(a,b){if($CLJS.z(b)){if($CLJS.F.g($CLJS.E(b),1))return $CLJS.B(b);a=[" ",(0,$CLJS.ma)(a)," "].join("");return $CLJS.F.g($CLJS.E(b),2)?[$CLJS.p.h($CLJS.B(b)),a,$CLJS.p.h($CLJS.hd(b))].join(""):[$CLJS.Du(", ",$CLJS.Au(b)),",",a,$CLJS.p.h($CLJS.id(b))].join("")}return null};$CLJS.y3=function(a,b){return $CLJS.x3.g(a,b instanceof $CLJS.M?b:$CLJS.ki.h(b))};var lla,mla,nla,ola,pla,B3;
$CLJS.z3=function(){function a(e,f){if($CLJS.Sa(f))return"";e=Math.abs(e);var k=$CLJS.rh.h(f);switch(k instanceof $CLJS.M?k.T:null){case "default":return $CLJS.vX("Default period","Default periods",e);case "millisecond":return $CLJS.vX("Millisecond","Milliseconds",e);case "second":return $CLJS.vX("Second","Seconds",e);case "minute":return $CLJS.vX("Minute","Minutes",e);case "hour":return $CLJS.vX("Hour","Hours",e);case "day":return $CLJS.vX("Day","Days",e);case "week":return $CLJS.vX("Week","Weeks",
e);case "month":return $CLJS.vX("Month","Months",e);case "quarter":return $CLJS.vX("Quarter","Quarters",e);case "year":return $CLJS.vX("Year","Years",e);case "minute-of-hour":return $CLJS.vX("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.vX("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.vX("Day of week","Days of week",e);case "day-of-month":return $CLJS.vX("Day of month","Days of month",e);case "day-of-year":return $CLJS.vX("Day of year","Days of year",e);case "week-of-year":return $CLJS.vX("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.vX("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.vX("Quarter of year","Quarters of year",e);default:return f=$CLJS.$u($CLJS.Zg(f),/-/),e=$CLJS.z(f),f=$CLJS.B(e),e=$CLJS.C(e),$CLJS.Du(" ",$CLJS.ce($CLJS.KC(f),e))}}function b(e){return d.g?d.g(1,e):d.call(null,1,e)}function c(){return d.g?d.g(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.o=c;d.h=b;d.g=a;return d}();$CLJS.x3=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Yi,$CLJS.sE],null),$CLJS.Yi,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.qE(f)},e,a,b,c,d)}();lla=$CLJS.Oe($CLJS.N);mla=$CLJS.Oe($CLJS.N);
nla=$CLJS.Oe($CLJS.N);ola=$CLJS.Oe($CLJS.N);pla=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Yi,$CLJS.sE],null),$CLJS.Yi,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));$CLJS.A3=new $CLJS.Xh($CLJS.xh.g("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.qE,pla,lla,mla,nla,ola);$CLJS.A3.m(null,$CLJS.Wh,function(){return null});$CLJS.A3.m(null,$CLJS.lJ,function(a){return $CLJS.ki.h(a)});B3=new $CLJS.Tg(null,new $CLJS.h(null,4,[$CLJS.cJ,null,$CLJS.fJ,null,$CLJS.gJ,null,$CLJS.dJ,null],null),null);
$CLJS.qla=$CLJS.Wf.j($CLJS.tf,$CLJS.Bk.g($CLJS.Ck.h(B3),$CLJS.Ze.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.lv,$CLJS.lJ,$CLJS.ki,a],null);return $CLJS.F.g(a,$CLJS.mv)?$CLJS.S.j(b,$CLJS.Wh,!0):b})),$CLJS.xJ);$CLJS.rla=$CLJS.Dk.g(function(a){var b=new $CLJS.h(null,2,[$CLJS.lv,$CLJS.lJ,$CLJS.ki,a],null);return $CLJS.F.g(a,$CLJS.nv)?$CLJS.S.j(b,$CLJS.Wh,!0):b},$CLJS.sJ);
$CLJS.sla=$CLJS.Wf.j($CLJS.tf,$CLJS.Bk.g($CLJS.Ck.h(B3),$CLJS.Ze.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.lv,$CLJS.lJ,$CLJS.ki,a],null);return $CLJS.F.g(a,$CLJS.nv)?$CLJS.S.j(b,$CLJS.Wh,!0):b})),$CLJS.yJ);$CLJS.j2.m(null,$CLJS.lJ,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.ki);return $CLJS.z3.h(a)});
$CLJS.q2.m(null,$CLJS.lJ,function(a,b,c){return $CLJS.Tk.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.xG,$CLJS.M1.j(a,b,c),$CLJS.g2,$CLJS.kD($CLJS.A3.h(c))],null),$CLJS.Ql(c,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wh,$CLJS.U1],null))]))});
$CLJS.C3=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Yi,$CLJS.sE],null),$CLJS.Yi,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,k,l){return $CLJS.qE(l)},e,a,b,c,d)}();$CLJS.C3.m(null,$CLJS.Wh,function(){return $CLJS.Ug});
$CLJS.D3=function(){function a(d,e,f){return $CLJS.C3.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();