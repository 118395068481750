var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var d5,f5,g5,h5,Bma;d5=function(a,b,c){var d=$CLJS.BY(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.fI);d=$CLJS.CJ($CLJS.Bk.j($CLJS.Vg([c]),$CLJS.HG,$CLJS.hd),d);if(!$CLJS.n(d))throw $CLJS.ai($CLJS.sG("No aggregation with uuid {0}",$CLJS.G([c])),new $CLJS.h(null,3,[$CLJS.tt,c,$CLJS.EO,a,$CLJS.FY,b],null));return d};$CLJS.e5=function(a){return $CLJS.v3($CLJS.DI,new $CLJS.Q(null,1,5,$CLJS.R,[a],null))};
f5=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);g5=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);h5=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);Bma=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.m2.m(null,$CLJS.fI,function(a,b){var c=$CLJS.De($CLJS.fI.h($CLJS.BY(a,b)));return $CLJS.n(c)?$CLJS.w3($CLJS.SG("and"),function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.jc(k),m=$CLJS.E(l),t=$CLJS.oe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.M1.v(a,b,v,$CLJS.N1);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),null)}t=$CLJS.B(k);return $CLJS.ce($CLJS.M1.v(a,
b,t,$CLJS.N1),f($CLJS.Kc(k)))}return null}},null,null)}(c)}()):null});$CLJS.p2.m(null,$CLJS.fI,function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.ED);e=$CLJS.J.g(e,$CLJS.xi);c=$CLJS.H(c,2,null);c=d5(a,b,c);return $CLJS.Tk.l($CLJS.G([$CLJS.L1.j(a,b,c),new $CLJS.h(null,2,[$CLJS.PM,$CLJS.hN,$CLJS.h2,$CLJS.HG.h($CLJS.hd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.ED,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.xi,e],null):null]))});
$CLJS.j2.m(null,$CLJS.fI,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);return $CLJS.M1.v(a,b,d5(a,b,c),d)});$CLJS.zH(g5,h5);
for(var i5=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gA,$CLJS.rI],null)),j5=null,k5=0,l5=0;;)if(l5<k5){var Cma=j5.X(null,l5);$CLJS.zH(Cma,g5);l5+=1}else{var m5=$CLJS.z(i5);if(m5){var n5=m5;if($CLJS.zd(n5)){var o5=$CLJS.jc(n5),Dma=$CLJS.lc(n5),Ema=o5,Fma=$CLJS.E(o5);i5=Dma;j5=Ema;k5=Fma}else{var Gma=$CLJS.B(n5);$CLJS.zH(Gma,g5);i5=$CLJS.C(n5);j5=null;k5=0}l5=0}else break}
$CLJS.j2.m(null,g5,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);if($CLJS.n(c))switch(a=$CLJS.M1.v(a,b,c,d),e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.sG("Count of {0}",$CLJS.G([a]));case "cum-count":return $CLJS.sG("Cumulative count of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.SG("Count");case "cum-count":return $CLJS.SG("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.k2.m(null,g5,function(a,b,c){a=$CLJS.H(c,0,null);a=a instanceof $CLJS.M?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.p2.m(null,g5,function(a,b,c){var d=$CLJS.S.j,e=$CLJS.Uh($CLJS.p2,h5);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.S,a,$CLJS.Kj,$CLJS.fF)});$CLJS.j2.m(null,$CLJS.ZH,function(){return $CLJS.SG("Case")});
$CLJS.k2.m(null,$CLJS.ZH,function(){return"case"});$CLJS.zH(f5,h5);for(var p5=$CLJS.z(new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.kI,$CLJS.NI,$CLJS.VH,$CLJS.Xj,$CLJS.EI,$CLJS.Jn,$CLJS.GI,$CLJS.DI,$CLJS.WH],null)),q5=null,r5=0,s5=0;;)if(s5<r5){var Hma=q5.X(null,s5);$CLJS.zH(Hma,f5);s5+=1}else{var t5=$CLJS.z(p5);if(t5){var u5=t5;if($CLJS.zd(u5)){var v5=$CLJS.jc(u5),Ima=$CLJS.lc(u5),Jma=v5,Kma=$CLJS.E(v5);p5=Ima;q5=Jma;r5=Kma}else{var Lma=$CLJS.B(u5);$CLJS.zH(Lma,f5);p5=$CLJS.C(u5);q5=null;r5=0}s5=0}else break}
$CLJS.k2.m(null,f5,function(a,b,c){a=$CLJS.H(c,0,null);$CLJS.H(c,1,null);$CLJS.H(c,2,null);c=a instanceof $CLJS.M?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.j2.m(null,f5,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);a=$CLJS.M1.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "avg":return $CLJS.sG("Average of {0}",$CLJS.G([a]));case "cum-sum":return $CLJS.sG("Cumulative sum of {0}",$CLJS.G([a]));case "distinct":return $CLJS.sG("Distinct values of {0}",$CLJS.G([a]));case "max":return $CLJS.sG("Max of {0}",$CLJS.G([a]));case "median":return $CLJS.sG("Median of {0}",$CLJS.G([a]));case "min":return $CLJS.sG("Min of {0}",
$CLJS.G([a]));case "stddev":return $CLJS.sG("Standard deviation of {0}",$CLJS.G([a]));case "sum":return $CLJS.sG("Sum of {0}",$CLJS.G([a]));case "var":return $CLJS.sG("Variance of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.j2.m(null,$CLJS.bI,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null);c=$CLJS.H(c,3,null);return $CLJS.sG("{0}th percentile of {1}",$CLJS.G([c,$CLJS.M1.v(a,b,e,d)]))});$CLJS.k2.m(null,$CLJS.bI,function(){return"percentile"});
$CLJS.zH($CLJS.bI,h5);$CLJS.j2.m(null,$CLJS.LI,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return $CLJS.sG("Sum of {0} matching condition",$CLJS.G([$CLJS.M1.v(a,b,e,d)]))});$CLJS.k2.m(null,$CLJS.LI,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.l2.j(a,b,d))].join("")});$CLJS.zH($CLJS.LI,h5);$CLJS.j2.m(null,$CLJS.xI,function(){return $CLJS.SG("Share of rows matching condition")});
$CLJS.k2.m(null,$CLJS.xI,function(){return"share"});$CLJS.zH($CLJS.xI,h5);$CLJS.j2.m(null,$CLJS.zI,function(){return $CLJS.SG("Count of rows matching condition")});$CLJS.k2.m(null,$CLJS.zI,function(){return"count-where"});$CLJS.zH($CLJS.zI,h5);
$CLJS.p2.m(null,h5,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null),e=$CLJS.Tk.l;d=$CLJS.n(d)?$CLJS.Ql($CLJS.L1.j(a,b,d),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.aN],null)):null;var f=$CLJS.Uh($CLJS.p2,$CLJS.Wh);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.Tk,$CLJS.G([d,a]))});
$CLJS.Mma=function(){function a(d){return $CLJS.v3($CLJS.gA,new $CLJS.Q(null,1,5,$CLJS.R,[d],null))}function b(){return $CLJS.v3($CLJS.gA,$CLJS.tf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.kY.m(null,$CLJS.fI,function(a){return a});
$CLJS.w5=function(){function a(d,e,f){for(;;)if($CLJS.F.g($CLJS.qE(f),$CLJS.TM))f=$CLJS.mY(f);else return $CLJS.f3(d,e,$CLJS.fI,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.x5=function(){function a(d,e){return $CLJS.De($CLJS.fI.h($CLJS.BY(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.y5=function(){function a(d,e){var f=$CLJS.De($CLJS.fI.h($CLJS.BY(d,e)));return null==f?null:$CLJS.Wf.j($CLJS.tf,$CLJS.Ze.h(function(k){var l=$CLJS.L1.j(d,e,k),m=$CLJS.S.l,t=$CLJS.ED.h(l);return m.call($CLJS.S,$CLJS.u3(l,$CLJS.xi,$CLJS.n(t)?t:$CLJS.qj),$CLJS.PM,$CLJS.hN,$CLJS.G([$CLJS.h2,$CLJS.HG.h($CLJS.hd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.j2.m(null,$CLJS.ZI,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.VI);return $CLJS.xG.h(a.o?a.o():a.call(null))});$CLJS.q2.m(null,$CLJS.ZI,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.jI);b=$CLJS.J.g(d,$CLJS.VI);c=$CLJS.J.g(d,$CLJS.UI);d=$CLJS.J.g(d,$CLJS.E0);a=$CLJS.S.l(b.o?b.o():b.call(null),$CLJS.g2,$CLJS.kD(a),$CLJS.G([$CLJS.e2,c]));return null!=d?$CLJS.S.j(a,$CLJS.U1,d):a});
$CLJS.Nma=function(){function a(d,e){var f=function(){var m=$CLJS.uC.h($CLJS.B2($CLJS.H1(d)));return $CLJS.n(m)?m:$CLJS.Ug}(),k=$CLJS.BY(d,e),l=$CLJS.u2.j(d,e,k);return $CLJS.De($CLJS.Wf.j($CLJS.tf,$CLJS.Bk.j($CLJS.af(function(m){m=$CLJS.XI.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.Il(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.UI),u=$CLJS.J.g(m,$CLJS.SI);if($CLJS.Sa(t))return m;if($CLJS.F.g(u,$CLJS.vt))return $CLJS.S.j(m,$CLJS.yY,l);t=$CLJS.De($CLJS.t3(function(v){return $CLJS.o1(u,
v)},l));return $CLJS.n(t)?$CLJS.S.j(m,$CLJS.yY,t):null}),$CLJS.Ze.h(function(m){return $CLJS.S.j(m,$CLJS.lv,$CLJS.ZI)})),$CLJS.$I))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Oma=function(){function a(d,e){return $CLJS.lY(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.jI.h(d),$CLJS.N,$CLJS.nY.h(e)],null))}function b(d){if($CLJS.Sa($CLJS.UI.h(d)))return $CLJS.lY(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jI.h(d),$CLJS.N],null));var e=$CLJS.jI.h(d);e=$CLJS.ha.g?$CLJS.ha.g("aggregation operator %s requires an argument",e):$CLJS.ha.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.ai(e,new $CLJS.h(null,1,[Bma,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();