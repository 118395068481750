var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");
'use strict';var Cv,Ev,Fv,Gv,Hv,Iv,Jv,Qv,Rv,Sv,Tv,Uv,Vv,Yv,aw,bw,cw,ew,fw,gw,hw,iw,jw,ow,rw,tw,ww,yw,zw,Ew,Fw,Hw,Iw,Jw,Pw,Qw,ax,ex,kx,mx,rx,vx,Ax,Cx,Dx,xx,Fx,Gx,Ix,Hx,Jx,Ox,Px,Zx,$x,Tx,ay,ey,hy,jy,ky,my,oy,py,sy,wy,xy,yy,zy,Ay,Fy,Iy,Jy,Ly,Oy,Py,Qy,Ty,Uy,Vy,Wy,Yy,Zy,Xy,cz,dz,ez,fz,gz,hz,iz,jz,kz,lz,mz,oz,pz,rz,vz,xz,Az,Bz,Fz,Iz,Cz,Mz,wx,zx,Oz,Pz,Sz,Tz,Wz,Xz,aaa,baa,caa,$z,daa,aA,Zz,bA,mw,cA,Dw,Hy,eaa,ox,faa,gaa,pw,Aw,Uz,haa,cx,Nx,Kw,iaa,jaa,$w,kaa,Dz,Ow,Mx,Yw,My,laa,Cw,maa,bz,uz,dA,naa,ny,fA,ry,bx,
Lx,lw,tz,Ww,nx,Uw,sw,oaa,Ex,paa,Wx,qy,Hz,qaa,Kz,Vw,raa,saa,taa,sx,uaa,$y,qx,vaa,Xx,Vx,waa,uw,xaa,Rz,Lz,Lw,hA,uy,iA,jA,by,yaa,Nw,zaa,Qz,Aaa,ux,Sy,Kx,Baa,vy,Vz,Caa,Ry,Daa,Ux,xw,Eaa,dx,lA,ty,Faa,qw,sz,px,Tw,zz,vw,By,Ez,Gy,Cy,Ey,az,cy,mA,nA,Gaa,dw,Haa,$v,kw,Iaa,Mw,Jaa;Cv=function(a){return 1==a.length&&" "<=a&&"~">=a||""<=a&&"�">=a};$CLJS.Dv=function(){};
Ev=function(a){if(null!=a&&null!=a.pc)a=a.pc(a);else{var b=Ev[$CLJS.va(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=Ev._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.Ya("IWriter.-flush",a);}return a};Fv=function(){};Gv=function(a){if(null!=a&&null!=a.Jc)a=a.Jc(a);else{var b=Gv[$CLJS.va(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=Gv._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.Ya("IPending.-realized?",a);}return a};
Hv=function(a){if("number"===typeof a)return String.fromCharCode(a);if("string"===typeof a&&1===a.length)return a;throw Error("Argument to char must be a character or number");};Iv=function(a,b){return a-b*$CLJS.Td(a,b)};Jv=function(a,b,c){if(null!=a&&null!=a.m)a.m(a,b,c);else{var d=Jv[$CLJS.va(null==a?null:a)];if(null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else if(d=Jv._,null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else throw $CLJS.Ya("IMultiFn.-add-method",a);}};
$CLJS.Kv=function(a,b){var c=$CLJS.cc($CLJS.N);a=$CLJS.z(a);for(b=$CLJS.z(b);;)if(a&&b)c=$CLJS.Zf.j(c,$CLJS.B(a),$CLJS.B(b)),a=$CLJS.C(a),b=$CLJS.C(b);else return $CLJS.ec(c)};$CLJS.Lv=function(a,b){return $CLJS.dh(a,b)};$CLJS.Mv=function(a,b){return new $CLJS.le(null,function(){var c=$CLJS.z(b);if(c){var d=$CLJS.B(c);d=a.h?a.h(d):a.call(null,d);c=$CLJS.n(d)?$CLJS.ce($CLJS.B(c),$CLJS.Mv(a,$CLJS.Kc(c))):null}else c=null;return c},null,null)};
$CLJS.Nv=function(a,b){return $CLJS.Ze.j(function(c){return c},b,$CLJS.Te(a,b))};Qv=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;$CLJS.P.g(Ov,0<b.length?new $CLJS.y(b.slice(0),0,null):null);$CLJS.$b($CLJS.Pv,"\n")};Rv=function(a){if("number"===typeof a)return a;if("string"===typeof a&&1===a.length)return a.charCodeAt(0);throw Error("Argument to char must be a character or number");};
Sv=function(a,b,c){var d=c;for(c=$CLJS.tf;;){if($CLJS.sd(d))return new $CLJS.Q(null,2,5,$CLJS.R,[c,b],null);var e=$CLJS.B(d);d=$CLJS.C(d);e=$CLJS.P.g(a,new $CLJS.Q(null,2,5,$CLJS.R,[e,b],null));b=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);c=$CLJS.$d.g(c,b);b=e}};Tv=function(a,b){var c=b;for(b=$CLJS.tf;;){var d=$CLJS.P.g(a,new $CLJS.Q(null,1,5,$CLJS.R,[c],null));c=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);if($CLJS.Sa(c))return new $CLJS.Q(null,2,5,$CLJS.R,[b,d],null);b=$CLJS.$d.g(b,c);c=d}};
Uv=function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.jc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);var v=$CLJS.H(t,0,null);$CLJS.H(t,1,null);l.add(new $CLJS.Q(null,2,5,$CLJS.R,[u,v],null));m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}f=
$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);k=$CLJS.H(f,0,null);$CLJS.H(f,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[l,k],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}()),$CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.jc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);l.add(new $CLJS.Q(null,
2,5,$CLJS.R,[u,t],null));m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}f=$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[l,f],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())],null)};
Vv=function(a,b){return $CLJS.Wf.g($CLJS.N,function(){return function e(d){return new $CLJS.le(null,function(){for(;;){var f=$CLJS.z(d);if(f){if($CLJS.zd(f)){var k=$CLJS.jc(f),l=$CLJS.E(k),m=$CLJS.oe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);m.add(new $CLJS.Q(null,2,5,$CLJS.R,[v,new $CLJS.Q(null,2,5,$CLJS.R,[u,b],null)],null));t+=1}else{k=!0;break a}return k?$CLJS.re($CLJS.te(m),e($CLJS.lc(f))):$CLJS.re($CLJS.te(m),null)}k=$CLJS.B(f);m=$CLJS.H(k,0,null);
k=$CLJS.H(k,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[m,new $CLJS.Q(null,2,5,$CLJS.R,[k,b],null)],null),e($CLJS.Kc(f)))}return null}},null,null)}(a)}())};$CLJS.Wv=function(){var a=$CLJS.Pv;if(null!=a&&null!=a.sf)a.sf(a);else{var b=$CLJS.Wv[$CLJS.va(null==a?null:a)];if(null!=b)b.h?b.h(a):b.call(null,a);else if(b=$CLJS.Wv._,null!=b)b.h?b.h(a):b.call(null,a);else throw $CLJS.Ya("IPrettyFlush.-ppflush",a);}};$CLJS.Xv=function(a,b){a=$CLJS.q($CLJS.q(a));return b.h?b.h(a):b.call(null,a)};
Yv=function(a,b,c){$CLJS.Gh.v($CLJS.q(a),$CLJS.S,b,c)};aw=function(a,b){$CLJS.F.g(b,"\n")?(Yv(a,$CLJS.Zv,0),Yv(a,$CLJS.dj,$CLJS.Xv(a,$CLJS.dj)+1)):Yv(a,$CLJS.Zv,$CLJS.Xv(a,$CLJS.Zv)+1);return $CLJS.$b($CLJS.Xv(a,$v),b)};bw=function(a,b,c,d){this.Ba=a;this.Jf=b;this.Xc=c;this.Xh=d;this.C=1074167808;this.I=0};
cw=function(a,b,c,d,e,f,k,l,m,t,u,v,x){this.parent=a;this.Cb=b;this.tb=c;this.sb=d;this.vb=e;this.wb=f;this.prefix=k;this.Ab=l;this.Db=m;this.xb=t;this.S=u;this.G=v;this.J=x;this.C=2230716170;this.I=139264};ew=function(a,b){for(b=dw.h(b);;){if(null==b)return!1;if(a===b)return!0;b=dw.h(b)}};fw=function(a,b,c,d,e,f,k,l){this.ca=a;this.data=b;this.$b=c;this.ba=d;this.aa=e;this.S=f;this.G=k;this.J=l;this.C=2230716170;this.I=139264};
gw=function(a,b,c,d,e,f,k,l){this.ca=a;this.type=b;this.ea=c;this.ba=d;this.aa=e;this.S=f;this.G=k;this.J=l;this.C=2230716170;this.I=139264};hw=function(a,b,c,d,e,f,k){this.ca=a;this.ea=b;this.ba=c;this.aa=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};iw=function(a,b,c,d,e,f,k){this.ca=a;this.ea=b;this.ba=c;this.aa=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};
jw=function(a,b,c,d,e,f,k,l,m){this.ca=a;this.ea=b;this.Vb=c;this.offset=d;this.ba=e;this.aa=f;this.S=k;this.G=l;this.J=m;this.C=2230716170;this.I=139264};
ow=function(a,b,c){b=$CLJS.z(b);for(var d=null,e=0,f=0;;)if(f<e){var k=d.X(null,f);if(!$CLJS.F.g(kw.h(k),lw)){var l=mw.h($CLJS.q($CLJS.q(a)));$CLJS.n(l)&&$CLJS.$b($v.h($CLJS.q($CLJS.q(a))),l)}nw.g(a,k);$CLJS.Gh.v($CLJS.q(a),$CLJS.S,mw,mw.h(k));k=mw.h($CLJS.q($CLJS.q(a)));$CLJS.n($CLJS.n(c)?k:c)&&($CLJS.$b($v.h($CLJS.q($CLJS.q(a))),k),$CLJS.Gh.v($CLJS.q(a),$CLJS.S,mw,null));f+=1}else if(b=$CLJS.z(b))$CLJS.zd(b)?(d=$CLJS.jc(b),b=$CLJS.lc(b),k=d,e=$CLJS.E(d),d=k):(k=$CLJS.B(b),$CLJS.F.g(kw.h(k),lw)||
(d=mw.h($CLJS.q($CLJS.q(a))),$CLJS.n(d)&&$CLJS.$b($v.h($CLJS.q($CLJS.q(a))),d)),nw.g(a,k),$CLJS.Gh.v($CLJS.q(a),$CLJS.S,mw,mw.h(k)),k=mw.h($CLJS.q($CLJS.q(a))),$CLJS.n($CLJS.n(c)?k:c)&&($CLJS.$b($v.h($CLJS.q($CLJS.q(a))),k),$CLJS.Gh.v($CLJS.q(a),$CLJS.S,mw,null)),b=$CLJS.C(b),d=null,e=0),f=0;else break};rw=function(a,b){var c=$CLJS.Xv($v.h($CLJS.q($CLJS.q(a))),$CLJS.Xj),d;(d=null==c)||(a=$CLJS.Xv($v.h($CLJS.q($CLJS.q(a))),$CLJS.Zv),b=(b=$CLJS.z(b))?pw.h($CLJS.id(b))-qw.h($CLJS.B(b)):0,d=a+b<c);return d};
tw=function(a,b,c){b=$CLJS.q(sw.h(b));return $CLJS.n(b)?b:!rw(a,c)};ww=function(a,b,c){var d=uw.h($CLJS.q($CLJS.q(a))),e=$CLJS.Xv($v.h($CLJS.q($CLJS.q(a))),$CLJS.Xj);return $CLJS.n(d)?$CLJS.n(e)?(d=$CLJS.q(vw.h(b))>=e-d)?tw(a,b,c):d:e:d};yw=function(a){var b=$CLJS.B(a),c=xw.h(b);b=$CLJS.z($CLJS.Mv(function(d){return!($CLJS.F.g(kw.h(d),lw)&&ew(xw.h(d),c))},$CLJS.C(a)));return new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.z($CLJS.Te($CLJS.E(b)+1,a))],null)};
zw=function(a){var b=$CLJS.B(a),c=xw.h(b);return $CLJS.z($CLJS.Mv(function(d){var e=xw.h(d);return!($CLJS.F.g(kw.h(d),lw)&&($CLJS.F.g(e,c)||ew(e,c)))},$CLJS.C(a)))};
Ew=function(a,b){$CLJS.$b($v.h($CLJS.q($CLJS.q(a))),"\n");$CLJS.Gh.v($CLJS.q(a),$CLJS.S,mw,null);b=xw.h(b);var c=Aw.h(b);$CLJS.n(c)&&$CLJS.$b($v.h($CLJS.q($CLJS.q(a))),c);c=$CLJS.P.g($CLJS.p,$CLJS.Ve($CLJS.q(Cw.h(b))-$CLJS.E(c)," "));$CLJS.$b($v.h($CLJS.q($CLJS.q(a))),c);a:for($CLJS.Pe(Dw.h(b),!0),$CLJS.Pe(sw.h(b),!0),a=dw.h(b);;)if($CLJS.n(a))$CLJS.Pe(sw.h(a),!0),$CLJS.Pe(Dw.h(a),!0),a=dw.h(a);else break a;return null};
Fw=function(a){var b=$CLJS.z($CLJS.Mv(function(c){return!$CLJS.F.g(kw.h(c),lw)},a));return new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.z($CLJS.Te($CLJS.E(b),a))],null)};Hw=function(a){for(var b=$CLJS.Hu.h($CLJS.q($CLJS.q(a)));;){$CLJS.Gh.v($CLJS.q(a),$CLJS.S,$CLJS.Hu,$CLJS.Wf.g($CLJS.tf,b));if(rw(a,b))return null;var c=Gw(a,b);if(b!==c)b=c;else return null}};
Iw=function(a,b){$CLJS.Gh.v($CLJS.q(a),$CLJS.S,$CLJS.Hu,$CLJS.$d.g($CLJS.Hu.h($CLJS.q($CLJS.q(a))),b));return rw(a,$CLJS.Hu.h($CLJS.q($CLJS.q(a))))?null:Hw(a)};Jw=function(a){var b=mw.h($CLJS.q($CLJS.q(a)));return $CLJS.n(b)?($CLJS.$b($v.h($CLJS.q($CLJS.q(a))),b),$CLJS.Gh.v($CLJS.q(a),$CLJS.S,mw,null)):null};
Pw=function(a,b){var c=$CLJS.Fu(b,"\n",-1);if($CLJS.F.g($CLJS.E(c),1))return b;b=Aw.h($CLJS.B(Kw.h($CLJS.q($CLJS.q(a)))));var d=$CLJS.B(c);if($CLJS.F.g(Lw,$CLJS.qi.h($CLJS.q($CLJS.q(a))))){var e=Mw.h($CLJS.q($CLJS.q(a))),f=e+$CLJS.E(d);$CLJS.Gh.v($CLJS.q(a),$CLJS.S,Mw,f);Iw(a,new fw(Nw,d,null,e,f,null,null,null));Hw(a);d=$CLJS.Hu.h($CLJS.q($CLJS.q(a)));$CLJS.n(d)&&(ow(a,d,!0),$CLJS.Gh.v($CLJS.q(a),$CLJS.S,$CLJS.Hu,$CLJS.tf))}else Jw(a),$CLJS.$b($v.h($CLJS.q($CLJS.q(a))),d);$CLJS.$b($v.h($CLJS.q($CLJS.q(a))),
"\n");d=$CLJS.z($CLJS.C($CLJS.Au(c)));e=null;for(var k=f=0;;)if(k<f){var l=e.X(null,k);$CLJS.$b($v.h($CLJS.q($CLJS.q(a))),l);$CLJS.$b($v.h($CLJS.q($CLJS.q(a))),"\n");$CLJS.n(b)&&$CLJS.$b($v.h($CLJS.q($CLJS.q(a))),b);k+=1}else if(d=$CLJS.z(d))e=d,$CLJS.zd(e)?(d=$CLJS.jc(e),k=$CLJS.lc(e),e=d,f=$CLJS.E(d),d=k):(d=$CLJS.B(e),$CLJS.$b($v.h($CLJS.q($CLJS.q(a))),d),$CLJS.$b($v.h($CLJS.q($CLJS.q(a))),"\n"),$CLJS.n(b)&&$CLJS.$b($v.h($CLJS.q($CLJS.q(a))),b),d=$CLJS.C(e),e=null,f=0),k=0;else break;$CLJS.Gh.v($CLJS.q(a),
$CLJS.S,Lw,Ow);return $CLJS.id(c)};Qw=function(a,b,c,d,e,f){this.Ba=a;this.Jf=b;this.gi=c;this.hh=d;this.Xc=e;this.Yh=f;this.C=1074167808;this.I=0};
$CLJS.Xw=function(a){var b=$CLJS.Rw,c=Sw,d=new cw(null,null,$CLJS.Oe(0),$CLJS.Oe(0),$CLJS.Oe(!1),$CLJS.Oe(!1),null,null,null,null,null,null,null),e=$CLJS.Oe($CLJS.Ag([Kw,uw,Tw,Uw,Vw,$CLJS.qi,Mw,mw,$v,Ww,$CLJS.Hu],[d,c,d,!0,null,Ow,0,null,new bw(a,b,$CLJS.Oe(new $CLJS.h(null,4,[$CLJS.Xj,b,$CLJS.Zv,0,$CLJS.dj,0,$v,a],null)),$CLJS.N),1,$CLJS.tf]));return new Qw(a,b,c,d,e,$CLJS.N)};
ax=function(a,b){var c=$CLJS.Pv;b=new cw(Kw.h($CLJS.q($CLJS.q(c))),null,$CLJS.Oe(0),$CLJS.Oe(0),$CLJS.Oe(!1),$CLJS.Oe(!1),a,null,b,null,null,null,null);$CLJS.Gh.v($CLJS.q(c),$CLJS.S,Kw,b);if($CLJS.F.g($CLJS.qi.h($CLJS.q($CLJS.q(c))),Ow)){Jw(c);var d=Yw.h($CLJS.q($CLJS.q(c)));$CLJS.n(d)&&(d.h?d.h($CLJS.Zw):d.call(null,$CLJS.Zw));$CLJS.n(a)&&$CLJS.$b($v.h($CLJS.q($CLJS.q(c))),a);c=$CLJS.Xv($v.h($CLJS.q($CLJS.q(c))),$CLJS.Zv);$CLJS.Pe(b.tb,c);$CLJS.Pe(b.sb,c)}else d=Mw.h($CLJS.q($CLJS.q(c))),a=d+($CLJS.n(a)?
$CLJS.E(a):0),$CLJS.Gh.v($CLJS.q(c),$CLJS.S,Mw,a),Iw(c,new hw($w,b,d,a,null,null,null))};
ex=function(){var a=$CLJS.Pv,b=Kw.h($CLJS.q($CLJS.q(a))),c=bx.h(b);if($CLJS.F.g($CLJS.qi.h($CLJS.q($CLJS.q(a))),Ow)){Jw(a);$CLJS.n(c)&&$CLJS.$b($v.h($CLJS.q($CLJS.q(a))),c);var d=Yw.h($CLJS.q($CLJS.q(a)));$CLJS.n(d)&&(d.h?d.h(cx):d.call(null,cx))}else d=Mw.h($CLJS.q($CLJS.q(a))),c=d+($CLJS.n(c)?$CLJS.E(c):0),$CLJS.Gh.v($CLJS.q(a),$CLJS.S,Mw,c),Iw(a,new iw(dx,b,d,c,null,null,null));$CLJS.Gh.v($CLJS.q(a),$CLJS.S,Kw,dw.h(b))};
$CLJS.fx=function(a){var b=null!=a?a.C&32768||$CLJS.r===a.Wf?!0:a.C?!1:$CLJS.Wa($CLJS.Dv,a):$CLJS.Wa($CLJS.Dv,a);return b?Uw.h($CLJS.q($CLJS.q(a))):b};$CLJS.jx=function(a){var b=gx;$CLJS.n(b)&&(b=$CLJS.Ma,b=$CLJS.n(b)?gx>=$CLJS.Ma:b);$CLJS.Sa($CLJS.hx)?Ov.call(null,a):$CLJS.n(b)?$CLJS.$b($CLJS.Pv,"..."):($CLJS.n(gx)&&(gx+=1),ix.call(null,a));return b};kx=function(a,b){if($CLJS.Sa(b.h?b.h(a):b.call(null,a)))throw Error(["Bad argument: ",$CLJS.p.h(a),". It must be one of ",$CLJS.p.h(b)].join(""));};
mx=function(){var a=$CLJS.ih;return $CLJS.n(a)?lx>=$CLJS.ih:a};rx=function(a){kx(a,new $CLJS.Tg(null,new $CLJS.h(null,4,[nx,null,ox,null,px,null,qx,null],null),null));var b=$CLJS.Pv;$CLJS.Gh.v($CLJS.q(b),$CLJS.S,$CLJS.qi,Lw);var c=Mw.h($CLJS.q($CLJS.q(b))),d=Kw.h($CLJS.q($CLJS.q(b)));Iw(b,new gw(lw,a,d,c,c,null,null,null))};
vx=function(a,b){kx(a,new $CLJS.Tg(null,new $CLJS.h(null,2,[sx,null,$CLJS.tx,null],null),null));var c=$CLJS.Pv,d=Kw.h($CLJS.q($CLJS.q(c)));if($CLJS.F.g($CLJS.qi.h($CLJS.q($CLJS.q(c))),Ow)){Jw(c);var e=Cw.h(d);if($CLJS.n($CLJS.F.g?$CLJS.F.g(sx,a):$CLJS.F.call(null,sx,a)))a=$CLJS.q(vw.h(d));else if($CLJS.n($CLJS.F.g?$CLJS.F.g($CLJS.tx,a):$CLJS.F.call(null,$CLJS.tx,a)))a=$CLJS.Xv($v.h($CLJS.q($CLJS.q(c))),$CLJS.Zv);else throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));$CLJS.Pe(e,b+a)}else e=
Mw.h($CLJS.q($CLJS.q(c))),Iw(c,new jw(ux,d,a,b,e,e,null,null,null))};Ax=function(a,b,c){b="string"===typeof b?wx(b):b;c=xx(c);a:{var d=new $CLJS.Ea,e=$CLJS.Sa(a)||!0===a?new $CLJS.sc(d):a,f=yx(b)&&$CLJS.Sa($CLJS.fx(e))?$CLJS.n($CLJS.fx(e))?e:$CLJS.Xw(e):e,k=$CLJS.Pv;$CLJS.Pv=f;try{try{zx(b,c)}finally{e!==f&&Ev(f)}var l=$CLJS.Sa(a)?$CLJS.p.h(d):!0===a?$CLJS.mh($CLJS.p.h(d)):null;break a}finally{$CLJS.Pv=k}l=void 0}return l};
Cx=function(a,b){a=[$CLJS.p.h(a),"\n",$CLJS.p.h(Bx),"\n",$CLJS.p.h($CLJS.P.g($CLJS.p,$CLJS.Ve(b," "))),"^\n"].join("");throw Error(a);};Dx=function(a,b,c,d,e,f){this.sc=a;this.pb=b;this.Zb=c;this.S=d;this.G=e;this.J=f;this.C=2230716170;this.I=139264};xx=function(a){a=$CLJS.z(a);return new Dx(a,a,0,null,null,null)};
Fx=function(a){var b=$CLJS.Hi.h(a);if($CLJS.n(b))return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B(b),new Dx(Ex.h(a),$CLJS.C(b),Mw.h(a)+1,null,null,null)],null);throw Error("Not enough arguments for format definition");};Gx=function(a){var b=Fx(a);a=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);a="string"===typeof a?wx(a):a;return new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null)};Ix=function(a,b){return b>=Mw.h(a)?(b=Mw.h(a)-b,Hx.g?Hx.g(a,b):Hx.call(null,a,b)):new Dx(Ex.h(a),$CLJS.Te(b,Ex.h(a)),b,null,null,null)};
Hx=function(a,b){var c=Mw.h(a)+b;return 0>b?Ix(a,c):new Dx(Ex.h(a),$CLJS.Te(b,$CLJS.Hi.h(a)),c,null,null,null)};Jx=function(a,b,c,d,e,f,k){this.cc=a;this.bc=b;this.jc=c;this.offset=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};
Ox=function(a,b){var c=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);var d=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);d=$CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,2,[Kx,null,Lx,null],null),null),c)?new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null):$CLJS.F.g(d,Mx)?Fx(b):$CLJS.F.g(d,Nx)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.E($CLJS.Hi.h(b)),b],null):new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);b=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[c,new $CLJS.Q(null,2,5,$CLJS.R,
[b,a],null)],null),d],null)};Px=function(a,b){b=Sv(Ox,b,a);a=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wf.g($CLJS.N,a),b],null)};
Zx=function(a,b,c){c=Fx(c);var d=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);if($CLJS.Ed(d))if($CLJS.F.g(Qx,10))var e=[$CLJS.p.h(d),$CLJS.n(Rx)?".":null].join("");else{e=$CLJS.p;var f=e.h;if($CLJS.n(Rx)){var k=$CLJS.J.g(Sx,Qx);k=$CLJS.n(k)?k:["#",$CLJS.p.h(Qx),"r"].join("")}else k=null;e=[f.call(e,k),$CLJS.p.h(Tx(Qx,d))].join("")}else e=null;a=$CLJS.n(e)?e:a.h?a.h(d):a.call(null,d);d=a.length;e=d+Ux.h(b);e=e>=Vx.h(b)?e:e+($CLJS.Td(Vx.h(b)-e-1,Wx.h(b))+1)*Wx.h(b);d=$CLJS.P.g($CLJS.p,$CLJS.Ve(e-d,Xx.h(b)));
$CLJS.n(Lx.h(b))?Yx.l($CLJS.G([[$CLJS.p.h(d),$CLJS.p.h(a)].join("")])):Yx.l($CLJS.G([[$CLJS.p.h(a),$CLJS.p.h(d)].join("")]));return c};$x=function(a,b){return $CLJS.ae($CLJS.B(Tv(function(c){return 0<c?new $CLJS.Q(null,2,5,$CLJS.R,[Iv(c,a),$CLJS.Td(c,a)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,null],null)},b)))};Tx=function(a,b){return 0===b?"0":$CLJS.P.g($CLJS.p,$CLJS.Ze.g(function(c){return 10>c?Hv(Rv("0")+c):Hv(Rv("a")+(c-10))},$x(a,b)))};
ay=function(a,b){return $CLJS.ae($CLJS.B(Tv(function(c){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.z($CLJS.ae($CLJS.Se(a,c))),$CLJS.z($CLJS.Te(a,c))],null)},$CLJS.ae(b))))};
ey=function(a,b,c){c=Fx(c);var d=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);if($CLJS.Ed(d)||"number"===typeof d&&!isNaN(d)&&Infinity!==d&&parseFloat(d)!==parseInt(d,10)&&$CLJS.F.g(d,Math.floor(d))){var e=0>d,f=Tx(a,e?-d:d);a=$CLJS.n(Kx.h(b))?function(){var k=$CLJS.Ze.g(function(m){return $CLJS.P.g($CLJS.p,m)},ay(by.h(b),f)),l=$CLJS.Ve($CLJS.E(k),cy.h(b));return $CLJS.P.g($CLJS.p,$CLJS.C($CLJS.We.g(l,k)))}():f;a=e?["-",$CLJS.p.h(a)].join(""):$CLJS.n(Lx.h(b))?["+",$CLJS.p.h(a)].join(""):a;a=a.length<Vx.h(b)?
[$CLJS.p.h($CLJS.P.g($CLJS.p,$CLJS.Ve(Vx.h(b)-a.length,Xx.h(b)))),$CLJS.p.h(a)].join(""):a;Yx.l($CLJS.G([a]))}else Zx($CLJS.dy,new $CLJS.h(null,5,[Vx,Vx.h(b),Wx,1,Ux,0,Xx,Xx.h(b),Lx,!0],null),xx(new $CLJS.Q(null,1,5,$CLJS.R,[d],null)));return c};
hy=function(a){var b=$CLJS.Td(a,100);a=Iv(a,100);var c=0<b?[$CLJS.p.h($CLJS.bd(fy,b))," hundred"].join(""):null,d=$CLJS.p,e=d.h;if(0<a)if(20>a)var f=$CLJS.bd(fy,a);else{f=$CLJS.Td(a,10);var k=Iv(a,10);f=[$CLJS.p.h(0<f?$CLJS.bd(gy,f):null),0<f&&0<k?"-":null,$CLJS.p.h(0<k?$CLJS.bd(fy,k):null)].join("")}else f=null;return[c,0<b&&0<a?" ":null,e.call(d,f)].join("")};
jy=function(a,b){var c=$CLJS.E(a),d=$CLJS.tf;--c;var e=$CLJS.B(a);for(a=$CLJS.C(a);;){if(null==a)return[$CLJS.p.h($CLJS.P.g($CLJS.p,$CLJS.Xe(", ",d))),$CLJS.sd(e)||$CLJS.sd(d)?null:", ",$CLJS.p.h(e),!$CLJS.sd(e)&&0<c+b?[" ",$CLJS.p.h($CLJS.bd(iy,c+b))].join(""):null].join("");d=$CLJS.sd(e)?d:$CLJS.$d.g(d,[$CLJS.p.h(e)," ",$CLJS.p.h($CLJS.bd(iy,c+b))].join(""));--c;e=$CLJS.B(a);a=$CLJS.C(a)}};
ky=function(a,b){b=Fx(b);var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);if("number"===typeof c&&0<c&&4E3>c){var d=$x(10,c);c=$CLJS.tf;for(var e=$CLJS.E(d)-1;;)if($CLJS.sd(d)){Yx.l($CLJS.G([$CLJS.P.g($CLJS.p,c)]));break}else{var f=$CLJS.B(d);c=$CLJS.F.g(0,f)?c:$CLJS.$d.g(c,$CLJS.bd($CLJS.bd(a,e),f-1));--e;d=$CLJS.C(d)}}else ey(10,new $CLJS.h(null,5,[Vx,0,Xx," ",cy,",",by,3,Kx,!0],null),xx(new $CLJS.Q(null,1,5,$CLJS.R,[c],null)));return b};
my=function(a,b){a=Fx(b);b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);var c=Rv(b);b=c&127;c&=128;var d=$CLJS.J.g(ly,b);0<c&&Yx.l($CLJS.G(["Meta-"]));Yx.l($CLJS.G([$CLJS.n(d)?d:32>b?["Control-",$CLJS.p.h(Hv(b+64))].join(""):$CLJS.F.g(b,127)?"Control-?":Hv(b)]));return a};
oy=function(a,b){var c=Fx(b);b=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);a=ny.h(a);if($CLJS.n($CLJS.F.g?$CLJS.F.g("o",a):$CLJS.F.call(null,"o",a)))Ax(!0,"\\o~3,'0o",$CLJS.G([Rv(b)]));else if($CLJS.n($CLJS.F.g?$CLJS.F.g("u",a):$CLJS.F.call(null,"u",a)))Ax(!0,"\\u~4,'0x",$CLJS.G([Rv(b)]));else if($CLJS.n($CLJS.F.g?$CLJS.F.g(null,a):$CLJS.F.call(null,null,a)))$CLJS.$b($CLJS.Pv,$CLJS.n($CLJS.F.g?$CLJS.F.g("\b",b):$CLJS.F.call(null,"\b",b))?"\\backspace":$CLJS.n($CLJS.F.g?$CLJS.F.g(" ",b):$CLJS.F.call(null,
" ",b))?"\\space":$CLJS.n($CLJS.F.g?$CLJS.F.g("\t",b):$CLJS.F.call(null,"\t",b))?"\\tab":$CLJS.n($CLJS.F.g?$CLJS.F.g("\n",b):$CLJS.F.call(null,"\n",b))?"\\newline":$CLJS.n($CLJS.F.g?$CLJS.F.g("\f",b):$CLJS.F.call(null,"\f",b))?"\\formfeed":$CLJS.n($CLJS.F.g?$CLJS.F.g("\r",b):$CLJS.F.call(null,"\r",b))?"\\return":$CLJS.n($CLJS.F.g?$CLJS.F.g('"',b):$CLJS.F.call(null,'"',b))?'\\"':$CLJS.n($CLJS.F.g?$CLJS.F.g("\\",b):$CLJS.F.call(null,"\\",b))?"\\\\":["\\",$CLJS.p.h(b)].join(""));else throw Error(["No matching clause: ",
$CLJS.p.h(a)].join(""));return c};py=function(a,b){b=Fx(b);a=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);Yx.l($CLJS.G([a]));return b};sy=function(a){a=$CLJS.B(a);return $CLJS.F.g(qy,a)||$CLJS.F.g(ry,a)};
wy=function(a,b,c){return $CLJS.hd(Sv(function(d,e){if(sy(e))return new $CLJS.Q(null,2,5,$CLJS.R,[null,e],null);e=Px(ty.h(d),e);var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);var k=Uv(f);f=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);f=$CLJS.S.j(f,uy,c);return new $CLJS.Q(null,2,5,$CLJS.R,[null,$CLJS.P.g(vy.h(d),new $CLJS.Q(null,3,5,$CLJS.R,[f,e,k],null))],null)},b,a))};
xy=function(a){a=$CLJS.p.h(a).toLowerCase();var b=a.indexOf("e"),c=a.indexOf(".");a=0>b?0>c?new $CLJS.Q(null,2,5,$CLJS.R,[a,$CLJS.p.h(a.length-1)],null):new $CLJS.Q(null,2,5,$CLJS.R,[[a.substring(0,c),a.substring(c+1)].join(""),$CLJS.p.h(c-1)],null):0>c?new $CLJS.Q(null,2,5,$CLJS.R,[a.substring(0,b),a.substring(b+1)],null):new $CLJS.Q(null,2,5,$CLJS.R,[[a.substring(0,1),a.substring(2,b)].join(""),a.substring(b+1)],null);b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);a:if(c=$CLJS.E(b),0<c&&$CLJS.F.g($CLJS.bd(b,
$CLJS.E(b)-1),"0"))for(--c;;){if(0>c){b="";break a}if($CLJS.F.g($CLJS.bd(b,c),"0"))--c;else{b=b.substring(0,c+1);break a}}a:{c=b;var d=$CLJS.E(c);if(0<d&&$CLJS.F.g($CLJS.bd(c,0),"0"))for(var e=0;;){if($CLJS.F.g(e,d)||!$CLJS.F.g($CLJS.bd(c,e),"0")){c=c.substring(e);break a}e+=1}}b=$CLJS.E(b)-$CLJS.E(c);a=0<$CLJS.E(a)&&$CLJS.F.g($CLJS.bd(a,0),"+")?a.substring(1):a;return $CLJS.sd(c)?new $CLJS.Q(null,2,5,$CLJS.R,["0",0],null):new $CLJS.Q(null,2,5,$CLJS.R,[c,parseInt(a,10)-b],null)};
yy=function(a,b,c,d){if($CLJS.n($CLJS.n(c)?c:d)){var e=$CLJS.E(a);d=$CLJS.n(d)?2>d?2:d:0;$CLJS.n(c)?c=b+c+1:0<=b?(c=b+1,--d,c=c>d?c:d):c=d+b;var f=$CLJS.F.g(c,0)?new $CLJS.Q(null,4,5,$CLJS.R,[["0",$CLJS.p.h(a)].join(""),b+1,1,e+1],null):new $CLJS.Q(null,4,5,$CLJS.R,[a,b,c,e],null);c=$CLJS.H(f,0,null);e=$CLJS.H(f,1,null);d=$CLJS.H(f,2,null);f=$CLJS.H(f,3,null);if($CLJS.n(d)){if(0>d)return new $CLJS.Q(null,3,5,$CLJS.R,["0",0,!1],null);if(f>d){b=$CLJS.bd(c,d);a=c.substring(0,d);if(Rv(b)>=Rv("5")){a:for(b=
$CLJS.E(a)-1,c=b|0;;){if(0>c){b=$CLJS.P.j($CLJS.p,"1",$CLJS.Ve(b+1,"0"));break a}if($CLJS.F.g("9",a.charAt(c)))--c;else{b=$CLJS.P.v($CLJS.p,a.substring(0,c),Hv(Rv(a.charAt(c))+1),$CLJS.Ve(b-c,"0"));break a}}a=$CLJS.E(b)>a.length;c=$CLJS.R;a&&(d=$CLJS.E(b)-1,b=b.substring(0,d));return new $CLJS.Q(null,3,5,c,[b,e,a],null)}return new $CLJS.Q(null,3,5,$CLJS.R,[a,e,!1],null)}}}return new $CLJS.Q(null,3,5,$CLJS.R,[a,b,!1],null)};
zy=function(a,b,c){var d=0>b?new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.p.h($CLJS.P.g($CLJS.p,$CLJS.Ve(-b-1,"0"))),$CLJS.p.h(a)].join(""),-1],null):new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null);a=$CLJS.H(d,0,null);var e=$CLJS.H(d,1,null);d=$CLJS.E(a);c=$CLJS.n(c)?e+c+1:e+1;c=d<c?[$CLJS.p.h(a),$CLJS.p.h($CLJS.P.g($CLJS.p,$CLJS.Ve(c-d,"0")))].join(""):a;0>b?b=[".",$CLJS.p.h(c)].join(""):(b+=1,b=[c.substring(0,b),".",c.substring(b)].join(""));return b};
Ay=function(a,b){return 0>b?[".",$CLJS.p.h(a)].join(""):[a.substring(0,b),".",a.substring(b)].join("")};
Fy=function(a,b){var c=By.h(a),d=Cy.h(a);b=Fx(b);var e=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);var f=0>e?new $CLJS.Q(null,2,5,$CLJS.R,["-",-e],null):new $CLJS.Q(null,2,5,$CLJS.R,["+",e],null),k=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);f=xy(f);var l=$CLJS.H(f,0,null),m=$CLJS.H(f,1,null)+$CLJS.Dy.h(a);f=function(){var x=Lx.h(a);return $CLJS.n(x)?x:0>e}();var t=$CLJS.Sa(d)&&$CLJS.E(l)-1<=m,u=yy(l,m,d,$CLJS.n(c)?c-($CLJS.n(f)?1:0):null);l=$CLJS.H(u,0,null);m=$CLJS.H(u,1,null);u=$CLJS.H(u,2,null);l=zy(l,$CLJS.n(u)?
m+1:m,d);d=$CLJS.n($CLJS.n(c)?$CLJS.n(d)?1<=d&&$CLJS.F.g(l.charAt(0),"0")&&$CLJS.F.g(l.charAt(1),".")&&l.length>c-($CLJS.n(f)?1:0):d:c)?l.substring(1):l;m=$CLJS.F.g($CLJS.B(d),".");if($CLJS.n(c)){l=d.length;l=$CLJS.n(f)?l+1:l;m=m&&!(l>=c);t=t&&!(l>=c);var v=m||t?l+1:l;$CLJS.n(function(){var x=v>c;return x?Ey.h(a):x}())?Yx.l($CLJS.G([$CLJS.P.g($CLJS.p,$CLJS.Ve(c,Ey.h(a)))])):Yx.l($CLJS.G([[$CLJS.p.h($CLJS.P.g($CLJS.p,$CLJS.Ve(c-v,Xx.h(a)))),$CLJS.p.h($CLJS.n(f)?k:null),m?"0":null,d,t?"0":null].join("")]))}else Yx.l($CLJS.G([[$CLJS.p.h($CLJS.n(f)?
k:null),m?"0":null,d,t?"0":null].join("")]));return b};
Iy=function(a,b){b=Fx(b);var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);var d=xy(0>c?-c:c);$CLJS.H(d,0,null);for($CLJS.H(d,1,null);;){var e=$CLJS.H(d,0,null),f=$CLJS.H(d,1,null),k=By.h(a),l=Cy.h(a),m=Gy.h(a),t=$CLJS.Dy.h(a),u=function(){var K=Hy.h(a);return $CLJS.n(K)?K:"E"}();d=function(){var K=Lx.h(a);return $CLJS.n(K)?K:0>c}();var v=0>=t,x=f-(t-1),A=$CLJS.p.h(Math.abs(x));u=[$CLJS.p.h(u),0>x?"-":"+",$CLJS.p.h($CLJS.n(m)?$CLJS.P.g($CLJS.p,$CLJS.Ve(m-A.length,"0")):null),A].join("");var D=u.length;
x=$CLJS.E(e);e=[$CLJS.p.h($CLJS.P.g($CLJS.p,$CLJS.Ve(-t,"0"))),$CLJS.p.h(e),$CLJS.p.h($CLJS.n(l)?$CLJS.P.g($CLJS.p,$CLJS.Ve(l-(x-1)-(0>t?-t:0),"0")):null)].join("");x=$CLJS.n(k)?k-D:null;e=yy(e,0,$CLJS.F.g(t,0)?l-1:0<t?l:0>t?l-1:null,$CLJS.n(x)?x-($CLJS.n(d)?1:0):null);x=$CLJS.H(e,0,null);$CLJS.H(e,1,null);A=$CLJS.H(e,2,null);e=Ay(x,t);l=$CLJS.F.g(t,$CLJS.E(x))&&null==l;if($CLJS.Sa(A)){if($CLJS.n(k)){f=e.length+D;f=$CLJS.n(d)?f+1:f;var I=(v=v&&!$CLJS.F.g(f,k))?f+1:f;f=l&&I<k;$CLJS.n(function(){var K=
I>k;K||(K=m,K=$CLJS.n(K)?D-2>m:K);return $CLJS.n(K)?Ey.h(a):K}())?Yx.l($CLJS.G([$CLJS.P.g($CLJS.p,$CLJS.Ve(k,Ey.h(a)))])):Yx.l($CLJS.G([[$CLJS.p.h($CLJS.P.g($CLJS.p,$CLJS.Ve(k-I-(f?1:0),Xx.h(a)))),$CLJS.n(d)?0>c?"-":"+":null,v?"0":null,e,f?"0":null,u].join("")]))}else Yx.l($CLJS.G([[$CLJS.n(d)?0>c?"-":"+":null,v?"0":null,e,l?"0":null,u].join("")]));break}else d=new $CLJS.Q(null,2,5,$CLJS.R,[x,f+1],null)}return b};
Jy=function(a,b,c){var d=Fx(b),e=$CLJS.H(d,0,null);$CLJS.H(d,1,null);d=xy(0>e?-e:e);var f=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);var k=By.h(a),l=Cy.h(a),m=Gy.h(a);d=$CLJS.F.g(e,0)?0:d+1;e=$CLJS.n(m)?m+2:4;k=$CLJS.n(k)?k-e:null;$CLJS.n(l)?f=l:(f=$CLJS.E(f),l=7>d?d:7,f=f>l?f:l);d=f-d;return 0<=d&&d<=f?(a=Fy(new $CLJS.h(null,6,[By,k,Cy,d,$CLJS.Dy,0,Ey,Ey.h(a),Xx,Xx.h(a),Lx,Lx.h(a)],null),b,c),Yx.l($CLJS.G([$CLJS.P.g($CLJS.p,$CLJS.Ve(e," "))])),a):Iy(a,b,c)};
Ly=function(a,b){b=Fx(b);var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);var d=xy(Math.abs(c)),e=$CLJS.H(d,0,null),f=$CLJS.H(d,1,null),k=Cy.h(a),l=$CLJS.Ky.h(a);d=By.h(a);var m=function(){var u=Lx.h(a);return $CLJS.n(u)?u:0>c}(),t=yy(e,f,k,null);e=$CLJS.H(t,0,null);f=$CLJS.H(t,1,null);t=$CLJS.H(t,2,null);k=zy(e,$CLJS.n(t)?f+1:f,k);l=[$CLJS.p.h($CLJS.P.g($CLJS.p,$CLJS.Ve(l-k.indexOf("."),"0"))),k].join("");k=l.length+($CLJS.n(m)?1:0);Yx.l($CLJS.G([[$CLJS.n(function(){var u=Kx.h(a);return $CLJS.n(u)?m:
u}())?0>c?"-":"+":null,$CLJS.p.h($CLJS.P.g($CLJS.p,$CLJS.Ve(d-k,Xx.h(a)))),$CLJS.n(function(){var u=$CLJS.Sa(Kx.h(a));return u?m:u}())?0>c?"-":"+":null,l].join("")]));return b};Oy=function(a,b){var c=My.h(a);c=$CLJS.n(c)?new $CLJS.Q(null,2,5,$CLJS.R,[c,b],null):Fx(b);b=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);var d=$CLJS.Ny.h(a);b=0>b||b>=$CLJS.E(d)?$CLJS.B($CLJS.Iu.h(a)):$CLJS.bd(d,b);return $CLJS.n(b)?wy(b,c,uy.h(a)):c};
Py=function(a,b){var c=Fx(b);b=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);var d=$CLJS.Ny.h(a);b=$CLJS.n(b)?$CLJS.hd(d):$CLJS.B(d);return $CLJS.n(b)?wy(b,c,uy.h(a)):c};Qy=function(a,b){var c=Fx(b),d=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);var e=$CLJS.Ny.h(a);e=$CLJS.n(d)?$CLJS.B(e):null;return $CLJS.n(d)?$CLJS.n(e)?wy(e,b,uy.h(a)):b:c};
Ty=function(a,b){var c=Ry.h(a),d=$CLJS.B($CLJS.Ny.h(a));d=$CLJS.sd(d)?Gx(b):new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);b=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);d=Fx(d);var e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);var f=0;e=xx(e);for(var k=-1;;){if($CLJS.Sa(c)&&$CLJS.F.g(Mw.h(e),k)&&1<f)throw Error("%{ construct not consuming any arguments: Infinite loop!");k=$CLJS.sd($CLJS.Hi.h(e))&&($CLJS.Sa(Kx.h(Sy.h(a)))||0<f);if($CLJS.n(k?k:$CLJS.n(c)?f>=c:c))return d;k=wy(b,e,uy.h(a));if($CLJS.F.g(qy,$CLJS.B(k)))return d;
f+=1;var l=Mw.h(e);e=k;k=l}};Uy=function(a,b){var c=Ry.h(a),d=$CLJS.B($CLJS.Ny.h(a));d=$CLJS.sd(d)?Gx(b):new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);b=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);d=Fx(d);var e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);for(var f=0;;){var k=$CLJS.sd(e)&&($CLJS.Sa(Kx.h(Sy.h(a)))||0<f);if($CLJS.n(k?k:$CLJS.n(c)?f>=c:c))return d;k=wy(b,xx($CLJS.B(e)),xx($CLJS.C(e)));if($CLJS.F.g(ry,$CLJS.B(k)))return d;f+=1;e=$CLJS.C(e)}};
Vy=function(a,b){var c=Ry.h(a),d=$CLJS.B($CLJS.Ny.h(a)),e=$CLJS.sd(d)?Gx(b):new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);b=$CLJS.H(e,0,null);d=0;e=$CLJS.H(e,1,null);for(var f=-1;;){if($CLJS.Sa(c)&&$CLJS.F.g(Mw.h(e),f)&&1<d)throw Error("%@{ construct not consuming any arguments: Infinite loop!");f=$CLJS.sd($CLJS.Hi.h(e))&&($CLJS.Sa(Kx.h(Sy.h(a)))||0<d);if($CLJS.n(f?f:$CLJS.n(c)?d>=c:c))return e;f=wy(b,e,uy.h(a));if($CLJS.F.g(qy,$CLJS.B(f)))return $CLJS.hd(f);d+=1;var k=Mw.h(e);e=f;f=k}};
Wy=function(a,b){var c=Ry.h(a),d=$CLJS.B($CLJS.Ny.h(a)),e=$CLJS.sd(d)?Gx(b):new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);b=$CLJS.H(e,0,null);d=0;for(e=$CLJS.H(e,1,null);;){var f=$CLJS.sd($CLJS.Hi.h(e))&&($CLJS.Sa(Kx.h(Sy.h(a)))||0<d);if($CLJS.n(f?f:$CLJS.n(c)?d>=c:c))return e;f=$CLJS.Hi.h(e);f=$CLJS.n(f)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B(f),new Dx(Ex.h(e),$CLJS.C(f),Mw.h(e)+1,null,null,null)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,e],null);e=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);e=wy(b,xx(e),f);
if($CLJS.F.g(ry,$CLJS.B(e)))return f;e=f;d+=1}};Yy=function(a,b){if($CLJS.n(Kx.h(Sy.h(a)))){var c=$CLJS.Ny.h(a),d=$CLJS.E(c),e=1<d?$CLJS.Oj.h(ty.h($CLJS.B($CLJS.B(c)))):$CLJS.n(Kx.h(a))?"(":null,f=$CLJS.bd(c,1<d?1:0);c=2<d?$CLJS.Oj.h(ty.h($CLJS.B($CLJS.bd(c,2)))):$CLJS.n(Kx.h(a))?")":null;d=Fx(b);b=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);if($CLJS.n(mx()))$CLJS.$b($CLJS.Pv,"#");else{var k=lx,l=gx;lx+=1;gx=0;try{ax(e,c),wy(f,xx(b),uy.h(a)),ex()}finally{gx=l,lx=k}}a=d}else a=Xy(a,b);return a};
Zy=function(a,b,c){for(var d=$CLJS.tf;;){if($CLJS.sd(a))return new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);var e=$CLJS.B(a);a:{var f=new $CLJS.Ea,k=$CLJS.Pv;$CLJS.Pv=new $CLJS.sc(f);try{var l=new $CLJS.Q(null,2,5,$CLJS.R,[wy(e,b,c),$CLJS.p.h(f)],null);break a}finally{$CLJS.Pv=k}l=void 0}b=$CLJS.H(l,0,null);e=$CLJS.H(l,1,null);if($CLJS.F.g(qy,$CLJS.B(b)))return new $CLJS.Q(null,2,5,$CLJS.R,[d,$CLJS.hd(b)],null);a=$CLJS.C(a);d=$CLJS.$d.g(d,e)}};
Xy=function(a,b){var c=function(){var Z=$CLJS.Iu.h(a);return $CLJS.n(Z)?Zy(Z,b,uy.h(a)):null}(),d=$CLJS.H(c,0,null);d=$CLJS.H(d,0,null);c=$CLJS.H(c,1,null);var e=$CLJS.n(c)?c:b;c=function(){var Z=$y.h(a);return $CLJS.n(Z)?Px(Z,e):null}();var f=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);var k=$CLJS.n(c)?c:e;c=function(){var Z=$CLJS.B(az.h(f));return $CLJS.n(Z)?Z:0}();var l=function(){var Z=$CLJS.B(bz.h(f));return $CLJS.n(Z)?Z:$CLJS.Xv($CLJS.Pv,$CLJS.Xj)}(),m=$CLJS.Ny.h(a);k=Zy(m,k,uy.h(a));var t=$CLJS.H(k,
0,null);k=$CLJS.H(k,1,null);var u=function(){var Z=$CLJS.E(t)-1+($CLJS.n(Kx.h(a))?1:0)+($CLJS.n(Lx.h(a))?1:0);return 1>Z?1:Z}();m=$CLJS.Od($CLJS.vk,$CLJS.Ze.g($CLJS.E,t));var v=Vx.h(a),x=Ux.h(a),A=Wx.h(a),D=m+u*x;v=D<=v?v:v+A*(1+$CLJS.Td(D-v-1,A));var I=v-m;m=function(){var Z=$CLJS.Td(I,u);return x>Z?x:Z}();A=I-m*u;m=$CLJS.P.g($CLJS.p,$CLJS.Ve(m,Xx.h(a)));$CLJS.n($CLJS.n(d)?$CLJS.Xv($v.h($CLJS.q($CLJS.q($CLJS.Pv))),$CLJS.Zv)+c+v>l:d)&&Yx.l($CLJS.G([d]));c=A;for(var K=t,U=function(){var Z=Kx.h(a);
return $CLJS.n(Z)?Z:$CLJS.F.g($CLJS.E(K),1)&&$CLJS.Sa(Lx.h(a))}();;)if($CLJS.z(K))Yx.l($CLJS.G([[$CLJS.p.h($CLJS.Sa(U)?$CLJS.B(K):null),$CLJS.p.h($CLJS.n(function(){var Z=U;return $CLJS.n(Z)?Z:(Z=$CLJS.C(K))?Z:Lx.h(a)}())?m:null),$CLJS.p.h(0<c?Xx.h(a):null)].join("")])),--c,K=d=$CLJS.n(U)?K:$CLJS.C(K),U=!1;else break;return k};cz=function(a,b){this.Ba=a;this.Zh=b;this.C=1074135040;this.I=0};dz=function(a){return new cz(a,$CLJS.N)};ez=function(a,b){this.Ba=a;this.$h=b;this.C=1074135040;this.I=0};
fz=function(a){return new ez(a,$CLJS.N)};
gz=function(a,b){var c=$CLJS.B(a);return $CLJS.P.g($CLJS.p,$CLJS.B(Tv(function(d){if($CLJS.sd(d))return new $CLJS.Q(null,2,5,$CLJS.R,[null,null],null);var e=RegExp("\\W\\w","g").exec(d);e=$CLJS.n(e)?e.index+1:e;return $CLJS.n(e)?new $CLJS.Q(null,2,5,$CLJS.R,[[d.substring(0,e),$CLJS.bd(d,e).toUpperCase()].join(""),d.substring(e+1)],null):new $CLJS.Q(null,2,5,$CLJS.R,[d,null],null)},$CLJS.n($CLJS.n(b)?$CLJS.n(c)?Cv(c):c:b)?[c.toUpperCase(),a.substring(1)].join(""):a)))};
hz=function(a,b,c){this.Ba=a;this.Id=b;this.ai=c;this.C=1074135040;this.I=0};iz=function(a){return new hz(a,$CLJS.Oe(!0),$CLJS.N)};jz=function(a,b,c){this.Ba=a;this.ud=b;this.bi=c;this.C=1074135040;this.I=0};kz=function(a){return new jz(a,$CLJS.Oe(!1),$CLJS.N)};lz=function(a,b){var c=$CLJS.n(Kx.h(a))?$CLJS.tx:sx;vx(c,$CLJS.Ky.h(a));return b};mz=function(a,b){a=$CLJS.n(Kx.h(a))?$CLJS.n(Lx.h(a))?nx:px:$CLJS.n(Lx.h(a))?ox:qx;rx(a);return b};
oz=function(a){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null),d=$CLJS.H(a,2,null);a=new RegExp(nz.source,"g");var e=a.exec(b);return $CLJS.n(e)?(d=$CLJS.B(e),b=b.substring(a.lastIndex),a=c+a.lastIndex,$CLJS.F.g(",",$CLJS.bd(b,0))?new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[d,c],null),new $CLJS.Q(null,3,5,$CLJS.R,[b.substring(1),a+1,!0],null)],null):new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[d,c],null),new $CLJS.Q(null,3,5,$CLJS.R,[b,a,!1],null)],null)):$CLJS.n(d)?Cx("Badly formed parameters in format directive",
c):new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,2,5,$CLJS.R,[b,c],null)],null)};pz=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.F.g(b.length,0)?null:$CLJS.F.g(b.length,1)&&$CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,2,["V",null,"v",null],null),null),$CLJS.bd(b,0))?Mx:$CLJS.F.g(b.length,1)&&$CLJS.F.g("#",$CLJS.bd(b,0))?Nx:$CLJS.F.g(b.length,2)&&$CLJS.F.g("'",$CLJS.bd(b,0))?$CLJS.bd(b,1):parseInt(b,10),a],null)};
rz=function(a,b){return Tv(function(c){var d=$CLJS.H(c,0,null),e=$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);if($CLJS.sd(d))return new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,3,5,$CLJS.R,[d,e,c],null)],null);var f=$CLJS.J.g(qz,$CLJS.B(d));return $CLJS.n(f)?$CLJS.Gd(c,f)?Cx(['Flag "',$CLJS.p.h($CLJS.B(d)),'" appears more than once in a directive'].join(""),e):new $CLJS.Q(null,2,5,$CLJS.R,[!0,new $CLJS.Q(null,3,5,$CLJS.R,[d.substring(1),e+1,$CLJS.S.j(c,f,new $CLJS.Q(null,2,5,$CLJS.R,[!0,e],null))],
null)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,3,5,$CLJS.R,[d,e,c],null)],null)},new $CLJS.Q(null,3,5,$CLJS.R,[a,b,$CLJS.N],null))};
vz=function(a,b){var c=sz.h(a);$CLJS.n(function(){var d=$CLJS.Sa(Lx.h(c));return d?Lx.h(b):d}())&&Cx(['"@" is an illegal flag for format directive "',$CLJS.p.h(tz.h(a)),'"'].join(""),$CLJS.bd(Lx.h(b),1));$CLJS.n(function(){var d=$CLJS.Sa(Kx.h(c));return d?Kx.h(b):d}())&&Cx(['":" is an illegal flag for format directive "',$CLJS.p.h(tz.h(a)),'"'].join(""),$CLJS.bd(Kx.h(b),1));$CLJS.n(function(){var d=$CLJS.Sa(uz.h(c));return d?(d=Lx.h(b),$CLJS.n(d)?Kx.h(b):d):d}())&&Cx(['Cannot combine "@" and ":" flags for format directive "',
$CLJS.p.h(tz.h(a)),'"'].join(""),function(){var d=$CLJS.bd(Kx.h(b),1),e=$CLJS.bd(Lx.h(b),1);return d<e?d:e}())};
xz=function(a,b,c,d){vz(a,c);$CLJS.E(b)>$CLJS.E(ty.h(a))&&Cx(Ax(null,'Too many parameters for directive "~C": ~D~:* ~[were~;was~:;were~] specified but only ~D~:* ~[are~;is~:;are~] allowed',$CLJS.G([tz.h(a),$CLJS.E(b),$CLJS.E(ty.h(a))])),$CLJS.hd($CLJS.B(b)));$CLJS.eh($CLJS.Ze.j(function(e,f){var k=$CLJS.B(e);return null==k||$CLJS.Gd(wz,k)||$CLJS.F.g($CLJS.hd($CLJS.hd(f)),$CLJS.Xa(k))?null:Cx(["Parameter ",$CLJS.Zg($CLJS.B(f)),' has bad type in directive "',$CLJS.p.h(tz.h(a)),'": ',$CLJS.p.h($CLJS.Xa(k))].join(""),
$CLJS.hd(e))},b,ty.h(a)));return $CLJS.Tk.l($CLJS.G([$CLJS.Wf.g($CLJS.N,$CLJS.ae(function(){return function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.zd(l)){var m=$CLJS.jc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),A=$CLJS.H(x,0,null);x=$CLJS.H(x,1,null);x=$CLJS.H(x,0,null);u.add(new $CLJS.Q(null,2,5,$CLJS.R,[A,new $CLJS.Q(null,2,5,$CLJS.R,[x,d],null)],null));v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.lc(l))):
$CLJS.re($CLJS.te(u),null)}m=$CLJS.B(l);u=$CLJS.H(m,0,null);m=$CLJS.H(m,1,null);m=$CLJS.H(m,0,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[u,new $CLJS.Q(null,2,5,$CLJS.R,[m,d],null)],null),k($CLJS.Kc(l)))}return null}},null,null)}(ty.h(a))}())),$CLJS.ab(function(e,f){return $CLJS.P.j($CLJS.S,e,f)},$CLJS.N,$CLJS.bf(function(e){return $CLJS.B($CLJS.bd(e,1))},$CLJS.Kv($CLJS.Pg(ty.h(a)),b))),c]))};
Az=function(a,b){b=Tv(oz,new $CLJS.Q(null,3,5,$CLJS.R,[a,b,!1],null));a=$CLJS.H(b,0,null);var c=$CLJS.H(b,1,null);b=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);b=rz(b,c);$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);var d=$CLJS.H(b,0,null),e=$CLJS.H(b,1,null);b=$CLJS.H(b,2,null);c=$CLJS.B(d);var f=$CLJS.J.g(yz,c.toUpperCase()),k=$CLJS.n(f)?xz(f,$CLJS.Ze.g(pz,a),b,e):null;$CLJS.Sa(c)&&Cx("Format string ended in the middle of a directive",e);$CLJS.Sa(f)&&Cx(['Directive "',$CLJS.p.h(c),'" is undefined'].join(""),
e);return new $CLJS.Q(null,2,5,$CLJS.R,[new Jx(function(){var l=zz.h(f);return l.g?l.g(k,e):l.call(null,k,e)}(),f,k,e,null,null,null),function(){var l=d.substring(1),m=e+1;if($CLJS.F.g("\n",tz.h(f))&&$CLJS.Sa(Kx.h(k)))a:{var t=new $CLJS.Q(null,2,5,$CLJS.R,[" ","\t"],null);t=$CLJS.td(t)?$CLJS.Yg(t):$CLJS.Vg([t]);for(var u=0;;){var v;(v=$CLJS.F.g(u,$CLJS.E(l)))||(v=$CLJS.bd(l,u),v=t.h?t.h(v):t.call(null,v),v=$CLJS.Sa(v));if(v){t=u;break a}u+=1}}else t=0;return new $CLJS.Q(null,2,5,$CLJS.R,[l.substring(t),
m+t],null)}()],null)};Bz=function(a,b){return new Jx(function(c,d){Yx.l($CLJS.G([a]));return d},null,new $CLJS.h(null,1,[$CLJS.Oj,a],null),b,null,null,null)};Fz=function(a,b){var c=Cz(Dz.h(Ez.h(a)),$CLJS.Ju.h(a),b);b=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[new Jx(vy.h(a),Ez.h(a),$CLJS.Tk.l($CLJS.G([ty.h(a),Vv(b,$CLJS.Ju.h(a))])),$CLJS.Ju.h(a),null,null,null),c],null)};
Iz=function(a,b,c){return Tv(function(d){if($CLJS.sd(d))return Cx("No closing bracket found.",b);var e=$CLJS.B(d);d=$CLJS.C(d);if($CLJS.n($CLJS.Gz.h(Dz.h(Ez.h(e)))))e=Fz(e,d);else if($CLJS.F.g($CLJS.Gz.h(a),tz.h(Ez.h(e))))e=new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,4,5,$CLJS.R,[Hz,ty.h(e),null,d],null)],null);else{var f=$CLJS.Lu.h(Dz.h(Ez.h(e)));f=$CLJS.n(f)?Kx.h(ty.h(e)):f;e=$CLJS.n(f)?new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Iu,null,ty.h(e),d],null)],null):
$CLJS.n($CLJS.Lu.h(Dz.h(Ez.h(e))))?new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Lu,null,null,d],null)],null):new $CLJS.Q(null,2,5,$CLJS.R,[e,d],null)}return e},c)};
Cz=function(a,b,c){return $CLJS.hd(Tv(function(d){var e=$CLJS.H(d,0,null),f=$CLJS.H(d,1,null);d=$CLJS.H(d,2,null);var k=Iz(a,b,d);d=$CLJS.H(k,0,null);var l=$CLJS.H(k,1,null);k=$CLJS.H(l,0,null);var m=$CLJS.H(l,1,null),t=$CLJS.H(l,2,null);l=$CLJS.H(l,3,null);return $CLJS.F.g(k,Hz)?new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Jz.l($CLJS.Ye,$CLJS.G([e,$CLJS.Ce([$CLJS.n(f)?$CLJS.Iu:$CLJS.Ny,new $CLJS.Q(null,1,5,$CLJS.R,[d],null),Sy,m])])),l],null)],null):$CLJS.F.g(k,$CLJS.Iu)?
$CLJS.n($CLJS.Iu.h(e))?Cx('Two else clauses ("~:;") inside bracket construction.',b):$CLJS.Sa($CLJS.Iu.h(a))?Cx('An else clause ("~:;") is in a bracket type that doesn\'t support it.',b):$CLJS.F.g(Kz,$CLJS.Iu.h(a))&&$CLJS.z($CLJS.Ny.h(e))?Cx('The else clause ("~:;") is only allowed in the first position for this directive.',b):$CLJS.F.g(Kz,$CLJS.Iu.h(a))?new $CLJS.Q(null,2,5,$CLJS.R,[!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Jz.l($CLJS.Ye,$CLJS.G([e,new $CLJS.h(null,2,[$CLJS.Iu,new $CLJS.Q(null,1,5,
$CLJS.R,[d],null),$y,t],null)])),!1,l],null)],null):new $CLJS.Q(null,2,5,$CLJS.R,[!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Jz.l($CLJS.Ye,$CLJS.G([e,new $CLJS.h(null,1,[$CLJS.Ny,new $CLJS.Q(null,1,5,$CLJS.R,[d],null)],null)])),!0,l],null)],null):$CLJS.F.g(k,$CLJS.Lu)?$CLJS.n(f)?Cx('A plain clause (with "~;") follows an else clause ("~:;") inside bracket construction.',b):$CLJS.Sa(Lz.h(a))?Cx('A separator ("~;") is in a bracket type that doesn\'t support it.',b):new $CLJS.Q(null,2,5,$CLJS.R,[!0,new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.Jz.l($CLJS.Ye,$CLJS.G([e,new $CLJS.h(null,1,[$CLJS.Ny,new $CLJS.Q(null,1,5,$CLJS.R,[d],null)],null)])),!1,l],null)],null):null},new $CLJS.Q(null,3,5,$CLJS.R,[new $CLJS.h(null,1,[$CLJS.Ny,$CLJS.tf],null),!1,c],null)))};Mz=function(a){return $CLJS.B(Tv(function(b){var c=$CLJS.B(b);b=$CLJS.C(b);var d=Dz.h(Ez.h(c));return $CLJS.n($CLJS.Gz.h(d))?Fz(c,b):new $CLJS.Q(null,2,5,$CLJS.R,[c,b],null)},a))};
wx=function(a){var b=Bx;Bx=a;try{return Mz($CLJS.B(Tv(function(c){var d=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);if($CLJS.sd(d))return new $CLJS.Q(null,2,5,$CLJS.R,[null,d],null);var e=d.indexOf("~");return 0>e?new $CLJS.Q(null,2,5,$CLJS.R,[Bz(d,c),new $CLJS.Q(null,2,5,$CLJS.R,["",c+d.length],null)],null):0===e?Az(d.substring(1),c+1):new $CLJS.Q(null,2,5,$CLJS.R,[Bz(d.substring(0,e),c),new $CLJS.Q(null,2,5,$CLJS.R,[d.substring(e),e+c],null)],null)},new $CLJS.Q(null,2,5,$CLJS.R,[a,0],null))))}finally{Bx=
b}};zx=function(a,b){Sv(function(c,d){if(sy(d))return new $CLJS.Q(null,2,5,$CLJS.R,[null,d],null);d=Px(ty.h(c),d);var e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);var f=Uv(e);e=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);e=$CLJS.S.j(e,uy,d);return new $CLJS.Q(null,2,5,$CLJS.R,[null,$CLJS.P.g(vy.h(c),new $CLJS.Q(null,3,5,$CLJS.R,[e,d,f],null))],null)},b,a);return null};
Oz=function(a){var b=$CLJS.B(a);b=Nz.h?Nz.h(b):Nz.call(null,b);return $CLJS.n($CLJS.n(b)?$CLJS.F.g(2,$CLJS.E(a)):b)?($CLJS.$b($CLJS.Pv,b),$CLJS.jx($CLJS.hd(a)),!0):null};
Pz=function(a){if($CLJS.n(mx()))$CLJS.$b($CLJS.Pv,"#");else{var b=lx,c=gx;lx+=1;gx=0;try{ax("[","]");for(var d=0,e=$CLJS.z(a);;){if($CLJS.Sa($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.jx($CLJS.B(e)),$CLJS.C(e))){$CLJS.$b($CLJS.Pv," ");rx(qx);a=d+1;var f=$CLJS.C(e);d=a;e=f;continue}}else $CLJS.$b($CLJS.Pv,"...");break}ex()}finally{gx=c,lx=b}}return null};
Sz=function(a){var b=$CLJS.xd(a)?null:function(){var m=new $CLJS.Fc(function(){return $CLJS.zh},Qz,$CLJS.Ag([$CLJS.ik,$CLJS.Li,$CLJS.T,$CLJS.dk,$CLJS.ni,$CLJS.Ki,$CLJS.dj,$CLJS.kk,$CLJS.mi,$CLJS.V,$CLJS.ck],[!0,$CLJS.ei,Rz,"cljs/core.cljs",15,1,10543,10543,$CLJS.W(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Vi],null)),"Returns [lifted-ns lifted-map] or nil if m can't be lifted.",$CLJS.n($CLJS.zh)?$CLJS.zh.H:null]));return m.h?m.h(a):m.call(null,a)}(),c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);var d=$CLJS.n(b)?
b:a,e=$CLJS.n(c)?["#:",$CLJS.p.h(c),"{"].join(""):"{";if($CLJS.n(mx()))$CLJS.$b($CLJS.Pv,"#");else{c=lx;b=gx;lx+=1;gx=0;try{ax(e,"}");e=0;for(var f=$CLJS.z(d);;){if($CLJS.Sa($CLJS.Ma)||e<$CLJS.Ma){if(f){if($CLJS.n(mx()))$CLJS.$b($CLJS.Pv,"#");else{d=lx;var k=gx;lx+=1;gx=0;try{ax(null,null),$CLJS.jx($CLJS.B($CLJS.B(f))),$CLJS.$b($CLJS.Pv," "),rx(qx),gx=0,$CLJS.jx($CLJS.B($CLJS.C($CLJS.B(f)))),ex()}finally{gx=k,lx=d}}if($CLJS.C(f)){$CLJS.$b($CLJS.Pv,", ");rx(qx);d=e+1;var l=$CLJS.C(f);e=d;f=l;continue}}}else $CLJS.$b($CLJS.Pv,
"...");break}ex()}finally{gx=b,lx=c}}return null};Tz=function(a){return $CLJS.$b($CLJS.Pv,$CLJS.Eh.l($CLJS.G([a])))};Wz=function(a){return a instanceof $CLJS.If?Uz:(null!=a?a.C&32768||$CLJS.r===a.Wf||(a.C?0:$CLJS.Wa($CLJS.Dv,a)):$CLJS.Wa($CLJS.Dv,a))?Vz:a instanceof $CLJS.w?$CLJS.zi:$CLJS.Cd(a)?$CLJS.sj:$CLJS.wd(a)?$CLJS.Wj:$CLJS.yd(a)?$CLJS.zj:$CLJS.ud(a)?$CLJS.Pi:null==a?null:$CLJS.Wh};
Xz=function(a){return $CLJS.yd(a)?new $CLJS.Q(null,2,5,$CLJS.R,["[","]"],null):new $CLJS.Q(null,2,5,$CLJS.R,["(",")"],null)};
aaa=function(a){if($CLJS.vd(a)){var b=Xz(a),c=$CLJS.H(b,0,null),d=$CLJS.H(b,1,null),e=$CLJS.z(a),f=$CLJS.B(e),k=$CLJS.C(e);if($CLJS.n(mx()))$CLJS.$b($CLJS.Pv,"#");else{var l=lx,m=gx,t=lx+1;lx=t;gx=0;try{ax(c,d);(function(){var mb=Yz("~w~:i");return function(){function Qb(kc){var Tb=null;if(0<arguments.length){Tb=0;for(var rc=Array(arguments.length-0);Tb<rc.length;)rc[Tb]=arguments[Tb+0],++Tb;Tb=new $CLJS.y(rc,0,null)}return Ab.call(this,Tb)}function Ab(kc){kc=xx(kc);return zx(mb,kc)}Qb.A=0;Qb.B=function(kc){kc=
$CLJS.z(kc);return Ab(kc)};Qb.l=Ab;return Qb}()})()(f);for(var u=k;;)if($CLJS.z(u)){(function(){var mb=Yz(" ");return function(Qb,Ab,kc){return function(){function Tb(Vb){var Rb=null;if(0<arguments.length){Rb=0;for(var Vd=Array(arguments.length-0);Rb<Vd.length;)Vd[Rb]=arguments[Rb+0],++Rb;Rb=new $CLJS.y(Vd,0,null)}return rc.call(this,Rb)}function rc(Vb){Vb=xx(Vb);return zx(kc,Vb)}Tb.A=0;Tb.B=function(Vb){Vb=$CLJS.z(Vb);return rc(Vb)};Tb.l=rc;return Tb}()}(u," ",mb,l,m,t,0,b,c,d,a,e,f,k,f,k)})()();
var v=$CLJS.B(u);if($CLJS.vd(v)){var x=Xz(v),A=$CLJS.H(x,0,null),D=$CLJS.H(x,1,null);if($CLJS.n(mx()))$CLJS.$b($CLJS.Pv,"#");else{var I=lx,K=gx,U=lx+1;lx=U;gx=0;try{ax(A,D);if($CLJS.F.g($CLJS.E(v),3)&&$CLJS.hd(v)instanceof $CLJS.M){var Z=v,da=$CLJS.H(Z,0,null),xa=$CLJS.H(Z,1,null),Ha=$CLJS.H(Z,2,null);(function(){var mb=Yz("~w ~w ");return function(Qb,Ab,kc){return function(){function Tb(Vb){var Rb=null;if(0<arguments.length){Rb=0;for(var Vd=Array(arguments.length-0);Rb<Vd.length;)Vd[Rb]=arguments[Rb+
0],++Rb;Rb=new $CLJS.y(Vd,0,null)}return rc.call(this,Rb)}function rc(Vb){Vb=xx(Vb);return zx(kc,Vb)}Tb.A=0;Tb.B=function(Vb){Vb=$CLJS.z(Vb);return rc(Vb)};Tb.l=rc;return Tb}()}(u,"~w ~w ",mb,Z,da,xa,Ha,I,K,U,0,x,A,D,v,l,m,t,0,b,c,d,a,e,f,k,f,k)})()(da,xa);$CLJS.vd(Ha)?function(){var mb=$CLJS.yd(Ha)?"~\x3c[~;~@{~w~^ ~:_~}~;]~:\x3e":"~\x3c(~;~@{~w~^ ~:_~}~;)~:\x3e",Qb="string"===typeof mb?Yz(mb):mb;return function(Ab,kc,Tb){return function(){function rc(Rb){var Vd=null;if(0<arguments.length){Vd=0;
for(var Bw=Array(arguments.length-0);Vd<Bw.length;)Bw[Vd]=arguments[Vd+0],++Vd;Vd=new $CLJS.y(Bw,0,null)}return Vb.call(this,Vd)}function Vb(Rb){Rb=xx(Rb);return zx(Tb,Rb)}rc.A=0;rc.B=function(Rb){Rb=$CLJS.z(Rb);return Vb(Rb)};rc.l=Vb;return rc}()}(u,mb,Qb,Z,da,xa,Ha,I,K,U,0,x,A,D,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()(Ha):$CLJS.jx(Ha)}else $CLJS.P.g(function(){var mb=Yz("~w ~:i~@{~w~^ ~:_~}");return function(Qb,Ab,kc){return function(){function Tb(Vb){var Rb=null;if(0<arguments.length){Rb=0;for(var Vd=
Array(arguments.length-0);Rb<Vd.length;)Vd[Rb]=arguments[Rb+0],++Rb;Rb=new $CLJS.y(Vd,0,null)}return rc.call(this,Rb)}function rc(Vb){Vb=xx(Vb);return zx(kc,Vb)}Tb.A=0;Tb.B=function(Vb){Vb=$CLJS.z(Vb);return rc(Vb)};Tb.l=rc;return Tb}()}(u,"~w ~:i~@{~w~^ ~:_~}",mb,I,K,U,0,x,A,D,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}(),v);ex()}finally{gx=K,lx=I}}$CLJS.C(u)&&function(){var mb=Yz("~_");return function(Qb,Ab,kc){return function(){function Tb(Vb){var Rb=null;if(0<arguments.length){Rb=0;for(var Vd=Array(arguments.length-
0);Rb<Vd.length;)Vd[Rb]=arguments[Rb+0],++Rb;Rb=new $CLJS.y(Vd,0,null)}return rc.call(this,Rb)}function rc(Vb){Vb=xx(Vb);return zx(kc,Vb)}Tb.A=0;Tb.B=function(Vb){Vb=$CLJS.z(Vb);return rc(Vb)};Tb.l=rc;return Tb}()}(u,"~_",mb,x,A,D,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()()}else $CLJS.jx(v),$CLJS.C(u)&&function(){var mb=Yz("~:_");return function(Qb,Ab,kc){return function(){function Tb(Vb){var Rb=null;if(0<arguments.length){Rb=0;for(var Vd=Array(arguments.length-0);Rb<Vd.length;)Vd[Rb]=arguments[Rb+0],++Rb;
Rb=new $CLJS.y(Vd,0,null)}return rc.call(this,Rb)}function rc(Vb){Vb=xx(Vb);return zx(kc,Vb)}Tb.A=0;Tb.B=function(Vb){Vb=$CLJS.z(Vb);return rc(Vb)};Tb.l=rc;return Tb}()}(u,"~:_",mb,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()();u=$CLJS.C(u)}else break;ex()}finally{gx=m,lx=l}}}else $CLJS.jx(a)};
baa=function(a,b){$CLJS.z(a)&&($CLJS.n(b)?function(){var c=Yz(" ~_");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.y(l,0,null)}return e.call(this,k)}function e(f){f=xx(f);return zx(c,f)}d.A=0;d.B=function(f){f=$CLJS.z(f);return e(f)};d.l=e;return d}()}()():function(){var c=Yz(" ~@_");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<
l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.y(l,0,null)}return e.call(this,k)}function e(f){f=xx(f);return zx(c,f)}d.A=0;d.B=function(f){f=$CLJS.z(f);return e(f)};d.l=e;return d}()}()(),function(){var c=Yz("~{~w~^ ~_~}");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.y(l,0,null)}return e.call(this,k)}function e(f){f=xx(f);return zx(c,f)}d.A=0;d.B=function(f){f=$CLJS.z(f);return e(f)};d.l=e;
return d}()}()(a))};caa=function(a){$CLJS.z(a)&&function(){var b=Yz(" ~_~{~w~^ ~_~}");return function(){function c(e){var f=null;if(0<arguments.length){f=0;for(var k=Array(arguments.length-0);f<k.length;)k[f]=arguments[f+0],++f;f=new $CLJS.y(k,0,null)}return d.call(this,f)}function d(e){e=xx(e);return zx(b,e)}c.A=0;c.B=function(e){e=$CLJS.z(e);return d(e)};c.l=d;return c}()}()(a)};
$z=function(a){if($CLJS.C(a)){var b=$CLJS.z(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);var d="string"===typeof $CLJS.B(c)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B(c),$CLJS.C(c)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,c],null);c=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);var e=$CLJS.wd($CLJS.B(d))?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B(d),$CLJS.C(d)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,d],null);d=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);if($CLJS.n(mx()))$CLJS.$b($CLJS.Pv,"#");else{var f=
lx,k=gx;lx+=1;gx=0;try{ax("(",")"),function(){var l=Yz("~w ~1I~@_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.y(x,0,null)}return t.call(this,v)}function t(u){u=xx(u);return zx(l,u)}m.A=0;m.B=function(u){u=$CLJS.z(u);return t(u)};m.l=t;return m}()}()(a,b),$CLJS.n(c)&&function(){var l=Yz(" ~_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-
0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.y(x,0,null)}return t.call(this,v)}function t(u){u=xx(u);return zx(l,u)}m.A=0;m.B=function(u){u=$CLJS.z(u);return t(u)};m.l=t;return m}()}()(c),$CLJS.n(d)&&function(){var l=Yz(" ~_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.y(x,0,null)}return t.call(this,v)}function t(u){u=xx(u);return zx(l,u)}m.A=0;m.B=function(u){u=$CLJS.z(u);return t(u)};
m.l=t;return m}()}()(d),$CLJS.yd($CLJS.B(e))?baa(e,$CLJS.n(c)?c:d):caa(e),ex()}finally{gx=k,lx=f}}return null}return Zz(a)};
daa=function(a){if($CLJS.n(mx()))$CLJS.$b($CLJS.Pv,"#");else{var b=lx,c=gx;lx+=1;gx=0;try{ax("[","]");for(var d=0;;){if($CLJS.Sa($CLJS.Ma)||d<$CLJS.Ma){if($CLJS.z(a)){if($CLJS.n(mx()))$CLJS.$b($CLJS.Pv,"#");else{var e=lx,f=gx;lx+=1;gx=0;try{ax(null,null),$CLJS.jx($CLJS.B(a)),$CLJS.C(a)&&($CLJS.$b($CLJS.Pv," "),rx(ox),$CLJS.jx($CLJS.hd(a))),ex()}finally{gx=f,lx=e}}if($CLJS.C($CLJS.Kc(a))){$CLJS.$b($CLJS.Pv," ");rx(qx);e=d+1;var k=$CLJS.C($CLJS.Kc(a));d=e;a=k;continue}}}else $CLJS.$b($CLJS.Pv,"...");
break}ex()}finally{gx=c,lx=b}}};
aA=function(a){var b=$CLJS.B(a);if($CLJS.n(mx()))$CLJS.$b($CLJS.Pv,"#");else{var c=lx,d=gx;lx+=1;gx=0;try{ax("(",")"),$CLJS.C(a)&&$CLJS.yd($CLJS.hd(a))?(function(){var e=Yz("~w ~1I~@_");return function(){function f(l){var m=null;if(0<arguments.length){m=0;for(var t=Array(arguments.length-0);m<t.length;)t[m]=arguments[m+0],++m;m=new $CLJS.y(t,0,null)}return k.call(this,m)}function k(l){l=xx(l);return zx(e,l)}f.A=0;f.B=function(l){l=$CLJS.z(l);return k(l)};f.l=k;return f}()}()(b),daa($CLJS.hd(a)),function(){var e=
Yz(" ~_~{~w~^ ~_~}");return function(){function f(l){var m=null;if(0<arguments.length){m=0;for(var t=Array(arguments.length-0);m<t.length;)t[m]=arguments[m+0],++m;m=new $CLJS.y(t,0,null)}return k.call(this,m)}function k(l){l=xx(l);return zx(e,l)}f.A=0;f.B=function(l){l=$CLJS.z(l);return k(l)};f.l=k;return f}()}()($CLJS.C($CLJS.Kc(a)))):Zz(a),ex()}finally{gx=d,lx=c}}return null};
Zz=function(a){if($CLJS.n(mx()))$CLJS.$b($CLJS.Pv,"#");else{var b=lx,c=gx;lx+=1;gx=0;try{ax("(",")");vx(sx,1);for(var d=0,e=$CLJS.z(a);;){if($CLJS.Sa($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.jx($CLJS.B(e)),$CLJS.C(e))){$CLJS.$b($CLJS.Pv," ");rx(qx);a=d+1;var f=$CLJS.C(e);d=a;e=f;continue}}else $CLJS.$b($CLJS.Pv,"...");break}ex()}finally{gx=c,lx=b}}return null};$CLJS.le.prototype.Jc=$CLJS.ua(2,function(){return $CLJS.Sa(this.Rb)});$CLJS.Ue.prototype.Jc=$CLJS.ua(1,function(){return!1});
$CLJS.Bh.prototype.Jc=$CLJS.ua(0,function(){return $CLJS.Sa(this.f)});$CLJS.Pv=null;bA=function bA(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return bA.l(0<c.length?new $CLJS.y(c.slice(0),0,null):null)};bA.l=function(a){a=$CLJS.z(a);for(var b=$CLJS.cc($CLJS.Yf);;)if(a){var c=$CLJS.C(a);if(null==c)throw Error(["No value supplied for key: ",$CLJS.p.h($CLJS.B(a))].join(""));var d=$CLJS.C(c);b=$CLJS.Zf.j(b,$CLJS.B(a),$CLJS.B(c));a=d}else return $CLJS.ec(b)};
bA.A=0;bA.B=function(a){return this.l($CLJS.z(a))};$CLJS.Jz=function Jz(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Jz.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
$CLJS.Jz.l=function(a,b){if($CLJS.n($CLJS.Je($CLJS.Rd,b))){var c=function(d,e){var f=$CLJS.yb(e),k=$CLJS.zb(e);if($CLJS.Gd(d,f)){e=$CLJS.S.j;var l=$CLJS.J.g(d,f);k=a.g?a.g(l,k):a.call(null,l,k);d=e.call($CLJS.S,d,f,k)}else d=$CLJS.S.j(d,f,k);return d};return $CLJS.Od(function(d,e){return $CLJS.ab(c,$CLJS.n(d)?d:$CLJS.N,$CLJS.z(e))},b)}return null};$CLJS.Jz.A=1;$CLJS.Jz.B=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.l(b,a)};
$CLJS.dy=function dy(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return dy.l(0<c.length?new $CLJS.y(c.slice(0),0,null):null)};$CLJS.dy.l=function(a){return $CLJS.wh(a,$CLJS.S.j($CLJS.Na(),$CLJS.Ga,!1))};$CLJS.dy.A=0;$CLJS.dy.B=function(a){return this.l($CLJS.z(a))};mw=new $CLJS.M(null,"trailing-white-space","trailing-white-space",1496006996);cA=new $CLJS.M(null,"relative-to","relative-to",-470100051);Dw=new $CLJS.M(null,"intra-block-nl","intra-block-nl",1808826875);
Hy=new $CLJS.M(null,"exponentchar","exponentchar",1986664222);eaa=new $CLJS.w(null,"when-let","when-let",-1383043480,null);ox=new $CLJS.M(null,"miser","miser",-556060186);$CLJS.tx=new $CLJS.M(null,"current","current",-1088038603);faa=new $CLJS.w(null,"-\x3e","-\x3e",-2139605430,null);gaa=new $CLJS.w(null,"..","..",-300507420,null);pw=new $CLJS.M(null,"end-pos","end-pos",-1643883926);Aw=new $CLJS.M(null,"per-line-prefix","per-line-prefix",846941813);Uz=new $CLJS.M(null,"queue","queue",1455835879);
haa=new $CLJS.w(null,"if-let","if-let",1803593690,null);cx=new $CLJS.M(null,"end","end",-268185958);Nx=new $CLJS.M(null,"remaining-arg-count","remaining-arg-count",-1216589335);Kw=new $CLJS.M(null,"logical-blocks","logical-blocks",-1466339776);iaa=new $CLJS.w(null,"defmacro","defmacro",2054157304,null);jaa=new $CLJS.w(null,"when-first","when-first",821699168,null);$w=new $CLJS.M(null,"start-block-t","start-block-t",-373430594);kaa=new $CLJS.w(null,"binding","binding",-2114503176,null);
Dz=new $CLJS.M(null,"bracket-info","bracket-info",-1600092774);Ow=new $CLJS.M(null,"writing","writing",-1486865108);Mx=new $CLJS.M(null,"parameter-from-args","parameter-from-args",-758446196);Yw=new $CLJS.M(null,"logical-block-callback","logical-block-callback",1612691194);My=new $CLJS.M(null,"selector","selector",762528866);laa=new $CLJS.w(null,"struct","struct",325972931,null);Cw=new $CLJS.M(null,"indent","indent",-148200125);maa=new $CLJS.w(null,"loop","loop",1244978678,null);
bz=new $CLJS.M(null,"max-columns","max-columns",1742323262);uz=new $CLJS.M(null,"both","both",-393648840);dA=new $CLJS.M(null,"colnum","colnum",2023796854);naa=new $CLJS.w(null,"doseq","doseq",221164135,null);$CLJS.eA=new $CLJS.M(null,"length","length",588987862);ny=new $CLJS.M(null,"char-format","char-format",-1016499218);fA=new $CLJS.M(null,"prefix","prefix",-265908465);ry=new $CLJS.M(null,"colon-up-arrow","colon-up-arrow",244853007);bx=new $CLJS.M(null,"suffix","suffix",367373057);
Lx=new $CLJS.M(null,"at","at",1476951349);lw=new $CLJS.M(null,"nl-t","nl-t",-1608382114);tz=new $CLJS.M(null,"directive","directive",793559132);Ww=new $CLJS.M(null,"buffer-level","buffer-level",928864731);nx=new $CLJS.M(null,"mandatory","mandatory",542802336);Uw=new $CLJS.M(null,"pretty-writer","pretty-writer",-1222834267);sw=new $CLJS.M(null,"done-nl","done-nl",-381024340);oaa=new $CLJS.w(null,"condp","condp",1054325175,null);Ex=new $CLJS.M(null,"seq","seq",-1817803783);
paa=new $CLJS.w(null,"defn","defn",-126010802,null);Wx=new $CLJS.M(null,"colinc","colinc",-584873385);qy=new $CLJS.M(null,"up-arrow","up-arrow",1705310333);Hz=new $CLJS.M(null,"right-bracket","right-bracket",951856080);qaa=new $CLJS.M(null,"radix","radix",857016463);Kz=new $CLJS.M(null,"first","first",-644103046);Vw=new $CLJS.M(null,"sections","sections",-886710106);$CLJS.Zw=new $CLJS.M(null,"start","start",-355208981);raa=new $CLJS.w("cljs.core","unquote","cljs.core/unquote",1013085760,null);
saa=new $CLJS.w(null,"defn-","defn-",1097765044,null);taa=new $CLJS.w("cljs.core","deref","cljs.core/deref",1901963335,null);sx=new $CLJS.M(null,"block","block",664686210);uaa=new $CLJS.w(null,"when","when",1064114221,null);$y=new $CLJS.M(null,"else-params","else-params",-832171646);$CLJS.gA=new $CLJS.M(null,"count","count",2139924085);$CLJS.Gz=new $CLJS.M(null,"right","right",-452581833);qx=new $CLJS.M(null,"linear","linear",872268697);vaa=new $CLJS.w(null,"when-not","when-not",-1223136340,null);
Xx=new $CLJS.M(null,"padchar","padchar",2018584530);Vx=new $CLJS.M(null,"mincol","mincol",1230695445);waa=new $CLJS.M(null,"not-delivered","not-delivered",1599158697);uw=new $CLJS.M(null,"miser-width","miser-width",-1310049437);xaa=new $CLJS.w(null,"with-local-vars","with-local-vars",837642072,null);Rz=new $CLJS.w(null,"lift-ns","lift-ns",602311926,null);Lz=new $CLJS.M(null,"allows-separator","allows-separator",-818967742);Lw=new $CLJS.M(null,"buffering","buffering",-876713613);
hA=new $CLJS.M(null,"arg1","arg1",951899358);uy=new $CLJS.M(null,"base-args","base-args",-1268706822);iA=new $CLJS.M(null,"arg3","arg3",-1486822496);jA=new $CLJS.M(null,"arg2","arg2",1729550917);by=new $CLJS.M(null,"commainterval","commainterval",-1980061083);yaa=new $CLJS.M(null,"right-margin","right-margin",-810413306);Nw=new $CLJS.M(null,"buffer-blob","buffer-blob",-1830112173);zaa=new $CLJS.w(null,"with-open","with-open",172119667,null);
Qz=new $CLJS.w("cljs.core","lift-ns","cljs.core/lift-ns",463499081,null);Aaa=new $CLJS.M(null,"lines","lines",-700165781);ux=new $CLJS.M(null,"indent-t","indent-t",528318969);Sy=new $CLJS.M(null,"right-params","right-params",-1790676237);Kx=new $CLJS.M(null,"colon","colon",-965200945);Baa=new $CLJS.w(null,"if-not","if-not",-265415609,null);vy=new $CLJS.M(null,"func","func",-238706040);$CLJS.kA=new $CLJS.M(null,"last","last",1105735132);Vz=new $CLJS.M(null,"deref","deref",-145586795);
Caa=new $CLJS.w(null,"dotimes","dotimes",-818708397,null);Ry=new $CLJS.M(null,"max-iterations","max-iterations",2021275563);Daa=new $CLJS.w(null,"cond","cond",1606708055,null);Ux=new $CLJS.M(null,"minpad","minpad",323570901);xw=new $CLJS.M(null,"logical-block","logical-block",-581022564);Eaa=new $CLJS.w(null,"struct-map","struct-map",-1387540878,null);dx=new $CLJS.M(null,"end-block-t","end-block-t",1544648735);lA=new $CLJS.M(null,"stream","stream",1534941648);
ty=new $CLJS.M(null,"params","params",710516235);Faa=new $CLJS.M(null,"circle","circle",1903212362);qw=new $CLJS.M(null,"start-pos","start-pos",668789086);sz=new $CLJS.M(null,"flags","flags",1775418075);px=new $CLJS.M(null,"fill","fill",883462889);Tw=new $CLJS.M(null,"buffer-block","buffer-block",-10937307);zz=new $CLJS.M(null,"generator-fn","generator-fn",811851656);vw=new $CLJS.M(null,"start-col","start-col",668080143);By=new $CLJS.M(null,"w","w",354169001);$CLJS.Ky=new $CLJS.M(null,"n","n",562130025);
Ez=new $CLJS.M(null,"def","def",-1043430536);$CLJS.Dy=new $CLJS.M(null,"k","k",-2146297393);Gy=new $CLJS.M(null,"e","e",1381269198);Cy=new $CLJS.M(null,"d","d",1972142424);Ey=new $CLJS.M(null,"overflowchar","overflowchar",-1620088106);az=new $CLJS.M(null,"min-remaining","min-remaining",962687677);cy=new $CLJS.M(null,"commachar","commachar",652859327);mA=new $CLJS.M(null,"section","section",-300141526);nA=new $CLJS.M(null,"pretty","pretty",-1916372486);Gaa=new $CLJS.w(null,"let","let",358118826,null);
dw=new $CLJS.M(null,"parent","parent",-878878779);$CLJS.Ny=new $CLJS.M(null,"clauses","clauses",1454841241);Haa=new $CLJS.w(null,"defonce","defonce",-1681484013,null);$v=new $CLJS.M(null,"base","base",185279322);kw=new $CLJS.M(null,"type-tag","type-tag",-1873863267);Iaa=new $CLJS.w(null,"locking","locking",1542862874,null);Mw=new $CLJS.M(null,"pos","pos",-864607220);Jaa=new $CLJS.M(null,"suppress-namespaces","suppress-namespaces",2130686956);$CLJS.Zv=new $CLJS.M(null,"cur","cur",1153190599);var Yx=function Yx(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Yx.l(0<c.length?new $CLJS.y(c.slice(0),0,null):null)},ix,Sw,pA,qA,rA,Rx,Qx,lx,gx,sA;Yx.l=function(a){return $CLJS.$b($CLJS.Pv,$CLJS.P.g($CLJS.dy,a))};Yx.A=0;Yx.B=function(a){return this.l($CLJS.z(a))};var Ov=function Ov(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Ov.l(0<c.length?new $CLJS.y(c.slice(0),0,null):null)};
Ov.l=function(a){return $CLJS.$b($CLJS.Pv,$CLJS.P.g($CLJS.Eh,a))};Ov.A=0;Ov.B=function(a){return this.l($CLJS.z(a))};$CLJS.g=bw.prototype;$CLJS.g.P=function(a,b){return new bw(this.Ba,this.Jf,this.Xc,b)};$CLJS.g.O=function(){return this.Xh};$CLJS.g.Qb=function(){return this.Xc};$CLJS.g.pc=function(){return Ev(this.Ba)};
$CLJS.g.Kc=function(a,b){a=$CLJS.Xa(b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(String,a):$CLJS.F.call(null,String,a)))return a=b.lastIndexOf("\n"),0>a?Yv(this,$CLJS.Zv,$CLJS.Xv(this,$CLJS.Zv)+$CLJS.E(b)):(Yv(this,$CLJS.Zv,$CLJS.E(b)-a-1),Yv(this,$CLJS.dj,$CLJS.Xv(this,$CLJS.dj)+$CLJS.E($CLJS.bf(function(c){return $CLJS.F.g(c,"\n")},b)))),$CLJS.$b($CLJS.Xv(this,$v),b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(Number,a):$CLJS.F.call(null,Number,a)))return aw(this,b);throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));
};$CLJS.g=cw.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "parent":return this.parent;case "section":return this.Cb;case "start-col":return this.tb;case "indent":return this.sb;case "done-nl":return this.vb;case "intra-block-nl":return this.wb;case "prefix":return this.prefix;case "per-line-prefix":return this.Ab;case "suffix":return this.Db;case "logical-block-callback":return this.xb;default:return $CLJS.J.j(this.G,b,c)}};
$CLJS.g.Fb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.kh(b,function(d){return $CLJS.kh(b,$CLJS.qh,""," ","",c,d)},"#cljs.pprint.logical-block{",", ","}",c,$CLJS.Ye.g(new $CLJS.Q(null,10,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[dw,this.parent],null),new $CLJS.Q(null,2,5,$CLJS.R,[mA,this.Cb],null),new $CLJS.Q(null,2,5,$CLJS.R,[vw,this.tb],null),new $CLJS.Q(null,2,5,$CLJS.R,[Cw,this.sb],null),new $CLJS.Q(null,2,5,$CLJS.R,[sw,this.vb],null),new $CLJS.Q(null,2,5,$CLJS.R,[Dw,this.wb],null),new $CLJS.Q(null,2,5,$CLJS.R,
[fA,this.prefix],null),new $CLJS.Q(null,2,5,$CLJS.R,[Aw,this.Ab],null),new $CLJS.Q(null,2,5,$CLJS.R,[bx,this.Db],null),new $CLJS.Q(null,2,5,$CLJS.R,[Yw,this.xb],null)],null),this.G))};$CLJS.g.Ca=function(){return new $CLJS.Bu(this,10,new $CLJS.Q(null,10,5,$CLJS.R,[dw,mA,vw,Cw,sw,Dw,fA,Aw,bx,Yw],null),$CLJS.n(this.G)?$CLJS.qc(this.G):$CLJS.Fe())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 10+$CLJS.E(this.G)};
$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1977012399^$CLJS.Pc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.parent,b.parent)&&$CLJS.F.g(this.Cb,b.Cb)&&$CLJS.F.g(this.tb,b.tb)&&$CLJS.F.g(this.sb,b.sb)&&$CLJS.F.g(this.vb,b.vb)&&$CLJS.F.g(this.wb,b.wb)&&$CLJS.F.g(this.prefix,b.prefix)&&$CLJS.F.g(this.Ab,b.Ab)&&$CLJS.F.g(this.Db,b.Db)&&$CLJS.F.g(this.xb,b.xb)&&$CLJS.F.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,10,[bx,null,Cw,null,dw,null,mA,null,sw,null,vw,null,fA,null,Aw,null,Yw,null,Dw,null],null),null),b)?$CLJS.tk.g($CLJS.Jb($CLJS.Wf.g($CLJS.N,this),this.S),b):new cw(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,$CLJS.De($CLJS.tk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "parent":case "section":case "start-col":case "indent":case "done-nl":case "intra-block-nl":case "prefix":case "per-line-prefix":case "suffix":case "logical-block-callback":return!0;default:return $CLJS.Gd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(dw,b):$CLJS.fe.call(null,dw,b))?new cw(c,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(mA,b):$CLJS.fe.call(null,mA,b))?new cw(this.parent,c,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(vw,b):$CLJS.fe.call(null,vw,b))?new cw(this.parent,this.Cb,c,this.sb,this.vb,this.wb,this.prefix,this.Ab,
this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Cw,b):$CLJS.fe.call(null,Cw,b))?new cw(this.parent,this.Cb,this.tb,c,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(sw,b):$CLJS.fe.call(null,sw,b))?new cw(this.parent,this.Cb,this.tb,this.sb,c,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Dw,b):$CLJS.fe.call(null,Dw,b))?new cw(this.parent,this.Cb,this.tb,this.sb,this.vb,c,this.prefix,
this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(fA,b):$CLJS.fe.call(null,fA,b))?new cw(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,c,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Aw,b):$CLJS.fe.call(null,Aw,b))?new cw(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,c,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(bx,b):$CLJS.fe.call(null,bx,b))?new cw(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,
this.prefix,this.Ab,c,this.xb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Yw,b):$CLJS.fe.call(null,Yw,b))?new cw(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,c,this.S,this.G,null):new cw(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,$CLJS.S.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.z($CLJS.Ye.g(new $CLJS.Q(null,10,5,$CLJS.R,[new $CLJS.Pf(dw,this.parent),new $CLJS.Pf(mA,this.Cb),new $CLJS.Pf(vw,this.tb),new $CLJS.Pf(Cw,this.sb),new $CLJS.Pf(sw,this.vb),new $CLJS.Pf(Dw,this.wb),new $CLJS.Pf(fA,this.prefix),new $CLJS.Pf(Aw,this.Ab),new $CLJS.Pf(bx,this.Db),new $CLJS.Pf(Yw,this.xb)],null),this.G))};$CLJS.g.P=function(a,b){return new cw(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,b,this.G,this.J)};
$CLJS.g.ja=function(a,b){return $CLJS.yd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.ab($CLJS.jb,this,b)};$CLJS.g=fw.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "data":return this.data;case "trailing-white-space":return this.$b;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};
$CLJS.g.Fb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.kh(b,function(d){return $CLJS.kh(b,$CLJS.qh,""," ","",c,d)},"#cljs.pprint.buffer-blob{",", ","}",c,$CLJS.Ye.g(new $CLJS.Q(null,5,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[kw,this.ca],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hj,this.data],null),new $CLJS.Q(null,2,5,$CLJS.R,[mw,this.$b],null),new $CLJS.Q(null,2,5,$CLJS.R,[qw,this.ba],null),new $CLJS.Q(null,2,5,$CLJS.R,[pw,this.aa],null)],null),this.G))};
$CLJS.g.Ca=function(){return new $CLJS.Bu(this,5,new $CLJS.Q(null,5,5,$CLJS.R,[kw,$CLJS.hj,mw,qw,pw],null),$CLJS.n(this.G)?$CLJS.qc(this.G):$CLJS.Fe())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 5+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1809113693^$CLJS.Pc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.ca,b.ca)&&$CLJS.F.g(this.data,b.data)&&$CLJS.F.g(this.$b,b.$b)&&$CLJS.F.g(this.ba,b.ba)&&$CLJS.F.g(this.aa,b.aa)&&$CLJS.F.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,5,[pw,null,mw,null,kw,null,qw,null,$CLJS.hj,null],null),null),b)?$CLJS.tk.g($CLJS.Jb($CLJS.Wf.g($CLJS.N,this),this.S),b):new fw(this.ca,this.data,this.$b,this.ba,this.aa,this.S,$CLJS.De($CLJS.tk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "data":case "trailing-white-space":case "start-pos":case "end-pos":return!0;default:return $CLJS.Gd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(kw,b):$CLJS.fe.call(null,kw,b))?new fw(c,this.data,this.$b,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g($CLJS.hj,b):$CLJS.fe.call(null,$CLJS.hj,b))?new fw(this.ca,c,this.$b,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(mw,b):$CLJS.fe.call(null,mw,b))?new fw(this.ca,this.data,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(qw,b):$CLJS.fe.call(null,qw,b))?new fw(this.ca,this.data,
this.$b,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(pw,b):$CLJS.fe.call(null,pw,b))?new fw(this.ca,this.data,this.$b,this.ba,c,this.S,this.G,null):new fw(this.ca,this.data,this.$b,this.ba,this.aa,this.S,$CLJS.S.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.z($CLJS.Ye.g(new $CLJS.Q(null,5,5,$CLJS.R,[new $CLJS.Pf(kw,this.ca),new $CLJS.Pf($CLJS.hj,this.data),new $CLJS.Pf(mw,this.$b),new $CLJS.Pf(qw,this.ba),new $CLJS.Pf(pw,this.aa)],null),this.G))};
$CLJS.g.P=function(a,b){return new fw(this.ca,this.data,this.$b,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.yd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.ab($CLJS.jb,this,b)};$CLJS.g=gw.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "type":return this.type;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.kh(b,function(d){return $CLJS.kh(b,$CLJS.qh,""," ","",c,d)},"#cljs.pprint.nl-t{",", ","}",c,$CLJS.Ye.g(new $CLJS.Q(null,5,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[kw,this.ca],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,this.type],null),new $CLJS.Q(null,2,5,$CLJS.R,[xw,this.ea],null),new $CLJS.Q(null,2,5,$CLJS.R,[qw,this.ba],null),new $CLJS.Q(null,2,5,$CLJS.R,[pw,this.aa],null)],null),this.G))};
$CLJS.g.Ca=function(){return new $CLJS.Bu(this,5,new $CLJS.Q(null,5,5,$CLJS.R,[kw,$CLJS.cj,xw,qw,pw],null),$CLJS.n(this.G)?$CLJS.qc(this.G):$CLJS.Fe())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 5+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1640656800^$CLJS.Pc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.ca,b.ca)&&$CLJS.F.g(this.type,b.type)&&$CLJS.F.g(this.ea,b.ea)&&$CLJS.F.g(this.ba,b.ba)&&$CLJS.F.g(this.aa,b.aa)&&$CLJS.F.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,5,[pw,null,$CLJS.cj,null,xw,null,kw,null,qw,null],null),null),b)?$CLJS.tk.g($CLJS.Jb($CLJS.Wf.g($CLJS.N,this),this.S),b):new gw(this.ca,this.type,this.ea,this.ba,this.aa,this.S,$CLJS.De($CLJS.tk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "type":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Gd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(kw,b):$CLJS.fe.call(null,kw,b))?new gw(c,this.type,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g($CLJS.cj,b):$CLJS.fe.call(null,$CLJS.cj,b))?new gw(this.ca,c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(xw,b):$CLJS.fe.call(null,xw,b))?new gw(this.ca,this.type,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(qw,b):$CLJS.fe.call(null,qw,b))?new gw(this.ca,this.type,
this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(pw,b):$CLJS.fe.call(null,pw,b))?new gw(this.ca,this.type,this.ea,this.ba,c,this.S,this.G,null):new gw(this.ca,this.type,this.ea,this.ba,this.aa,this.S,$CLJS.S.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.z($CLJS.Ye.g(new $CLJS.Q(null,5,5,$CLJS.R,[new $CLJS.Pf(kw,this.ca),new $CLJS.Pf($CLJS.cj,this.type),new $CLJS.Pf(xw,this.ea),new $CLJS.Pf(qw,this.ba),new $CLJS.Pf(pw,this.aa)],null),this.G))};
$CLJS.g.P=function(a,b){return new gw(this.ca,this.type,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.yd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.ab($CLJS.jb,this,b)};$CLJS.g=hw.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.kh(b,function(d){return $CLJS.kh(b,$CLJS.qh,""," ","",c,d)},"#cljs.pprint.start-block-t{",", ","}",c,$CLJS.Ye.g(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[kw,this.ca],null),new $CLJS.Q(null,2,5,$CLJS.R,[xw,this.ea],null),new $CLJS.Q(null,2,5,$CLJS.R,[qw,this.ba],null),new $CLJS.Q(null,2,5,$CLJS.R,[pw,this.aa],null)],null),this.G))};
$CLJS.g.Ca=function(){return new $CLJS.Bu(this,4,new $CLJS.Q(null,4,5,$CLJS.R,[kw,xw,qw,pw],null),$CLJS.n(this.G)?$CLJS.qc(this.G):$CLJS.Fe())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-414877272^$CLJS.Pc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.ca,b.ca)&&$CLJS.F.g(this.ea,b.ea)&&$CLJS.F.g(this.ba,b.ba)&&$CLJS.F.g(this.aa,b.aa)&&$CLJS.F.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,4,[pw,null,xw,null,kw,null,qw,null],null),null),b)?$CLJS.tk.g($CLJS.Jb($CLJS.Wf.g($CLJS.N,this),this.S),b):new hw(this.ca,this.ea,this.ba,this.aa,this.S,$CLJS.De($CLJS.tk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Gd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(kw,b):$CLJS.fe.call(null,kw,b))?new hw(c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(xw,b):$CLJS.fe.call(null,xw,b))?new hw(this.ca,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(qw,b):$CLJS.fe.call(null,qw,b))?new hw(this.ca,this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(pw,b):$CLJS.fe.call(null,pw,b))?new hw(this.ca,this.ea,this.ba,c,this.S,this.G,null):new hw(this.ca,
this.ea,this.ba,this.aa,this.S,$CLJS.S.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.z($CLJS.Ye.g(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Pf(kw,this.ca),new $CLJS.Pf(xw,this.ea),new $CLJS.Pf(qw,this.ba),new $CLJS.Pf(pw,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new hw(this.ca,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.yd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.ab($CLJS.jb,this,b)};$CLJS.g=iw.prototype;
$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.kh(b,function(d){return $CLJS.kh(b,$CLJS.qh,""," ","",c,d)},"#cljs.pprint.end-block-t{",", ","}",c,$CLJS.Ye.g(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[kw,this.ca],null),new $CLJS.Q(null,2,5,$CLJS.R,[xw,this.ea],null),new $CLJS.Q(null,2,5,$CLJS.R,[qw,this.ba],null),new $CLJS.Q(null,2,5,$CLJS.R,[pw,this.aa],null)],null),this.G))};
$CLJS.g.Ca=function(){return new $CLJS.Bu(this,4,new $CLJS.Q(null,4,5,$CLJS.R,[kw,xw,qw,pw],null),$CLJS.n(this.G)?$CLJS.qc(this.G):$CLJS.Fe())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1365867980^$CLJS.Pc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.ca,b.ca)&&$CLJS.F.g(this.ea,b.ea)&&$CLJS.F.g(this.ba,b.ba)&&$CLJS.F.g(this.aa,b.aa)&&$CLJS.F.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,4,[pw,null,xw,null,kw,null,qw,null],null),null),b)?$CLJS.tk.g($CLJS.Jb($CLJS.Wf.g($CLJS.N,this),this.S),b):new iw(this.ca,this.ea,this.ba,this.aa,this.S,$CLJS.De($CLJS.tk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Gd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(kw,b):$CLJS.fe.call(null,kw,b))?new iw(c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(xw,b):$CLJS.fe.call(null,xw,b))?new iw(this.ca,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(qw,b):$CLJS.fe.call(null,qw,b))?new iw(this.ca,this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(pw,b):$CLJS.fe.call(null,pw,b))?new iw(this.ca,this.ea,this.ba,c,this.S,this.G,null):new iw(this.ca,
this.ea,this.ba,this.aa,this.S,$CLJS.S.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.z($CLJS.Ye.g(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Pf(kw,this.ca),new $CLJS.Pf(xw,this.ea),new $CLJS.Pf(qw,this.ba),new $CLJS.Pf(pw,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new iw(this.ca,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.yd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.ab($CLJS.jb,this,b)};$CLJS.g=jw.prototype;
$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "relative-to":return this.Vb;case "offset":return this.offset;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.kh(b,function(d){return $CLJS.kh(b,$CLJS.qh,""," ","",c,d)},"#cljs.pprint.indent-t{",", ","}",c,$CLJS.Ye.g(new $CLJS.Q(null,6,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[kw,this.ca],null),new $CLJS.Q(null,2,5,$CLJS.R,[xw,this.ea],null),new $CLJS.Q(null,2,5,$CLJS.R,[cA,this.Vb],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ju,this.offset],null),new $CLJS.Q(null,2,5,$CLJS.R,[qw,this.ba],null),new $CLJS.Q(null,2,5,$CLJS.R,[pw,this.aa],null)],null),this.G))};
$CLJS.g.Ca=function(){return new $CLJS.Bu(this,6,new $CLJS.Q(null,6,5,$CLJS.R,[kw,xw,cA,$CLJS.Ju,qw,pw],null),$CLJS.n(this.G)?$CLJS.qc(this.G):$CLJS.Fe())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 6+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1602780238^$CLJS.Pc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.ca,b.ca)&&$CLJS.F.g(this.ea,b.ea)&&$CLJS.F.g(this.Vb,b.Vb)&&$CLJS.F.g(this.offset,b.offset)&&$CLJS.F.g(this.ba,b.ba)&&$CLJS.F.g(this.aa,b.aa)&&$CLJS.F.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,6,[$CLJS.Ju,null,pw,null,cA,null,xw,null,kw,null,qw,null],null),null),b)?$CLJS.tk.g($CLJS.Jb($CLJS.Wf.g($CLJS.N,this),this.S),b):new jw(this.ca,this.ea,this.Vb,this.offset,this.ba,this.aa,this.S,$CLJS.De($CLJS.tk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "relative-to":case "offset":case "start-pos":case "end-pos":return!0;default:return $CLJS.Gd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(kw,b):$CLJS.fe.call(null,kw,b))?new jw(c,this.ea,this.Vb,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(xw,b):$CLJS.fe.call(null,xw,b))?new jw(this.ca,c,this.Vb,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(cA,b):$CLJS.fe.call(null,cA,b))?new jw(this.ca,this.ea,c,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g($CLJS.Ju,b):$CLJS.fe.call(null,$CLJS.Ju,
b))?new jw(this.ca,this.ea,this.Vb,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(qw,b):$CLJS.fe.call(null,qw,b))?new jw(this.ca,this.ea,this.Vb,this.offset,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(pw,b):$CLJS.fe.call(null,pw,b))?new jw(this.ca,this.ea,this.Vb,this.offset,this.ba,c,this.S,this.G,null):new jw(this.ca,this.ea,this.Vb,this.offset,this.ba,this.aa,this.S,$CLJS.S.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.z($CLJS.Ye.g(new $CLJS.Q(null,6,5,$CLJS.R,[new $CLJS.Pf(kw,this.ca),new $CLJS.Pf(xw,this.ea),new $CLJS.Pf(cA,this.Vb),new $CLJS.Pf($CLJS.Ju,this.offset),new $CLJS.Pf(qw,this.ba),new $CLJS.Pf(pw,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new jw(this.ca,this.ea,this.Vb,this.offset,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.yd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.ab($CLJS.jb,this,b)};
var nw=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Yi,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("cljs.pprint","write-token"),function(f,k){return kw.h(k)},e,a,b,c,d)}();
nw.m(null,$w,function(a,b){var c=Yw.h($CLJS.q($CLJS.q(a)));$CLJS.n(c)&&(c.h?c.h($CLJS.Zw):c.call(null,$CLJS.Zw));b=xw.h(b);c=fA.h(b);$CLJS.n(c)&&$CLJS.$b($v.h($CLJS.q($CLJS.q(a))),c);a=$CLJS.Xv($v.h($CLJS.q($CLJS.q(a))),$CLJS.Zv);$CLJS.Pe(vw.h(b),a);return $CLJS.Pe(Cw.h(b),a)});nw.m(null,dx,function(a,b){var c=Yw.h($CLJS.q($CLJS.q(a)));$CLJS.n(c)&&(c.h?c.h(cx):c.call(null,cx));b=bx.h(xw.h(b));return $CLJS.n(b)?$CLJS.$b($v.h($CLJS.q($CLJS.q(a))),b):null});
nw.m(null,ux,function(a,b){var c=xw.h(b),d=Cw.h(c),e=$CLJS.Ju.h(b);b=cA.h(b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(sx,b):$CLJS.F.call(null,sx,b)))a=$CLJS.q(vw.h(c));else if($CLJS.n($CLJS.F.g?$CLJS.F.g($CLJS.tx,b):$CLJS.F.call(null,$CLJS.tx,b)))a=$CLJS.Xv($v.h($CLJS.q($CLJS.q(a))),$CLJS.Zv);else throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));return $CLJS.Pe(d,e+a)});nw.m(null,Nw,function(a,b){return $CLJS.$b($v.h($CLJS.q($CLJS.q(a))),$CLJS.hj.h(b))});
nw.m(null,lw,function(a,b){var c=$CLJS.F.g($CLJS.cj.h(b),nx);c||(c=(c=!$CLJS.F.g($CLJS.cj.h(b),px))?$CLJS.q(sw.h(xw.h(b))):c);$CLJS.n(c)?Ew.g?Ew.g(a,b):Ew.call(null,a,b):(b=mw.h($CLJS.q($CLJS.q(a))),$CLJS.n(b)&&$CLJS.$b($v.h($CLJS.q($CLJS.q(a))),b));return $CLJS.Gh.v($CLJS.q(a),$CLJS.S,mw,null)});
var oA=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Yi,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("cljs.pprint","emit-nl?"),function(f){return $CLJS.cj.h(f)},e,a,b,c,d)}();oA.m(null,qx,function(a,b,c){a=xw.h(a);return tw(b,a,c)});oA.m(null,ox,function(a,b,c){a=xw.h(a);return ww(b,a,c)});
oA.m(null,px,function(a,b,c,d){a=xw.h(a);var e=$CLJS.q(Dw.h(a));return $CLJS.n(e)?e:(d=!rw(b,d))?d:ww(b,a,c)});oA.m(null,nx,function(){return!0});
var Gw=function Gw(a,b){var d=Fw(b);b=$CLJS.H(d,0,null);var e=$CLJS.H(d,1,null);$CLJS.n(b)&&ow(a,b,!1);if($CLJS.n(e)){d=yw(e);b=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);var f=$CLJS.B(e),k=oA.v(f,a,b,zw(e));$CLJS.n(k)&&(Ew(a,f),e=$CLJS.C(e));rw(a,e)?a=e:(e=Gw.g?Gw.g(a,b):Gw.call(null,a,b),$CLJS.F.g(e,b)?(ow(a,b,!1),a=d):a=$CLJS.Wf.g($CLJS.tf,$CLJS.Ye.g(e,d)));return a}return null};$CLJS.g=Qw.prototype;$CLJS.g.P=function(a,b){return new Qw(this.Ba,this.Jf,this.gi,this.hh,this.Xc,b)};$CLJS.g.O=function(){return this.Yh};
$CLJS.g.Qb=function(){return this.Xc};
$CLJS.g.Kc=function(a,b){a=$CLJS.Xa(b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(String,a):$CLJS.F.call(null,String,a))){var c=Pw(this,b);b=c.replace(/\s+$/,"");a=c.substring(b.length);var d=$CLJS.qi.h($CLJS.q($CLJS.q(this)));if($CLJS.F.g(d,Ow))return Jw(this),$CLJS.$b($v.h($CLJS.q($CLJS.q(this))),b),$CLJS.Gh.v($CLJS.q(this),$CLJS.S,mw,a);d=Mw.h($CLJS.q($CLJS.q(this)));c=d+$CLJS.E(c);$CLJS.Gh.v($CLJS.q(this),$CLJS.S,Mw,c);return Iw(this,new fw(Nw,b,a,d,c,null,null,null))}if($CLJS.n($CLJS.F.g?$CLJS.F.g(Number,
a):$CLJS.F.call(null,Number,a)))return $CLJS.F.g($CLJS.qi.h($CLJS.q($CLJS.q(this))),Ow)?(Jw(this),b=$CLJS.$b($v.h($CLJS.q($CLJS.q(this))),b)):$CLJS.F.g(b,"\n")?b=Pw(this,"\n"):(a=Mw.h($CLJS.q($CLJS.q(this))),c=a+1,$CLJS.Gh.v($CLJS.q(this),$CLJS.S,Mw,c),b=Hv(b),b=Iw(this,new fw(Nw,b,null,a,c,null,null,null))),b;throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};$CLJS.g.pc=function(){this.sf(null);return Ev($v.h($CLJS.q($CLJS.q(this))))};
$CLJS.g.sf=function(){return $CLJS.F.g($CLJS.qi.h($CLJS.q($CLJS.q(this))),Lw)?(ow(this,$CLJS.Hu.h($CLJS.q($CLJS.q(this))),!0),$CLJS.Gh.v($CLJS.q(this),$CLJS.S,$CLJS.Hu,$CLJS.tf)):Jw(this)};$CLJS.hx=!0;ix=null;$CLJS.Rw=72;Sw=40;pA=null;qA=null;rA=null;Rx=null;Qx=10;lx=0;gx=null;sA=function sA(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return sA.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
sA.l=function(a,b){var c=$CLJS.Tk.l($CLJS.G([new $CLJS.h(null,1,[lA,!0],null),$CLJS.P.g(bA,b)]));b=Qx;var d=qA,e=$CLJS.Ma,f=$CLJS.ih,k=pA,l=Sw,m=ix,t=$CLJS.hx,u=Rx,v=$CLJS.Ia,x=$CLJS.Rw,A=rA,D=$v.g(c,Qx),I=Faa.g(c,qA),K=$CLJS.eA.g(c,$CLJS.Ma),U=$CLJS.Nu.g(c,$CLJS.ih),Z=Aaa.g(c,pA),da=uw.g(c,Sw),xa=$CLJS.Gi.g(c,ix),Ha=nA.g(c,$CLJS.hx),mb=qaa.g(c,Rx),Qb=$CLJS.Ga.g(c,$CLJS.Ia),Ab=yaa.g(c,$CLJS.Rw),kc=Jaa.g(c,rA);Qx=D;qA=I;$CLJS.Ma=K;$CLJS.ih=U;pA=Z;Sw=da;ix=xa;$CLJS.hx=Ha;Rx=mb;$CLJS.Ia=Qb;$CLJS.Rw=
Ab;rA=kc;try{var Tb=new $CLJS.Ea,rc=$CLJS.Gd(c,lA)?lA.h(c):!0,Vb=!0===rc||null==rc?new $CLJS.sc(Tb):rc;if($CLJS.n($CLJS.hx)){var Rb=$CLJS.Sa($CLJS.fx(Vb));c=$CLJS.Pv;$CLJS.Pv=Rb?$CLJS.Xw(Vb):Vb;try{$CLJS.jx(a),$CLJS.Wv()}finally{$CLJS.Pv=c}}else{Rb=$CLJS.Pv;$CLJS.Pv=Vb;try{Ov.call(null,a)}finally{$CLJS.Pv=Rb}}!0===rc&&$CLJS.mh($CLJS.p.h(Tb));return null==rc?$CLJS.p.h(Tb):null}finally{rA=A,$CLJS.Rw=x,$CLJS.Ia=v,Rx=u,$CLJS.hx=t,ix=m,Sw=l,pA=k,$CLJS.ih=f,$CLJS.Ma=e,qA=d,Qx=b}};sA.A=1;
sA.B=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.l(b,a)};var Bx=null;$CLJS.g=Dx.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "seq":return this.sc;case "rest":return this.pb;case "pos":return this.Zb;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.kh(b,function(d){return $CLJS.kh(b,$CLJS.qh,""," ","",c,d)},"#cljs.pprint.arg-navigator{",", ","}",c,$CLJS.Ye.g(new $CLJS.Q(null,3,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[Ex,this.sc],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Hi,this.pb],null),new $CLJS.Q(null,2,5,$CLJS.R,[Mw,this.Zb],null)],null),this.G))};$CLJS.g.Ca=function(){return new $CLJS.Bu(this,3,new $CLJS.Q(null,3,5,$CLJS.R,[Ex,$CLJS.Hi,Mw],null),$CLJS.n(this.G)?$CLJS.qc(this.G):$CLJS.Fe())};
$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 3+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-402038447^$CLJS.Pc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.sc,b.sc)&&$CLJS.F.g(this.pb,b.pb)&&$CLJS.F.g(this.Zb,b.Zb)&&$CLJS.F.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,3,[Mw,null,Ex,null,$CLJS.Hi,null],null),null),b)?$CLJS.tk.g($CLJS.Jb($CLJS.Wf.g($CLJS.N,this),this.S),b):new Dx(this.sc,this.pb,this.Zb,this.S,$CLJS.De($CLJS.tk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "seq":case "rest":case "pos":return!0;default:return $CLJS.Gd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(Ex,b):$CLJS.fe.call(null,Ex,b))?new Dx(c,this.pb,this.Zb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g($CLJS.Hi,b):$CLJS.fe.call(null,$CLJS.Hi,b))?new Dx(this.sc,c,this.Zb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Mw,b):$CLJS.fe.call(null,Mw,b))?new Dx(this.sc,this.pb,c,this.S,this.G,null):new Dx(this.sc,this.pb,this.Zb,this.S,$CLJS.S.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.z($CLJS.Ye.g(new $CLJS.Q(null,3,5,$CLJS.R,[new $CLJS.Pf(Ex,this.sc),new $CLJS.Pf($CLJS.Hi,this.pb),new $CLJS.Pf(Mw,this.Zb)],null),this.G))};$CLJS.g.P=function(a,b){return new Dx(this.sc,this.pb,this.Zb,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.yd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.ab($CLJS.jb,this,b)};$CLJS.g=Jx.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "func":return this.cc;case "def":return this.bc;case "params":return this.jc;case "offset":return this.offset;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.kh(b,function(d){return $CLJS.kh(b,$CLJS.qh,""," ","",c,d)},"#cljs.pprint.compiled-directive{",", ","}",c,$CLJS.Ye.g(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[vy,this.cc],null),new $CLJS.Q(null,2,5,$CLJS.R,[Ez,this.bc],null),new $CLJS.Q(null,2,5,$CLJS.R,[ty,this.jc],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ju,this.offset],null)],null),this.G))};
$CLJS.g.Ca=function(){return new $CLJS.Bu(this,4,new $CLJS.Q(null,4,5,$CLJS.R,[vy,Ez,ty,$CLJS.Ju],null),$CLJS.n(this.G)?$CLJS.qc(this.G):$CLJS.Fe())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-829256337^$CLJS.Pc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.cc,b.cc)&&$CLJS.F.g(this.bc,b.bc)&&$CLJS.F.g(this.jc,b.jc)&&$CLJS.F.g(this.offset,b.offset)&&$CLJS.F.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,4,[$CLJS.Ju,null,vy,null,ty,null,Ez,null],null),null),b)?$CLJS.tk.g($CLJS.Jb($CLJS.Wf.g($CLJS.N,this),this.S),b):new Jx(this.cc,this.bc,this.jc,this.offset,this.S,$CLJS.De($CLJS.tk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "func":case "def":case "params":case "offset":return!0;default:return $CLJS.Gd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(vy,b):$CLJS.fe.call(null,vy,b))?new Jx(c,this.bc,this.jc,this.offset,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Ez,b):$CLJS.fe.call(null,Ez,b))?new Jx(this.cc,c,this.jc,this.offset,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(ty,b):$CLJS.fe.call(null,ty,b))?new Jx(this.cc,this.bc,c,this.offset,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g($CLJS.Ju,b):$CLJS.fe.call(null,$CLJS.Ju,b))?new Jx(this.cc,this.bc,this.jc,c,this.S,
this.G,null):new Jx(this.cc,this.bc,this.jc,this.offset,this.S,$CLJS.S.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.z($CLJS.Ye.g(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Pf(vy,this.cc),new $CLJS.Pf(Ez,this.bc),new $CLJS.Pf(ty,this.jc),new $CLJS.Pf($CLJS.Ju,this.offset)],null),this.G))};$CLJS.g.P=function(a,b){return new Jx(this.cc,this.bc,this.jc,this.offset,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.yd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.ab($CLJS.jb,this,b)};
var Sx=new $CLJS.h(null,3,[2,"#b",8,"#o",16,"#x"],null),fy=new $CLJS.Q(null,20,5,$CLJS.R,"zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(" "),null),tA=new $CLJS.Q(null,20,5,$CLJS.R,"zeroth first second third fourth fifth sixth seventh eighth ninth tenth eleventh twelfth thirteenth fourteenth fifteenth sixteenth seventeenth eighteenth nineteenth".split(" "),null),gy=new $CLJS.Q(null,10,5,$CLJS.R,"  twenty thirty forty fifty sixty seventy eighty ninety".split(" "),
null),Kaa=new $CLJS.Q(null,10,5,$CLJS.R,"  twentieth thirtieth fortieth fiftieth sixtieth seventieth eightieth ninetieth".split(" "),null),iy=new $CLJS.Q(null,22,5,$CLJS.R," thousand million billion trillion quadrillion quintillion sextillion septillion octillion nonillion decillion undecillion duodecillion tredecillion quattuordecillion quindecillion sexdecillion septendecillion octodecillion novemdecillion vigintillion".split(" "),null),Laa=new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,9,5,$CLJS.R,
"I II III IIII V VI VII VIII VIIII".split(" "),null),new $CLJS.Q(null,9,5,$CLJS.R,"X XX XXX XXXX L LX LXX LXXX LXXXX".split(" "),null),new $CLJS.Q(null,9,5,$CLJS.R,"C CC CCC CCCC D DC DCC DCCC DCCCC".split(" "),null),new $CLJS.Q(null,3,5,$CLJS.R,["M","MM","MMM"],null)],null),Maa=new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,9,5,$CLJS.R,"I II III IV V VI VII VIII IX".split(" "),null),new $CLJS.Q(null,9,5,$CLJS.R,"X XX XXX XL L LX LXX LXXX XC".split(" "),null),new $CLJS.Q(null,9,5,$CLJS.R,"C CC CCC CD D DC DCC DCCC CM".split(" "),
null),new $CLJS.Q(null,3,5,$CLJS.R,["M","MM","MMM"],null)],null),ly=new $CLJS.h(null,5,[8,"Backspace",9,"Tab",10,"Newline",13,"Return",32,"Space"],null);cz.prototype.P=function(a,b){return new cz(this.Ba,b)};cz.prototype.O=function(){return this.Zh};cz.prototype.pc=function(){return Ev(this.Ba)};
cz.prototype.Kc=function(a,b){a=$CLJS.Xa(b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(String,a):$CLJS.F.call(null,String,a)))return $CLJS.$b(this.Ba,b.toLowerCase());if($CLJS.n($CLJS.F.g?$CLJS.F.g(Number,a):$CLJS.F.call(null,Number,a)))return $CLJS.$b(this.Ba,Hv(b).toLowerCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};ez.prototype.P=function(a,b){return new ez(this.Ba,b)};ez.prototype.O=function(){return this.$h};ez.prototype.pc=function(){return Ev(this.Ba)};
ez.prototype.Kc=function(a,b){a=$CLJS.Xa(b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(String,a):$CLJS.F.call(null,String,a)))return $CLJS.$b(this.Ba,b.toUpperCase());if($CLJS.n($CLJS.F.g?$CLJS.F.g(Number,a):$CLJS.F.call(null,Number,a)))return $CLJS.$b(this.Ba,Hv(b).toUpperCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};hz.prototype.P=function(a,b){return new hz(this.Ba,this.Id,b)};hz.prototype.O=function(){return this.ai};hz.prototype.pc=function(){return Ev(this.Ba)};
hz.prototype.Kc=function(a,b){a=$CLJS.Xa(b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(String,a):$CLJS.F.call(null,String,a)))return $CLJS.$b(this.Ba,gz(b.toLowerCase(),$CLJS.q(this.Id))),0<b.length?$CLJS.Pe(this.Id,$CLJS.Aa($CLJS.bd(b,$CLJS.E(b)-1))):null;if($CLJS.n($CLJS.F.g?$CLJS.F.g(Number,a):$CLJS.F.call(null,Number,a)))return b=Hv(b),a=$CLJS.n($CLJS.q(this.Id))?b.toUpperCase():b,$CLJS.$b(this.Ba,a),$CLJS.Pe(this.Id,$CLJS.Aa(b));throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};
jz.prototype.P=function(a,b){return new jz(this.Ba,this.ud,b)};jz.prototype.O=function(){return this.bi};jz.prototype.pc=function(){return Ev(this.Ba)};
jz.prototype.Kc=function(a,b){a=$CLJS.Xa(b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(String,a):$CLJS.F.call(null,String,a)))return b=b.toLowerCase(),$CLJS.Sa($CLJS.q(this.ud))?(a=RegExp("\\S","g").exec(b),a=$CLJS.n(a)?a.index:a,$CLJS.n(a)?($CLJS.$b(this.Ba,[b.substring(0,a),$CLJS.bd(b,a).toUpperCase(),b.substring(a+1).toLowerCase()].join("")),$CLJS.Pe(this.ud,!0)):$CLJS.$b(this.Ba,b)):$CLJS.$b(this.Ba,b.toLowerCase());if($CLJS.n($CLJS.F.g?$CLJS.F.g(Number,a):$CLJS.F.call(null,Number,a)))return b=Hv(b),a=$CLJS.Sa($CLJS.q(this.ud)),
$CLJS.n(a?Cv(b):a)?($CLJS.Pe(this.ud,!0),$CLJS.$b(this.Ba,b.toUpperCase())):$CLJS.$b(this.Ba,b.toLowerCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};
var yz=$CLJS.Ag("ASDBOXRPCFEG$%\x26|~\nT*?()[;]{}\x3c\x3e^W_I".split(""),[new $CLJS.h(null,5,[tz,"A",ty,new $CLJS.h(null,4,[Vx,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Wx,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),Ux,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Xx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null)],null),sz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Kx,null,Lx,null,uz,null],null),null),Dz,$CLJS.N,zz,function(){return function(a,b){return Zx($CLJS.dy,a,b)}}],null),new $CLJS.h(null,
5,[tz,"S",ty,new $CLJS.h(null,4,[Vx,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Wx,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),Ux,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Xx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null)],null),sz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Kx,null,Lx,null,uz,null],null),null),Dz,$CLJS.N,zz,function(){return function(a,b){return Zx($CLJS.Eh,a,b)}}],null),new $CLJS.h(null,5,[tz,"D",ty,new $CLJS.h(null,4,[Vx,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Xx,new $CLJS.Q(null,
2,5,$CLJS.R,[" ",String],null),cy,new $CLJS.Q(null,2,5,$CLJS.R,[",",String],null),by,new $CLJS.Q(null,2,5,$CLJS.R,[3,Number],null)],null),sz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Kx,null,Lx,null,uz,null],null),null),Dz,$CLJS.N,zz,function(){return function(a,b){return ey(10,a,b)}}],null),new $CLJS.h(null,5,[tz,"B",ty,new $CLJS.h(null,4,[Vx,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Xx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),cy,new $CLJS.Q(null,2,5,$CLJS.R,[",",String],null),by,new $CLJS.Q(null,
2,5,$CLJS.R,[3,Number],null)],null),sz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Kx,null,Lx,null,uz,null],null),null),Dz,$CLJS.N,zz,function(){return function(a,b){return ey(2,a,b)}}],null),new $CLJS.h(null,5,[tz,"O",ty,new $CLJS.h(null,4,[Vx,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Xx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),cy,new $CLJS.Q(null,2,5,$CLJS.R,[",",String],null),by,new $CLJS.Q(null,2,5,$CLJS.R,[3,Number],null)],null),sz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Kx,null,Lx,null,uz,
null],null),null),Dz,$CLJS.N,zz,function(){return function(a,b){return ey(8,a,b)}}],null),new $CLJS.h(null,5,[tz,"X",ty,new $CLJS.h(null,4,[Vx,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Xx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),cy,new $CLJS.Q(null,2,5,$CLJS.R,[",",String],null),by,new $CLJS.Q(null,2,5,$CLJS.R,[3,Number],null)],null),sz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Kx,null,Lx,null,uz,null],null),null),Dz,$CLJS.N,zz,function(){return function(a,b){return ey(16,a,b)}}],null),new $CLJS.h(null,
5,[tz,"R",ty,new $CLJS.h(null,5,[$v,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Vx,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Xx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),cy,new $CLJS.Q(null,2,5,$CLJS.R,[",",String],null),by,new $CLJS.Q(null,2,5,$CLJS.R,[3,Number],null)],null),sz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Kx,null,Lx,null,uz,null],null),null),Dz,$CLJS.N,zz,function(a){return $CLJS.n($CLJS.B($v.h(a)))?function(b,c){return ey($v.h(b),b,c)}:$CLJS.n(function(){var b=Lx.h(a);return $CLJS.n(b)?
Kx.h(a):b}())?function(b,c){return ky(Laa,c)}:$CLJS.n(Lx.h(a))?function(b,c){return ky(Maa,c)}:$CLJS.n(Kx.h(a))?function(b,c){b=Fx(c);c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);if($CLJS.F.g(0,c))Yx.l($CLJS.G(["zeroth"]));else{var d=$x(1E3,0>c?-c:c);if($CLJS.E(d)<=$CLJS.E(iy)){var e=$CLJS.Ze.g(hy,$CLJS.Nv(1,d));e=jy(e,1);var f=$CLJS.id(d);d=$CLJS.Td(f,100);f=Iv(f,100);var k=0<d?[$CLJS.p.h($CLJS.bd(fy,d))," hundred"].join(""):null,l=$CLJS.p,m=l.h;if(0<f)if(20>f)var t=$CLJS.bd(tA,f);else{t=$CLJS.Td(f,10);
var u=Iv(f,10);t=0<t&&!(0<u)?$CLJS.bd(Kaa,t):[$CLJS.p.h(0<t?$CLJS.bd(gy,t):null),0<t&&0<u?"-":null,$CLJS.p.h(0<u?$CLJS.bd(tA,u):null)].join("")}else t=0<d?"th":null;d=[k,0<d&&0<f?" ":null,m.call(l,t)].join("");Yx.l($CLJS.G([[0>c?"minus ":null,$CLJS.sd(e)||$CLJS.sd(d)?$CLJS.sd(e)?d:[e,"th"].join(""):[e,", ",d].join("")].join("")]))}else ey(10,new $CLJS.h(null,5,[Vx,0,Xx," ",cy,",",by,3,Kx,!0],null),xx(new $CLJS.Q(null,1,5,$CLJS.R,[c],null))),e=Iv(c,100),c=11<e||19>e,e=Iv(e,10),Yx.l($CLJS.G([1===e&&
c?"st":2===e&&c?"nd":3===e&&c?"rd":"th"]))}return b}:function(b,c){c=Fx(c);b=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);if($CLJS.F.g(0,b))Yx.l($CLJS.G(["zero"]));else{var d=$x(1E3,0>b?-b:b);$CLJS.E(d)<=$CLJS.E(iy)?(d=$CLJS.Ze.g(hy,d),d=jy(d,0),Yx.l($CLJS.G([[0>b?"minus ":null,d].join("")]))):ey(10,new $CLJS.h(null,5,[Vx,0,Xx," ",cy,",",by,3,Kx,!0],null),xx(new $CLJS.Q(null,1,5,$CLJS.R,[b],null)))}return c}}],null),new $CLJS.h(null,5,[tz,"P",ty,$CLJS.N,sz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Kx,null,Lx,
null,uz,null],null),null),Dz,$CLJS.N,zz,function(){return function(a,b){b=$CLJS.n(Kx.h(a))?Hx(b,-1):b;a=$CLJS.n(Lx.h(a))?new $CLJS.Q(null,2,5,$CLJS.R,["y","ies"],null):new $CLJS.Q(null,2,5,$CLJS.R,["","s"],null);var c=Fx(b);b=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);Yx.l($CLJS.G([$CLJS.F.g(b,1)?$CLJS.B(a):$CLJS.hd(a)]));return c}}],null),new $CLJS.h(null,5,[tz,"C",ty,new $CLJS.h(null,1,[ny,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null)],null),sz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Kx,null,Lx,null,
uz,null],null),null),Dz,$CLJS.N,zz,function(a){return $CLJS.n(Kx.h(a))?my:$CLJS.n(Lx.h(a))?oy:py}],null),new $CLJS.h(null,5,[tz,"F",ty,new $CLJS.h(null,5,[By,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Cy,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),$CLJS.Dy,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Ey,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null),Xx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null)],null),sz,new $CLJS.Tg(null,new $CLJS.h(null,1,[Lx,null],null),null),Dz,$CLJS.N,zz,function(){return Fy}],
null),new $CLJS.h(null,5,[tz,"E",ty,new $CLJS.h(null,7,[By,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Cy,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Gy,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),$CLJS.Dy,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),Ey,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null),Xx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),Hy,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null)],null),sz,new $CLJS.Tg(null,new $CLJS.h(null,1,[Lx,null],null),null),Dz,$CLJS.N,
zz,function(){return Iy}],null),new $CLJS.h(null,5,[tz,"G",ty,new $CLJS.h(null,7,[By,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Cy,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Gy,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),$CLJS.Dy,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),Ey,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null),Xx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),Hy,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null)],null),sz,new $CLJS.Tg(null,new $CLJS.h(null,1,[Lx,null],
null),null),Dz,$CLJS.N,zz,function(){return Jy}],null),new $CLJS.h(null,5,[tz,"$",ty,new $CLJS.h(null,4,[Cy,new $CLJS.Q(null,2,5,$CLJS.R,[2,Number],null),$CLJS.Ky,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),By,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Xx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null)],null),sz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Kx,null,Lx,null,uz,null],null),null),Dz,$CLJS.N,zz,function(){return Ly}],null),new $CLJS.h(null,5,[tz,"%",ty,new $CLJS.h(null,1,[$CLJS.gA,new $CLJS.Q(null,
2,5,$CLJS.R,[1,Number],null)],null),sz,$CLJS.Ug,Dz,$CLJS.N,zz,function(){return function(a,b){a=$CLJS.gA.h(a);for(var c=0;;)if(c<a)Qv(),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[tz,"\x26",ty,new $CLJS.h(null,1,[$CLJS.gA,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null)],null),sz,new $CLJS.Tg(null,new $CLJS.h(null,1,[nA,null],null),null),Dz,$CLJS.N,zz,function(){return function(a,b){a=$CLJS.gA.h(a);0<a&&((null!=$CLJS.Pv?$CLJS.Pv.C&32768||$CLJS.r===$CLJS.Pv.Wf||($CLJS.Pv.C?0:$CLJS.Wa($CLJS.Dv,
$CLJS.Pv)):$CLJS.Wa($CLJS.Dv,$CLJS.Pv))?$CLJS.F.g(0,$CLJS.Xv($v.h($CLJS.q($CLJS.q($CLJS.Pv))),$CLJS.Zv))||Qv():Qv());--a;for(var c=0;;)if(c<a)Qv(),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[tz,"|",ty,new $CLJS.h(null,1,[$CLJS.gA,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null)],null),sz,$CLJS.Ug,Dz,$CLJS.N,zz,function(){return function(a,b){a=$CLJS.gA.h(a);for(var c=0;;)if(c<a)Yx.l($CLJS.G(["\f"])),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[tz,"~",ty,new $CLJS.h(null,1,[$CLJS.Ky,
new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null)],null),sz,$CLJS.Ug,Dz,$CLJS.N,zz,function(){return function(a,b){a=$CLJS.Ky.h(a);Yx.l($CLJS.G([$CLJS.P.g($CLJS.p,$CLJS.Ve(a,"~"))]));return b}}],null),new $CLJS.h(null,5,[tz,"\n",ty,$CLJS.N,sz,new $CLJS.Tg(null,new $CLJS.h(null,2,[Kx,null,Lx,null],null),null),Dz,$CLJS.N,zz,function(){return function(a,b){$CLJS.n(Lx.h(a))&&Qv();return b}}],null),new $CLJS.h(null,5,[tz,"T",ty,new $CLJS.h(null,2,[dA,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),Wx,new $CLJS.Q(null,
2,5,$CLJS.R,[1,Number],null)],null),sz,new $CLJS.Tg(null,new $CLJS.h(null,2,[Lx,null,nA,null],null),null),Dz,$CLJS.N,zz,function(a){return $CLJS.n(Lx.h(a))?function(b,c){var d=dA.h(b);b=Wx.h(b);var e=d+$CLJS.Xv($v.h($CLJS.q($CLJS.q($CLJS.Pv))),$CLJS.Zv);e=0<b?Iv(e,b):0;d+=$CLJS.F.g(0,e)?0:b-e;Yx.l($CLJS.G([$CLJS.P.g($CLJS.p,$CLJS.Ve(d," "))]));return c}:function(b,c){var d=dA.h(b);b=Wx.h(b);var e=$CLJS.Xv($v.h($CLJS.q($CLJS.q($CLJS.Pv))),$CLJS.Zv);d=e<d?d-e:$CLJS.F.g(b,0)?0:b-Iv(e-d,b);Yx.l($CLJS.G([$CLJS.P.g($CLJS.p,
$CLJS.Ve(d," "))]));return c}}],null),new $CLJS.h(null,5,[tz,"*",ty,new $CLJS.h(null,1,[$CLJS.Ky,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null)],null),sz,new $CLJS.Tg(null,new $CLJS.h(null,2,[Kx,null,Lx,null],null),null),Dz,$CLJS.N,zz,function(){return function(a,b){var c=$CLJS.Ky.h(a);return $CLJS.n(Lx.h(a))?Ix(b,c):Hx(b,$CLJS.n(Kx.h(a))?-c:c)}}],null),new $CLJS.h(null,5,[tz,"?",ty,$CLJS.N,sz,new $CLJS.Tg(null,new $CLJS.h(null,1,[Lx,null],null),null),Dz,$CLJS.N,zz,function(a){return $CLJS.n(Lx.h(a))?
function(b,c){var d=Gx(c);c=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return wy(c,d,uy.h(b))}:function(b,c){var d=Gx(c);c=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);var e=Fx(d);d=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);d=xx(d);wy(c,d,uy.h(b));return e}}],null),new $CLJS.h(null,5,[tz,"(",ty,$CLJS.N,sz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Kx,null,Lx,null,uz,null],null),null),Dz,new $CLJS.h(null,3,[$CLJS.Gz,")",Lz,null,$CLJS.Iu,null],null),zz,function(a){var b=$CLJS.n(function(){var c=Lx.h(a);return $CLJS.n(c)?
Kx.h(a):c}())?fz:$CLJS.n(Kx.h(a))?iz:$CLJS.n(Lx.h(a))?kz:dz;return function(c,d){a:{var e=$CLJS.B($CLJS.Ny.h(c)),f=$CLJS.Pv;$CLJS.Pv=b.h?b.h($CLJS.Pv):b.call(null,$CLJS.Pv);try{var k=wy(e,d,uy.h(c));break a}finally{$CLJS.Pv=f}k=void 0}return k}}],null),new $CLJS.h(null,5,[tz,")",ty,$CLJS.N,sz,$CLJS.Ug,Dz,$CLJS.N,zz,function(){return null}],null),new $CLJS.h(null,5,[tz,"[",ty,new $CLJS.h(null,1,[My,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null)],null),sz,new $CLJS.Tg(null,new $CLJS.h(null,2,[Kx,
null,Lx,null],null),null),Dz,new $CLJS.h(null,3,[$CLJS.Gz,"]",Lz,!0,$CLJS.Iu,$CLJS.kA],null),zz,function(a){return $CLJS.n(Kx.h(a))?Py:$CLJS.n(Lx.h(a))?Qy:Oy}],null),new $CLJS.h(null,5,[tz,";",ty,new $CLJS.h(null,2,[az,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),bz,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null)],null),sz,new $CLJS.Tg(null,new $CLJS.h(null,1,[Kx,null],null),null),Dz,new $CLJS.h(null,1,[$CLJS.Lu,!0],null),zz,function(){return null}],null),new $CLJS.h(null,5,[tz,"]",ty,$CLJS.N,
sz,$CLJS.Ug,Dz,$CLJS.N,zz,function(){return null}],null),new $CLJS.h(null,5,[tz,"{",ty,new $CLJS.h(null,1,[Ry,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null)],null),sz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Kx,null,Lx,null,uz,null],null),null),Dz,new $CLJS.h(null,2,[$CLJS.Gz,"}",Lz,!1],null),zz,function(a){var b=Lx.h(a);b=$CLJS.n(b)?Kx.h(a):b;return $CLJS.n(b)?Wy:$CLJS.n(Kx.h(a))?Uy:$CLJS.n(Lx.h(a))?Vy:Ty}],null),new $CLJS.h(null,5,[tz,"}",ty,$CLJS.N,sz,new $CLJS.Tg(null,new $CLJS.h(null,1,[Kx,null],
null),null),Dz,$CLJS.N,zz,function(){return null}],null),new $CLJS.h(null,5,[tz,"\x3c",ty,new $CLJS.h(null,4,[Vx,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Wx,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),Ux,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Xx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null)],null),sz,new $CLJS.Tg(null,new $CLJS.h(null,4,[Kx,null,Lx,null,uz,null,nA,null],null),null),Dz,new $CLJS.h(null,3,[$CLJS.Gz,"\x3e",Lz,!0,$CLJS.Iu,Kz],null),zz,function(){return Yy}],null),new $CLJS.h(null,
5,[tz,"\x3e",ty,$CLJS.N,sz,new $CLJS.Tg(null,new $CLJS.h(null,1,[Kx,null],null),null),Dz,$CLJS.N,zz,function(){return null}],null),new $CLJS.h(null,5,[tz,"^",ty,new $CLJS.h(null,3,[hA,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),jA,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),iA,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null)],null),sz,new $CLJS.Tg(null,new $CLJS.h(null,1,[Kx,null],null),null),Dz,$CLJS.N,zz,function(){return function(a,b){var c=hA.h(a),d=jA.h(a),e=iA.h(a),f=$CLJS.n(Kx.h(a))?
ry:qy;return $CLJS.n($CLJS.n(c)?$CLJS.n(d)?e:d:c)?c<=d&&d<=e?new $CLJS.Q(null,2,5,$CLJS.R,[f,b],null):b:$CLJS.n($CLJS.n(c)?d:c)?$CLJS.F.g(c,d)?new $CLJS.Q(null,2,5,$CLJS.R,[f,b],null):b:$CLJS.n(c)?$CLJS.F.g(c,0)?new $CLJS.Q(null,2,5,$CLJS.R,[f,b],null):b:($CLJS.n(Kx.h(a))?$CLJS.sd($CLJS.Hi.h(uy.h(a))):$CLJS.sd($CLJS.Hi.h(b)))?new $CLJS.Q(null,2,5,$CLJS.R,[f,b],null):b}}],null),new $CLJS.h(null,5,[tz,"W",ty,$CLJS.N,sz,new $CLJS.Tg(null,new $CLJS.h(null,4,[Kx,null,Lx,null,uz,null,nA,null],null),null),
Dz,$CLJS.N,zz,function(a){if($CLJS.n(function(){var c=Lx.h(a);return $CLJS.n(c)?c:Kx.h(a)}())){var b=$CLJS.Ye.g($CLJS.n(Lx.h(a))?new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Nu,null,$CLJS.eA,null],null):$CLJS.tf,$CLJS.n(Kx.h(a))?new $CLJS.Q(null,2,5,$CLJS.R,[nA,!0],null):$CLJS.tf);return function(c,d){d=Fx(d);c=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.n($CLJS.P.j(sA,c,b))?new $CLJS.Q(null,2,5,$CLJS.R,[qy,d],null):d}}return function(c,d){d=Fx(d);c=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.n($CLJS.jx(c))?
new $CLJS.Q(null,2,5,$CLJS.R,[qy,d],null):d}}],null),new $CLJS.h(null,5,[tz,"_",ty,$CLJS.N,sz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Kx,null,Lx,null,uz,null],null),null),Dz,$CLJS.N,zz,function(){return mz}],null),new $CLJS.h(null,5,[tz,"I",ty,new $CLJS.h(null,1,[$CLJS.Ky,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null)],null),sz,new $CLJS.Tg(null,new $CLJS.h(null,1,[Kx,null],null),null),Dz,$CLJS.N,zz,function(){return lz}],null)]),nz=/^([vV]|#|('.)|([+-]?\d+)|(?=,))/,wz=new $CLJS.Tg(null,new $CLJS.h(null,
2,[Nx,null,Mx,null],null),null),qz=new $CLJS.h(null,2,[":",Kx,"@",Lx],null),yx=function yx(a){for(;;){if($CLJS.sd(a))return!1;var c=nA.h(sz.h(Ez.h($CLJS.B(a))));$CLJS.n(c)||(c=$CLJS.Je(yx,$CLJS.B($CLJS.Ny.h(ty.h($CLJS.B(a))))),c=$CLJS.n(c)?c:$CLJS.Je(yx,$CLJS.B($CLJS.Iu.h(ty.h($CLJS.B(a))))));if($CLJS.n(c))return!0;a=$CLJS.C(a)}},Yz=$CLJS.Hh(wx),Nz=new $CLJS.h(null,6,[$CLJS.Fj,"'",$CLJS.Jj,"#'",$CLJS.Ku,"@",$CLJS.Mu,"~",taa,"@",raa,"~"],null);
(function(){var a=Yz("~\x3c[~;~@{~w~^, ~:_~}~;]~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.y(f,0,null)}return c.call(this,e)}function c(d){d=xx(d);return zx(a,d)}b.A=0;b.B=function(d){d=$CLJS.z(d);return c(d)};b.l=c;return b}()})();
var uA=function(){var a=Yz("~\x3c#{~;~@{~w~^ ~:_~}~;}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.y(f,0,null)}return c.call(this,e)}function c(d){d=xx(d);return zx(a,d)}b.A=0;b.B=function(d){d=$CLJS.z(d);return c(d)};b.l=c;return b}()}(),vA=new $CLJS.h(null,2,["core$future_call","Future","core$promise","Promise"],null),Naa=function(){var a=Yz("~\x3c\x3c-(~;~@{~w~^ ~_~}~;)-\x3c~:\x3e");
return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.y(f,0,null)}return c.call(this,e)}function c(d){d=xx(d);return zx(a,d)}b.A=0;b.B=function(d){d=$CLJS.z(d);return c(d)};b.l=c;return b}()}(),wA,Oaa=$CLJS.Oe($CLJS.N),Paa=$CLJS.Oe($CLJS.N),Qaa=$CLJS.Oe($CLJS.N),Raa=$CLJS.Oe($CLJS.N),Saa=$CLJS.J.j($CLJS.N,$CLJS.Yi,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));
wA=new $CLJS.Xh($CLJS.xh.g("cljs.pprint","simple-dispatch"),Wz,Saa,Oaa,Paa,Qaa,Raa);Jv(wA,$CLJS.sj,function(a){if($CLJS.Sa(Oz(a)))if($CLJS.n(mx()))$CLJS.$b($CLJS.Pv,"#");else{var b=lx,c=gx;lx+=1;gx=0;try{ax("(",")");for(var d=0,e=$CLJS.z(a);;){if($CLJS.Sa($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.jx($CLJS.B(e)),$CLJS.C(e))){$CLJS.$b($CLJS.Pv," ");rx(qx);a=d+1;var f=$CLJS.C(e);d=a;e=f;continue}}else $CLJS.$b($CLJS.Pv,"...");break}ex()}finally{gx=c,lx=b}}return null});Jv(wA,$CLJS.zj,Pz);Jv(wA,$CLJS.Wj,Sz);
Jv(wA,$CLJS.Pi,uA);Jv(wA,null,function(){return $CLJS.$b($CLJS.Pv,$CLJS.Eh.l($CLJS.G([null])))});Jv(wA,$CLJS.Wh,Tz);ix=wA;
var xA=function(){var a=Yz("~:\x3c~w~^ ~@_~w~^ ~_~@{~w~^ ~_~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.y(f,0,null)}return c.call(this,e)}function c(d){d=xx(d);return zx(a,d)}b.A=0;b.B=function(d){d=$CLJS.z(d);return c(d)};b.l=c;return b}()}(),yA=function(){var a=Yz("~:\x3c~1I~w~^ ~@_~w~@{ ~_~w~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=
Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.y(f,0,null)}return c.call(this,e)}function c(d){d=xx(d);return zx(a,d)}b.A=0;b.B=function(d){d=$CLJS.z(d);return c(d)};b.l=c;return b}()}(),zA=$CLJS.N,Taa=function(a){return $CLJS.Wf.g($CLJS.N,$CLJS.$e($CLJS.Rd,$CLJS.G([function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.jc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=new $CLJS.Q(null,
2,5,$CLJS.R,[t,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xh.h($CLJS.Zg($CLJS.B(t))),$CLJS.hd(t)],null)],null);l.add(t);m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}l=$CLJS.B(e);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[l,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xh.h($CLJS.Zg($CLJS.B(l))),$CLJS.hd(l)],null)],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}()])))}(function(a){return $CLJS.Wf.g($CLJS.N,$CLJS.Ze.g(function(b){var c=$CLJS.H(b,0,null),d=$CLJS.H(b,
1,null);var e=$CLJS.ge(c);e=$CLJS.n(e)?e:$CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,24,[$CLJS.Ri,"null",$CLJS.gj,"null",$CLJS.ek,"null",$CLJS.gk,"null",$CLJS.bj,"null",$CLJS.Bj,"null",$CLJS.xj,"null",$CLJS.Cj,"null",$CLJS.ji,"null",$CLJS.Mj,"null",$CLJS.rj,"null",$CLJS.jj,"null",$CLJS.Tj,"null",$CLJS.fk,"null",$CLJS.Ji,"null",$CLJS.Fi,"null",$CLJS.$i,"null",$CLJS.lj,"null",$CLJS.Si,"null",$CLJS.Jj,"null",$CLJS.Fj,"null",$CLJS.Yj,"null",$CLJS.Bi,"null",$CLJS.Vj,"null"],null),null),c);return $CLJS.Sa(e)?
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xh.g("clojure.core",$CLJS.Zg(c)),d],null):b},a))}($CLJS.Ag([$CLJS.Si,$CLJS.Fi,jaa,$CLJS.Mj,oaa,gaa,iaa,paa,maa,laa,naa,Baa,vaa,$CLJS.Vj,uaa,zaa,xaa,Haa,eaa,$CLJS.jj,Caa,Daa,Gaa,$CLJS.Gj,saa,Iaa,faa,haa,kaa,Eaa],[xA,function(a){var b=$CLJS.hd(a),c=$CLJS.B($CLJS.Kc($CLJS.Kc(a)));if($CLJS.yd(b)){a=zA;zA=$CLJS.F.g(1,$CLJS.E(b))?$CLJS.Ce([$CLJS.B(b),"%"]):$CLJS.Wf.g($CLJS.N,$CLJS.Ze.j(function(d,e){return new $CLJS.Q(null,2,5,$CLJS.R,[d,["%",$CLJS.p.h(e)].join("")],
null)},b,$CLJS.Lv(1,$CLJS.E(b)+1)));try{return function(){var d=Yz("~\x3c#(~;~@{~w~^ ~_~}~;)~:\x3e");return function(){function e(k){var l=null;if(0<arguments.length){l=0;for(var m=Array(arguments.length-0);l<m.length;)m[l]=arguments[l+0],++l;l=new $CLJS.y(m,0,null)}return f.call(this,l)}function f(k){k=xx(k);return zx(d,k)}e.A=0;e.B=function(k){k=$CLJS.z(k);return f(k)};e.l=f;return e}()}()(c)}finally{zA=a}}else return Zz(a)},aA,yA,function(a){if(3<$CLJS.E(a)){if($CLJS.n(mx()))$CLJS.$b($CLJS.Pv,
"#");else{var b=lx,c=gx;lx+=1;gx=0;try{ax("(",")");vx(sx,1);$CLJS.P.g(function(){var l=Yz("~w ~@_~w ~@_~w ~_");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.y(x,0,null)}return t.call(this,v)}function t(u){u=xx(u);return zx(l,u)}m.A=0;m.B=function(u){u=$CLJS.z(u);return t(u)};m.l=t;return m}()}(),a);for(var d=0,e=$CLJS.z($CLJS.Te(3,a));;){if($CLJS.Sa($CLJS.Ma)||d<$CLJS.Ma){if(e){if($CLJS.n(mx()))$CLJS.$b($CLJS.Pv,
"#");else{a=lx;var f=gx;lx+=1;gx=0;try{ax(null,null),$CLJS.jx($CLJS.B(e)),$CLJS.C(e)&&($CLJS.$b($CLJS.Pv," "),rx(ox),$CLJS.jx($CLJS.hd(e))),ex()}finally{gx=f,lx=a}}if($CLJS.C($CLJS.Kc(e))){$CLJS.$b($CLJS.Pv," ");rx(qx);a=d+1;var k=$CLJS.C($CLJS.Kc(e));d=a;e=k;continue}}}else $CLJS.$b($CLJS.Pv,"...");break}ex()}finally{gx=c,lx=b}}return null}return Zz(a)},xA,$z,$z,aA,xA,aA,yA,yA,xA,yA,aA,aA,xA,aA,function(a){if($CLJS.C(a)){var b=$CLJS.z(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);var d=
"string"===typeof $CLJS.B(c)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B(c),$CLJS.C(c)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,c],null);c=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);d=$CLJS.wd($CLJS.B(d))?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B(d),$CLJS.C(d)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,d],null);var e=$CLJS.H(d,0,null),f=$CLJS.H(d,1,null);if($CLJS.n(mx()))$CLJS.$b($CLJS.Pv,"#");else{d=lx;var k=gx;lx+=1;gx=0;try{ax("(",")");(function(){var m=Yz("~w ~1I~@_~w");return function(){function t(v){var x=
null;if(0<arguments.length){x=0;for(var A=Array(arguments.length-0);x<A.length;)A[x]=arguments[x+0],++x;x=new $CLJS.y(A,0,null)}return u.call(this,x)}function u(v){v=xx(v);return zx(m,v)}t.A=0;t.B=function(v){v=$CLJS.z(v);return u(v)};t.l=u;return t}()})()(a,b);$CLJS.n($CLJS.n(c)?c:$CLJS.n(e)?e:$CLJS.z(f))&&function(){var m=Yz("~@:_");return function(){function t(v){var x=null;if(0<arguments.length){x=0;for(var A=Array(arguments.length-0);x<A.length;)A[x]=arguments[x+0],++x;x=new $CLJS.y(A,0,null)}return u.call(this,
x)}function u(v){v=xx(v);return zx(m,v)}t.A=0;t.B=function(v){v=$CLJS.z(v);return u(v)};t.l=u;return t}()}()();$CLJS.n(c)&&Ax(!0,'"~a"~:[~;~:@_~]',$CLJS.G([c,$CLJS.n(e)?e:$CLJS.z(f)]));$CLJS.n(e)&&function(){var m=Yz("~w~:[~;~:@_~]");return function(){function t(v){var x=null;if(0<arguments.length){x=0;for(var A=Array(arguments.length-0);x<A.length;)A[x]=arguments[x+0],++x;x=new $CLJS.y(A,0,null)}return u.call(this,x)}function u(v){v=xx(v);return zx(m,v)}t.A=0;t.B=function(v){v=$CLJS.z(v);return u(v)};
t.l=u;return t}()}()(e,$CLJS.z(f));for(a=f;;){aaa($CLJS.B(a));var l=$CLJS.C(a);if(l)b=l,rx(qx),a=b;else break}ex()}finally{gx=k,lx=d}}return null}return $CLJS.jx(a)},aA,function(a){if($CLJS.n(mx()))$CLJS.$b($CLJS.Pv,"#");else{var b=lx,c=gx;lx+=1;gx=0;try{ax("(",")");vx(sx,1);$CLJS.jx($CLJS.B(a));if($CLJS.C(a)){$CLJS.$b($CLJS.Pv," ");rx(qx);for(var d=0,e=$CLJS.C(a);;){if($CLJS.Sa($CLJS.Ma)||d<$CLJS.Ma){if(e){if($CLJS.n(mx()))$CLJS.$b($CLJS.Pv,"#");else{a=lx;var f=gx;lx+=1;gx=0;try{ax(null,null),$CLJS.jx($CLJS.B(e)),
$CLJS.C(e)&&($CLJS.$b($CLJS.Pv," "),rx(ox),$CLJS.jx($CLJS.hd(e))),ex()}finally{gx=f,lx=a}}if($CLJS.C($CLJS.Kc(e))){$CLJS.$b($CLJS.Pv," ");rx(qx);a=d+1;var k=$CLJS.C($CLJS.Kc(e));d=a;e=k;continue}}}else $CLJS.$b($CLJS.Pv,"...");break}}ex()}finally{gx=c,lx=b}}return null},aA,$z,$z,xA,xA,aA,aA,xA]))),AA,Uaa=$CLJS.Oe($CLJS.N),Vaa=$CLJS.Oe($CLJS.N),Waa=$CLJS.Oe($CLJS.N),Xaa=$CLJS.Oe($CLJS.N),Yaa=$CLJS.J.j($CLJS.N,$CLJS.Yi,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));
AA=new $CLJS.Xh($CLJS.xh.g("cljs.pprint","code-dispatch"),Wz,Yaa,Uaa,Vaa,Waa,Xaa);Jv(AA,$CLJS.sj,function(a){if($CLJS.Sa(Oz(a))){var b=Taa.call(null,$CLJS.B(a));return $CLJS.n(b)?b.h?b.h(a):b.call(null,a):Zz(a)}return null});Jv(AA,$CLJS.zi,function(a){var b=a.h?a.h(zA):a.call(null,zA);return $CLJS.n(b)?Yx.l($CLJS.G([b])):$CLJS.n(rA)?Yx.l($CLJS.G([$CLJS.Zg(a)])):Ov.call(null,a)});Jv(AA,$CLJS.zj,Pz);Jv(AA,$CLJS.Wj,Sz);Jv(AA,$CLJS.Pi,uA);Jv(AA,Uz,Naa);
Jv(AA,Vz,function(a){var b=$CLJS.p,c=b.h,d=$CLJS.Xa(a).name;var e=$CLJS.gh(/^[^$]+\$[^$]+/,d);e=$CLJS.n(e)?vA.h?vA.h(e):vA.call(null,e):null;b=["#\x3c",c.call(b,$CLJS.n(e)?e:d),"@",$CLJS.p.h($CLJS.ya(a)),": "].join("");if($CLJS.n(mx()))$CLJS.$b($CLJS.Pv,"#");else{c=lx;d=gx;lx+=1;gx=0;try{ax(b,"\x3e");vx(sx,-(b.length-2));rx(qx);var f=null!=a?a.I&1||$CLJS.r===a.dj?!0:a.I?!1:$CLJS.Wa(Fv,a):$CLJS.Wa(Fv,a);var k=f?!Gv(a):f;$CLJS.jx(k?waa:$CLJS.q(a));ex()}finally{gx=d,lx=c}}return null});Jv(AA,null,Ov);
Jv(AA,$CLJS.Wh,Tz);ix=wA;