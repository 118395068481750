var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';var tla,ula,I3,J3,vla,K3,L3;
$CLJS.E3=function(a){return $CLJS.n($CLJS.o1.g?$CLJS.o1.g($CLJS.h1,a):$CLJS.o1.call(null,$CLJS.h1,a))?$CLJS.r3:$CLJS.n($CLJS.o1.g?$CLJS.o1.g($CLJS.d1,a):$CLJS.o1.call(null,$CLJS.d1,a))?$CLJS.r3:$CLJS.n($CLJS.o1.g?$CLJS.o1.g($CLJS.a1,a):$CLJS.o1.call(null,$CLJS.a1,a))?$CLJS.dla:$CLJS.n($CLJS.o1.g?$CLJS.o1.g($CLJS.k1,a):$CLJS.o1.call(null,$CLJS.k1,a))?$CLJS.ela:$CLJS.n($CLJS.o1.g?$CLJS.o1.g($CLJS.j1,a):$CLJS.o1.call(null,$CLJS.j1,a))?$CLJS.fla:$CLJS.n($CLJS.o1.g?$CLJS.o1.g($CLJS.$0,a):$CLJS.o1.call(null,
$CLJS.$0,a))?$CLJS.gla:$CLJS.n($CLJS.o1.g?$CLJS.o1.g($CLJS.X0,a):$CLJS.o1.call(null,$CLJS.X0,a))?$CLJS.hla:$CLJS.n($CLJS.o1.g?$CLJS.o1.g($CLJS.Z0,a):$CLJS.o1.call(null,$CLJS.Z0,a))?$CLJS.ila:$CLJS.n($CLJS.o1.g?$CLJS.o1.g($CLJS.e1,a):$CLJS.o1.call(null,$CLJS.e1,a))?$CLJS.jla:$CLJS.kla};$CLJS.F3=function(a){if("number"===typeof a)return a;switch(a instanceof $CLJS.M?a.T:null){case "current":return 0;case "next":return 1;case "last":return-1;default:return 0}};
$CLJS.G3=function(a,b){a=$CLJS.F3(a);b=$CLJS.n(b)?b:$CLJS.nv;return 0===a?$CLJS.F.g(b,$CLJS.nv)?$CLJS.SG("Today"):$CLJS.sG("This {0}",$CLJS.G([$CLJS.z3.h(b)])):$CLJS.F.g(a,1)?$CLJS.F.g(b,$CLJS.nv)?$CLJS.SG("Tomorrow"):$CLJS.sG("Next {0}",$CLJS.G([$CLJS.z3.h(b)])):$CLJS.F.g(a,-1)?$CLJS.F.g(b,$CLJS.nv)?$CLJS.SG("Yesterday"):$CLJS.sG("Previous {0}",$CLJS.G([$CLJS.z3.h(b)])):0>a?$CLJS.sG("Previous {0} {1}",$CLJS.G([Math.abs(a),$CLJS.z3.g(Math.abs(a),b)])):0<a?$CLJS.sG("Next {0} {1}",$CLJS.G([a,$CLJS.z3.g(a,
b)])):null};$CLJS.H3=function(a){var b=$CLJS.E3(a);b=$CLJS.De(b);return null==b?a:$CLJS.S.j(a,$CLJS.wN,b)};tla=new $CLJS.M(null,"legacy-filter","legacy-filter",-948552546);ula=new $CLJS.M(null,"query-filters","query-filters",409521440);I3=new $CLJS.M("metabase.lib.filter","unary","metabase.lib.filter/unary",-336199667);J3=new $CLJS.M("metabase.lib.filter","varargs","metabase.lib.filter/varargs",1417033079);vla=new $CLJS.M(null,"matching-filters","matching-filters",-326129659);
K3=new $CLJS.M("metabase.lib.filter","compound","metabase.lib.filter/compound",1924103780);L3=new $CLJS.M("metabase.lib.filter","binary","metabase.lib.filter/binary",-1921360491);for(var M3=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.dt],null)),N3=null,O3=0,P3=0;;)if(P3<O3){var wla=N3.X(null,P3);$CLJS.zH(wla,K3);P3+=1}else{var Q3=$CLJS.z(M3);if(Q3){var R3=Q3;if($CLJS.zd(R3)){var S3=$CLJS.jc(R3),xla=$CLJS.lc(R3),yla=S3,zla=$CLJS.E(S3);M3=xla;N3=yla;O3=zla}else{var Ala=$CLJS.B(R3);$CLJS.zH(Ala,K3);M3=$CLJS.C(R3);N3=null;O3=0}P3=0}else break}
for(var T3=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.II],null)),U3=null,V3=0,W3=0;;)if(W3<V3){var Bla=U3.X(null,W3);$CLJS.zH(Bla,J3);W3+=1}else{var X3=$CLJS.z(T3);if(X3){var Y3=X3;if($CLJS.zd(Y3)){var Z3=$CLJS.jc(Y3),Cla=$CLJS.lc(Y3),Dla=Z3,Ela=$CLJS.E(Z3);T3=Cla;U3=Dla;V3=Ela}else{var Fla=$CLJS.B(Y3);$CLJS.zH(Fla,J3);T3=$CLJS.C(Y3);U3=null;V3=0}W3=0}else break}
for(var $3=$CLJS.z(new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Bs,$CLJS.Ds,$CLJS.xs,$CLJS.zs,$CLJS.hI,$CLJS.BI,$CLJS.pI,$CLJS.iI],null)),a4=null,b4=0,c4=0;;)if(c4<b4){var Gla=a4.X(null,c4);$CLJS.zH(Gla,L3);c4+=1}else{var d4=$CLJS.z($3);if(d4){var e4=d4;if($CLJS.zd(e4)){var f4=$CLJS.jc(e4),Hla=$CLJS.lc(e4),Ila=f4,Jla=$CLJS.E(f4);$3=Hla;a4=Ila;b4=Jla}else{var Kla=$CLJS.B(e4);$CLJS.zH(Kla,L3);$3=$CLJS.C(e4);a4=null;b4=0}c4=0}else break}
for(var g4=$CLJS.z(new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.RH,$CLJS.cI,$CLJS.TH,$CLJS.$H,$CLJS.ft],null)),h4=null,i4=0,j4=0;;)if(j4<i4){var Lla=h4.X(null,j4);$CLJS.zH(Lla,I3);j4+=1}else{var k4=$CLJS.z(g4);if(k4){var l4=k4;if($CLJS.zd(l4)){var m4=$CLJS.jc(l4),Mla=$CLJS.lc(l4),Nla=m4,Ola=$CLJS.E(m4);g4=Mla;h4=Nla;i4=Ola}else{var Pla=$CLJS.B(l4);$CLJS.zH(Pla,I3);g4=$CLJS.C(l4);h4=null;i4=0}j4=0}else break}
$CLJS.m2.m(null,$CLJS.eY,function(a,b){var c=$CLJS.De($CLJS.eY.h($CLJS.BY(a,b)));return $CLJS.n(c)?$CLJS.sG("Filtered by {0}",$CLJS.G([$CLJS.w3($CLJS.SG("and"),function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.jc(k),m=$CLJS.E(l),t=$CLJS.oe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.M1.v(a,b,v,$CLJS.N1);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),null)}t=$CLJS.B(k);
return $CLJS.ce($CLJS.M1.v(a,b,t,$CLJS.N1),f($CLJS.Kc(k)))}return null}},null,null)}(c)}())])):null});
$CLJS.j2.m(null,K3,function(a,b,c,d){c=$CLJS.z(c);var e=$CLJS.B(c);c=$CLJS.C(c);$CLJS.B(c);var f=$CLJS.C(c);return $CLJS.w3(function(){var k=e instanceof $CLJS.M?e.T:null;switch(k){case "and":return $CLJS.SG("and");case "or":return $CLJS.SG("or");default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}(),function(){return function m(l){return new $CLJS.le(null,function(){for(;;){var t=$CLJS.z(l);if(t){if($CLJS.zd(t)){var u=$CLJS.jc(t),v=$CLJS.E(u),x=$CLJS.oe(v);a:for(var A=0;;)if(A<
v){var D=$CLJS.kd(u,A);D=$CLJS.M1.v(a,b,D,d);x.add(D);A+=1}else{u=!0;break a}return u?$CLJS.re($CLJS.te(x),m($CLJS.lc(t))):$CLJS.re($CLJS.te(x),null)}x=$CLJS.B(t);return $CLJS.ce($CLJS.M1.v(a,b,x,d),m($CLJS.Kc(t)))}return null}},null,null)}(f)}())});
$CLJS.j2.m(null,J3,function(a,b,c,d){var e=$CLJS.z(c);c=$CLJS.B(e);e=$CLJS.C(e);$CLJS.B(e);var f=$CLJS.C(e);e=$CLJS.Ze.g(function(k){return $CLJS.M1.v(a,b,k,d)},f);if($CLJS.F.g($CLJS.E(f),2))switch(f=$CLJS.H(e,0,null),e=$CLJS.H(e,1,null),c=c instanceof $CLJS.M?c.T:null,c){case "\x3d":return $CLJS.sG("{0} equals {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.sG("{0} does not equal {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}else switch(f=$CLJS.B(e),e=$CLJS.w3($CLJS.SG("or"),
$CLJS.Kc(e)),c=c instanceof $CLJS.M?c.T:null,c){case "\x3d":return $CLJS.sG("{0} equals any of {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.sG("{0} does not equal any of {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.j2.m(null,L3,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);var f=$CLJS.H(c,2,null);c=$CLJS.H(c,3,null);f=$CLJS.M1.v(a,b,f,d);a=$CLJS.M1.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "\x3c":return $CLJS.sG("{0} is less than {1}",$CLJS.G([f,a]));case "\x3c\x3d":return $CLJS.sG("{0} is less than or equal to {1}",$CLJS.G([f,a]));case "\x3e":return $CLJS.sG("{0} is greater than {1}",$CLJS.G([f,a]));case "\x3e\x3d":return $CLJS.sG("{0} is greater than or equal to {1}",$CLJS.G([f,
a]));case "starts-with":return $CLJS.sG("{0} starts with {1}",$CLJS.G([f,a]));case "ends-with":return $CLJS.sG("{0} ends with {1}",$CLJS.G([f,a]));case "contains":return $CLJS.sG("{0} contains {1}",$CLJS.G([f,a]));case "does-not-contain":return $CLJS.sG("{0} does not contain {1}",$CLJS.G([f,a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});
$CLJS.j2.m(null,$CLJS.SH,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null),f=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);return $CLJS.sG("{0} is between {1} and {2}",$CLJS.G([$CLJS.M1.v(a,b,e,d),$CLJS.M1.v(a,b,f,d),$CLJS.M1.v(a,b,c,d)]))});
$CLJS.j2.m(null,$CLJS.YH,function(a,b,c,d){$CLJS.H(c,0,null);var e=$CLJS.H(c,1,null),f=$CLJS.H(c,2,null),k=$CLJS.H(c,3,null),l=$CLJS.H(c,4,null),m=$CLJS.H(c,5,null),t=$CLJS.H(c,6,null);c=$CLJS.H(c,7,null);return $CLJS.M1.v(a,b,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.kt,e,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.SH,e,f,t,l],null),new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.SH,e,k,m,c],null)],null),d)});
$CLJS.j2.m(null,I3,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);a=$CLJS.M1.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "is-null":return $CLJS.sG("{0} is empty",$CLJS.G([a]));case "not-null":return $CLJS.sG("{0} is not empty",$CLJS.G([a]));case "is-empty":return $CLJS.sG("{0} is empty",$CLJS.G([a]));case "not-empty":return $CLJS.sG("{0} is not empty",$CLJS.G([a]));case "not":return $CLJS.sG("not {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",
$CLJS.p.h(e)].join(""));}});$CLJS.j2.m(null,$CLJS.CI,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null),f=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);return $CLJS.sG("{0} is within {1}",$CLJS.G([$CLJS.M1.v(a,b,e,d),$CLJS.G3(f,c).toLowerCase()]))});
$CLJS.n4=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var k=Array(arguments.length-2);f<k.length;)k[f]=arguments[f+2],++f;f=new $CLJS.y(k,0,null)}return b.call(this,c,d,f)}function b(c,d,e){return $CLJS.v3($CLJS.Es,$CLJS.ce(c,$CLJS.ce(d,e)))}a.A=2;a.B=function(c){var d=$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.Kc(c);return b(d,e,c)};a.l=b;return a}();
$CLJS.o4=function(){function a(d,e,f){for(;;)if($CLJS.F.g($CLJS.qE(f),$CLJS.RM))f=$CLJS.mY(f);else return e=$CLJS.n(e)?e:-1,f=$CLJS.nY.h(f),$CLJS.IY.l(d,e,$CLJS.Ek,$CLJS.G([$CLJS.eY,$CLJS.eG($CLJS.$d,$CLJS.tf),f]))}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.p4=function(){function a(d,e){return $CLJS.De($CLJS.eY.h($CLJS.BY(d,$CLJS.n(e)?e:-1)))}function b(d){return c.g?c.g(d,null):c.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.q4=function(){function a(d,e){var f=$CLJS.BY(d,e);return $CLJS.De($CLJS.Wf.j($CLJS.tf,$CLJS.Bk.g($CLJS.Ze.h($CLJS.H3),$CLJS.af($CLJS.wN)),$CLJS.u2.j(d,e,f)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.r4=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var k=Array(arguments.length-2);f<k.length;)k[f]=arguments[f+2],++f;f=new $CLJS.y(k,0,null)}return b.call(this,c,d,f)}function b(c,d,e){c=$CLJS.wd(c)?$CLJS.jI.h(c):$CLJS.rh.h(c);return $CLJS.lY($CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[c,$CLJS.N,$CLJS.nY.h(d)],null),$CLJS.Ze.g($CLJS.nY,e)))}a.A=2;a.B=function(c){var d=$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.Kc(c);return b(d,e,c)};a.l=b;return a}();
$CLJS.Qla=function(){function a(d,e,f){var k=$CLJS.H(f,0,null);$CLJS.H(f,1,null);f=$CLJS.H(f,2,null);var l=$CLJS.BY(d,e);l=$CLJS.u2.j(d,e,l);d=$CLJS.n3.v(d,e,f,l);d=$CLJS.CJ(function(m){return $CLJS.F.g($CLJS.jI.h(m),k)},$CLJS.E3(d));return $CLJS.n(d)?d:$CLJS.q3.h(k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;
c.j=a;return c}();
$CLJS.Rla=function(){function a(d,e,f){var k=$CLJS.w_(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.EO,$CLJS.UH],null),f);d=$CLJS.uf($CLJS.p4.g(d,e));e=$CLJS.bf(function(l){return $CLJS.F.g($CLJS.w_(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.EO,$CLJS.UH],null),$CLJS.G_.h(l)),k)},d);if($CLJS.z(e)){if($CLJS.C(e))throw $CLJS.ai("Multiple matching filters found",new $CLJS.h(null,3,[tla,k,ula,d,vla,e],null));return $CLJS.B(e)}return null}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,
e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Sla=function(){function a(d,e,f){f=$CLJS.l0.j(d,e,f);d=$CLJS.q4.g(d,e);return $CLJS.n3.g(f,d)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();