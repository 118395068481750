var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var q7,Ona,Pna,Qna,s7,t7,u7,v7,Rna,Sna,y7,w7,x7,z7,A7,Tna,Una,Vna,B7,Wna,Xna,C7,Yna,Zna;q7=function(a,b){if(b=$CLJS.z(b)){var c=$CLJS.z(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.z(c)?(c=q7($CLJS.J.g(a,b),c),$CLJS.sd(c)?$CLJS.tk.g(a,b):$CLJS.S.j(a,b,c)):$CLJS.tk.g(a,b)}return a};
Ona=function(a,b,c,d){return function t(f,k,l,m){var u=$CLJS.z(k);k=$CLJS.B(u);u=$CLJS.C(u);var v=$CLJS.Rl(f,k);return $CLJS.n(v)?u?$CLJS.S.j(f,k,t($CLJS.zb(v),u,l,m)):$CLJS.S.j(f,k,$CLJS.P.j(l,$CLJS.zb(v),m)):f}(a,b,c,d)};Pna=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return Ona(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.y(b.slice(3),0,null):null)};$CLJS.r7=function(a){return $CLJS.HG.h($CLJS.OG(a))};
Qna=function(a,b,c,d){var e=$CLJS.F.g($CLJS.WR,$CLJS.B(b))?$CLJS.vY(d,$CLJS.uY(c)):d;return Pna(a,b,function(f){return $CLJS.uf(function(){return function m(l){return new $CLJS.le(null,function(){for(;;){var t=$CLJS.z(l);if(t){if($CLJS.zd(t)){var u=$CLJS.jc(t),v=$CLJS.E(u),x=$CLJS.oe(v);a:for(var A=0;;)if(A<v){var D=$CLJS.kd(u,A);D=$CLJS.F.g($CLJS.r7(D),$CLJS.r7(c))?e:D;x.add(D);A+=1}else{u=!0;break a}return u?$CLJS.re($CLJS.te(x),m($CLJS.lc(t))):$CLJS.re($CLJS.te(x),null)}x=$CLJS.B(t);return $CLJS.ce($CLJS.F.g($CLJS.r7(x),
$CLJS.r7(c))?e:x,m($CLJS.Kc(t)))}return null}},null,null)}(f)}())})};
s7=function(a,b,c){var d=$CLJS.OC(a,b);if($CLJS.n(d)){var e=$CLJS.r7(c),f=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B(b),$CLJS.id(b)],null);c=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);d=$CLJS.Wf.j($CLJS.tf,$CLJS.Ck.h($CLJS.Bk.g($CLJS.Vg([e]),$CLJS.r7)),d);d=$CLJS.F.g(b,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.$G],null))&&$CLJS.Ie(function(k){return $CLJS.t4(k,$CLJS.BD)},d)?null:d;if($CLJS.z(d))return $CLJS.RN(a,b,d);if($CLJS.F.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.AP,$CLJS.rN],null),new $CLJS.Q(null,2,5,$CLJS.R,
[c,f],null)))throw $CLJS.ai($CLJS.SG("Cannot remove the final join condition"),new $CLJS.h(null,1,[$CLJS.rN,$CLJS.OC(a,b)],null));return $CLJS.F.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.AP,$CLJS.$G],null),new $CLJS.Q(null,2,5,$CLJS.R,[c,f],null))?$CLJS.oZ.v(a,$CLJS.rd(b),$CLJS.tk,f):q7(a,b)}return a};t7=function(a,b){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.oN);b=0>b?$CLJS.E(a)+b:b;return b+1<$CLJS.E(a)?b+1:null};
u7=function(a,b){var c=$CLJS.U4.g(a,b),d=$CLJS.dh(0,$CLJS.E(c));return $CLJS.Ye.l(new $CLJS.Q(null,6,5,$CLJS.R,[new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.LT],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.eI],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.eY],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.$G],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.fI],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.WR],null)],null),function(){return function k(f){return new $CLJS.le(null,function(){for(var l=f;;)if(l=$CLJS.z(l)){if($CLJS.zd(l)){var m=
$CLJS.jc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),A=$CLJS.bd(c,x);$CLJS.$G.h(A)instanceof $CLJS.M||u.add(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AP,x,$CLJS.$G],null));v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.lc(l))):$CLJS.re($CLJS.te(u),null)}u=$CLJS.B(l);m=$CLJS.bd(c,u);if($CLJS.$G.h(m)instanceof $CLJS.M)l=$CLJS.Kc(l);else return $CLJS.ce(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AP,u,$CLJS.$G],null),k($CLJS.Kc(l)))}else return null},null,null)}(d)}(),$CLJS.G([function(){return function k(f){return new $CLJS.le(null,
function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.zd(l)){var m=$CLJS.jc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);u.add(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AP,x,$CLJS.rN],null));v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.lc(l))):$CLJS.re($CLJS.te(u),null)}u=$CLJS.B(l);return $CLJS.ce(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AP,u,$CLJS.rN],null),k($CLJS.Kc(l)))}return null}},null,null)}(d)}()]))};
v7=function(a,b,c){var d=$CLJS.H(c,0,null),e=$CLJS.H(c,1,null);e=$CLJS.O(e);var f=$CLJS.J.g(e,$CLJS.wI),k=$CLJS.J.g(e,$CLJS.rT),l=$CLJS.H(c,2,null);return $CLJS.B($CLJS.CJ(function(m){$CLJS.H(m,0,null);m=$CLJS.H(m,1,null);$CLJS.H(m,0,null);$CLJS.H(m,1,null);m=$CLJS.H(m,2,null);return $CLJS.F.g($CLJS.B(m),d)&&$CLJS.F.g($CLJS.wI.h($CLJS.hd(m)),f)&&$CLJS.F.g($CLJS.rT.h($CLJS.hd(m)),k)&&$CLJS.F.g($CLJS.id(m),l)},$CLJS.Pl($CLJS.yt,$CLJS.LT.h($CLJS.BY(a,b)))))};
Rna=function(a,b,c,d){c=v7(a,b,c);return $CLJS.n(c)?$CLJS.IY.l(a,b,$CLJS.oZ,$CLJS.G([new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.LT,c,2,1],null),$CLJS.Bk.g(function(e){return $CLJS.UC($CLJS.Ke($CLJS.ll),e)},$CLJS.Tk),d])):a};Sna=function(a,b,c){c=v7(a,b,c);return $CLJS.n(c)?$CLJS.IY.l(a,b,s7,$CLJS.G([new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.LT],null),$CLJS.OC($CLJS.BY(a,b),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.LT,c],null))])):a};
y7=function(a,b,c,d,e,f){var k=$CLJS.IY.l(a,b,f,$CLJS.G([d,e])),l=$CLJS.r7(e);return $CLJS.zk.g(a,k)?$CLJS.B($CLJS.z($CLJS.bf($CLJS.Ta,function(m,t){try{if($CLJS.yd(t)&&1===$CLJS.E(t))try{var u=$CLJS.bd(t,0);if($CLJS.fe(u,$CLJS.WR))return new $CLJS.Q(null,1,5,$CLJS.R,[function(){var K=$CLJS.uY(e);K=w7.W?w7.W(k,b,c,$CLJS.BD,$CLJS.N,K):w7.call(null,k,b,c,$CLJS.BD,$CLJS.N,K);return x7.v?x7.v(K,b,c,l):x7.call(null,K,b,c,l)}()],null);throw $CLJS.oX;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.oX)try{u=
$CLJS.bd(t,0);if($CLJS.fe(u,$CLJS.fI))return new $CLJS.Q(null,1,5,$CLJS.R,[function(){var U=w7.W?w7.W(k,b,c,$CLJS.fI,$CLJS.N,l):w7.call(null,k,b,c,$CLJS.fI,$CLJS.N,l);return x7.v?x7.v(U,b,c,l):x7.call(null,U,b,c,l)}()],null);throw $CLJS.oX;}catch(U){if(U instanceof Error){var v=U;if(v===$CLJS.oX)throw $CLJS.oX;throw v;}throw U;}else throw m;else throw K;}else throw $CLJS.oX;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.oX)try{if($CLJS.yd(t)&&1===$CLJS.E(t))try{var x=$CLJS.bd(t,0);if($CLJS.fe(x,
$CLJS.eI))return new $CLJS.Q(null,1,5,$CLJS.R,[x7.v?x7.v(k,b,c,l):x7.call(null,k,b,c,l)],null);throw $CLJS.oX;}catch(U){if(U instanceof Error)if(v=U,v===$CLJS.oX)try{x=$CLJS.bd(t,0);if($CLJS.fe(x,$CLJS.$G))return new $CLJS.Q(null,1,5,$CLJS.R,[x7.v?x7.v(k,b,c,l):x7.call(null,k,b,c,l)],null);throw $CLJS.oX;}catch(Z){if(Z instanceof Error){var A=Z;if(A===$CLJS.oX)throw $CLJS.oX;throw A;}throw Z;}else throw v;else throw U;}else throw $CLJS.oX;}catch(U){if(U instanceof Error)if(v=U,v===$CLJS.oX)try{if($CLJS.yd(t)&&
3===$CLJS.E(t))try{var D=$CLJS.bd(t,0);if($CLJS.fe(D,$CLJS.AP))try{var I=$CLJS.bd(t,2);if($CLJS.fe(I,$CLJS.$G))return new $CLJS.Q(null,1,5,$CLJS.R,[x7.v?x7.v(k,b,c,l):x7.call(null,k,b,c,l)],null);throw $CLJS.oX;}catch(Z){if(Z instanceof Error){A=Z;if(A===$CLJS.oX)throw $CLJS.oX;throw A;}throw Z;}else throw $CLJS.oX;}catch(Z){if(Z instanceof Error){A=Z;if(A===$CLJS.oX)throw $CLJS.oX;throw A;}throw Z;}else throw $CLJS.oX;}catch(Z){if(Z instanceof Error){A=Z;if(A===$CLJS.oX)return new $CLJS.Q(null,1,
5,$CLJS.R,[k],null);throw A;}throw Z;}else throw v;else throw U;}else throw m;else throw K;}}($CLJS.tf,d)))):k};
w7=function(a,b,c,d,e,f){var k=$CLJS.BY(a,b),l=$CLJS.$e(function(m){var t=$CLJS.OC(k,m);return $CLJS.n(t)?$CLJS.Ol(function(u){return $CLJS.B($CLJS.z($CLJS.bf($CLJS.Ta,function D(x,A){try{if($CLJS.yd(A)&&3===$CLJS.E(A))try{var I=$CLJS.bd(A,0);if($CLJS.F.g(I,d))try{var K=$CLJS.bd(A,1);if($CLJS.sd(e)||$CLJS.z2($CLJS.Yg(e),$CLJS.Yg(K)))try{var U=$CLJS.bd(A,2);if($CLJS.F.g(U,f))return new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[m,u],null)],null);throw $CLJS.oX;}catch(da){if(da instanceof
Error){var Z=da;if(Z===$CLJS.oX)throw $CLJS.oX;throw Z;}throw da;}else throw $CLJS.oX;}catch(da){if(da instanceof Error){Z=da;if(Z===$CLJS.oX)throw $CLJS.oX;throw Z;}throw da;}else throw $CLJS.oX;}catch(da){if(da instanceof Error){Z=da;if(Z===$CLJS.oX)throw $CLJS.oX;throw Z;}throw da;}else throw $CLJS.oX;}catch(da){if(da instanceof Error){Z=da;if(Z===$CLJS.oX)return $CLJS.xX(D,x,A);throw Z;}throw da;}}($CLJS.tf,u))))},t):null},$CLJS.G([u7(a,b)]));return $CLJS.ab(function(m,t){var u=$CLJS.H(t,0,null);
t=$CLJS.H(t,1,null);return y7(m,b,c,u,t,s7)},a,l)};x7=function(a,b,c,d){b=t7(c,b);if($CLJS.n(b)){var e=$CLJS.BY(c,b);c=$CLJS.Je(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.PM),l=$CLJS.J.g(f,$CLJS.h2);return $CLJS.F.g($CLJS.p0,k)&&$CLJS.F.g(d,l)?$CLJS.C0.h(f):null},$CLJS.u2.j(c,b,e));return $CLJS.n(c)?w7(a,b,a,$CLJS.qI,$CLJS.N,c):a}return a};
z7=function(a,b,c,d,e){var f=$CLJS.nY.h(c),k=$CLJS.BY(a,b);c=$CLJS.CJ(function(t){var u=$CLJS.OC(k,t);if($CLJS.n(u)){var v=$CLJS.r7(f);return $CLJS.n($CLJS.Je($CLJS.Bk.j($CLJS.Vg([v]),$CLJS.HG,$CLJS.hd),u))?t:null}return null},u7(a,b));var l=(d=$CLJS.F.g($CLJS.PI,d))?$CLJS.nY.h(e):null;e=d?function(t,u,v){return Qna(t,u,v,l)}:s7;var m=$CLJS.F.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.eI],null),c);a=d&&m&&$CLJS.F.g($CLJS.B(f),$CLJS.B(l))&&$CLJS.F.g($CLJS.id(f),$CLJS.id(l))?Rna(a,b,f,$CLJS.Ql($CLJS.hd(l),
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rT,$CLJS.wI],null))):m?Sna(a,b,f):a;return $CLJS.n(c)?y7(a,b,a,c,f,e):a};A7=function(a,b){return $CLJS.t4(a,$CLJS.qI)&&$CLJS.F.g($CLJS.K2(a),b)};Tna=function(a,b,c){return function k(e,f){try{if(A7(f,b))return $CLJS.y4(f,c);throw $CLJS.oX;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.oX)return $CLJS.RY(k,e,f);throw l;}throw m;}}($CLJS.tf,a)};
Una=function(a,b,c){var d=$CLJS.AP.h(a),e=-1<b&&b<$CLJS.E(d)?new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.OC(d,new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.dH],null))],null):null;b=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.zk.g(e,c):b)){var f=$CLJS.EY();$CLJS.s4(f,$CLJS.Ze.g($CLJS.dH,d));c=f(c);return Tna($CLJS.RN(a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AP,b,$CLJS.dH],null),c),e,c)}return a};
Vna=function(a,b,c){if($CLJS.Ed(c))return c;var d=function(){var e=$CLJS.Vg([c]);return"string"===typeof c?$CLJS.Bk.g(e,$CLJS.dH):e}();return $CLJS.Je(function(e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.Pl($CLJS.yt,$CLJS.AP.h($CLJS.BY(a,b))))};
B7=function(a,b,c,d){b=$CLJS.wv.g($CLJS.Yg($CLJS.u2.j(b,c,$CLJS.BY(b,c))),$CLJS.Yg($CLJS.u2.j(a,c,$CLJS.BY(a,c))));return $CLJS.ab(function(e,f){return $CLJS.P.N(w7,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};Wna=function(a,b,c){a=B7(a,b,c,function(d){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qI,new $CLJS.h(null,1,[$CLJS.qR,$CLJS.R0.h(d)],null),$CLJS.Oi.h(d)],null)});c=t7(a,c);return $CLJS.n(c)?B7(a,b,c,function(d){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qI,$CLJS.N,$CLJS.C0.h(d)],null)}):a};
Xna=function(a,b,c){return $CLJS.Ed(c)?$CLJS.OC($CLJS.BY(a,b),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AP,c,$CLJS.dH],null)):$CLJS.wd(c)?$CLJS.dH.h(c):c};C7=function(a,b,c,d){var e=Xna(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.IY)(a,b,function(k){var l=$CLJS.AP.h(k);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.NG(k,$CLJS.AP,l)});return(0,$CLJS.IY)(f,b,function(k){return $CLJS.MY(k,$CLJS.AP,function(l){return $CLJS.Dk.g(function(m){return $CLJS.G4(f,b,m)},l)})})}(),Wna(c,a,b)):a};
Yna=function(a,b){return null!=$CLJS.B($CLJS.z($CLJS.bf($CLJS.Ta,function f(d,e){try{if(A7(e,b))return new $CLJS.Q(null,1,5,$CLJS.R,[e],null);throw $CLJS.oX;}catch(l){if(l instanceof Error){var k=l;if(k===$CLJS.oX)return $CLJS.xX(f,d,e);throw k;}throw l;}}($CLJS.tf,a))))};Zna=new $CLJS.M(null,"remove","remove",-131428414);$CLJS.$na=function(){function a(d,e,f){return $CLJS.wd(f)&&$CLJS.F.g($CLJS.lv.h(f),$CLJS.jN)?$CLJS.D7.j?$CLJS.D7.j(d,e,f):$CLJS.D7.call(null,d,e,f):z7(d,e,f,Zna,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.F7=function(){function a(d,e,f,k){return $CLJS.wd(f)&&$CLJS.F.g($CLJS.lv.h(f),$CLJS.jN)?$CLJS.E7.v?$CLJS.E7.v(d,e,f,k):$CLJS.E7.call(null,d,e,f,k):z7(d,e,f,$CLJS.PI,k)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.aoa=function(){function a(d,e,f,k){f=Vna(d,e,f);return $CLJS.n(f)?$CLJS.IY.l(d,e,Una,$CLJS.G([f,k])):d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.D7=function(){function a(d,e,f){return C7(d,e,f,function(k,l){return $CLJS.De($CLJS.t3(function(m){return!($CLJS.F.g($CLJS.dH.h(m),l)||Yna(m,l))},k))})}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.E7=function(){function a(d,e,f,k){return null==k?$CLJS.D7.j(d,e,f):C7(d,e,f,function(l,m){return $CLJS.Dk.g(function(t){return $CLJS.F.g($CLJS.dH.h(t),m)?k:t},l)})}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();