var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var s6,t6,w6,x6,z6,Ana,B6,C6,E6,G6,I6,J6,Bna,K6,P6,Q6,T6,V6,W6,Z6,b7,c7,d7,f7;s6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.common","metabase.lib.schema.drill-thru/drill-thru.common",1573044895);t6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.pk","metabase.lib.schema.drill-thru/drill-thru.pk",339574481);$CLJS.u6=new $CLJS.M("drill-thru","quick-filter","drill-thru/quick-filter",-1465756422);$CLJS.v6=new $CLJS.M(null,"row","row",-570139521);
w6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.fk-details.fk-column","metabase.lib.schema.drill-thru/drill-thru.fk-details.fk-column",-380335831);x6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.distribution","metabase.lib.schema.drill-thru/drill-thru.distribution",937770403);$CLJS.y6=new $CLJS.M(null,"pivots","pivots",90109371);z6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.pivot","metabase.lib.schema.drill-thru/drill-thru.pivot",-1408664484);
Ana=new $CLJS.M("metabase.lib.schema.drill-thru","context","metabase.lib.schema.drill-thru/context",122725764);$CLJS.A6=new $CLJS.M(null,"location","location",1815599388);B6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.column-filter","metabase.lib.schema.drill-thru/drill-thru.column-filter",603928893);C6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.summarize-column-by-time","metabase.lib.schema.drill-thru/drill-thru.summarize-column-by-time",1590982743);
$CLJS.D6=new $CLJS.M(null,"initial-op","initial-op",918033121);E6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.summarize-column","metabase.lib.schema.drill-thru/drill-thru.summarize-column",1770935578);$CLJS.F6=new $CLJS.M("drill-thru","column-filter","drill-thru/column-filter",1535293733);G6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.automatic-insights","metabase.lib.schema.drill-thru/drill-thru.automatic-insights",671448614);
$CLJS.H6=new $CLJS.M("drill-thru","fk-filter","drill-thru/fk-filter",1598101197);I6=new $CLJS.M("drill-thru","automatic-insights","drill-thru/automatic-insights",10252211);J6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.zoom-in.timeseries.next-unit","metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries.next-unit",-2018118498);Bna=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru","metabase.lib.schema.drill-thru/drill-thru",-124537581);
K6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.object-details","metabase.lib.schema.drill-thru/drill-thru.object-details",-725614444);$CLJS.L6=new $CLJS.M("drill-thru","sort","drill-thru/sort",511059541);$CLJS.M6=new $CLJS.M("drill-thru","summarize-column","drill-thru/summarize-column",-156285576);$CLJS.N6=new $CLJS.M(null,"sort-directions","sort-directions",300459345);$CLJS.O6=new $CLJS.M(null,"aggregations","aggregations",-1081114338);
P6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.fk-details","metabase.lib.schema.drill-thru/drill-thru.fk-details",233147406);Q6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.quick-filter","metabase.lib.schema.drill-thru/drill-thru.quick-filter",-1703184103);$CLJS.R6=new $CLJS.M(null,"row-count","row-count",1060167988);$CLJS.S6=new $CLJS.M("drill-thru","distribution","drill-thru/distribution",1480876450);
T6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.quick-filter.operator","metabase.lib.schema.drill-thru/drill-thru.quick-filter.operator",-1269703311);$CLJS.U6=new $CLJS.M("drill-thru","underlying-records","drill-thru/underlying-records",-1420917644);V6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.fk-filter","metabase.lib.schema.drill-thru/drill-thru.fk-filter",-1219793148);
W6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.zoom","metabase.lib.schema.drill-thru/drill-thru.zoom",-262809778);$CLJS.X6=new $CLJS.M(null,"next-unit","next-unit",166270362);$CLJS.Y6=new $CLJS.M("drill-thru","summarize-column-by-time","drill-thru/summarize-column-by-time",-765720821);Z6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.zoom-in.timeseries","metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries",-890057316);
$CLJS.$6=new $CLJS.M("drill-thru","pivot","drill-thru/pivot",-1038336574);$CLJS.a7=new $CLJS.M("drill-thru","zoom-in.timeseries","drill-thru/zoom-in.timeseries",-168450975);b7=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.underlying-records","metabase.lib.schema.drill-thru/drill-thru.underlying-records",1571614324);c7=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.sort","metabase.lib.schema.drill-thru/drill-thru.sort",-920673199);
d7=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.summarize-column.aggregation-type","metabase.lib.schema.drill-thru/drill-thru.summarize-column.aggregation-type",1395206778);$CLJS.e7=new $CLJS.M("drill-thru","zoom","drill-thru/zoom",-1212878631);f7=new $CLJS.M("metabase.lib.schema.drill-thru","pivot-types","metabase.lib.schema.drill-thru/pivot-types",1174694312);$CLJS.Y(f7,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ak,$CLJS.KI,$CLJS.A6,$CLJS.VA],null));$CLJS.Y(s6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,$CLJS.ee],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lv,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.AN],null)],null)],null));
$CLJS.Y(K6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,s6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uN,$CLJS.vt],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tN,$CLJS.ut],null)],null)],null));
$CLJS.Y(t6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,K6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.xN],null)],null)],null)],null));$CLJS.Y(w6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.D0,$CLJS.fN],null)],null)],null));
$CLJS.Y(P6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,K6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.yN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,w6],null)],null)],null)],null));
$CLJS.Y(W6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,K6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.e7],null)],null)],null)],null));$CLJS.Y(T6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.BG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UH,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eH],null)],null)],null));
$CLJS.Y(Q6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,s6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.u6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wN,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pj,T6],null)],null)],null)],null));
$CLJS.Y(V6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,s6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.H6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UH,$CLJS.eH],null)],null)],null));
$CLJS.Y(x6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,s6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.S6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null)],null)],null)],null));
$CLJS.Y(z6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,s6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.$6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.y6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,f7,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null)],null)],null)],null)],null)],null));
$CLJS.Y(c7,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,s6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.L6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.N6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pj,$CLJS.vN],null)],null)],null)],null));$CLJS.Y(d7,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ak,$CLJS.kI,$CLJS.VH,$CLJS.DI],null));
$CLJS.Y(E6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,s6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.M6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.O6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,d7],null)],null)],null)],null)],null));
$CLJS.Y(C6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,s6,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.Y6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.eI,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ki,$CLJS.mJ],null)],null)],null));
$CLJS.Y(B6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,s6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.F6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.D6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.RL],null)],null)],null)],null));
$CLJS.Y(b7,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,s6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.U6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.R6,$CLJS.ml],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.F0,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.Va],null)],null)],null)],null));
$CLJS.Y(G6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,s6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,I6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lv,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.AN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null)],null)],null)],null));
$CLJS.Y(J6,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ak,$CLJS.hi,$CLJS.nj,$CLJS.ej,$CLJS.nv,$CLJS.mv,$CLJS.kv],null));
$CLJS.Y(Z6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,s6,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.a7],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tj,$CLJS.Ta],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,J6],null)],null)],null)],null));
$CLJS.Y(Bna,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,s6,new $CLJS.Q(null,16,5,$CLJS.R,[$CLJS.jt,new $CLJS.h(null,2,[$CLJS.Gi,$CLJS.cj,$CLJS.uu,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.tj);return["Invalid drill thru (unknown :type): ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xN,t6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yN,P6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.e7,W6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.u6,Q6],null),new $CLJS.Q(null,2,5,$CLJS.R,
[$CLJS.H6,V6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.S6,x6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$6,z6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.L6,c7],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.M6,E6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Y6,C6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.F6,B6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.U6,b7],null),new $CLJS.Q(null,2,5,$CLJS.R,[I6,G6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.a7,Z6],null)],null)],null));
$CLJS.Y(Ana,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.vt],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.v6,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aI,$CLJS.Va],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tj,
$CLJS.vt],null)],null)],null)],null)],null));