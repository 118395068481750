var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var Bca,zG,CG,MG;$CLJS.rG=function(a){return $CLJS.Bk.g($CLJS.Ze.h(a),$CLJS.DE)};$CLJS.sG=function(a,b){a=$CLJS.$u($CLJS.RC(a,/''/,"'"),/\{\d+\}/);return $CLJS.P.j($CLJS.xu.t,$CLJS.Wk(a),$CLJS.Wk(b))};$CLJS.tG=new $CLJS.M("lib","metadata","lib/metadata",1798917220);$CLJS.uG=new $CLJS.M("metabase.lib.schema.common","positive-int","metabase.lib.schema.common/positive-int",-733946956);$CLJS.vG=new $CLJS.M(null,"num-bins","num-bins",847672055);$CLJS.wG=new $CLJS.M(null,"mbql","mbql",69346710);
$CLJS.xG=new $CLJS.M(null,"display-name","display-name",694513143);Bca=new $CLJS.M("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.yG=new $CLJS.M("metabase.lib.schema.common","semantic-or-relation-type","metabase.lib.schema.common/semantic-or-relation-type",-158604052);zG=new $CLJS.M("metabase.lib.schema.common","relation-type","metabase.lib.schema.common/relation-type",1412287664);$CLJS.AG=new $CLJS.M("lib","external-op","lib/external-op",-1470923877);
$CLJS.BG=new $CLJS.M("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);CG=new $CLJS.M("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);$CLJS.DG=new $CLJS.M(null,"database-type","database-type",-426840562);$CLJS.EG=new $CLJS.M(null,"operator","operator",-1860875338);
$CLJS.FG=new $CLJS.M("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.GG=new $CLJS.M("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);$CLJS.HG=new $CLJS.M("lib","uuid","lib/uuid",-2145250720);$CLJS.IG=new $CLJS.M(null,"x","x",2099068185);$CLJS.JG=new $CLJS.M("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);
$CLJS.KG=new $CLJS.M(null,"bin-width","bin-width",1377922579);$CLJS.LG=new $CLJS.M(null,"strategy","strategy",-1471631918);MG=new $CLJS.M("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.Y($CLJS.BG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Oj,new $CLJS.h(null,1,[$CLJS.Jn,1],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.at,new $CLJS.h(null,1,[$CLJS.su,"non-blank string"],null),$CLJS.Ke($CLJS.SC)],null)],null));$CLJS.Y($CLJS.FG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,1,[$CLJS.Jn,0],null)],null));$CLJS.Y($CLJS.uG,$CLJS.Al);$CLJS.Y(MG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Oj,new $CLJS.h(null,2,[$CLJS.Jn,36,$CLJS.Xj,36],null)],null));
$CLJS.Y(CG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.at,new $CLJS.h(null,2,[$CLJS.su,"valid semantic type",$CLJS.uu,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.tj);return["Not a valid semantic type: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.EE(a,$CLJS.ij)}],null));
$CLJS.Y(zG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.at,new $CLJS.h(null,2,[$CLJS.su,"valid relation type",$CLJS.uu,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.tj);return["Not a valid relation type: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.EE(a,$CLJS.fi)}],null));$CLJS.Y($CLJS.yG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,CG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,zG],null)],null));
$CLJS.Y($CLJS.JG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.at,new $CLJS.h(null,2,[$CLJS.su,"valid base type",$CLJS.uu,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.tj);return["Not a valid base type: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.EE(a,$CLJS.qj)}],null));
$CLJS.Y($CLJS.GG,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.HG,MG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ED,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.JG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.JG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Kj,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,
$CLJS.yG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DG,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.BG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.BG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xG,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.BG],null)],null)],null));
$CLJS.Y(Bca,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lv,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.AG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.EG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,$CLJS.Oj,$CLJS.Mi],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pj,$CLJS.vt],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),$CLJS.GG],null)],null));