var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.registry.js");
'use strict';var EX,GX,HX,cia,LX,MX,NX,eia,OX,PX,QX,SX,TX,UX,VX,WX,XX,ZX,$X,aY,bY,cY,fia,fY,gY,dia;EX=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.Ck.h(function(b){var c=$CLJS.H(b,0,null);$CLJS.H(b,1,null);return $CLJS.ke(c)}),a)};
$CLJS.FX=function(a){var b=2>$CLJS.E(a);return b?b:$CLJS.P.g($CLJS.KE,function(){return function e(d){return new $CLJS.le(null,function(){for(;;){var f=$CLJS.z(d);if(f){var k=f;if($CLJS.zd(k)){var l=$CLJS.jc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v);$CLJS.se(t,$CLJS.QG(x,function(){return function(A){return $CLJS.tk.l(EX(A),$CLJS.ED,$CLJS.G([$CLJS.xi]))}}(v,x,l,m,t,k,f,b)));v+=1}else return!0}()?$CLJS.re($CLJS.te(t),e($CLJS.lc(k))):$CLJS.re($CLJS.te(t),
null)}var u=$CLJS.B(k);return $CLJS.ce($CLJS.QG(u,function(){return function(v){return $CLJS.tk.l(EX(v),$CLJS.ED,$CLJS.G([$CLJS.xi]))}}(u,k,f,b)),e($CLJS.Kc(k)))}return null}},null,null)}(a)}())};
GX=function(a,b){a=new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tf,a],null)],null);for(var c=$CLJS.tf;;){var d=$CLJS.qd(a);if($CLJS.n(d)){var e=d,f=$CLJS.H(e,0,null),k=$CLJS.H(e,1,null),l=e,m=$CLJS.rd(a);a=function(t,u,v,x,A,D,I,K){return function(U){return $CLJS.Wf.j(v,$CLJS.Ze.h(function(Z,da,xa,Ha,mb){return function(Qb){var Ab=$CLJS.H(Qb,0,null);Qb=$CLJS.H(Qb,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$d.g(mb,Ab),Qb],null)}}(t,u,v,x,A,D,I,K)),U)}}(a,c,m,e,f,k,l,d);
$CLJS.n(b.h?b.h(k):b.call(null,k))?(a=m,c=$CLJS.$d.g(c,l)):a=$CLJS.wd(k)?a(k):$CLJS.vd(k)?a($CLJS.Pl($CLJS.yt,k)):m}else return c}};HX=function(a,b,c){return $CLJS.yd(c)&&$CLJS.F.g(a,$CLJS.B(c))&&!$CLJS.Gd(b,$CLJS.J.g(c,2))};$CLJS.IX=function(a){var b=$CLJS.Wf.j($CLJS.Ug,$CLJS.Ze.h($CLJS.Bk.g($CLJS.hH,$CLJS.hd)),$CLJS.WR.h(a));return GX($CLJS.tk.l(a,$CLJS.AP,$CLJS.G([$CLJS.jX])),function(c){return HX($CLJS.BD,b,c)})};
$CLJS.JX=function(a){var b=$CLJS.Wf.j($CLJS.Ug,$CLJS.Ze.h($CLJS.Bk.g($CLJS.HG,$CLJS.hd)),$CLJS.fI.h(a));return GX($CLJS.tk.l(a,$CLJS.AP,$CLJS.G([$CLJS.jX])),function(c){return HX($CLJS.fI,b,c)})};cia=function(a){function b(d){return $CLJS.$e(c,$CLJS.G([$CLJS.AP.h(d)]))}function c(d){return $CLJS.ce($CLJS.dH.h(d),$CLJS.$e(b,$CLJS.G([$CLJS.oN.h(d)])))}return $CLJS.n($CLJS.KX.h(a))?$CLJS.Le(!0):$CLJS.Yg(b(a))};
LX=function(a){if($CLJS.vd(a))for(var b=$CLJS.Le(!1),c=$CLJS.z(a),d=$CLJS.B(c),e=$CLJS.C(c),f=b,k=0,l=a;;){var m=f,t=k,u=l,v=$CLJS.z(u),x=$CLJS.B(v),A=$CLJS.C(v),D=x,I=A,K=$CLJS.NC(m,cia(D));f=$CLJS.B($CLJS.z($CLJS.bf($CLJS.Ta,function(U,Z,da,xa,Ha,mb){return function Tb(Ab,kc){try{if($CLJS.yd(kc)&&3===$CLJS.E(kc))try{var rc=$CLJS.bd(kc,0);if($CLJS.fe(rc,$CLJS.qI))try{var Vb=$CLJS.bd(kc,1);if($CLJS.n($CLJS.qR.h(Vb)))try{var Rb=$CLJS.bd(kc,1);if(null!=Rb?Rb.C&256||$CLJS.r===Rb.kf||(Rb.C?0:$CLJS.Wa($CLJS.rb,
Rb)):$CLJS.Wa($CLJS.rb,Rb))try{var Vd=$CLJS.J.j(Rb,$CLJS.qR,$CLJS.zX);if($CLJS.n($CLJS.Ke(xa)(Vd))){var Bw=$CLJS.J.g(Rb,$CLJS.qR);$CLJS.bd(kc,2);return new $CLJS.Q(null,1,5,$CLJS.R,[["Invalid :field reference in stage ",$CLJS.p.h(mb),": no join named ",$CLJS.Eh.l($CLJS.G([Bw]))].join("")],null)}throw $CLJS.oX;}catch(mg){if(mg instanceof Error){var Qi=mg;if(Qi===$CLJS.oX)throw $CLJS.oX;throw Qi;}throw mg;}else throw $CLJS.oX;}catch(mg){if(mg instanceof Error){Qi=mg;if(Qi===$CLJS.oX)throw $CLJS.oX;
throw Qi;}throw mg;}else throw $CLJS.oX;}catch(mg){if(mg instanceof Error){Qi=mg;if(Qi===$CLJS.oX)throw $CLJS.oX;throw Qi;}throw mg;}else throw $CLJS.oX;}catch(mg){if(mg instanceof Error){Qi=mg;if(Qi===$CLJS.oX)throw $CLJS.oX;throw Qi;}throw mg;}else throw $CLJS.oX;}catch(mg){if(mg instanceof Error){Qi=mg;if(Qi===$CLJS.oX)return $CLJS.xX(Tb,Ab,kc);throw Qi;}throw mg;}}}(f,k,l,K,m,t,u,v,x,A,D,I,b,0,a,a,c,d,e,d,e)($CLJS.tf,$CLJS.tk.l(D,$CLJS.AP,$CLJS.G([dia]))))));if($CLJS.n(f))return f;if($CLJS.z(I))k=
t+1,f=K,l=I;else return null}else return null};MX=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);NX=new $CLJS.w("metabase.lib.schema.util","distinct-refs?","metabase.lib.schema.util/distinct-refs?",-262561159,null);eia=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);OX=new $CLJS.w(null,"distinct-refs?","distinct-refs?",-2065255505,null);
PX=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);QX=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.RX=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);SX=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);
TX=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);UX=new $CLJS.w(null,"refs","refs",80480079,null);VX=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);WX=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);XX=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);
$CLJS.YX=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);ZX=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);$X=new $CLJS.M("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);aY=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);bY=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);
cY=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.dY=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.KX=new $CLJS.M(null,"source-card","source-card",-1580820390);fia=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);$CLJS.eY=new $CLJS.M(null,"filters","filters",974726919);fY=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);
gY=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);$CLJS.hY=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);dia=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.Y(VX,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lv,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.YX],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gS,$CLJS.Ta],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Pt,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pj,$CLJS.ol],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.iQ,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),$CLJS.BG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DS,new $CLJS.h(null,1,[$CLJS.Bt,
!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.bX],null)],null)],null));$CLJS.Y($X,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.uI],null));
$CLJS.Y(MX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pj,new $CLJS.h(null,1,[$CLJS.Jn,1],null),$X],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.at,new $CLJS.h(null,1,[$CLJS.su,"Breakouts must be distinct"],null),new $CLJS.Fc(function(){return $CLJS.FX},NX,$CLJS.Ag([$CLJS.Li,$CLJS.T,$CLJS.dk,$CLJS.ni,$CLJS.Ki,$CLJS.dj,$CLJS.kk,$CLJS.mi,$CLJS.V,$CLJS.ck],[$CLJS.nX,OX,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.W(new $CLJS.Q(null,1,5,$CLJS.R,[UX],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",
$CLJS.n($CLJS.FX)?$CLJS.FX.H:null]))],null)],null));
$CLJS.Y(aY,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pj,new $CLJS.h(null,1,[$CLJS.Jn,1],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.uI],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.at,new $CLJS.h(null,1,[$CLJS.su,":fields must be distinct"],null),new $CLJS.Fc(function(){return $CLJS.FX},NX,$CLJS.Ag([$CLJS.Li,$CLJS.T,$CLJS.dk,$CLJS.ni,$CLJS.Ki,$CLJS.dj,$CLJS.kk,$CLJS.mi,$CLJS.V,$CLJS.ck],[$CLJS.nX,OX,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.W(new $CLJS.Q(null,
1,5,$CLJS.R,[UX],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",$CLJS.n($CLJS.FX)?$CLJS.FX.H:null]))],null)],null));$CLJS.Y(bY,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eH],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.OH],null),$CLJS.Wj,$CLJS.Oj],null)],null));
$CLJS.Y(ZX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pj,new $CLJS.h(null,1,[$CLJS.Jn,1],null),bY],null));
var iY=$CLJS.NC(function(a){a=$CLJS.B($CLJS.IX(a));if($CLJS.n(a)){var b=$CLJS.OC(a,new $CLJS.Q(null,2,5,$CLJS.R,[1,2],null));return $CLJS.n(b)?["Invalid :expression reference: no expression named ",$CLJS.Eh.l($CLJS.G([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null},function(a){a=$CLJS.B($CLJS.JX(a));if($CLJS.n(a)){var b=$CLJS.OC(a,new $CLJS.Q(null,2,5,$CLJS.R,[1,2],null));return $CLJS.n(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.h(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null});$CLJS.Y(cY,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.at,new $CLJS.h(null,2,[$CLJS.su,"Valid references for a single query stage",$CLJS.uu,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.tj);return iY(a)}],null),$CLJS.Ke(iY)],null));
$CLJS.Y($CLJS.hY,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lv,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.RX],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AP,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mN],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.WR,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.bH],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.eI,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),MX],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fI,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.TI],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$G,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),aY],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.eY,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),ZX],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LT,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.zN],null)],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bR,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SM],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.KX,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XM],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.at,new $CLJS.h(null,1,[$CLJS.su,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Gd(a,$CLJS.FS)}],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.at,
new $CLJS.h(null,1,[$CLJS.su,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.Ke($CLJS.KN($CLJS.bR,$CLJS.KX))],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,cY],null)],null));$CLJS.Y(PX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.hY],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bR,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SM],null)],null)],null)],null));
$CLJS.Y(QX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.hY],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.KX,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XM],null)],null)],null)],null));$CLJS.Y(TX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,PX],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,QX],null)],null));
$CLJS.Y(WX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.hY],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.at,new $CLJS.h(null,1,[$CLJS.su,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.Ke($CLJS.NC($CLJS.bR,$CLJS.KX))],null)],null));$CLJS.Y(XX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ak,$CLJS.YX,$CLJS.RX],null));
$CLJS.Y(eia,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lv,XX],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.jt,new $CLJS.h(null,1,[$CLJS.Gi,$CLJS.lv],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.YX,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,VX],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RX,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.hY],null)],null)],null)],null));
$CLJS.Y(gY,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lv,XX],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.jt,new $CLJS.h(null,1,[$CLJS.Gi,$CLJS.lv],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.YX,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,VX],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RX,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,TX],null)],null)],null)],null));$CLJS.Y(SX,WX);
$CLJS.Y(fY,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.at,new $CLJS.h(null,2,[$CLJS.su,"Valid references for all query stages",$CLJS.uu,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.tj);return LX.h?LX.h(a):LX.call(null,a)}],null),$CLJS.Ke(LX)],null));
$CLJS.Y($CLJS.kN,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Rs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,gY],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Is,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,SX],null)],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,fY],null)],null));
$CLJS.Y(fia,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lv,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.dY],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RR,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,$CLJS.YM,$CLJS.cN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.oN,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.kN],null)],null)],null),$CLJS.Wha],null));