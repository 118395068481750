var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var TB=function(){},UB=function(a){return $CLJS.J.g($CLJS.IA,a).value},dba=function(a){var b=UB(a);return $CLJS.n(function(){var c=UB($CLJS.ZA);return $CLJS.wk.g?$CLJS.wk.g(c,b):$CLJS.wk.call(null,c,b)}())?$CLJS.EB:$CLJS.n(function(){var c=UB($CLJS.YA);return $CLJS.wk.g?$CLJS.wk.g(c,b):$CLJS.wk.call(null,c,b)}())?$CLJS.OB:$CLJS.n(function(){var c=UB($CLJS.WA);return $CLJS.wk.g?$CLJS.wk.g(c,b):$CLJS.wk.call(null,c,b)}())?$CLJS.HB:$CLJS.n(function(){var c=UB($CLJS.QA);return $CLJS.wk.g?
$CLJS.wk.g(c,b):$CLJS.wk.call(null,c,b)}())?$CLJS.NB:$CLJS.n(function(){var c=UB($CLJS.XA);return $CLJS.wk.g?$CLJS.wk.g(c,b):$CLJS.wk.call(null,c,b)}())?$CLJS.PB:$CLJS.n(function(){var c=UB($CLJS.TA);return $CLJS.wk.g?$CLJS.wk.g(c,b):$CLJS.wk.call(null,c,b)}())?$CLJS.KB:$CLJS.n(function(){var c=UB($CLJS.NA);return $CLJS.wk.g?$CLJS.wk.g(c,b):$CLJS.wk.call(null,c,b)}())?$CLJS.LB:$CLJS.MB},VB=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.p.h(b),$CLJS.p.h(" ")].join(""),
a],null)},WB=function(a,b,c){var d=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.$d.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.SB,c))].join(""),$CLJS.G(["color:black"]))],null)},XB=function(a,b,c){var d=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.$d.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.SB,$CLJS.QB)),";background-color:",$CLJS.p.h($CLJS.J.g($CLJS.SB,
c))].join(""),$CLJS.G(["color:black;background-color:inherit"]))],null)},eba=function(a){function b(c,d){return d>=c}a=UB(a);if(b(UB($CLJS.ZA),a))return"error";if(b(UB($CLJS.YA),a))return"warn";if(b(UB($CLJS.WA),a))return"info";b(UB($CLJS.QA),a);return"log"},YB=function(a){return function(b){var c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.OA),e=$CLJS.J.g(c,$CLJS.Nu);b=$CLJS.J.g(c,$CLJS.$A);e=eba(e);e=$CLJS.Ca(console,e);e=$CLJS.n(e)?e:console.log;$CLJS.P.g(e,a.h?a.h(c):a.call(null,c));return $CLJS.n(b)?(c=["[",
$CLJS.p.h(d),"]"].join(""),d=$CLJS.p.h(b),b=b.stack,e.v?e.v(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},ZB=function ZB(a,b){for(;;){if($CLJS.F.g($CLJS.DB,b))return WB(a,", ",$CLJS.MB);if($CLJS.F.g($CLJS.FB,b))return VB(a);if(b instanceof $CLJS.M)return WB(a,b,$CLJS.HB);if(b instanceof $CLJS.w)return WB(a,b,$CLJS.NB);if("string"===typeof b)return WB(a,$CLJS.Eh.l($CLJS.G([b])),$CLJS.GB);if($CLJS.qf(b)){var d=VB(function(){var f=a,k=$CLJS.yb(b);return ZB.g?ZB.g(f,k):ZB.call(null,f,k)}()),e=$CLJS.zb(b);
return ZB.g?ZB.g(d,e):ZB.call(null,d,e)}if(b instanceof $CLJS.h||b instanceof $CLJS.zg)return d=a,d=WB(d,"{",$CLJS.RB),d=$CLJS.ab(ZB,d,$CLJS.Xe($CLJS.DB,b)),WB(d,"}",$CLJS.RB);if($CLJS.wd(b))return d=a,d=WB(d,["#",$CLJS.p.h(function(){var f=$CLJS.Xa(b),k=f.name;return $CLJS.sd(k)?$CLJS.Eh.l($CLJS.G([f])):k}())," "].join(""),$CLJS.IB),d=WB(d,"{",$CLJS.RB),d=$CLJS.ab(ZB,d,$CLJS.Xe($CLJS.DB,b)),WB(d,"}",$CLJS.RB);if($CLJS.ud(b))return d=a,d=WB(d,"#{",$CLJS.RB),d=$CLJS.ab(ZB,d,$CLJS.Xe($CLJS.FB,b)),WB(d,
"}",$CLJS.RB);if($CLJS.yd(b))return d=a,d=WB(d,"[",$CLJS.RB),d=$CLJS.ab(ZB,d,$CLJS.Xe($CLJS.FB,b)),WB(d,"]",$CLJS.RB);if(b instanceof $CLJS.If)d=WB(a,"#queue ",$CLJS.IB),e=$CLJS.Wf.g($CLJS.tf,b),a=d,b=e;else{if($CLJS.Cd(b))return d=a,d=WB(d,"(",$CLJS.IB),d=$CLJS.ab(ZB,d,$CLJS.Xe($CLJS.FB,b)),WB(d,")",$CLJS.IB);if(null!=b?b.I&16384||$CLJS.r===b.Ui||(b.I?0:$CLJS.Wa(TB,b)):$CLJS.Wa(TB,b))d=WB(a,"#atom ",$CLJS.IB),e=$CLJS.q(b),a=d,b=e;else if($CLJS.Yh(b))d=WB(a,"#uuid ",$CLJS.IB),e=$CLJS.p.h(b),a=d,b=
e;else if($CLJS.Ua(b))d=WB(a,"#js ",$CLJS.IB),e=$CLJS.ab(function(f,k){return function(l,m){return $CLJS.S.j(l,$CLJS.rh.h(m),$CLJS.Ca(k,m))}}(a,b),$CLJS.N,Object.keys(b)),a=d,b=e;else if($CLJS.Ra(b))d=WB(a,"#js ",$CLJS.IB),e=$CLJS.Wf.g($CLJS.tf,b),a=d,b=e;else return WB(a,$CLJS.Eh.l($CLJS.G([b])),$CLJS.JB)}}};$CLJS.fba=YB(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Nu);var b=$CLJS.J.g(a,$CLJS.OA),c=$CLJS.J.g(a,$CLJS.Ii);$CLJS.J.g(a,$CLJS.$A);return new $CLJS.Q(null,2,5,$CLJS.R,[["[",$CLJS.p.h(b),"]"].join(""),c],null)});
$CLJS.gba=YB(function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Nu);a=$CLJS.J.g(b,$CLJS.OA);var d=$CLJS.J.g(b,$CLJS.Ii);$CLJS.J.g(b,$CLJS.$A);b=dba(c);d=ZB(VB(XB(XB(XB(new $CLJS.Q(null,2,5,$CLJS.R,["",$CLJS.tf],null),"[",b),a,b),"]",b)),d);a=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.ce(a,d)});
$CLJS.hba=YB(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Nu);var b=$CLJS.J.g(a,$CLJS.OA),c=$CLJS.J.g(a,$CLJS.Ii);$CLJS.J.g(a,$CLJS.$A);return new $CLJS.Q(null,2,5,$CLJS.R,[["[",$CLJS.p.h(b),"]"].join(""),$CLJS.Eh.l($CLJS.G([c]))],null)});