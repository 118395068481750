var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var Kca,Lca,Mca,Nca,Oca,Pca,Qca,WI,Rca,Sca,Tca,Uca,Vca,Wca,Xca,Yca,YI,Zca;$CLJS.SI=new $CLJS.M(null,"supported-field","supported-field",-2061545519);Kca=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.TI=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Lca=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Mca=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.UI=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);Nca=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.VI=new $CLJS.M(null,"display-info","display-info",-816930907);Oca=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);Pca=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
Qca=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);WI=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);Rca=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);Sca=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);Tca=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);Uca=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);
Vca=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);Wca=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);Xca=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.XI=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);Yca=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);YI=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.ZI=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);Zca=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.IH($CLJS.gA,$CLJS.G([$CLJS.iu,$CLJS.mj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.BD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.fH],null)],null)],null)],null)]));$CLJS.IH($CLJS.rI,$CLJS.G([$CLJS.iu,$CLJS.mj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.BD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.fH],null)],null)],null)],null)]));
$CLJS.GH($CLJS.kI,$CLJS.G([$CLJS.iu,$CLJS.$F,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null)],null)]));$CLJS.GH($CLJS.VH,$CLJS.G([$CLJS.iu,$CLJS.mj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.fH],null)],null)]));$CLJS.GH($CLJS.zI,$CLJS.G([$CLJS.iu,$CLJS.mj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eH],null)],null)]));
$CLJS.GH($CLJS.Xj,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mH],null)],null)]));$CLJS.zH($CLJS.Xj,$CLJS.lH);$CLJS.GH($CLJS.EI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null)],null)]));$CLJS.zH($CLJS.EI,$CLJS.lH);$CLJS.GH($CLJS.Jn,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mH],null)],null)]));$CLJS.zH($CLJS.Jn,$CLJS.lH);
$CLJS.Y(WI,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.su,"valid percentile"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.at,new $CLJS.h(null,1,[$CLJS.su,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.GH($CLJS.bI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,WI],null)]));$CLJS.zH($CLJS.bI,$CLJS.lH);
$CLJS.GH($CLJS.xI,$CLJS.G([$CLJS.iu,$CLJS.$F,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eH],null)],null)]));$CLJS.GH($CLJS.GI,$CLJS.G([$CLJS.iu,$CLJS.$F,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null)],null)]));$CLJS.GH($CLJS.DI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null)],null)]));
$CLJS.GH($CLJS.NI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null)],null)]));$CLJS.zH($CLJS.DI,$CLJS.lH);$CLJS.zH($CLJS.NI,$CLJS.lH);$CLJS.GH($CLJS.LI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eH],null)],null)]));$CLJS.zH($CLJS.LI,$CLJS.lH);
$CLJS.GH($CLJS.WH,$CLJS.G([$CLJS.iu,$CLJS.$F,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null)],null)]));$CLJS.Y(YI,new $CLJS.Q(null,17,5,$CLJS.R,[$CLJS.dt,Yca,Wca,Pca,Lca,Kca,Oca,Qca,Rca,Sca,Vca,Tca,Zca,Xca,Mca,Uca,$CLJS.ol],null));$CLJS.Y($CLJS.TI,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pj,new $CLJS.h(null,1,[$CLJS.Jn,1],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,YI],null)],null));
$CLJS.$I=new $CLJS.Q(null,10,5,$CLJS.R,[new $CLJS.h(null,4,[$CLJS.jI,$CLJS.gA,$CLJS.UI,!1,$CLJS.XI,$CLJS.LH,$CLJS.VI,function(){return new $CLJS.h(null,3,[$CLJS.xG,$CLJS.SG("Count of rows"),$CLJS.aI,$CLJS.SG("Count"),$CLJS.Pu,$CLJS.SG("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.jI,$CLJS.DI,$CLJS.SI,$CLJS.Uj,$CLJS.UI,!0,$CLJS.XI,$CLJS.LH,$CLJS.VI,function(){return new $CLJS.h(null,3,[$CLJS.xG,$CLJS.SG("Sum of ..."),$CLJS.aI,$CLJS.SG("Sum"),$CLJS.Pu,$CLJS.SG("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.jI,$CLJS.kI,$CLJS.SI,$CLJS.Uj,$CLJS.UI,!0,$CLJS.XI,$CLJS.LH,$CLJS.VI,function(){return new $CLJS.h(null,3,[$CLJS.xG,$CLJS.SG("Average of ..."),$CLJS.aI,$CLJS.SG("Average"),$CLJS.Pu,$CLJS.SG("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.jI,$CLJS.EI,$CLJS.SI,$CLJS.Uj,$CLJS.UI,!0,$CLJS.XI,$CLJS.mI,$CLJS.VI,function(){return new $CLJS.h(null,3,[$CLJS.xG,$CLJS.SG("Median of ..."),$CLJS.aI,$CLJS.SG("Median"),$CLJS.Pu,$CLJS.SG("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.jI,$CLJS.VH,$CLJS.SI,$CLJS.vt,$CLJS.UI,!0,$CLJS.XI,$CLJS.LH,$CLJS.VI,function(){return new $CLJS.h(null,3,[$CLJS.xG,$CLJS.SG("Number of distinct values of ..."),$CLJS.aI,$CLJS.SG("Distinct values"),$CLJS.Pu,$CLJS.SG("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.jI,$CLJS.NI,$CLJS.SI,$CLJS.Uj,$CLJS.UI,!0,$CLJS.XI,$CLJS.LH,$CLJS.VI,function(){return new $CLJS.h(null,3,[$CLJS.xG,$CLJS.SG("Cumulative sum of ..."),
$CLJS.aI,$CLJS.SG("Sum"),$CLJS.Pu,$CLJS.SG("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.jI,$CLJS.rI,$CLJS.UI,!1,$CLJS.XI,$CLJS.LH,$CLJS.VI,function(){return new $CLJS.h(null,3,[$CLJS.xG,$CLJS.SG("Cumulative count of rows"),$CLJS.aI,$CLJS.SG("Count"),$CLJS.Pu,$CLJS.SG("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.jI,$CLJS.GI,$CLJS.SI,$CLJS.Uj,$CLJS.UI,
!0,$CLJS.XI,$CLJS.AI,$CLJS.VI,function(){return new $CLJS.h(null,3,[$CLJS.xG,$CLJS.SG("Standard deviation of ..."),$CLJS.aI,$CLJS.SG("SD"),$CLJS.Pu,$CLJS.SG("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.jI,$CLJS.Jn,$CLJS.SI,$CLJS.Ni,$CLJS.UI,!0,$CLJS.XI,$CLJS.LH,$CLJS.VI,function(){return new $CLJS.h(null,3,[$CLJS.xG,$CLJS.SG("Minimum of ..."),$CLJS.aI,$CLJS.SG("Min"),$CLJS.Pu,$CLJS.SG("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.jI,$CLJS.Xj,$CLJS.SI,$CLJS.Ni,$CLJS.UI,!0,$CLJS.XI,$CLJS.LH,$CLJS.VI,function(){return new $CLJS.h(null,3,[$CLJS.xG,$CLJS.SG("Maximum of ..."),$CLJS.aI,$CLJS.SG("Max"),$CLJS.Pu,$CLJS.SG("Maximum value of a column")],null)}],null)],null);
$CLJS.Y(Nca,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lv,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.ZI],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jI,$CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.ak],null),$CLJS.Ze.h($CLJS.jI),$CLJS.$I)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.SI,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,$CLJS.Mi],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UI,$CLJS.ut],null),new $CLJS.Q(null,2,5,
$CLJS.R,[$CLJS.XI,$CLJS.Mi],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.VI,$CLJS.md],null)],null));