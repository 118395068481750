var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var TG,YG,Cca,Dca,Eca,Fca,Gca,Hca;
$CLJS.RG=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.vC([$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-4",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(b),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),
$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a())].join("").toLowerCase())};$CLJS.SG=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.sG(arguments[0],1<b.length?new $CLJS.y(b.slice(1),0,null):null)};TG=function(a){return $CLJS.yd(a)&&$CLJS.B(a)instanceof $CLJS.M};
$CLJS.VG=function(a){var b=function(){var c=TG(a);return c?(c=$CLJS.wd($CLJS.hd(a)))?(c=$CLJS.xi.h($CLJS.hd(a)),$CLJS.n(c)?c:$CLJS.ED.h($CLJS.hd(a))):c:c}();return $CLJS.n(b)?b:$CLJS.UG.h(a)};
YG=function(a,b){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.at,new $CLJS.h(null,2,[$CLJS.su,"valid MBQL clause",$CLJS.uu,function(c){c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.tj);return["invalid MBQL clause: ",$CLJS.Eh.l($CLJS.G([c]))].join("")}],null),$CLJS.Ke(TG)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.WG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.at,new $CLJS.h(null,1,[$CLJS.su,b],null),function(c){c=$CLJS.VG(c);
return XG(c,a)}],null)],null)};$CLJS.ZG=new $CLJS.M(null,"right-join","right-join",-56349359);Cca=new $CLJS.M("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);Dca=new $CLJS.M("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);Eca=new $CLJS.M("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.$G=new $CLJS.M(null,"fields","fields",-1932066230);
$CLJS.aH=new $CLJS.M(null,"expr","expr",745722291);$CLJS.bH=new $CLJS.M("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.cH=new $CLJS.M("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.dH=new $CLJS.M(null,"alias","alias",-2039751630);$CLJS.eH=new $CLJS.M("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);
$CLJS.fH=new $CLJS.M("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.gH=new $CLJS.M("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.hH=new $CLJS.M("lib","expression-name","lib/expression-name",-1799326590);$CLJS.iH=new $CLJS.M("mbql.clause","field","mbql.clause/field",1497292735);Fca=new $CLJS.M("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);
$CLJS.jH=new $CLJS.M(null,"left-join","left-join",-672831855);$CLJS.kH=new $CLJS.M("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.lH=new $CLJS.M("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.mH=new $CLJS.M("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);
Gca=new $CLJS.w("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);Hca=new $CLJS.M("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.nH=new $CLJS.M(null,"inner-join","inner-join",659431740);$CLJS.oH=new $CLJS.M("metabase.lib.schema.expression","emptyable","metabase.lib.schema.expression/emptyable",941775581);
$CLJS.WG=new $CLJS.M("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);$CLJS.pH=new $CLJS.M("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.qH=new $CLJS.M(null,"full-join","full-join",1305476385);$CLJS.rH=new $CLJS.M("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);
$CLJS.sH=new $CLJS.M("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.UG=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Yi,$CLJS.sE],null),$CLJS.Yi,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.schema.expression","type-of-method"),function(f){var k=$CLJS.qE(f);return $CLJS.F.g(k,$CLJS.DD)?$CLJS.Xa(f):k},e,a,b,c,d)}();
$CLJS.Y(Dca,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.gH],null),$CLJS.JG],null));$CLJS.UG.m(null,$CLJS.Wh,function(a){throw $CLJS.ai($CLJS.sG("{0}: Don''t know how to determine the type of {1}",$CLJS.G([Gca,$CLJS.Eh.l($CLJS.G([a]))])),new $CLJS.h(null,1,[$CLJS.aH,a],null));});$CLJS.UG.m(null,$CLJS.lH,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.VG(a)});
var XG=function XG(a,b){return $CLJS.ud(a)?$CLJS.Je(function(d){return XG.g?XG.g(d,b):XG.call(null,d,b)},a):$CLJS.ud(b)?$CLJS.Je(function(d){return XG.g?XG.g(a,d):XG.call(null,a,d)},b):$CLJS.F.g(a,$CLJS.gH)?!0:$CLJS.EE(a,b)};$CLJS.Y($CLJS.eH,YG($CLJS.Aj,"expression returning a boolean"));$CLJS.Y($CLJS.pH,YG($CLJS.Sj,"expression returning a string"));$CLJS.Y($CLJS.sH,YG($CLJS.mj,"expression returning an integer"));$CLJS.Y(Eca,YG($CLJS.$F,"expression returning a non-integer real number"));
$CLJS.Y($CLJS.rH,YG($CLJS.Ej,"expression returning a number"));$CLJS.Y(Fca,YG($CLJS.eF,"expression returning a date"));$CLJS.Y(Hca,YG($CLJS.cG,"expression returning a time"));$CLJS.Y(Cca,YG($CLJS.DF,"expression returning a date time"));$CLJS.Y($CLJS.cH,YG($CLJS.Lj,"expression returning a date, time, or date time"));$CLJS.tH=new $CLJS.Tg(null,new $CLJS.h(null,3,[$CLJS.Ej,null,$CLJS.Sj,null,$CLJS.Lj,null],null),null);$CLJS.Y($CLJS.mH,YG($CLJS.tH,"an expression that can be compared with :\x3e or :\x3c"));
var Ica=new $CLJS.Tg(null,new $CLJS.h(null,7,[$CLJS.OE,null,$CLJS.Ej,null,$CLJS.Sj,null,$CLJS.uF,null,$CLJS.Lj,null,$CLJS.Aj,null,$CLJS.YF,null],null),null);$CLJS.Y($CLJS.oH,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pH],null),YG($CLJS.uF,"expression returning a BSONID")],null));$CLJS.Y($CLJS.kH,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,YG(Ica,"an expression that can appear in :\x3d or :!\x3d")],null));
$CLJS.Y($CLJS.fH,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,YG($CLJS.qj,"any type of expression")],null));
$CLJS.Y($CLJS.bH,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pj,new $CLJS.h(null,1,[$CLJS.Jn,1],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.fH],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Rs,$CLJS.vt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hH,$CLJS.Oj],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Is,$CLJS.vt],null)],null)],null)],null));