var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var O7=function(a){return $CLJS.n(N7)?["(",$CLJS.p.h(a),")"].join(""):a},roa=function(a,b,c){var d=$CLJS.BY(a,b);a=$CLJS.u2.j(a,b,d);var e=$CLJS.lD(c);return $CLJS.Je(function(f){return $CLJS.F.g($CLJS.lD($CLJS.T.h(f)),e)},a)},soa=function(a,b){var c=$CLJS.Ek.v(a,$CLJS.WR,$CLJS.eG($CLJS.$d,$CLJS.tf),b);return $CLJS.yd($CLJS.$G.h(a))?$CLJS.Ek.v(c,$CLJS.$G,$CLJS.$d,$CLJS.lY(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BD,$CLJS.N,$CLJS.uY(b)],null))):c},toa=function(a,b,c){var d=$CLJS.uY(c);return $CLJS.S.l($CLJS.L1.j(a,
b,c),$CLJS.PM,$CLJS.w0,$CLJS.G([$CLJS.T,d,$CLJS.xG,d]))},P7=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);var uoa=function(){function a(d,e,f){var k=$CLJS.BY(d,e);k=$CLJS.CJ($CLJS.Bk.g($CLJS.Vg([f]),$CLJS.uY),$CLJS.WR.h(k));if($CLJS.n(k))return k;throw $CLJS.ai($CLJS.sG("No expression named {0}",$CLJS.G([$CLJS.Eh.l($CLJS.G([f]))])),new $CLJS.h(null,3,[$CLJS.XT,f,$CLJS.EO,d,$CLJS.FY,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.n2.m(null,$CLJS.BD,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);c=uoa.j(a,b,c);return $CLJS.o2.j(a,b,c)});$CLJS.p2.m(null,$CLJS.BD,function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null),e=$CLJS.H(c,2,null);return new $CLJS.h(null,7,[$CLJS.lv,$CLJS.NM,$CLJS.h2,$CLJS.HG.h(d),$CLJS.T,e,$CLJS.hH,e,$CLJS.xG,$CLJS.M1.j(a,b,c),$CLJS.ED,$CLJS.o2.j(a,b,c),$CLJS.PM,$CLJS.w0],null)});$CLJS.j2.m(null,$CLJS.wD,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.j2.m(null,$CLJS.uD,function(a,b,c){return $CLJS.p.h(c)});$CLJS.j2.m(null,$CLJS.vD,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.j2.m(null,$CLJS.BD,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);return $CLJS.H(c,2,null)});$CLJS.k2.m(null,$CLJS.BD,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);return $CLJS.H(c,2,null)});
for(var N7=!1,voa=new $CLJS.h(null,4,[$CLJS.Hs,"+",$CLJS.iu,"-",$CLJS.Is,"×",$CLJS.MI,"÷"],null),Q7=$CLJS.z(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Hs,$CLJS.iu,$CLJS.MI,$CLJS.Is],null)),R7=null,S7=0,T7=0;;)if(T7<S7){var woa=R7.X(null,T7);$CLJS.zH(woa,P7);T7+=1}else{var U7=$CLJS.z(Q7);if(U7){var V7=U7;if($CLJS.zd(V7)){var W7=$CLJS.jc(V7),xoa=$CLJS.lc(V7),yoa=W7,zoa=$CLJS.E(W7);Q7=xoa;R7=yoa;S7=zoa}else{var Aoa=$CLJS.B(V7);$CLJS.zH(Aoa,P7);Q7=$CLJS.C(V7);R7=null;S7=0}T7=0}else break}
$CLJS.j2.m(null,P7,function(a,b,c){var d=$CLJS.z(c);c=$CLJS.B(d);d=$CLJS.C(d);$CLJS.B(d);d=$CLJS.C(d);c=$CLJS.J.g(voa,c);a:{var e=N7;N7=!0;try{var f=$CLJS.Du([" ",$CLJS.Zg(c)," "].join(""),$CLJS.Ze.g($CLJS.pv($CLJS.M1,a,b),d));break a}finally{N7=e}f=void 0}return O7(f)});$CLJS.k2.m(null,P7,function(){return"expression"});
$CLJS.n2.m(null,$CLJS.IJ,function(a,b,c){c=$CLJS.z(c);$CLJS.B(c);c=$CLJS.C(c);$CLJS.B(c);var d=$CLJS.C(c);return $CLJS.Od($CLJS.BH,function(){return function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.zd(l)){var m=$CLJS.jc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=$CLJS.o2.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.lc(l))):$CLJS.re($CLJS.te(u),null)}u=$CLJS.B(l);return $CLJS.ce($CLJS.o2.j(a,
b,u),k($CLJS.Kc(l)))}return null}},null,null)}(d)}())});$CLJS.j2.m(null,$CLJS.iL,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null),f=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);a=$CLJS.p.h($CLJS.M1.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.z3.g(f,c).toLowerCase();0<f?f=$CLJS.ha.j?$CLJS.ha.j("+ %d %s",f,c):$CLJS.ha.call(null,"+ %d %s",f,c):(f=Math.abs(f),f=$CLJS.ha.j?$CLJS.ha.j("- %d %s",f,c):$CLJS.ha.call(null,"- %d %s",f,c));f=O7(f);return[a," ",d.call(b,f)].join("")});
$CLJS.k2.m(null,$CLJS.iL,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null),e=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);a=$CLJS.p.h($CLJS.l2.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.z3.g(e,c).toLowerCase();0<e?e=$CLJS.ha.j?$CLJS.ha.j("plus_%s_%s",e,c):$CLJS.ha.call(null,"plus_%s_%s",e,c):(e=Math.abs(e),e=$CLJS.ha.j?$CLJS.ha.j("minus_%d_%s",e,c):$CLJS.ha.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.j2.m(null,$CLJS.tI,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return $CLJS.M1.v(a,b,e,d)});$CLJS.k2.m(null,$CLJS.tI,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return $CLJS.l2.j(a,b,d)});
$CLJS.Boa=function(){function a(d,e,f,k){e=$CLJS.n(e)?e:-1;if($CLJS.n(roa(d,e,f)))throw $CLJS.ai("Expression name conflicts with a column in the same query stage",new $CLJS.h(null,1,[$CLJS.XT,f],null));return $CLJS.IY.l(d,e,soa,$CLJS.G([$CLJS.vY($CLJS.nY.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.v=a;return c}();$CLJS.Coa=function(){function a(d,e){var f=$CLJS.De($CLJS.WR.h($CLJS.BY(d,e)));return null==f?null:$CLJS.Dk.g($CLJS.pv(toa,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.X7=function(){function a(d,e){return $CLJS.De($CLJS.WR.h($CLJS.BY(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.kY.m(null,$CLJS.BD,function(a){return a});
$CLJS.Doa=function(){function a(d,e,f){var k=$CLJS.Wf.g($CLJS.N,$CLJS.Pl(function(m,t){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uY(t),m],null)},$CLJS.X7.g(d,e))),l=$CLJS.BY(d,e);d=$CLJS.u2.j(d,e,l);return $CLJS.De($CLJS.t3(function(m){return $CLJS.Sa(f)||$CLJS.zk.g($CLJS.PM.h(m),$CLJS.w0)||$CLJS.J.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();