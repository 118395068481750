var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var OK,QK,SK,dL,hL,pL;$CLJS.NK=new $CLJS.M(null,"get-month","get-month",-369374731);OK=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.PK=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);QK=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.RK=new $CLJS.M(null,"iso","iso",-1366207543);SK=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.TK=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.UK=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.VK=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.WK=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.XK=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.YK=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.ZK=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.$K=new $CLJS.M(null,"us","us",746429226);$CLJS.aL=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.bL=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.cL=new $CLJS.M(null,"now","now",-1650525531);dL=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.eL=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.fL=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.gL=new $CLJS.M(null,"get-week","get-week",-888059349);
hL=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.iL=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.jL=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.kL=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.lL=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.mL=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.nL=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.oL=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);pL=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.qL=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.rL=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.sL=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.tL=new $CLJS.M(null,"target","target",253001721);$CLJS.GH($CLJS.ZK,$CLJS.G([$CLJS.iu,$CLJS.pF,$CLJS.qt,$CLJS.kJ]));$CLJS.UG.m(null,$CLJS.WK,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=$CLJS.VG(a);return $CLJS.ud(a)?(a=$CLJS.xv.g(a,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.eF,null,$CLJS.DF,null],null),null)),$CLJS.F.g($CLJS.E(a),1)?$CLJS.B(a):a):a});$CLJS.zH($CLJS.WK,$CLJS.lH);
for(var uL=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iL,$CLJS.oL],null)),vL=null,wL=0,xL=0;;)if(xL<wL){var yL=vL.X(null,xL);$CLJS.GH(yL,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cH],null),$CLJS.qt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.kJ],null)]));$CLJS.zH(yL,$CLJS.WK);xL+=1}else{var zL=$CLJS.z(uL);if(zL){var AL=zL;if($CLJS.zd(AL)){var BL=$CLJS.jc(AL),fea=$CLJS.lc(AL),gea=BL,hea=$CLJS.E(BL);uL=fea;vL=gea;wL=hea}else{var CL=$CLJS.B(AL);$CLJS.GH(CL,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,
[$CLJS.X,$CLJS.cH],null),$CLJS.qt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.kJ],null)]));$CLJS.zH(CL,$CLJS.WK);uL=$CLJS.C(AL);vL=null;wL=0}xL=0}else break}
for(var DL=$CLJS.z(new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.UK,$CLJS.NK,$CLJS.fL,$CLJS.nL,$CLJS.jL,$CLJS.TK,$CLJS.qL],null)),EL=null,FL=0,GL=0;;)if(GL<FL){var iea=EL.X(null,GL);$CLJS.GH(iea,$CLJS.G([$CLJS.iu,$CLJS.mj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cH],null)],null)]));GL+=1}else{var HL=$CLJS.z(DL);if(HL){var IL=HL;if($CLJS.zd(IL)){var JL=$CLJS.jc(IL),jea=$CLJS.lc(IL),kea=JL,lea=$CLJS.E(JL);DL=jea;EL=kea;FL=lea}else{var mea=$CLJS.B(IL);$CLJS.GH(mea,$CLJS.G([$CLJS.iu,
$CLJS.mj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cH],null)],null)]));DL=$CLJS.C(IL);EL=null;FL=0}GL=0}else break}$CLJS.GH($CLJS.YK,$CLJS.G([$CLJS.iu,$CLJS.mj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cH],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cH],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.hJ],null)]));
for(var KL=$CLJS.z(new $CLJS.Tg(null,new $CLJS.h(null,8,[$CLJS.qL,null,$CLJS.jL,null,$CLJS.nL,null,$CLJS.kL,null,$CLJS.TK,null,$CLJS.NK,null,$CLJS.UK,null,$CLJS.fL,null],null),null)),LL=null,ML=0,NL=0;;)if(NL<ML){var nea=LL.X(null,NL);$CLJS.GH(nea,$CLJS.G([$CLJS.iu,$CLJS.mj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cH],null)],null)]));NL+=1}else{var OL=$CLJS.z(KL);if(OL){var PL=OL;if($CLJS.zd(PL)){var QL=$CLJS.jc(PL),oea=$CLJS.lc(PL),pea=QL,qea=$CLJS.E(QL);
KL=oea;LL=pea;ML=qea}else{var rea=$CLJS.B(PL);$CLJS.GH(rea,$CLJS.G([$CLJS.iu,$CLJS.mj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cH],null)],null)]));KL=$CLJS.C(PL);LL=null;ML=0}NL=0}else break}$CLJS.Y(pL,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ak,$CLJS.RK,$CLJS.$K,$CLJS.eL],null));
$CLJS.IH($CLJS.gL,$CLJS.G([$CLJS.iu,$CLJS.mj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bL,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cH],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,pL],null)],null)],null)],null)]));
$CLJS.Y($CLJS.lL,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,$CLJS.BG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ak,new $CLJS.h(null,2,[$CLJS.su,"valid timezone ID",$CLJS.uu,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.tj);return["invalid timezone ID: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null)],null),$CLJS.bJ($CLJS.Hd,$CLJS.MK.tz.names())),$CLJS.yK],null)],null));
$CLJS.IH($CLJS.PK,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bL,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cH],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tL,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.lL],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.oC,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.lL],null)],null)],null)],
null)]));$CLJS.zH($CLJS.PK,$CLJS.WK);$CLJS.GH($CLJS.cL,$CLJS.G([$CLJS.iu,$CLJS.hF]));$CLJS.Y(SK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.JG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.at,new $CLJS.h(null,1,[$CLJS.su,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Je(function(b){return $CLJS.EE(a,b)},new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.eF,$CLJS.DF],null))}],null)],null));
$CLJS.Y(OK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.GG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ED,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,SK],null)],null)],null)],null));
$CLJS.RI.g($CLJS.VK,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Rs,new $CLJS.h(null,1,[$CLJS.su,"valid :absolute-datetime clause"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.VK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,OK],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ts,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Rs,new $CLJS.h(null,1,[$CLJS.su,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,
4,5,$CLJS.R,[$CLJS.dt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.CK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.EK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.wK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.Wh],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.jJ],null)],null)],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Rs,new $CLJS.h(null,1,[$CLJS.su,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.tx],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.GK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.Wh],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eJ],null)],null)],null)],null)],null)],null));
$CLJS.UG.m(null,$CLJS.VK,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);a=$CLJS.F.g(b,$CLJS.tx)?$CLJS.F.g(a,$CLJS.Wh)?$CLJS.DF:$CLJS.n($CLJS.yH($CLJS.jJ,a))?$CLJS.eF:$CLJS.DF:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.fh($CLJS.KK,b))?$CLJS.eF:$CLJS.n($CLJS.fh($CLJS.LK,b))?$CLJS.eF:null:null;if($CLJS.n(a))return a;b=$CLJS.VG(b);b=$CLJS.ud(b)?$CLJS.Wf.j($CLJS.Ug,$CLJS.af(function(c){return $CLJS.EE(c,$CLJS.Lj)}),b):b;return $CLJS.ud(b)&&
$CLJS.F.g($CLJS.E(b),1)?$CLJS.B(b):b});$CLJS.Y(hL,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.tx],null),$CLJS.qt],null));
$CLJS.IH($CLJS.aL,$CLJS.G([$CLJS.iu,$CLJS.DF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ky,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,hL],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.kJ],null)],null)],null)],null)]));
$CLJS.GH($CLJS.VA,$CLJS.G([$CLJS.iu,$CLJS.cG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pH],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.iJ],null)]));$CLJS.Y(QK,new $CLJS.Q(null,12,5,$CLJS.R,[$CLJS.ak,$CLJS.gJ,$CLJS.si,$CLJS.Zj,$CLJS.XK,$CLJS.rL,$CLJS.sL,$CLJS.vj,$CLJS.yi,$CLJS.oi,$CLJS.Zi,$CLJS.dJ],null));$CLJS.Y(dL,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ak,$CLJS.RK,$CLJS.$K,$CLJS.eL],null));
$CLJS.IH($CLJS.mL,$CLJS.G([$CLJS.iu,$CLJS.mj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bL,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cH],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,QK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,dL],null)],null)],
null)],null)]));