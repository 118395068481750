var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var ZW,$W,aX,cX,dX,eX,fX,gX,hX,iX;ZW=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);$W=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);aX=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.bX=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);cX=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);dX=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);eX=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
fX=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);gX=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);hX=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);iX=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.Y(ZW,$CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ak,new $CLJS.h(null,1,[$CLJS.su,"Valid template tag :widget-type"],null),$CLJS.tC],null),$CLJS.Pg($CLJS.UW)));$CLJS.Y(cX,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ak,$CLJS.CO,$CLJS.sP,$CLJS.fO,$CLJS.bv,$CLJS.ID,$CLJS.yR],null));
$CLJS.Y(fX,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.BG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xG,$CLJS.BG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Oi,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,$CLJS.BG,$CLJS.tt],null)],null)],null));
$CLJS.Y(gX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,fX],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),$CLJS.ol],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UP,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),$CLJS.ut],null)],null)],null));
$CLJS.Y(dX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,gX],null),new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.fO],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fO,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.iH],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NP,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,ZW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Bt,!0],
null),$CLJS.Wj],null)],null)],null));
$CLJS.Y(eX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,fX],null),new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.CO],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.oO,$CLJS.BG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QR,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),$CLJS.eN],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.RR,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),$CLJS.YM],null)],null)],null));
$CLJS.Y(iX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,fX],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.sP],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$S,$CLJS.XM],null)],null)],null));$CLJS.Y(hX,$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.ak],null),$CLJS.IW));
$CLJS.Y($W,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,gX],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,hX],null)],null)],null)],null));
$CLJS.Y(aX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,cX],null)],null)],null),new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.jt,new $CLJS.h(null,1,[$CLJS.Gi,$CLJS.cj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fO,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,dX],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.CO,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,eX],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sP,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,iX],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ko,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$W],null)],null)],null)],null));
$CLJS.Y($CLJS.bX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,$CLJS.BG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,aX],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.at,new $CLJS.h(null,1,[$CLJS.su,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Ie(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return $CLJS.F.g(c,$CLJS.T.h(b))},a)}],null)],null));