var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var $ca,ada,bda,cda,dda,eda,fda,gda,hda;$ca=function(a,b){a.sort(b||$CLJS.Da)};ada=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Da;$ca(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.aJ=function(a){return $CLJS.F.g(a,$CLJS.Hd)?$CLJS.Hd:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.bJ=function(a,b){if($CLJS.z(b)){var c=$CLJS.ue.h?$CLJS.ue.h(b):$CLJS.ue.call(null,b);ada(c,$CLJS.aJ(a));return $CLJS.od($CLJS.z(c),$CLJS.pd(b))}return $CLJS.Jc};$CLJS.cJ=new $CLJS.M(null,"second","second",-444702010);$CLJS.dJ=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);
$CLJS.eJ=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.fJ=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.gJ=new $CLJS.M(null,"year-of-era","year-of-era",682445876);bda=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
cda=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.hJ=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.iJ=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);
$CLJS.jJ=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);dda=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);eda=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);
fda=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);gda=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.kJ=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);
$CLJS.lJ=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.mJ=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);$CLJS.nJ=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
hda=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var oJ=new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.yi,$CLJS.vj,$CLJS.uj,$CLJS.ii,$CLJS.Zj,$CLJS.si,$CLJS.ci,$CLJS.gJ],null),pJ=$CLJS.Yg(oJ),ida,jda,kda;$CLJS.Y(fda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ak,new $CLJS.h(null,1,[$CLJS.su,"Valid date extraction unit"],null)],null),pJ));var qJ=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.nv,$CLJS.ej,$CLJS.nj,$CLJS.hi,$CLJS.ci],null),rJ=$CLJS.Yg(qJ);
$CLJS.Y(eda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ak,new $CLJS.h(null,1,[$CLJS.su,"Valid date truncation unit"],null)],null),rJ));$CLJS.sJ=$CLJS.Wf.j($CLJS.tf,$CLJS.Vk.o(),$CLJS.Ye.g(qJ,oJ));ida=$CLJS.Yg($CLJS.sJ);$CLJS.Y($CLJS.jJ,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ak,new $CLJS.h(null,1,[$CLJS.su,"Valid date bucketing unit"],null)],null),ida));var tJ=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dJ,$CLJS.Zi,$CLJS.oi],null),uJ=$CLJS.Yg(tJ);
$CLJS.Y(dda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ak,new $CLJS.h(null,1,[$CLJS.su,"Valid time extraction unit"],null)],null),uJ));var vJ=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.fJ,$CLJS.cJ,$CLJS.kv,$CLJS.mv],null),wJ=$CLJS.Yg(vJ);$CLJS.Y(hda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ak,new $CLJS.h(null,1,[$CLJS.su,"Valid time truncation unit"],null)],null),wJ));$CLJS.xJ=$CLJS.Wf.j($CLJS.tf,$CLJS.Vk.o(),$CLJS.Ye.g(vJ,tJ));jda=$CLJS.Yg($CLJS.xJ);
$CLJS.Y(cda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ak,new $CLJS.h(null,1,[$CLJS.su,"Valid time bucketing unit"],null)],null),jda));$CLJS.yJ=$CLJS.Wf.j($CLJS.tf,$CLJS.Vk.o(),$CLJS.Ye.l(vJ,qJ,$CLJS.G([tJ,oJ])));kda=$CLJS.Yg($CLJS.yJ);$CLJS.Y($CLJS.eJ,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ak,new $CLJS.h(null,1,[$CLJS.su,"Valid datetime bucketing unit"],null)],null),$CLJS.yJ));var lda=$CLJS.$d.g(kda,$CLJS.Wh);
$CLJS.Y($CLJS.mJ,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ak,new $CLJS.h(null,1,[$CLJS.su,"Valid temporal bucketing unit"],null)],null),lda));var mda=$CLJS.ov.g(rJ,wJ);$CLJS.Y($CLJS.hJ,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ak,new $CLJS.h(null,1,[$CLJS.su,"Valid datetime truncation unit"],null)],null),mda));$CLJS.zJ=$CLJS.ov.g(pJ,uJ);$CLJS.Y(bda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ak,new $CLJS.h(null,1,[$CLJS.su,"Valid datetime extraction unit"],null)],null),$CLJS.zJ));
var AJ=$CLJS.$d.g(rJ,$CLJS.ci);$CLJS.Y($CLJS.nJ,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ak,new $CLJS.h(null,1,[$CLJS.su,"Valid date interval unit"],null)],null),AJ));$CLJS.Y($CLJS.iJ,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ak,new $CLJS.h(null,1,[$CLJS.su,"Valid time interval unit"],null)],null),wJ));var nda=$CLJS.ov.g(AJ,wJ);$CLJS.Y($CLJS.kJ,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ak,new $CLJS.h(null,1,[$CLJS.su,"Valid datetime interval unit"],null)],null),nda));
$CLJS.Y(gda,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lv,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.lJ],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ki,$CLJS.mJ],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Bt,!0],null),$CLJS.ut],null)],null));