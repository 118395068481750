var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.util.malli.registry.js");
'use strict';var uK,vK,Uda,Vda,Wda,zK,AK,BK,DK,Xda,FK;uK=function(a){switch(arguments.length){case 2:return $CLJS.yH(arguments[0],arguments[1]);case 3:return $CLJS.xH(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};vK=function(a){return["(?:",$CLJS.p.h($CLJS.P.g($CLJS.p,a)),")?"].join("")};Uda=new $CLJS.M("metabase.lib.schema.literal","temporal","metabase.lib.schema.literal/temporal",1227892305);
Vda=new $CLJS.M("metabase.lib.schema.literal","non-integer-real","metabase.lib.schema.literal/non-integer-real",1396696695);$CLJS.wK=new $CLJS.M("metabase.lib.schema.literal","string.year","metabase.lib.schema.literal/string.year",-936788792);Wda=new $CLJS.M("metabase.lib.schema.literal","string","metabase.lib.schema.literal/string",-1434211409);$CLJS.xK=new $CLJS.M("metabase.lib.schema.literal","time","metabase.lib.schema.literal/time",1928421901);
$CLJS.yK=new $CLJS.M("metabase.lib.schema.literal","string.zone-offset","metabase.lib.schema.literal/string.zone-offset",-437074263);zK=new $CLJS.M("metabase.lib.schema.literal","string.time","metabase.lib.schema.literal/string.time",1501090167);AK=new $CLJS.M("metabase.lib.schema.literal","string.date","metabase.lib.schema.literal/string.date",1716358402);BK=new $CLJS.M("metabase.lib.schema.literal","string.datetime","metabase.lib.schema.literal/string.datetime",-1597850940);
$CLJS.CK=new $CLJS.M("metabase.lib.schema.literal","date","metabase.lib.schema.literal/date",-906463835);DK=new $CLJS.M("metabase.lib.schema.literal","value.options","metabase.lib.schema.literal/value.options",-1918568845);Xda=new $CLJS.M("metabase.lib.schema.literal","integer","metabase.lib.schema.literal/integer",-1120986283);$CLJS.EK=new $CLJS.M("metabase.lib.schema.literal","string.year-month","metabase.lib.schema.literal/string.year-month",-1014149016);
FK=new $CLJS.M("metabase.lib.schema.literal","boolean","metabase.lib.schema.literal/boolean",1793502815);$CLJS.GK=new $CLJS.M("metabase.lib.schema.literal","datetime","metabase.lib.schema.literal/datetime",985830761);$CLJS.UG.m(null,$CLJS.HD,function(){return $CLJS.qj});$CLJS.Y(FK,$CLJS.ut);$CLJS.UG.m(null,$CLJS.LD,function(){return $CLJS.Aj});$CLJS.Y(FK,$CLJS.ut);$CLJS.Y(Xda,$CLJS.qt);$CLJS.UG.m(null,$CLJS.wD,function(){return $CLJS.mj});$CLJS.Y(Vda,$CLJS.pt);$CLJS.UG.m(null,$CLJS.uD,function(){return $CLJS.$F});$CLJS.Y(Wda,$CLJS.Oj);
var Yda=[":\\d{2}",vK($CLJS.G(["\\.\\d{1,6}"]))].join(""),HK=["\\d{2}:\\d{2}",vK($CLJS.G([Yda]))].join(""),IK=["\\d{4}-\\d{2}-\\d{2}T",HK].join(""),JK=["(?:Z|(?:[+-]",HK,"))"].join(""),Zda=$CLJS.hh(["(?:Z|(?:[+-]",HK,"))"].join("")),$da=$CLJS.hh("^\\d{4}-\\d{2}-\\d{2}$"),aea=$CLJS.hh(["^",HK,"$"].join("")),bea=$CLJS.hh(["^",HK,JK,"$"].join("")),cea=$CLJS.hh(["^",IK,"$"].join("")),dea=$CLJS.hh(["^",IK,JK,"$"].join(""));
$CLJS.Y(AK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.et,new $CLJS.h(null,1,[$CLJS.su,"date string literal"],null),$da],null));$CLJS.Y($CLJS.yK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.et,new $CLJS.h(null,1,[$CLJS.su,"timezone offset string literal"],null),Zda],null));
$CLJS.Y(zK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.et,new $CLJS.h(null,1,[$CLJS.su,"local time string literal"],null),aea],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.et,new $CLJS.h(null,1,[$CLJS.su,"offset time string literal"],null),bea],null)],null));
$CLJS.Y(BK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.et,new $CLJS.h(null,1,[$CLJS.su,"local date time string literal"],null),cea],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.et,new $CLJS.h(null,1,[$CLJS.su,"offset date time string literal"],null),dea],null)],null));
$CLJS.UG.m(null,$CLJS.vD,function(a){return $CLJS.n($CLJS.yH?$CLJS.yH(BK,a):uK.call(null,BK,a))?new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.Sj,null,$CLJS.DF,null],null),null):$CLJS.n($CLJS.yH?$CLJS.yH(AK,a):uK.call(null,AK,a))?new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.eF,null,$CLJS.Sj,null],null),null):$CLJS.n($CLJS.yH?$CLJS.yH(zK,a):uK.call(null,zK,a))?new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.cG,null,$CLJS.Sj,null],null),null):$CLJS.Sj});$CLJS.Y($CLJS.CK,AK);$CLJS.Y($CLJS.xK,zK);
$CLJS.Y($CLJS.GK,BK);$CLJS.Y(Uda,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.dt,$CLJS.CK,$CLJS.xK,$CLJS.GK],null));$CLJS.KK=$CLJS.hh("^\\d{4}-\\d{2}$");$CLJS.Y($CLJS.EK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.et,new $CLJS.h(null,1,[$CLJS.su,"year-month string literal"],null),$CLJS.KK],null));$CLJS.LK=$CLJS.hh("^\\d{4}$");$CLJS.Y($CLJS.wK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.et,new $CLJS.h(null,1,[$CLJS.su,"year string literal"],null),$CLJS.LK],null));
$CLJS.Y(DK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ru,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.GG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xi,$CLJS.JG],null)],null)],null));$CLJS.RI.g($CLJS.tj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.gt,new $CLJS.h(null,1,[$CLJS.su,"Value :value clause"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,$CLJS.tj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,DK],null),$CLJS.ol],null));