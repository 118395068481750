var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var DJ,EJ,oda,GJ,pda,qda,rda,HJ,FJ;$CLJS.BJ=function(a,b){return $CLJS.ec($CLJS.ab(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.Zf.j(c,e,$CLJS.$d.g($CLJS.J.j(c,e,$CLJS.tf),d))},$CLJS.cc($CLJS.N),b))};$CLJS.CJ=function(a,b){return $CLJS.ab(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Rc(d):null},null,b)};
DJ=function(a,b){$CLJS.H(a,0,null);$CLJS.H(a,1,null);$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);b=$CLJS.EE(b,$CLJS.eF)?$CLJS.nJ:$CLJS.EE(b,$CLJS.cG)?$CLJS.iJ:$CLJS.EE(b,$CLJS.DF)?$CLJS.kJ:null;return $CLJS.n(b)?$CLJS.yH(b,a):!0};
EJ=function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);a=$CLJS.BJ(function(d){return $CLJS.EE($CLJS.VG(d),$CLJS.pF)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.zk.g($CLJS.E(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.E(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.VG($CLJS.B(a));return $CLJS.Je(function(d){$CLJS.H(d,0,null);$CLJS.H(d,1,null);$CLJS.H(d,2,null);
var e=$CLJS.H(d,3,null);return $CLJS.n(DJ(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
oda=function(a){return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.su,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Rs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.GG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ps,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,FJ],null)],
null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cH],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ps,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,FJ],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.at,new $CLJS.h(null,1,[$CLJS.uu,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.tj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(EJ(b))].join("")}],null),$CLJS.Ke(EJ)],null)],null)};
GJ=function(a){var b=$CLJS.R,c=oda(a);a=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Rs,new $CLJS.h(null,1,[$CLJS.su,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Es,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.GG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ps,new $CLJS.h(null,1,[$CLJS.Jn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null)],null)],null)],null);
return new $CLJS.Q(null,3,5,b,[$CLJS.dt,c,a],null)};pda=function(a){return $CLJS.Od($CLJS.BH,$CLJS.Ze.g(function(b){var c=$CLJS.VG(b),d=$CLJS.EE(c,$CLJS.gH);b=d?$CLJS.yH($CLJS.uI,b):d;return $CLJS.n(b)?$CLJS.Ej:c},a))};qda=function(a){a=$CLJS.CJ(function(b){return!$CLJS.EE(b,$CLJS.pF)},$CLJS.Ze.g($CLJS.VG,a));return $CLJS.EE(a,$CLJS.gH)?$CLJS.Lj:a};rda=function(a){return $CLJS.n($CLJS.Je(function(b){return $CLJS.EE($CLJS.VG(b),$CLJS.pF)},a))?qda(a):pda(a)};
HJ=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.IJ=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);FJ=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.Y(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Us,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aH,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cH],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Hs,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,FJ],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.at,new $CLJS.h(null,1,[$CLJS.su,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);var c=$CLJS.VG(a);return $CLJS.Ie(function(d){return DJ(d,c)},b)}],null)],null));
$CLJS.Y(HJ,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ps,new $CLJS.h(null,1,[$CLJS.Jn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null)],null)],null));$CLJS.RI.g($CLJS.Hs,GJ($CLJS.Hs));$CLJS.RI.g($CLJS.iu,GJ($CLJS.iu));$CLJS.IH($CLJS.Is,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pt,HJ],null)]));$CLJS.IH($CLJS.MI,$CLJS.G([$CLJS.iu,$CLJS.$F,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pt,HJ],null)]));
for(var JJ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Hs,$CLJS.iu,$CLJS.Is],null)),KJ=null,LJ=0,MJ=0;;)if(MJ<LJ){var sda=KJ.X(null,MJ);$CLJS.zH(sda,$CLJS.IJ);MJ+=1}else{var NJ=$CLJS.z(JJ);if(NJ){var OJ=NJ;if($CLJS.zd(OJ)){var PJ=$CLJS.jc(OJ),tda=$CLJS.lc(OJ),uda=PJ,vda=$CLJS.E(PJ);JJ=tda;KJ=uda;LJ=vda}else{var wda=$CLJS.B(OJ);$CLJS.zH(wda,$CLJS.IJ);JJ=$CLJS.C(OJ);KJ=null;LJ=0}MJ=0}else break}$CLJS.UG.m(null,$CLJS.IJ,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);return rda(a)});
$CLJS.GH($CLJS.QH,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null)],null)]));$CLJS.zH($CLJS.QH,$CLJS.lH);
for(var QJ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OI,$CLJS.JH,$CLJS.dI],null)),RJ=null,SJ=0,TJ=0;;)if(TJ<SJ){var xda=RJ.X(null,TJ);$CLJS.GH(xda,$CLJS.G([$CLJS.iu,$CLJS.$F,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null)],null)]));TJ+=1}else{var UJ=$CLJS.z(QJ);if(UJ){var VJ=UJ;if($CLJS.zd(VJ)){var WJ=$CLJS.jc(VJ),yda=$CLJS.lc(VJ),zda=WJ,Ada=$CLJS.E(WJ);QJ=yda;RJ=zda;SJ=Ada}else{var Bda=$CLJS.B(VJ);$CLJS.GH(Bda,$CLJS.G([$CLJS.iu,$CLJS.$F,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null)],null)]));QJ=$CLJS.C(VJ);RJ=null;SJ=0}TJ=0}else break}
for(var XJ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MH,$CLJS.oI,$CLJS.vI],null)),YJ=null,ZJ=0,$J=0;;)if($J<ZJ){var Cda=YJ.X(null,$J);$CLJS.GH(Cda,$CLJS.G([$CLJS.iu,$CLJS.mj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null)],null)]));$J+=1}else{var aK=$CLJS.z(XJ);if(aK){var bK=aK;if($CLJS.zd(bK)){var cK=$CLJS.jc(bK),Dda=$CLJS.lc(bK),Eda=cK,Fda=$CLJS.E(cK);XJ=Dda;YJ=Eda;ZJ=Fda}else{var Gda=$CLJS.B(bK);$CLJS.GH(Gda,$CLJS.G([$CLJS.iu,$CLJS.mj,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null)],null)]));XJ=$CLJS.C(bK);YJ=null;ZJ=0}$J=0}else break}$CLJS.GH($CLJS.NH,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null)],null)]));
$CLJS.UG.m(null,$CLJS.NH,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.EE($CLJS.VG(b),$CLJS.mj)&&$CLJS.EE($CLJS.VG(a),$CLJS.mj)?$CLJS.mj:$CLJS.$F});