var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.core.js");require("./metabase.lib.equality.js");require("./metabase.lib.join.js");require("./metabase.lib.js.metadata.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.order_by.js");require("./metabase.lib.stage.js");require("./metabase.lib.util.js");require("./metabase.mbql.js.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var Psa=function(a){return $CLJS.ec($CLJS.ab(function(b,c){return $CLJS.Zf.j(b,c,$CLJS.J.j(b,c,0)+1)},$CLJS.cc($CLJS.N),a))},Qsa=function(a,b,c,d){var e=$CLJS.Wf.j($CLJS.N,$CLJS.Sl(function(f,k){return new $CLJS.Q(null,2,5,$CLJS.R,[k,f],null)}),d);return function l(k){return new $CLJS.le(null,function(){for(;;){var m=$CLJS.z(k);if(m){if($CLJS.zd(m)){var t=$CLJS.jc(m),u=$CLJS.E(t),v=$CLJS.oe(u);a:for(var x=0;;)if(x<u){var A=$CLJS.kd(t,x);A=$CLJS.n3.v(a,b,A,d);A=$CLJS.J.j(e,A,-1);v.add(A);
x+=1}else{t=!0;break a}return t?$CLJS.re($CLJS.te(v),l($CLJS.lc(m))):$CLJS.re($CLJS.te(v),null)}v=$CLJS.B(m);v=$CLJS.n3.v(a,b,v,d);return $CLJS.ce($CLJS.J.j(e,v,-1),l($CLJS.Kc(m)))}return null}},null,null)}(c)},D9=function(a,b){return new $CLJS.Bh(function(){try{return $CLJS.z9.g(a,b)}catch(e){if(e instanceof Error){var c=e,d=$CLJS.XC($CLJS.SA);$CLJS.n($CLJS.WC("metabase.lib.js.metadata",d))&&(c instanceof Error?$CLJS.VC("metabase.lib.js.metadata",d,$CLJS.qY("Error parsing %s objects: %s",$CLJS.G([a,
$CLJS.pX(c)])),c):$CLJS.VC("metabase.lib.js.metadata",d,$CLJS.qY(c,$CLJS.G(["Error parsing %s objects: %s",a,$CLJS.pX(c)])),null));return null}throw e;}})},Rsa=function(a,b){return function e(d){return new $CLJS.le(null,function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.zd(f)){var k=$CLJS.jc(f),l=$CLJS.E(k),m=$CLJS.oe(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.kd(k,x);$CLJS.H(A,0,null);var D=$CLJS.H(A,1,null),I=function(){var K=D;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=
I;return $CLJS.n(K)?$CLJS.F.g($CLJS.$M.h(I),b):K}())&&m.add(I);x+=1}else return!0}()?$CLJS.re($CLJS.te(m),e($CLJS.lc(f))):$CLJS.re($CLJS.te(m),null)}var t=$CLJS.B(f);$CLJS.H(t,0,null);var u=$CLJS.H(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.F.g($CLJS.$M.h(v),b):x}()))return $CLJS.ce(v,e($CLJS.Kc(f)));f=$CLJS.Kc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.$G.h(a);return null==d?null:$CLJS.q(d)}())},
Ssa=function(a,b){return function e(d){return new $CLJS.le(null,function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.zd(f)){var k=$CLJS.jc(f),l=$CLJS.E(k),m=$CLJS.oe(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.kd(k,x);$CLJS.H(A,0,null);var D=$CLJS.H(A,1,null),I=function(){var K=D;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=I;return $CLJS.n(K)?$CLJS.F.g($CLJS.$M.h(I),b):K}())&&m.add(I);x+=1}else return!0}()?$CLJS.re($CLJS.te(m),e($CLJS.lc(f))):$CLJS.re($CLJS.te(m),null)}var t=
$CLJS.B(f);$CLJS.H(t,0,null);var u=$CLJS.H(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.F.g($CLJS.$M.h(v),b):x}()))return $CLJS.ce(v,e($CLJS.Kc(f)));f=$CLJS.Kc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.u9.h(a);return null==d?null:$CLJS.q(d)}())},Tsa=function(a,b){return function e(d){return new $CLJS.le(null,function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.zd(f)){var k=$CLJS.jc(f),l=$CLJS.E(k),m=
$CLJS.oe(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.kd(k,x);$CLJS.H(A,0,null);var D=$CLJS.H(A,1,null),I=function(){var K=D;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=I;return $CLJS.n(K)?$CLJS.F.g($CLJS.$M.h(I),b):K}())&&m.add(I);x+=1}else return!0}()?$CLJS.re($CLJS.te(m),e($CLJS.lc(f))):$CLJS.re($CLJS.te(m),null)}var t=$CLJS.B(f);$CLJS.H(t,0,null);var u=$CLJS.H(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?
$CLJS.F.g($CLJS.$M.h(v),b):x}()))return $CLJS.ce(v,e($CLJS.Kc(f)));f=$CLJS.Kc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.v9.h(a);return null==d?null:$CLJS.q(d)}())},E9=function(a,b,c,d){this.ag=a;this.Ci=b;this.rc=c;this.ei=d;this.C=393216;this.I=0},F9=function(a){return $CLJS.Cu($CLJS.cC(a),function(b){return $CLJS.Ek.j($CLJS.PC(b,$CLJS.rh),$CLJS.cj,$CLJS.rh)})},G9=function(a,b){if($CLJS.n0(b))a=b;else{var c=new $CLJS.h(null,6,[$CLJS.y9,D9($CLJS.RR,b),$CLJS.w9,D9($CLJS.HY,
b),$CLJS.$G,D9($CLJS.qI,b),$CLJS.t9,D9($CLJS.sP,b),$CLJS.u9,D9($CLJS.lI,b),$CLJS.v9,D9($CLJS.OH,b)],null),d=$CLJS.XC($CLJS.RA);$CLJS.n($CLJS.WC("metabase.lib.js.metadata",d))&&$CLJS.VC("metabase.lib.js.metadata",d,$CLJS.dy.l($CLJS.G(["Created metadata provider for metadata"])),null);a=new E9(a,b,c,$CLJS.N)}return a},H9=function(a,b){return $CLJS.MY($CLJS.MY((0,$CLJS.v_)($CLJS.C9($CLJS.cC(a))),$CLJS.gS,function(c){return $CLJS.Tk.l($CLJS.G([new $CLJS.h(null,1,[$CLJS.DS,$CLJS.N],null),c]))}),$CLJS.EO,
function(c){var d=function(){var e=$CLJS.$G.h(c);return $CLJS.n(e)?e:function l(k){return new $CLJS.le(null,function(){for(;;){var m=$CLJS.z(k);if(m){if($CLJS.zd(m)){var t=$CLJS.jc(m),u=$CLJS.E(t),v=$CLJS.oe(u);a:for(var x=0;;)if(x<u){var A=$CLJS.kd(t,x);v.add(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qI,A,null],null));x+=1}else{t=!0;break a}return t?$CLJS.re($CLJS.te(v),l($CLJS.lc(m))):$CLJS.re($CLJS.te(v),null)}v=$CLJS.B(m);return $CLJS.ce(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qI,v,null],null),l($CLJS.Kc(m)))}return null}},
null,null)}(b)}();return $CLJS.S.j(c,$CLJS.$G,Psa(d))})},I9=function(a){return $CLJS.Q_.h($CLJS.Ek.j($CLJS.bC(a,$CLJS.G([$CLJS.ri,!0])),0,$CLJS.rh))},Usa=function(a){var b=$CLJS.Ua(a)?a:null;return $CLJS.n(b)?(a=$CLJS.B9.h?$CLJS.B9.h(b):$CLJS.B9.call(null,b),$CLJS.F.g($CLJS.PM.h(a),$CLJS.hN)?(b=I9(b.field_ref),$CLJS.S.j(a,$CLJS.h2,$CLJS.id(b))):a):a},J9=function(a){return function(b){return new $CLJS.h(null,2,[$CLJS.aI,(a.h?a.h(b):a.call(null,b)).name,$CLJS.tj,b.value],null)}};$CLJS.g=E9.prototype;
$CLJS.g.P=function(a,b){return new E9(this.ag,this.Ci,this.rc,b)};$CLJS.g.O=function(){return this.ei};$CLJS.g.fi=$CLJS.r;$CLJS.g.qg=function(a,b){a=this.rc;a=null==a?null:$CLJS.u9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.rg=function(a,b){return Ssa(this.rc,b)};$CLJS.g.ug=function(a,b){a=this.rc;a=null==a?null:$CLJS.w9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};
$CLJS.g.pg=function(a,b){return Rsa(this.rc,b)};$CLJS.g.og=function(a,b){a=this.rc;a=null==a?null:$CLJS.$G.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.tg=function(a,b){return Tsa(this.rc,b)};$CLJS.g.mg=function(a,b){a=this.rc;a=null==a?null:$CLJS.t9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};
$CLJS.g.sg=function(a,b){a=this.rc;a=null==a?null:$CLJS.v9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.ng=function(){var a=this.rc,b=this.ag;a=null==a?null:$CLJS.y9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};var K9=function K9(a){switch(arguments.length){case 1:return K9.h(arguments[0]);case 2:return K9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.extract_template_tags",K9);K9.h=function(a){return K9.g(a,$CLJS.N)};K9.g=function(a,b){return $CLJS.Wk($CLJS.Qra.l($CLJS.G([a,F9(b)])))};K9.A=2;
var L9=function L9(a){return $CLJS.ke(a)?[$CLJS.ge(a),"/",$CLJS.Zg(a)].join(""):$CLJS.wd(a)?$CLJS.Cu(a,L9):$CLJS.vd(a)?$CLJS.Ze.g(L9,a):a},M9=function M9(a){return $CLJS.wd(a)?$CLJS.Cu($CLJS.PC(a,$CLJS.Fba),M9):$CLJS.vd(a)?$CLJS.Ze.g(M9,a):a},N9=function N9(a){switch(arguments.length){case 1:return N9.h(arguments[0]);case 2:return N9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.order_by_clause",N9);
N9.h=function(a){return N9.g(a,$CLJS.nI)};N9.g=function(a,b){return $CLJS.Tra.l($CLJS.G([$CLJS.g9.l($CLJS.G([$CLJS.bC(a,$CLJS.G([$CLJS.ri,!0]))])),$CLJS.rh.h(b)]))};N9.A=2;var O9=function O9(a){switch(arguments.length){case 2:return O9.g(arguments[0],arguments[1]);case 3:return O9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.available_binning_strategies",O9);
O9.g=function(a,b){return $CLJS.ue($CLJS.f9.l($CLJS.G([a,b])))};O9.j=function(a,b,c){return $CLJS.ue($CLJS.f9.l($CLJS.G([a,b,c])))};O9.A=3;var P9=function P9(a){switch(arguments.length){case 2:return P9.g(arguments[0],arguments[1]);case 3:return P9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.available_temporal_buckets",P9);P9.g=function(a,b){return $CLJS.ue($CLJS.i9.l($CLJS.G([a,b])))};
P9.j=function(a,b,c){return $CLJS.ue($CLJS.i9.l($CLJS.G([a,b,c])))};P9.A=3;var Q9=function Q9(a){switch(arguments.length){case 2:return Q9.g(arguments[0],arguments[1]);case 3:return Q9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.query_EQ_",Q9);Q9.g=function(a,b){return Q9.j(a,b,null)};Q9.j=function(a,b,c){a=H9(a,c);b=H9(b,c);return $CLJS.F.g(a,b)};Q9.A=3;
var R9=function R9(a){switch(arguments.length){case 1:return R9.h(arguments[0]);case 2:return R9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.aggregation_clause",R9);R9.h=function(a){return $CLJS.e9.l($CLJS.G([a]))};R9.g=function(a,b){return $CLJS.e9.l($CLJS.G([a,b]))};R9.A=2;
var S9=function S9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return S9.l(arguments[0],arguments[1],2<c.length?new $CLJS.y(c.slice(2),0,null):null)};$CLJS.za("metabase.lib.js.filter_clause",S9);S9.l=function(a,b,c){return $CLJS.P.v($CLJS.hra,a,b,c)};S9.A=2;S9.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return this.l(b,a,c)};
var T9=function T9(a){switch(arguments.length){case 2:return T9.g(arguments[0],arguments[1]);case 3:return T9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.expressionable_columns",T9);T9.g=function(a,b){return T9.g(a,b)};T9.j=function(a,b,c){return $CLJS.ue($CLJS.Uqa.l($CLJS.G([a,b,c])))};T9.A=3;
var U9=function U9(a){switch(arguments.length){case 3:return U9.j(arguments[0],arguments[1],arguments[2]);case 4:return U9.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.with_different_database",U9);U9.j=function(a,b,c){return U9.v(a,b,c,null)};U9.v=function(a,b,c,d){return $CLJS.Ora.l($CLJS.G([a,G9(b,c),$CLJS.bC(d,$CLJS.G([$CLJS.ri,!0]))]))};U9.A=4;
var Vsa=J9(function(a){return a.col}),Wsa=J9(function(a){return a.column}),V9=function V9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return V9.l(arguments[0],arguments[1],arguments[2],3<c.length?new $CLJS.y(c.slice(3),0,null):null)};$CLJS.za("metabase.lib.js.drill_thru",V9);V9.l=function(a,b,c,d){return $CLJS.P.N($CLJS.Oqa,a,b,c,d)};V9.A=3;
V9.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);d=$CLJS.C(d);return this.l(b,a,c,d)};
module.exports={extract_template_tags:K9,engine:function(a){return $CLJS.Zg($CLJS.Jra.l($CLJS.G([a])))},field_id:function(a){return $CLJS.Yqa.l($CLJS.G([a]))},filter_operator:function(a,b,c){return $CLJS.ira.l($CLJS.G([a,b,c]))},binning:function(a){return $CLJS.Eqa.l($CLJS.G([a]))},query:function(a,b,c){c=$CLJS.bC(c,$CLJS.G([$CLJS.ri,!0]));c=$CLJS.n($CLJS.cj.h(c))?c:$CLJS.S.j(c,$CLJS.cj,$CLJS.EO);c=(0,$CLJS.v_)(c);c=$CLJS.Q_.h(c);var d=$CLJS.XC($CLJS.RA);$CLJS.n($CLJS.WC("metabase.lib.js",d))&&$CLJS.VC("metabase.lib.js",
d,$CLJS.qY("query map: %s",$CLJS.G([$CLJS.Eh.l($CLJS.G([c]))])),null);return $CLJS.Vra.l($CLJS.G([G9(a,b),c]))},columns_group_columns:function(a){return $CLJS.ue($CLJS.Jqa.l($CLJS.G([a])))},join_fields:function(a){a=$CLJS.tra.l($CLJS.G([a]));return a instanceof $CLJS.M?$CLJS.kD(a):$CLJS.ue(a)},aggregate:function(a,b,c){return $CLJS.zqa.l($CLJS.G([a,b,$CLJS.bC(c,$CLJS.G([$CLJS.ri,!0]))]))},join_conditions:function(a){return $CLJS.ue($CLJS.sra.l($CLJS.G([a])))},query_EQ_:Q9,temporal_bucket:function(a){return $CLJS.gsa.l($CLJS.G([a]))},
available_drill_thrus:function(a,b,c,d,e,f){return $CLJS.ue($CLJS.Nqa.l($CLJS.G([a,b,$CLJS.Tk.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.Ki,$CLJS.B9.h?$CLJS.B9.h(c):$CLJS.B9.call(null,c),$CLJS.tj,void 0===d?null:null==d?$CLJS.j7:d],null),$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.v6,$CLJS.Dk.g(Vsa,e)],null):null,$CLJS.n($CLJS.De(f))?new $CLJS.h(null,1,[$CLJS.GY,$CLJS.Dk.g(Wsa,f)],null):null]))])))},remove_clause:function(a,b,c){return $CLJS.Xra.l($CLJS.G([a,b,$CLJS.g9.l($CLJS.G([$CLJS.bC(c,$CLJS.G([$CLJS.ri,
!0]))]))]))},suggested_join_condition:function(a,b,c){return $CLJS.yra.l($CLJS.G([a,b,c]))},replace_clause:function(a,b,c,d){return $CLJS.$ra.l($CLJS.G([a,b,$CLJS.g9.l($CLJS.G([$CLJS.bC(c,$CLJS.G([$CLJS.ri,!0]))])),$CLJS.g9.l($CLJS.G([$CLJS.bC(d,$CLJS.G([$CLJS.ri,!0]))]))]))},drop_stage:function(a){return $CLJS.csa.l($CLJS.G([a]))},join_lhs_display_name:function(a,b,c,d){return $CLJS.ura.l($CLJS.G([a,b,c,d]))},add_field:function(a,b,c){return $CLJS.Xqa.l($CLJS.G([a,b,c]))},pivot_types:function(a){return $CLJS.ue($CLJS.Qqa.l($CLJS.G([a])))},
native_extras:function(a){return $CLJS.Wk($CLJS.Mra.l($CLJS.G([a])))},available_temporal_buckets:P9,with_binning:function(a,b){return $CLJS.Fqa.l($CLJS.G([a,b]))},append_stage:function(a){return $CLJS.bsa.l($CLJS.G([a]))},join:function(a,b,c){return $CLJS.mra.l($CLJS.G([a,b,c]))},aggregations:function(a,b){return $CLJS.ue($CLJS.Bqa.l($CLJS.G([a,b])))},drill_thru:V9,raw_native_query:function(a){return $CLJS.Gra.l($CLJS.G([a]))},filterable_column_operators:function(a){return $CLJS.ue($CLJS.gra.l($CLJS.G([a])))},
with_temporal_bucket:function(a,b){return $CLJS.hsa.l($CLJS.G([a,b]))},with_join_conditions:function(a,b){return $CLJS.Bra.l($CLJS.G([a,$CLJS.bC(b,$CLJS.G([$CLJS.ri,!0]))]))},available_metrics:function(a){return $CLJS.ue($CLJS.Era.l($CLJS.G([a])))},with_different_database:U9,with_fields:function(a,b,c){return $CLJS.cra.l($CLJS.G([a,b,c]))},is_column_metadata:function(a){return $CLJS.wd(a)&&$CLJS.F.g($CLJS.NM,$CLJS.lv.h(a))},join_clause:function(a,b){return $CLJS.nra.l($CLJS.G([a,b]))},aggregation_clause:R9,
external_op:function(a){a=$CLJS.Lqa.l($CLJS.G([a]));var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.EG);var c=$CLJS.J.g(b,$CLJS.Wi);b=$CLJS.J.g(b,$CLJS.Pt);return{operator:a,options:$CLJS.Wk(c),args:$CLJS.ue(b)}},with_join_fields:function(a,b){return $CLJS.zra.l($CLJS.G([a,"string"===typeof b?$CLJS.rh.h(b):b]))},find_visible_column_for_legacy_ref:function(a,b,c){return $CLJS.ara.l($CLJS.G([a,b,c]))},visible_columns:function(a,b){var c=$CLJS.BY(a,b),d=$CLJS.u2.j(a,b,c);c=$CLJS.s2.j(a,b,c);return $CLJS.ue($CLJS.v4(a,
b,d,c))},available_binning_strategies:O9,find_column_indexes_from_legacy_refs:function(a,b,c,d){var e=$CLJS.fI.h($CLJS.BY(a,b)),f=$CLJS.Wf.j($CLJS.N,$CLJS.Sl(function(u,v){$CLJS.H(v,0,null);v=$CLJS.H(v,1,null);v=$CLJS.O(v);v=$CLJS.J.g(v,$CLJS.HG);return new $CLJS.Q(null,2,5,$CLJS.R,[u,v],null)}),e);e=$CLJS.Wf.j($CLJS.N,$CLJS.Sl(function(u,v){$CLJS.H(v,0,null);v=$CLJS.H(v,1,null);v=$CLJS.O(v);v=$CLJS.J.g(v,$CLJS.HG);return new $CLJS.Q(null,2,5,$CLJS.R,[v,u],null)}),e);var k=$CLJS.P_,l=$CLJS.O_;$CLJS.P_=
f;$CLJS.O_=e;try{var m=$CLJS.Dk.g(Usa,c),t=$CLJS.Ze.g(I9,d);return $CLJS.ue(Qsa(a,b,t,m))}finally{$CLJS.O_=l,$CLJS.P_=k}},expression:function(a,b,c,d){return $CLJS.Sqa.l($CLJS.G([a,b,c,d]))},describe_relative_datetime:function(a,b){a="string"===typeof a?$CLJS.rh.h(a):a;b="string"===typeof b?$CLJS.rh.h(b):b;return $CLJS.fsa.l($CLJS.G([a,b]))},order_bys:function(a,b){return $CLJS.ue($CLJS.Ura.l($CLJS.G([a,b])))},display_info:function(a,b,c){return $CLJS.Wk.l(M9($CLJS.Cra.l($CLJS.G([$CLJS.Q4(a,b),b,
c]))),$CLJS.G([$CLJS.hk,$CLJS.kD]))},fieldable_columns:function(a,b){return $CLJS.ue($CLJS.Zqa.l($CLJS.G([a,b])))},template_tags:function(a){return $CLJS.Wk($CLJS.Ira.l($CLJS.G([a])))},selected_aggregation_operators:function(a,b){return $CLJS.ue($CLJS.Dqa.l($CLJS.G([$CLJS.z(a),b])))},order_by_clause:N9,filterable_columns:function(a,b){return $CLJS.ue($CLJS.fra.l($CLJS.G([a,b])))},join_condition_rhs_columns:function(a,b,c,d,e){return $CLJS.ue($CLJS.qra.l($CLJS.G([a,b,c,d,e])))},join_condition_update_temporal_bucketing:function(a,
b,c,d){return $CLJS.rra.l($CLJS.G([a,b,c,d]))},filters:function(a,b){return $CLJS.ue($CLJS.era.l($CLJS.G([a,b])))},join_condition_lhs_columns:function(a,b,c,d,e){return $CLJS.ue($CLJS.ora.l($CLJS.G([a,b,c,d,e])))},table_or_card_metadata:function(a,b){return $CLJS.J2(a,b)},orderable_columns:function(a,b){return $CLJS.ue($CLJS.o7.g(a,b))},find_filter_for_legacy_filter:function(a,b,c){return $CLJS.jra.l($CLJS.G([a,b,$CLJS.bC(c,$CLJS.G([$CLJS.ri,!0]))]))},suggestedName:function(a){return $CLJS.Dra.l($CLJS.G([a]))},
find_column_for_legacy_ref:function(a,b,c,d){return $CLJS.Rqa.l($CLJS.G([a,b,c,d]))},has_write_permission:function(a){return $CLJS.Pra.l($CLJS.G([a]))},metadataProvider:G9,filter_clause:S9,fields:function(a,b){return $CLJS.ue($CLJS.$qa.l($CLJS.G([a,b])))},available_aggregation_operators:function(a,b){return $CLJS.ue($CLJS.Cqa.l($CLJS.G([a,b])))},breakouts:function(a,b){return $CLJS.ue($CLJS.Iqa.l($CLJS.G([a,b])))},joinable_columns:function(a,b,c){return $CLJS.ue($CLJS.wra.l($CLJS.G([a,b,c])))},group_columns:function(a){return $CLJS.ue($CLJS.Kqa.l($CLJS.G([a])))},
describe_temporal_unit:function(a,b){b="string"===typeof b?$CLJS.rh.h(b):b;return $CLJS.dsa.l($CLJS.G([a,b]))},joins:function(a,b){return $CLJS.ue($CLJS.xra.l($CLJS.G([a,b])))},native_query:function(a,b,c){return $CLJS.Fra.l($CLJS.G([G9(a,b),c]))},available_join_strategies:function(a,b){return $CLJS.ue($CLJS.lra.l($CLJS.G([a,b])))},legacy_query:function(a){return $CLJS.Wk.l(L9($CLJS.G_.h(a)),$CLJS.G([$CLJS.hk,$CLJS.kD]))},expression_clause:function(a,b,c){return $CLJS.Vqa.l($CLJS.G([$CLJS.rh.h(a),
c,$CLJS.bC(b,$CLJS.G([$CLJS.ri,!0]))]))},with_native_extras:function(a,b){return $CLJS.Nra.l($CLJS.G([a,$CLJS.bC(b,$CLJS.G([$CLJS.ri,!0]))]))},join_strategy:function(a){return $CLJS.vra.l($CLJS.G([a]))},picker_info:function(a,b){var c=$CLJS.lv.h(b);switch(c instanceof $CLJS.M?c.T:null){case "metadata/table":return{databaseId:$CLJS.RR.h(a),tableId:$CLJS.Oi.h(b)};case "metadata/card":return{databaseId:$CLJS.RR.h(a),tableId:["card__",$CLJS.p.h($CLJS.Oi.h(b))].join(""),cardId:$CLJS.Oi.h(b),isModel:$CLJS.u0.h(b)};
default:return a=$CLJS.XC($CLJS.UA),$CLJS.n($CLJS.WC("metabase.lib.js",a))&&$CLJS.VC("metabase.lib.js",a,$CLJS.dy.l($CLJS.G(["Cannot provide picker-info for",$CLJS.lv.h(b)])),null),null}},expression_parts:function(a,b,c){a=$CLJS.Wqa.l($CLJS.G([a,b,c]));return $CLJS.rX(function(d){if($CLJS.wd(d)&&$CLJS.F.g($CLJS.z8,$CLJS.lv.h(d))){var e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.EG);var f=$CLJS.J.g(e,$CLJS.Wi);e=$CLJS.J.g(e,$CLJS.Pt);return{operator:$CLJS.Zg(d),options:$CLJS.Wk($CLJS.Ql(f,new $CLJS.Q(null,2,5,
$CLJS.R,[$CLJS.yI,$CLJS.FI],null))),args:$CLJS.ue($CLJS.Ze.g(function(k){return k instanceof $CLJS.M?$CLJS.kD(k):k},e))}}return d},a)},aggregation_operator_columns:function(a){return $CLJS.ue($CLJS.Aqa.l($CLJS.G([a])))},find_filterable_column_for_legacy_ref:function(a,b,c){return $CLJS.kra.l($CLJS.G([a,b,c]))},available_segments:function(a){return $CLJS.ue($CLJS.asa.l($CLJS.G([a])))},join_condition_operators:function(a,b,c,d){return $CLJS.ue($CLJS.pra.l($CLJS.G([a,b,c,d])))},expressionable_columns:T9,
filter:function(a,b,c){return $CLJS.dra.l($CLJS.G([a,b,$CLJS.bC(c,$CLJS.G([$CLJS.ri,!0]))]))},change_direction:function(a,b){return $CLJS.Rra.l($CLJS.G([a,b]))},pivot_columns_for_type:function(a,b){return $CLJS.Pqa.l($CLJS.G([a,b]))},legacy_field_ref:function(a){return $CLJS.Wk($CLJS.Ek.v($CLJS.G_.h($CLJS.h9.l($CLJS.G([a]))),2,$CLJS.Cu,function(b){return $CLJS.ke(b)?$CLJS.kD(b):b}))},remove_field:function(a,b,c){return $CLJS.bra.l($CLJS.G([a,b,c]))},with_template_tags:function(a,b){return $CLJS.Kra.l($CLJS.G([a,
F9(b)]))},expressions:function(a,b){return $CLJS.ue($CLJS.Tqa.l($CLJS.G([a,b])))},with_join_strategy:function(a,b){return $CLJS.Ara.l($CLJS.G([a,b]))},order_by:function(a,b,c,d){return $CLJS.Sra.l($CLJS.G([a,b,c,$CLJS.rh.h(d)]))},describe_temporal_interval:function(a,b){a="string"===typeof a?$CLJS.rh.h(a):a;b="string"===typeof b?$CLJS.rh.h(b):b;return $CLJS.esa.l($CLJS.G([a,b]))},breakout:function(a,b,c){return $CLJS.Gqa.l($CLJS.G([a,b,$CLJS.h9.l($CLJS.G([c]))]))},database_id:function(a){return $CLJS.Mqa.l($CLJS.G([a]))},
required_native_extras:function(a,b){return $CLJS.ue($CLJS.Ze.g($CLJS.kD,$CLJS.Lra.l($CLJS.G([G9(a,b)]))))},remove_join:function(a,b,c){return $CLJS.Yra.l($CLJS.G([a,b,c]))},joined_thing:function(a,b){return $CLJS.J4(a,b)},with_native_query:function(a,b){return $CLJS.Hra.l($CLJS.G([a,b]))},breakoutable_columns:function(a,b){return $CLJS.ue($CLJS.Hqa.l($CLJS.G([a,b])))},rename_join:function(a,b,c,d){return $CLJS.Zra.l($CLJS.G([a,b,c,d]))},with_different_table:function(a,b){return $CLJS.Wra.l($CLJS.G([a,
b]))}};