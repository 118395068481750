var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.breakout.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Apa,Bpa,N8,Cpa,Dpa,Epa,Fpa,Gpa,Hpa,Ipa,O8,P8,Q8,Jpa,R8;
Apa=function(a){return function(b){var c=$CLJS.Re($CLJS.Ug);return function(){function d(l,m){var t=a.h?a.h(m):a.call(null,m);if($CLJS.Gd($CLJS.q(c),t))return l;c.Cd(null,$CLJS.$d.g(c.Qb(null),t));return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);
};k.o=f;k.h=e;k.g=d;return k}()}};Bpa=function(a,b,c){return $CLJS.Wf.j($CLJS.tf,$CLJS.rG(function(d){return $CLJS.u2.v(a,b,d,new $CLJS.h(null,2,[$CLJS.d2,c,$CLJS.Z1,!1],null))}),$CLJS.AP.h($CLJS.BY(a,b)))};N8=function(a,b,c){return $CLJS.Wf.j($CLJS.tf,$CLJS.rG(function(d){return $CLJS.s2.v(a,b,d,c)}),$CLJS.AP.h($CLJS.BY(a,b)))};$CLJS.Q4=function(a,b){return $CLJS.ab(function(c,d){return $CLJS.IY.l(c,d,$CLJS.S,$CLJS.G([$CLJS.m8,$CLJS.s2.j(c,d,$CLJS.BY(c,d))]))},a,$CLJS.Lv(0,$CLJS.AY(a,b)))};
Cpa=function(a,b){a=$CLJS.BY(a,b);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.lv),d=$CLJS.J.g(a,$CLJS.KX);b=$CLJS.m8.h(a);if($CLJS.n(b))return b;var e=$CLJS.jX.h(a);if($CLJS.n(e)&&$CLJS.n(function(){var k=$CLJS.F.g(c,$CLJS.YX);return k?k:d}())){var f=function(){var k=c instanceof $CLJS.M?c.T:null;switch(k){case "mbql.stage/native":return $CLJS.M0;case "mbql.stage/mbql":return $CLJS.G0;default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}();return $CLJS.De(function(){return function m(l){return new $CLJS.le(null,
function(){for(;;){var t=$CLJS.z(l);if(t){if($CLJS.zd(t)){var u=$CLJS.jc(t),v=$CLJS.E(u),x=$CLJS.oe(v);a:for(var A=0;;)if(A<v){var D=$CLJS.kd(u,A);D=$CLJS.Tk.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.o0,$CLJS.T.h(D),$CLJS.C0,$CLJS.T.h(D)],null),D,new $CLJS.h(null,1,[$CLJS.PM,f],null)]));x.add(D);A+=1}else{u=!0;break a}return u?$CLJS.re($CLJS.te(x),m($CLJS.lc(t))):$CLJS.re($CLJS.te(x),null)}x=$CLJS.B(t);return $CLJS.ce($CLJS.Tk.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.o0,$CLJS.T.h(x),$CLJS.C0,$CLJS.T.h(x)],
null),x,new $CLJS.h(null,1,[$CLJS.PM,f],null)])),m($CLJS.Kc(t)))}return null}},null,null)}($CLJS.yY.h(e))}())}return null};
Dpa=function(a,b,c){return $CLJS.De(function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.jc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),A=t,D=$CLJS.S,I=D.l,K=x,U=$CLJS.NC($CLJS.o0,$CLJS.T)(x);x=$CLJS.M2(a,x);x=c.h?c.h(x):c.call(null,x);D=I.call(D,K,$CLJS.PM,$CLJS.bN,$CLJS.G([$CLJS.o0,U,$CLJS.C0,x]));A.add(D);v+=1}else return!0}()?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),
null)}var u=$CLJS.B(k);return $CLJS.ce($CLJS.S.l(u,$CLJS.PM,$CLJS.bN,$CLJS.G([$CLJS.o0,$CLJS.NC($CLJS.o0,$CLJS.T)(u),$CLJS.C0,function(){var v=$CLJS.M2(a,u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Kc(k)))}return null}},null,null)}($CLJS.l6(a,b))}())};
Epa=function(a,b,c){return $CLJS.De(function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.jc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),A=t,D=$CLJS.S,I=D.l,K=x,U=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);D=I.call(D,K,$CLJS.PM,$CLJS.hN,$CLJS.G([$CLJS.o0,U,$CLJS.C0,x]));A.add(D);v+=1}else return!0}()?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),null)}var u=
$CLJS.B(k);return $CLJS.ce($CLJS.S.l(u,$CLJS.PM,$CLJS.hN,$CLJS.G([$CLJS.o0,$CLJS.T.h(u),$CLJS.C0,function(){var v=$CLJS.T.h(u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Kc(k)))}return null}},null,null)}($CLJS.y5.g(a,b))}())};
Fpa=function(a,b,c){var d=$CLJS.BY(a,b);if($CLJS.n(d)){d=$CLJS.O(d);var e=$CLJS.J.g(d,$CLJS.$G);return $CLJS.De(function(){return function l(k){return new $CLJS.le(null,function(){for(;;){var m=$CLJS.z(k);if(m){if($CLJS.zd(m)){var t=$CLJS.jc(m),u=$CLJS.E(t),v=$CLJS.oe(u);return function(){for(var K=0;;)if(K<u){var U=$CLJS.kd(t,K),Z=$CLJS.H(U,0,null),da=function(){var Ha=Z;Ha=Ha instanceof $CLJS.M?Ha.T:null;switch(Ha){case "field":return $CLJS.L0;case "expression":return $CLJS.w0;default:throw Error(["No matching clause: ",
$CLJS.p.h(Ha)].join(""));}}(),xa=$CLJS.L1.j(a,b,U);$CLJS.se(v,$CLJS.S.l(xa,$CLJS.PM,da,$CLJS.G([$CLJS.o0,$CLJS.l2.j(a,b,xa),$CLJS.C0,function(){var Ha=$CLJS.M2(a,xa);return c.h?c.h(Ha):c.call(null,Ha)}()])));K+=1}else return!0}()?$CLJS.re($CLJS.te(v),l($CLJS.lc(m))):$CLJS.re($CLJS.te(v),null)}var x=$CLJS.B(m),A=$CLJS.H(x,0,null),D=function(){var K=A;K=K instanceof $CLJS.M?K.T:null;switch(K){case "field":return $CLJS.L0;case "expression":return $CLJS.w0;default:throw Error(["No matching clause: ",
$CLJS.p.h(K)].join(""));}}(),I=$CLJS.L1.j(a,b,x);return $CLJS.ce($CLJS.S.l(I,$CLJS.PM,D,$CLJS.G([$CLJS.o0,$CLJS.l2.j(a,b,I),$CLJS.C0,function(){var K=$CLJS.M2(a,I);return c.h?c.h(K):c.call(null,K)}()])),l($CLJS.Kc(m)))}return null}},null,null)}(e)}())}return null};Gpa=function(a,b,c){return $CLJS.De($CLJS.Wf.j($CLJS.tf,$CLJS.rG(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)}),new $CLJS.Q(null,2,5,$CLJS.R,[Dpa,Epa],null)))};
Hpa=function(a,b,c){var d=$CLJS.e3(a,b);return $CLJS.n(d)?$CLJS.De(function(){return function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.zd(l)){var m=$CLJS.jc(l),t=$CLJS.E(m),u=$CLJS.oe(t);return function(){for(var A=0;;)if(A<t){var D=$CLJS.kd(m,A);var I=$CLJS.NC($CLJS.C0,$CLJS.o0)(D);I=$CLJS.n(I)?I:$CLJS.l2.j(a,b,D);D=$CLJS.tk.g($CLJS.Tk.l($CLJS.G([D,new $CLJS.h(null,3,[$CLJS.PM,$CLJS.p0,$CLJS.o0,I,$CLJS.C0,c.h?c.h(I):c.call(null,I)],null),$CLJS.n($CLJS.R2.h(D))?
$CLJS.Ql(D,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.R2],null)):null])),$CLJS.o8);u.add(D);A+=1}else return!0}()?$CLJS.re($CLJS.te(u),k($CLJS.lc(l))):$CLJS.re($CLJS.te(u),null)}var v=$CLJS.B(l),x=function(){var A=$CLJS.NC($CLJS.C0,$CLJS.o0)(v);return $CLJS.n(A)?A:$CLJS.l2.j(a,b,v)}();return $CLJS.ce($CLJS.tk.g($CLJS.Tk.l($CLJS.G([v,new $CLJS.h(null,3,[$CLJS.PM,$CLJS.p0,$CLJS.o0,x,$CLJS.C0,c.h?c.h(x):c.call(null,x)],null),$CLJS.n($CLJS.R2.h(v))?$CLJS.Ql(v,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.R2],null)):null])),
$CLJS.o8),k($CLJS.Kc(l)))}return null}},null,null)}($CLJS.s2.j(a,d,$CLJS.BY(a,d)))}()):null};Ipa=function(a,b,c,d){return $CLJS.n(c)?(c=$CLJS.K1(a,c),$CLJS.n(c)?$CLJS.De($CLJS.u2.v(a,b,c,d)):null):null};
O8=function(a,b,c){return $CLJS.De(function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.jc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),A=t;var D=$CLJS.ED.h(x);var I=$CLJS.S.l,K=x,U=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);D=$CLJS.u3(I.call($CLJS.S,K,$CLJS.PM,$CLJS.w0,$CLJS.G([$CLJS.o0,U,$CLJS.C0,x])),$CLJS.xi,$CLJS.n(D)?D:$CLJS.qj);A.add(D);v+=1}else return!0}()?$CLJS.re($CLJS.te(t),
f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),null)}var u=$CLJS.B(k);return $CLJS.ce(function(){var v=$CLJS.ED.h(u),x=$CLJS.S.l,A=u,D=$CLJS.T.h(u);var I=$CLJS.T.h(u);I=c.h?c.h(I):c.call(null,I);return $CLJS.u3(x.call($CLJS.S,A,$CLJS.PM,$CLJS.w0,$CLJS.G([$CLJS.o0,D,$CLJS.C0,I])),$CLJS.xi,$CLJS.n(v)?v:$CLJS.qj)}(),f($CLJS.Kc(k)))}return null}},null,null)}($CLJS.Coa.g(a,b))}())};
P8=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.d2);return $CLJS.Dk.g(function(f){return $CLJS.tk.l(f,$CLJS.R0,$CLJS.G([$CLJS.o8,$CLJS.n8,$CLJS.ZM]))},function(){var f=Hpa(a,b,e);if($CLJS.n(f))return f;f=$CLJS.BY(a,b);f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.bR),l=$CLJS.J.g(f,$CLJS.KX),m=$CLJS.n(k)?function(){var t=$CLJS.I1(a,k);return $CLJS.u2.v(a,b,t,d)}():null;if($CLJS.n(m))return m;l=$CLJS.n(l)?Ipa(a,b,l,$CLJS.S.j(d,$CLJS.Z1,!1)):null;return $CLJS.n(l)?l:function v(u){return new $CLJS.le(null,
function(){for(;;){var x=$CLJS.z(u);if(x){if($CLJS.zd(x)){var A=$CLJS.jc(x),D=$CLJS.E(A),I=$CLJS.oe(D);return function(){for(var U=0;;)if(U<D){var Z=$CLJS.kd(A,U),da=I,xa=$CLJS.S,Ha=xa.l,mb=Z,Qb=$CLJS.T.h(Z);Z=$CLJS.T.h(Z);Z=e.h?e.h(Z):e.call(null,Z);xa=Ha.call(xa,mb,$CLJS.PM,$CLJS.M0,$CLJS.G([$CLJS.o0,Qb,$CLJS.C0,Z]));da.add(xa);U+=1}else return!0}()?$CLJS.re($CLJS.te(I),v($CLJS.lc(x))):$CLJS.re($CLJS.te(I),null)}var K=$CLJS.B(x);return $CLJS.ce($CLJS.S.l(K,$CLJS.PM,$CLJS.M0,$CLJS.G([$CLJS.o0,$CLJS.T.h(K),
$CLJS.C0,function(){var U=$CLJS.T.h(K);return e.h?e.h(U):e.call(null,U)}()])),v($CLJS.Kc(x)))}return null}},null,null)}($CLJS.yY.h($CLJS.jX.h(f)))}())};
Q8=function(a,b){var c=$CLJS.H(a,0,null);$CLJS.H(a,1,null);var d=$CLJS.H(a,2,null);switch(c instanceof $CLJS.M?c.T:null){case "field":if("number"===typeof d||"string"===typeof d)return $CLJS.F.g(d,$CLJS.Oi.h(b));throw $CLJS.ai("unknown type of :field ref in lib.stage/ref-to?",new $CLJS.h(null,2,[$CLJS.WT,a,$CLJS.Ki,b],null));case "expression":return $CLJS.F.g(d,$CLJS.T.h(b));default:throw $CLJS.ai("unknown clause in lib.stage/ref-to?",new $CLJS.h(null,2,[$CLJS.WT,a,$CLJS.Ki,b],null));}};
Jpa=function(a,b,c){var d=$CLJS.eI.h($CLJS.BY(a,b));return $CLJS.n(d)?function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){var m=l;if($CLJS.zd(m)){var t=$CLJS.jc(m),u=$CLJS.E(t),v=$CLJS.oe(u);return function(){for(var A=0;;)if(A<u){var D=$CLJS.kd(t,A);$CLJS.se(v,function(){var I=$CLJS.CJ(function(Z,da){return function(xa){return Q8(xa,da)}}(A,D,t,u,v,m,l,d,d),d);if($CLJS.n(I)){var K=$CLJS.h8(I);I=$CLJS.w4(I);I=$CLJS.O(I);I=$CLJS.J.g(I,$CLJS.ki);var U=D;K=$CLJS.n(K)?$CLJS.d6(U,
K):U;return $CLJS.n(I)?$CLJS.y3(K,I):K}return D}());A+=1}else return!0}()?$CLJS.re($CLJS.te(v),k($CLJS.lc(m))):$CLJS.re($CLJS.te(v),null)}var x=$CLJS.B(m);return $CLJS.ce(function(){var A=$CLJS.CJ(function(K){return function(U){return Q8(U,K)}}(x,m,l,d,d),d);if($CLJS.n(A)){var D=$CLJS.h8(A);A=$CLJS.w4(A);A=$CLJS.O(A);A=$CLJS.J.g(A,$CLJS.ki);var I=x;D=$CLJS.n(D)?$CLJS.d6(I,D):I;return $CLJS.n(A)?$CLJS.y3(D,A):D}return x}(),k($CLJS.Kc(m)))}return null}},null,null)}(c):c};
R8=new $CLJS.M("metabase.lib.stage","stage","metabase.lib.stage/stage",1448689281);$CLJS.ima={};$CLJS.zH($CLJS.RX,R8);$CLJS.zH($CLJS.YX,R8);$CLJS.y2.m(null,$CLJS.RX,function(a){return $CLJS.x2(a,new $CLJS.h(null,2,[$CLJS.fI,$CLJS.Me($CLJS.Dk,$CLJS.y2),$CLJS.eY,$CLJS.Me($CLJS.Dk,$CLJS.y2)],null))});$CLJS.p2.m(null,R8,function(){throw $CLJS.ai("You can't calculate a metadata map for a stage! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.t2.m(null,R8,function(a,b,c,d){d=$CLJS.O(d);c=$CLJS.J.g(d,$CLJS.d2);var e=$CLJS.J.g(d,$CLJS.Z1);a=$CLJS.Q4(a,b);var f=$CLJS.O(d);var k=$CLJS.J.g(f,$CLJS.d2),l=$CLJS.J.g(f,$CLJS.b2),m=$CLJS.J.g(f,$CLJS.i2);f=$CLJS.Ye.l(P8(a,b,f),$CLJS.n(m)?O8(a,b,k):null,$CLJS.G([$CLJS.n(l)?Bpa(a,b,k):null]));k=$CLJS.Ye.g;d=$CLJS.n(e)?(e=$CLJS.Sa($CLJS.KX.h($CLJS.BY(a,b))))?e:$CLJS.f2.h(d):e;return Jpa(a,b,k.call($CLJS.Ye,f,$CLJS.n(d)?$CLJS.N2(a,b,f,c):null))});
$CLJS.r2.m(null,R8,function(a,b,c,d){c=$CLJS.O(d);d=$CLJS.J.g(c,$CLJS.d2);var e=Cpa(a,b);if($CLJS.n(e))return e;a=$CLJS.Q4(a,b);e=Gpa(a,b,d);var f=Fpa(a,b,d);return $CLJS.n(e)?$CLJS.Wf.g(e,f):$CLJS.n(f)?($CLJS.eh(f),$CLJS.Wf.j($CLJS.tf,Apa(function(k){return $CLJS.tk.l(k,$CLJS.P0,$CLJS.G([$CLJS.PM,$CLJS.h2,$CLJS.C0]))}),$CLJS.Ye.g(f,N8(a,b,c)))):$CLJS.Ye.l(P8(a,b,new $CLJS.h(null,2,[$CLJS.Z1,!1,$CLJS.d2,d],null)),O8(a,b,d),$CLJS.G([N8(a,b,c)]))});$CLJS.j2.m(null,$CLJS.YX,function(){return $CLJS.SG("Native query")});
var S8=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bR,$CLJS.KX,$CLJS.AP],null),T8=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.fI,$CLJS.eI,$CLJS.eY,$CLJS.LT,$CLJS.hU],null);
$CLJS.j2.m(null,$CLJS.RX,function(a,b,c,d){var e=$CLJS.Q4(a,b);a=$CLJS.De(function(){var f=$CLJS.Wf.j($CLJS.N,$CLJS.Bk.g($CLJS.DE,$CLJS.Ze.h(function(l){return new $CLJS.Q(null,2,5,$CLJS.R,[l,$CLJS.Cka.j(e,b,l)],null)})),new $CLJS.Q(null,2,5,$CLJS.R,[S8,T8],null)),k=$CLJS.Du(" + ",$CLJS.Ck.g($CLJS.SC,$CLJS.Ze.g(f,S8)));f=$CLJS.Ze.g(f,T8);return $CLJS.Du(", ",$CLJS.Ck.g($CLJS.SC,$CLJS.ce(k,f)))}());if($CLJS.n(a))return a;a=$CLJS.e3(e,b);return $CLJS.n(a)?$CLJS.M1.v(e,a,$CLJS.BY(e,a),d):null});